import React from 'react';
import { isEmpty } from 'underscore';

import { displayId, displayName, addKeyProp, GridButton } from '../../../core/utils/helpers';

import Gridview from '../../../elements/Gridview';

export default function () {
    const { doctor } = this.props;
    const config = [
        { dataIndex: 'id', title: 'Doctor Ref', render: displayId },
        { dataIndex: 'first_name', title: 'Name', render: (value, record) => displayName(record) },
        { dataIndex: 'email', title: 'Email' },
        { dataIndex: 'phone_no', title: 'Phone' },
        { dataIndex: 'key', align: 'right', render: () => <GridButton href={this.handleRemoveDoctor} title="Remove" /> },
    ];

    return (
        <Gridview
            title="Assigned Doctor"
            config={config}
            data={!isEmpty(doctor) ? addKeyProp([doctor]) : []}
            pagination={false} />
    );
}
