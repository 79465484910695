import moment from 'moment';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import * as productActions from '../../../core/product/productActions';

import DOM from './updateProductStock';

const { DB_DATE_FORMAT } = require('../../../core/utils/values').default;

class UpdateProductStock extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            modalUpdateStockVisible: false,
            stock: (props?.product?.quantity || 0),
        };
    }

    componentWillMount() {
        const { actions, productId } = this.props;
        actions.getProductRequest(productId);
    }

    // Handle update stock modal visibility change
    setModalUpdateStockVisible = modalUpdateStockVisible => this.setState({ modalUpdateStockVisible });

    // Handle stock input change
    handleStockInputChange = e => this.setState({ stock: e.target.value });

    // Handle stock update form submit
    handleUpdateStockSubmit = () => {
        const { actions, productId } = this.props;
        const values = {
            id: productId,
            quantity: this.state.stock,
            quantity_last_updated: moment().format(DB_DATE_FORMAT),
        };

        actions.updateProductRequest(values);
        this.setState({ modalUpdateStockVisible: false, stock: '' });
    }

    render() {
        return this.view();
    }
}

UpdateProductStock.propTypes = {
    actions: PropTypes.object.isRequired,
    productId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        product: state.product.product,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...productActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProductStock);
