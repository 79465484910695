import React from 'react';
import { isUndefined } from 'underscore';
import { Link } from 'react-router';

import './breadcrumb.css';

export default function () {
    const { breadcrumb } = this.props;

    return (breadcrumb ?
        <div className="breadcrumb">
            <div className="breadcrumb__row">
                {this.getBreadcrumbTree(breadcrumb).map(item => (
                    <span key={item.label} className="breadcrumb__part">
                        <span className="arrow-separator">{' > '}</span>
                        {!isUndefined(item.href) ? (
                            <Link className="underlined" to={item.href}>
                                {item.label}
                            </Link>
                        ) : (
                            <div className="active">{item.label}</div>
                        )}
                    </span>
                ))}
            </div>
        </div>
    : false);
}
