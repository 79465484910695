import alertify from 'alertify.js';
import { put, call, takeEvery } from 'redux-saga/effects';
import { notification } from 'antd';
import { fetchApiAuth } from '../utils/api';

import {
    getUserSuccess,
    getUserFailure
} from './userActions';

const {
    GET_USER_REQUEST,
} = require('./userActions').constants;

function* getUser() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/user'
        });

        yield put(getUserSuccess(response.data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getUserFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield* [
        takeEvery(GET_USER_REQUEST, getUser),
    ];
}
