import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_REFERRERS_REQUEST: null,
    GET_REFERRERS_SUCCESS: null,
    GET_REFERRERS_FAILURE: null,

    GET_REFERRER_REQUEST: null,
    GET_REFERRER_SUCCESS: null,
    GET_REFERRER_FAILURE: null,

    UPDATE_REFERRER_REQUEST: null,
    UPDATE_REFERRER_SUCCESS: null,
    UPDATE_REFERRER_FAILURE: null,

    GET_REFERRER_CUSTOMER_REQUEST: null,
    GET_REFERRER_CUSTOMER_SUCCESS: null,
    GET_REFERRER_CUSTOMER_FAILURE: null,

    ADD_REFERRER_CUSTOMER_REQUEST: null,
    ADD_REFERRER_CUSTOMER_SUCCESS: null,
    ADD_REFERRER_CUSTOMER_FAILURE: null,
});

export const {
    getReferrersRequest,
    getReferrersSuccess,
    getReferrersFailure
} = createActions(
    constants.GET_REFERRERS_REQUEST,
    constants.GET_REFERRERS_SUCCESS,
    constants.GET_REFERRERS_FAILURE
);

export const {
    getReferrerRequest,
    getReferrerSuccess,
    getReferrerFailure
} = createActions(
    constants.GET_REFERRER_REQUEST,
    constants.GET_REFERRER_SUCCESS,
    constants.GET_REFERRER_FAILURE
);

export const {
    updateReferrerRequest,
    updateReferrerSuccess,
    updateReferrerFailure
} = createActions(
    constants.UPDATE_REFERRER_REQUEST,
    constants.UPDATE_REFERRER_SUCCESS,
    constants.UPDATE_REFERRER_FAILURE
);

export const {
    getReferrerCustomerRequest,
    getReferrerCustomerSuccess,
    getReferrerCustomerFailure
} = createActions(
    constants.GET_REFERRER_CUSTOMER_REQUEST,
    constants.GET_REFERRER_CUSTOMER_SUCCESS,
    constants.GET_REFERRER_CUSTOMER_FAILURE
);

export const {
    addReferrerCustomerRequest,
    addReferrerCustomerSuccess,
    addReferrerCustomerFailure
} = createActions(
    constants.ADD_REFERRER_CUSTOMER_REQUEST,
    constants.ADD_REFERRER_CUSTOMER_SUCCESS,
    constants.ADD_REFERRER_CUSTOMER_FAILURE
);
