import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Alert } from 'antd';
import { isEmpty } from 'underscore';
import moment from 'moment';

import { checkDate } from '../../../core/utils/helpers.js';

import * as orderActions from '../../../core/order/orderActions';

class RepeatOrderItemFailureBanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.timeout = null;
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.checkForRepeatOrderItemErrorsRequest();
        this.timeout = setInterval(() => actions.checkForRepeatOrderItemErrorsRequest(), 60000);
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUnmount() {
        clearInterval(this.timeout);
    }

    render() {
        const { repeatOrderItemErrors } = this.props;

        repeatOrderItemErrors.sort((a, b) => ((a.created_at > b.created_at) ? 1 : -1));
        let dueDate = null;
        let title = null;
        let id = null;
        let customer_first_name = null;
        let customer_last_name = null;
        if (!isEmpty(repeatOrderItemErrors)) {
            if (repeatOrderItemErrors[0].reorder_date) {
                dueDate = moment(checkDate(repeatOrderItemErrors[0].reorder_date)).add(3, 'weeks');
                if (dueDate.day() === 5) {
                    dueDate.add(3, 'days');
                }
                dueDate = dueDate.format('DD/MM/YYYY');
            }
            if (repeatOrderItemErrors[0].product.title) title = repeatOrderItemErrors[0].product.title;
            if (repeatOrderItemErrors[0].id) id = repeatOrderItemErrors[0].id;
            if (repeatOrderItemErrors[0].order.customer_first_name) customer_first_name = repeatOrderItemErrors[0].order.customer_first_name;
            if (repeatOrderItemErrors[0].order.customer_last_name) customer_last_name = repeatOrderItemErrors[0].order.customer_last_name;
        }

        return (
            <div>
                { !isEmpty(repeatOrderItemErrors)
                &&
                <Alert
                    message={repeatOrderItemErrors.length > 1
                        ?
                        `${repeatOrderItemErrors.length} auto order items have failed to generate. The most recent failed item has the following details: id - ${id}, name - ${title || 'n/a'}, due date - ${dueDate || 'n/a'}, patient's name - ${customer_first_name} ${customer_last_name}.`
                        :
                        `An auto order item has failed to generate. Details: id - ${id}, name - ${title || 'n/a'}, due date - ${dueDate || 'n/a'}, patient's name - ${customer_first_name} ${customer_last_name}.`
                    }
                    banner
                    closable />
                }
            </div>
        );
    }
}

RepeatOrderItemFailureBanner.defaultProps = {
};

RepeatOrderItemFailureBanner.propTypes = {
    actions: PropTypes.shape({
        checkForRepeatOrderItemErrorsRequest: PropTypes.func,
    }).isRequired,
    repeatOrderItemErrors: PropTypes.shape({
        length: PropTypes.number,
        sort: PropTypes.func,
    }).isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        repeatOrderItemErrors: state.order.repeatOrderItemErrors,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...orderActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RepeatOrderItemFailureBanner);
