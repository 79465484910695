import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Row, Col } from 'antd';

import AddCarehomeNurse from '../../forms/AddCarehomeNurse';

const AddNurse = ({ carehomeId, visible, changeVisible }) => (
    <Modal centered width={540} footer={null} visible={visible} onOk={() => changeVisible(false)} onCancel={() => changeVisible(false)}>
        <div className="text-center">
            <h4 className="h-4">Add nurse to care home</h4>
        </div>
        <div className="mt-2">
            <Row type="flex" justify="center">
                <Col span={24} md={20}>
                    <AddCarehomeNurse carehomeId={carehomeId} changeVisible={changeVisible} />
                </Col>
            </Row>
        </div>
    </Modal>
);

AddNurse.propTypes = {
    carehomeId: PropTypes.number.isRequired,
    changeVisible: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
};

export default AddNurse;
