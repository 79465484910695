import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { Modal } from 'antd';
import { browserHistory } from 'react-router';

import * as customerActions from '../../core/customer/customerActions';

import DOM from './order';

class OrderPage extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            readOnly: false,
            modalNotesVisible: false,
            modalSplitVisible: false,
            modalUnsavedVisible: false,
            name: parseInt(props.params.id, 10) ? `Order: #${props.params.id}` : 'New Order',
            toolbar: this.getToolbar(parseInt(props.params.id, 10)),
            tab: 1,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ toolbar: this.getToolbar(parseInt(nextProps.params.id, 10), this.state.tab) });
    }

    // Generate toolbar based on state and tab
    getToolbar = (id, key) => {
        const tab = key || 1;
        const readOnly = this.state ? this.state.readOnly : false;
        const toolbar = [];

        if (id) {
            toolbar.push({ id: 'actionNotes', caption: 'Notes', icon: 'sticky-note', onClick: () => { this.setModalNotesVisible(true); } });
        } else {
            toolbar.push({ id: 'actionNotes',
                caption: 'Notes',
                icon: 'sticky-note',
                onClick: () => {
                    Modal.warning({
                        title: 'Warning',
                        content: 'You can add notes to already saved orders only.'
                    });
                } });
        }

        if (tab === 1) {
            toolbar.push({
                id: 'actionAddCustomer',
                caption: 'Add customer',
                icon: 'new-order',
                onClick: () => {
                    this.props.actions.updateOrderSearchResultsOfflineRequest([]);
                    browserHistory.push('/customer/0');
                },
                target: 'blank'
            });
        }

        if (!readOnly && tab === 2) {
            toolbar.push({ id: 'actionSplit', caption: 'Split', icon: 'split', onClick: () => { this.setModalSplitVisible(true); } });
        }

        if (!readOnly) {
            toolbar.push({ id: 'actionSave', caption: tab === 3 ? 'Save & Submit' : 'Save', icon: 'save', onClick: this.handleUpdateOrderSubmit });
        }

        return toolbar;
    }

    // Handle set readOnly
    setReadOnly = readOnly => this.setState({ readOnly, toolbar: this.getToolbar(parseInt(this.props.params.id, 10)) });

    // Handle toggle unsaved message modal
    setModalUnsavedVisible = modalUnsavedVisible => this.setState({ modalUnsavedVisible });

    // Handle toggle order notes modal
    setModalNotesVisible = modalNotesVisible => this.setState({ modalNotesVisible });

    // Handle toggle split order modal
    setModalSplitVisible = modalSplitVisible => this.setState({ modalSplitVisible });

    // Handle open modal based on state name
    handleModalVisible = (modal, visible) => {
        const newState = this.state;
        newState[modal] = visible;
        this.setState(newState);
    }

    // Handle tab change
    handleTabChange = key => this.setState({ tab: key, toolbar: this.getToolbar(parseInt(this.props.params.id, 10), key) });

    // Handle update order submit
    handleUpdateOrderSubmit = () => this.props.actions.submit('update-order');

    render() {
        return this.view();
    }
}

OrderPage.propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.order.isFetching
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            submit,
            ...customerActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
