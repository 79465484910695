import React from 'react';
import { Form as ReduxForm } from 'redux-form';
import { Row, Col } from 'antd';

import Form from '../../../elements/Form';
import Loading from '../../../elements/Loading';
import { isRequired, isRequiredOrZero, charLessThan } from '../../../core/utils/validators';

const { STATUS_OPTIONS } = require('../../../core/utils/options').default;

const FormGroup = Form.Group;
const formTypes = Form.formTypes;

export default function () {
    const formLeftConfig = [
        { type: formTypes.TEXT, name: 'gp_reference', label: 'GP Reference' },
        { type: formTypes.TEXT, name: 'title', label: 'Practice Name', validate: [isRequired] },
        { type: formTypes.TEXT, name: 'telephone', label: 'Phone', validate: [isRequired, value => charLessThan(value, 15)] },
        { type: formTypes.TEXT, name: 'fax', label: 'Fax', validate: [value => charLessThan(value, 15)] },
        { type: formTypes.EMAIL, name: 'email', label: 'Email', validate: [isRequired] },
        { type: formTypes.SELECT, name: 'is_active', label: 'Status', options: STATUS_OPTIONS, validate: [isRequiredOrZero] }
    ];

    const formRightConfig = [
        { type: formTypes.TEXT, name: 'address_line_1', label: 'Line 1', validate: [isRequired] },
        { type: formTypes.TEXT, name: 'address_line_2', label: 'Line 2' },
        { type: formTypes.TEXT, name: 'address_line_3', label: 'Line 3' },
        { type: formTypes.TEXT, name: 'city_region', label: 'City/Region', validate: [isRequired] },
        { type: formTypes.TEXT, name: 'postcode', label: 'Postcode', validate: [isRequired, value => charLessThan(value, 10)] },
    ];

    return (
        <div className="container">
            <div className="section">
                <ReduxForm onSubmit={this.props.handleSubmit(this.handleUpdatePracticeSubmit)} className="ant-form ant-form-vertical">
                    <div className="section__header">
                        <h3 className="h-4">Practice details</h3>
                    </div>
                    <div className="section-white">
                        {!this.props.isFetching ?
                            <Row gutter={80} type="flex" justify="center">
                                <Col span={24} md={12} lg={10}>
                                    <FormGroup config={formLeftConfig} />
                                </Col>
                                <Col span={24} md={12} lg={10}>
                                    <FormGroup config={formRightConfig} />
                                </Col>
                            </Row>
                        : <Loading className="flex-center-middle" style={{ minHeight: 490 }} />}
                    </div>
                </ReduxForm>
            </div>
        </div>
    );
}
