import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Row, Col, Input, DatePicker, Select } from 'antd';

import Form from '../../../../../elements/Form';
import styles from './styles';
import { checkDate } from '../../../../../core/utils/helpers.js';

const EXEMPTION_REASONS = [
    { label: 'A - Is aged 60 or older, or under 16 (unless the birthdate is on the form)', value: 'A', },
    { label: 'B - Is 16, 17 or 18 years old and in full-time education', value: 'B', },
    { label: 'D - Materntity exemption certificate', value: 'D', },
    { label: 'E - Medical exemption certificate', value: 'E', },
    { label: 'F - Prescription pre-payment certificate', value: 'F', },
    { label: 'G - Prescription exemption certificate issued by the Ministry of Defence', value: 'G', },
    { label: 'L - HC2 (full help) certificate', value: 'L', },
    { label: 'H - Income Support or Income-related Employment and Support Allowance', value: 'H', },
    { label: 'K - Income-based Jobseekers Allowance', value: 'K', },
    { label: 'M - Tax Credit Exemption Certificate', value: 'M', },
    { label: 'S - Pension Credit guarantee credit (including partners)', value: 'S', },
    { label: 'U - Universal Credit and meets the criteria. Learn more at nhsbsa.nhs.uk/UC', value: 'U', },
];

class PrescriptionInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.activeTab !== '4') {
            return false;
        }
        return true;
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    _renderRow(value) {
        const { handleSetPrescriptionInformation, prescriptionInformation } = this.props;

        let inputElement = null;

        switch (value[0]) {
        case 'exempt_from_prescription_fee':
            inputElement = (<p>{value[1] ? 'Yes' : 'No'}</p>);
            break;
        case 'exemption_reason': {
            const reason = EXEMPTION_REASONS.find(x => x.value === value[1]);
            inputElement = (<p>{reason && reason.label}</p>);
            break;
        }
        case 'certificate_expiry_date':
            inputElement = (<p>{checkDate(value[1], 'output', 'DD/MM/YYYY')}</p>);
            break;
        default:
            inputElement = (<p>{value[1]}</p>);
            break;
        }

        if (value[0] === 'exemption_reason' && !prescriptionInformation.exempt_from_prescription_fee) {
            return null;
        }
        return (
            <Row key={value[0]} gutter={48} type="flex" justify="center">
                <Col span={7} type="flex" align="center"><p style={{ ...styles.text2, textAlign: 'left', }}>{this.capitalizeFirstLetter(value[0].replace(/_/g, ' '))}</p>
                </Col>
                <Col span={11} type="flex" align="flex-start">
                    {inputElement}
                </Col>
            </Row>
        );
    }

    render() {
        const { prescriptionInformation } = this.props;

        return (
            <div className="section order_prescription_info_tab">
                <div className="container">
                    <p style={styles.text1}>Prescription Information</p>
                    {Object.entries(prescriptionInformation).map(value => this._renderRow(value))}
                </div>
            </div>
        );
    }
}

PrescriptionInfo.defaultProps = {
    prescriptionInformation: {},
    handleSetPrescriptionInformation: () => {},
};

PrescriptionInfo.propTypes = {
    activeTab: PropTypes.string.isRequired,
    prescriptionInformation: PropTypes.object,
    handleSetPrescriptionInformation: PropTypes.func,
};

export default PrescriptionInfo;
