import React from 'react';
import Layout from '../components/layout/Layout';

import logoSrc from '../assets/images/half-logo.svg';
import warningSrc from '../assets/images/warning.png';

const NotFound = () => (
    <Layout type="login" title="Not found">
        <div className="loginform-wrap">
            <div className="loginform-container" style={{ margin: 'auto', padding: 0 }}>
                <div className="loginform-backdrop" style={{ margin: 0, marginBottom: 60 }}>
                    <div className="form-wrap">
                        <img src={warningSrc} alt="Warning" className="mb-2" width={66} height={58} />
                        <h1>Page Not Found</h1>
                        <p>Page your are looking for does not exists.</p>
                    </div>
                </div>
            </div>

            <div className="loginform-aside">
                <img className="loginform__illustr" src={logoSrc} alt="Jemsys" />
            </div>
        </div>
    </Layout>
);

export default NotFound;
