import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import * as customerActions from '../../../core/customer/customerActions';

import DOM from './updateAutoOrder';

class UpdateAutoOrderForm extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    // Handle submit auto order update for customer
    handleUpdateAutoOrderSubmit = (values) => {
        const { id, customerId, actions } = this.props;
        actions.updateCustomerAutoOrderRequest({
            id: customerId,
            order_repeat_id: id,
            ...values
        });
    };

    render() {
        return this.view();
    }
}

UpdateAutoOrderForm.propTypes = {
    actions: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    customerId: PropTypes.number.isRequired,
};

const UpdateAutoOrder = reduxForm({
    form: 'update-auto-order',
    enableReinitialize: true,
})(UpdateAutoOrderForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        initialValues: {
            interval: ownProps.interval,
            interval_type: ownProps.intervalType,
        }
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAutoOrder);
