import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import DOM from './customersHome';

import * as carehomeActions from '../../../core/carehome/carehomeActions';

class CustomersHome extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentWillMount() {
        const { actions, carehomeId } = this.props;
        actions.getCarehomeCustomerRequest(carehomeId);
    }

    render() {
        return this.view();
    }
}

CustomersHome.propTypes = {
    actions: PropTypes.object.isRequired,
    carehomeId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        customers: state.carehome.customers,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...carehomeActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomersHome);
