import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_CAREHOMES_REQUEST: null,
    GET_CAREHOMES_SUCCESS: null,
    GET_CAREHOMES_FAILURE: null,

    GET_CAREHOME_REQUEST: null,
    GET_CAREHOME_SUCCESS: null,
    GET_CAREHOME_FAILURE: null,

    UPDATE_CAREHOME_REQUEST: null,
    UPDATE_CAREHOME_SUCCESS: null,
    UPDATE_CAREHOME_FAILURE: null,

    GET_CAREHOME_NURSE_REQUEST: null,
    GET_CAREHOME_NURSE_SUCCESS: null,
    GET_CAREHOME_NURSE_FAILURE: null,

    ADD_CAREHOME_NURSE_REQUEST: null,
    ADD_CAREHOME_NURSE_SUCCESS: null,
    ADD_CAREHOME_NURSE_FAILURE: null,

    REMOVE_CAREHOME_NURSE_REQUEST: null,
    REMOVE_CAREHOME_NURSE_SUCCESS: null,
    REMOVE_CAREHOME_NURSE_FAILURE: null,

    GET_CAREHOME_CUSTOMER_REQUEST: null,
    GET_CAREHOME_CUSTOMER_SUCCESS: null,
    GET_CAREHOME_CUSTOMER_FAILURE: null,

    RESET_CAREHOMES: null,
});

export const {
    getCarehomesRequest,
    getCarehomesSuccess,
    getCarehomesFailure
} = createActions(
    constants.GET_CAREHOMES_REQUEST,
    constants.GET_CAREHOMES_SUCCESS,
    constants.GET_CAREHOMES_FAILURE
);

export const {
    getCarehomeRequest,
    getCarehomeSuccess,
    getCarehomeFailure
} = createActions(
    constants.GET_CAREHOME_REQUEST,
    constants.GET_CAREHOME_SUCCESS,
    constants.GET_CAREHOME_FAILURE
);

export const {
    updateCarehomeRequest,
    updateCarehomeSuccess,
    updateCarehomeFailure
} = createActions(
    constants.UPDATE_CAREHOME_REQUEST,
    constants.UPDATE_CAREHOME_SUCCESS,
    constants.UPDATE_CAREHOME_FAILURE
);

export const {
    getCarehomeNurseRequest,
    getCarehomeNurseSuccess,
    getCarehomeNurseFailure
} = createActions(
    constants.GET_CAREHOME_NURSE_REQUEST,
    constants.GET_CAREHOME_NURSE_SUCCESS,
    constants.GET_CAREHOME_NURSE_FAILURE
);

export const {
    addCarehomeNurseRequest,
    addCarehomeNurseSuccess,
    addCarehomeNurseFailure
} = createActions(
    constants.ADD_CAREHOME_NURSE_REQUEST,
    constants.ADD_CAREHOME_NURSE_SUCCESS,
    constants.ADD_CAREHOME_NURSE_FAILURE
);

export const {
    removeCarehomeNurseRequest,
    removeCarehomeNurseSuccess,
    removeCarehomeNurseFailure
} = createActions(
    constants.REMOVE_CAREHOME_NURSE_REQUEST,
    constants.REMOVE_CAREHOME_NURSE_SUCCESS,
    constants.REMOVE_CAREHOME_NURSE_FAILURE
);

export const {
    getCarehomeCustomerRequest,
    getCarehomeCustomerSuccess,
    getCarehomeCustomerFailure
} = createActions(
    constants.GET_CAREHOME_CUSTOMER_REQUEST,
    constants.GET_CAREHOME_CUSTOMER_SUCCESS,
    constants.GET_CAREHOME_CUSTOMER_FAILURE
);

export const {
    resetCarehomes,
} = createActions(
    constants.RESET_CAREHOMES,
);
