import React from 'react';
import moment from 'moment';
import { Checkbox } from 'antd';
import { find } from 'underscore';
import Gridview from '../../../elements/Gridview';
import Icon from '../../../elements/Icon';

import { displayId, displayDate, displayName, displayNumber, GridButton } from '../../../core/utils/helpers';

const { DB_DATE_FORMAT } = require('../../../core/utils/values').default;
const { FULL_ORDER_STATUS_OPTIONS } = require('../../../core/utils/options').default;

export default function () {
    const { orders, total, page, postBackorderChasers, isFetching, backorderChaserIsFetching, backorderSelectAll } = this.props;

    // Render checkbox
    const getCheckbox = (value, record, type) => {
        if (record.available_comms[type]) {
            return (
                <Checkbox
                    checked={this.getCheckboxValue(type, record.id)}
                    onChange={(e) => { this.handleCheckboxChange(type, record.id, e.target.checked); }} />
            );
        }
        return false;
    };

    // Render button select all
    const renderButtonSelectAll = type => (
        <Checkbox
            checked={backorderSelectAll}
            onChange={() => this.handleSelectAll(type, backorderSelectAll)} />
    );

    const renderOrderStatus = (value) => {
        const option = find(FULL_ORDER_STATUS_OPTIONS, item => item.value === value);
        return option ? option.label : '-';
    };

    const renderShippingPostcode = (value, record) => {
        const postcode = record.customerPostcode?.postcode;
        return postcode || '-';
    };

    // Pre header commponent
    const preHeader = (
        <div className="table__selection mb-2">
            <h5 className="h-5">Your selection</h5>
            <div className="table__selection-items">
                <div className="table__selection-item"><Icon name="mail" /> {displayNumber(postBackorderChasers.length)}</div>
            </div>
        </div>
    );

    // Gridview config
    const config = [
        { dataIndex: 'ref', title: 'Order ID', width: 100, render: displayId },
        { dataIndex: 'created_at', title: 'Order Created', render: value => displayDate(value) },
        { dataIndex: 'order_status', title: 'Status', render: renderOrderStatus },
        { dataIndex: 'customer_id', title: 'Customer ID', render: displayId },
        { dataIndex: 'name', title: 'Customer Name', className: 'text-semibold', render: (value, record) => displayName(record, 'customer_first_name', 'customer_last_name') },
        { dataIndex: 'postcode', title: 'Shipping Postcode', render: renderShippingPostcode },
        { dataIndex: 'send_post', title: renderButtonSelectAll('post'), className: 'text-center padding-right-20', render: (value, record) => getCheckbox(value, record, 'post') },
    ];

    return (
        <Gridview
            config={config}
            data={orders}
            loading={backorderChaserIsFetching ? 'floating' : isFetching}
            pagination={{
                simple: true,
                onChange: this.handlePagination,
                pageSize: 10,
                total,
                current: page,
                className: 'custom-pagination',
            }}
            preHeader={preHeader} />
    );
}
