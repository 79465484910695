const styles = {
    // Text
    text1: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#2d3748',
    },
};

export default styles;
