import React from 'react';

import Gridview from '../../../elements/Gridview';
import { GridButton, displayName, displayNumber, displayId } from '../../../core/utils/helpers';

export default function () {
    const { carehomes, nurses, isFetching, carehomesTotal, carehomesPage, nursesTotal, nursesPage } = this.props;
    const config = {
        'Care Homes': [
            { dataIndex: 'id', title: 'Care Home ID', search: false, type: 'text', render: displayId },
            { dataIndex: 'title', title: 'Care Home Name', search: true, type: 'text' },
            { dataIndex: 'city_region', title: 'City/Region', search: true, type: 'text', visible: false },
            { dataIndex: 'postcode', title: 'Postcode', search: true, type: 'text' },
            { dataIndex: 'num_nurses', title: 'Num Nurses', className: 'text-center', render: displayNumber },
            { dataIndex: 'key', align: 'right', render: (value, record) => <GridButton href={`/care-home/${record.id}`} /> },
        ],
        nurses: [
            { dataIndex: 'id', title: 'Nurse ID', render: displayId },
            { dataIndex: 'name', title: 'Name', render: (value, record) => displayName(record) },
            { dataIndex: 'postcode', title: 'Postcode' },
            { dataIndex: 'first_name', title: 'Forenames', search: true, type: 'text', visible: false },
            { dataIndex: 'last_name', title: 'Surname', search: true, type: 'text', visible: false },
            { dataIndex: 'key', align: 'right', render: (value, record) => <GridButton href={`/nurse/${record.id}`} /> },
        ],
    };

    return (
        <Gridview
            config={config}
            data={{ 'Care Homes': carehomes, nurses }}
            handleClearSearch={this.handleClearSearch}
            handleSearch={this.handleSearch}
            loading={isFetching}
            searchTitle={'Care Home & Nurse search'}
            title={'Care Home & Nurse Search Results'}
            pagination={{
                'Care Homes': {
                    simple: true,
                    onChange: this.handleCarehomePagination,
                    pageSize: 10,
                    total: carehomesTotal,
                    current: carehomesPage,
                    className: 'custom-pagination',
                },
                nurses: {
                    simple: true,
                    onChange: this.handleNursePagination,
                    pageSize: 10,
                    total: nursesTotal,
                    current: nursesPage,
                    className: 'custom-pagination',
                }
            }} />
    );
}
