import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, stopSubmit } from 'redux-form';
import { isEqual } from 'underscore';
import alertify from 'alertify.js';
import { browserHistory } from 'react-router';

import * as productActions from '../../../core/product/productActions';
import * as reportActions from '../../../core/report/reportActions';
import * as supplierActions from '../../../core/supplier/supplierActions';
import * as customerActions from '../../../core/customer/customerActions';

import DOM from './runReport';

const {
    REPORT_TYPE_AUTO_ORDER_ITEMS_PER_PATIENT,
    REPORT_TYPE_PAYMENT_EXAMPTION_LETTER
} = require('../../../core/utils/values').default;

class RunReportForm extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            searchInput: '',
            searchValue: null,
        };
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.getProductsRequest();
        actions.getSuppliersRequest();
    }

    // Generate Report
    handleRunReportSubmit = (values) => {
        const { actions } = this.props;

        // if report type is examption letter
        if (this.props.reportType === REPORT_TYPE_PAYMENT_EXAMPTION_LETTER) {
            values.auto_order_item_patient_id = this.state.searchValue;
            
            if (!this.state.searchValue || this.state.searchValue === '') {
                alertify.error('The "Auto Order Item Patient Id" field is required');
                return;
            }

            browserHistory.push({
                pathname: `/exemption-letter/${this.state.searchValue}`,
                state: {
                    selectedCustomerId: values.auto_order_item_patient_id
                }
            });
            return;
        }
            
        if (this.props.reportType === REPORT_TYPE_AUTO_ORDER_ITEMS_PER_PATIENT) {
            values.auto_order_item_patient_id = this.state.searchValue;
            if (!this.state.searchValue || this.state.searchValue === '') {
                alertify.error('The "Auto Order Item Patient Id" field is required');
                actions.stopSubmit('run-report');
                return;
            }
        }

        actions.getReportRequest(values);
    };

    render() {
        return this.view();
    }
}

RunReportForm.propTypes = {
    actions: PropTypes.object.isRequired,
};

const selector = formValueSelector('run-report');
const RunReport = reduxForm({
    form: 'run-report',
    enableReinitialize: true,
})(RunReportForm);

function mapStateToProps(state, ownProps) {
    const reportType = selector(state, 'report_type');
    return {
        ...ownProps,
        reportType,
        products: state.product.products.rows,
        suppliers: state.supplier.suppliers.rows,
        customerSearchResults: state.customer.customerSearchResults,
        isFetching: state.report.isFetching
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...productActions,
            ...reportActions,
            ...supplierActions,
            ...customerActions,
            stopSubmit,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RunReport);
