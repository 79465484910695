import { put, call, takeEvery } from 'redux-saga/effects';
import alertify from 'alertify.js';
import { browserHistory } from 'react-router';
import { isUndefined } from 'underscore';
import { notification } from 'antd';
import { fetchApiAuth, formatParams } from '../utils/api';
import { addKeyProp, mapPropsToInts } from '../utils/helpers';

import {
    getReferrersSuccess,
    getReferrersFailure,

    getReferrerSuccess,
    getReferrerFailure,

    updateReferrerSuccess,
    updateReferrerFailure,

    getReferrerCustomerSuccess,
    getReferrerCustomerFailure,

    addReferrerCustomerSuccess,
    addReferrerCustomerFailure,
} from './referrerActions';

const {
    GET_REFERRERS_REQUEST,
    GET_REFERRER_REQUEST,
    UPDATE_REFERRER_REQUEST,
    GET_REFERRER_CUSTOMER_REQUEST,
    ADD_REFERRER_CUSTOMER_REQUEST,
} = require('./referrerActions').constants;

function* getReferrers({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/referrer/search',
            params: formatParams(payload || {})
        });

        let data = !isUndefined(response.data) ? response.data : [];
        if (data) {
            data = mapPropsToInts(data, ['page', 'total']);
            data.rows = addKeyProp(data.rows);
        }

        yield put(getReferrersSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getReferrersFailure(e.response ? e.response.data.message : e));
    }
}

function* getReferrer({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (params.id !== 0) {
            response = yield call(fetchApiAuth, {
                method: 'GET',
                url: '/referrer/get',
                params
            });
        }

        const referrer = !isUndefined(response) ? response.data : {};
        yield put(getReferrerSuccess(mapPropsToInts(referrer, ['status'])));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getReferrerFailure(e.response ? e.response.data.message : e));
    }
}

function* updateReferrer({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/referrer/edit',
            body: payload
        });

        const referrer = !isUndefined(response.data) ? response.data : {};
        if (referrer) {
            browserHistory.push(`/referrer/${referrer.id}`);
            notification.success({ message: 'Success', description: 'Referrer has been updated' });
        }

        yield put(updateReferrerSuccess(mapPropsToInts(referrer, ['status'])));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(updateReferrerFailure(e.response ? e.response.data.message : e));
    }
}

function* getReferrerCustomer({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/referrer/customer',
            params: formatParams(payload)
        });

        let data = !isUndefined(response.data) ? response.data : [];
        if (data) {
            data = addKeyProp(data);
        }

        yield put(getReferrerCustomerSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getReferrerCustomerFailure(e.response ? e.response.data.message : e));
    }
}

function* addReferrerCustomer({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/referrer/customer',
            body: {
                id: payload.referrerId,
                customer_id: payload.customerId,
            }
        });

        let data = !isUndefined(response.data) ? response.data : [];
        if (data) {
            data = addKeyProp(data);
        }

        yield put(addReferrerCustomerSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(addReferrerCustomerFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* referrerSaga() {
    yield* [
        takeEvery(GET_REFERRERS_REQUEST, getReferrers),
        takeEvery(GET_REFERRER_REQUEST, getReferrer),
        takeEvery(UPDATE_REFERRER_REQUEST, updateReferrer),
        takeEvery(GET_REFERRER_CUSTOMER_REQUEST, getReferrerCustomer),
        takeEvery(ADD_REFERRER_CUSTOMER_REQUEST, addReferrerCustomer),
    ];
}
