import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { isUndefined } from 'underscore';
import { reduxForm } from 'redux-form';

import * as practiceActions from '../../../core/practice/practiceActions';

import DOM from './updatePractice';

class UpdatePracticeForm extends Component {
    constructor(props) {
        super(props);
        this.handleSetPageName(props);
        this.view = DOM;
    }

    componentWillMount() {
        const { actions, practiceId } = this.props;
        actions.getPracticeRequest(practiceId);
    }

    componentWillReceiveProps(nextProps) {
        this.handleSetPageName(nextProps);
    }

    // Handle set page name
    handleSetPageName(props) {
        const { setPageName, initialValues, practiceId, isFetching } = props;
        if (!isFetching) {
            if (!isUndefined(initialValues.title)) {
                setPageName(`#${initialValues.id} - ${initialValues.title}`);
            } else if (practiceId === 0) {
                setPageName('New Practice');
            }
        }
    }

    // Handle submit form
    handleUpdatePracticeSubmit = (values) => {
        const { actions, practiceId } = this.props;
        actions.updatePracticeRequest({ id: practiceId, ...values });
    };

    render() {
        return this.view();
    }
}

UpdatePracticeForm.propTypes = {
    actions: PropTypes.object.isRequired,
    practiceId: PropTypes.number.isRequired,
};

const UpdatePractice = reduxForm({
    form: 'update-practice',
    enableReinitialize: true,
})(UpdatePracticeForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        initialValues: state.practice.practice,
        practice: state.practice.practice,
        isFetching: state.practice.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...practiceActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePractice);
