import React from 'react';
import { Button } from 'antd';

import Gridview from '../../../elements/Gridview';
import AddNurse from '../../modals/AddNurse';
import { displayId, displayName, GridButton } from '../../../core/utils/helpers';

export default function () {
    const { nurses, carehomeId } = this.props;
    const { modalAddNurseVisible } = this.state;

    // Gridview title
    const title = (
        <div className="table__topnav">
            <h6 className="h-6">Nurses associated with this care home</h6>
            <Button type="default" className="ant-btn-xs grey" onClick={() => { this.setModalAddNurseVisible(true); }}>Assign nurse to home</Button>
        </div>
    );

    // Render gridview buttons
    const renderButtons = (value, record) => (
        <div className="btn-bar">
            <GridButton href={`/nurse/${record.id}`} />
            <GridButton href={() => this.handleRemoveCarehomeNurse(record.id)} title="Remove" />
        </div>
    );

    // Gridview config
    const config = [
        { dataIndex: 'id', title: 'Nurse ID', width: 120, render: displayId },
        { dataIndex: 'first_name', title: 'Name', render: (value, record) => displayName(record) },
        { dataIndex: 'key', align: 'right', render: renderButtons },
    ];

    return (
        <div>
            <Gridview
                scroll={{ y: 300 }}
                preHeader={title}
                config={config}
                data={nurses}
                pagination={false} />

            <AddNurse
                carehomeId={carehomeId}
                visible={modalAddNurseVisible}
                changeVisible={this.setModalAddNurseVisible} />
        </div>
    );
}
