import React from 'react';
import { Form as AntForm, Button } from 'antd';

import Form from '../../../elements/Form';
import { isRequired, charLessThan } from '../../../core/utils/validators';

const FormGroup = Form.Group;
const formTypes = Form.formTypes;

export default function () {
    const formConfig = [
        { type: formTypes.TEXT, name: 'first_name', label: 'First Name', validate: [isRequired] },
        { type: formTypes.TEXT, name: 'last_name', label: 'Surname(s)', validate: [isRequired] },
        { type: formTypes.TEXT, name: 'phone_no', label: 'Phone number (optional)', validate: [value => charLessThan(value, 15)] },
        { type: formTypes.EMAIL, name: 'email', label: 'Email (optional)' },
    ];

    return (
        <AntForm onSubmit={this.props.handleSubmit(this.handleAddDoctorSubmit)} layout="vertical">
            <FormGroup config={formConfig} />
            <div className="text-center mt-2">
                <Button type="primary" htmlType="submit" className="wide">Add to practice</Button>
            </div>
        </AntForm>
    );
}
