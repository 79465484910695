import React from 'react';
import { Row, Col } from 'antd';

import Layout from '../../components/layout/Layout';
import UpdateProduct from '../../components/forms/UpdateProduct';
import UpdateProductStock from '../../components/forms/UpdateProductStock';

export default function () {
    const { name, toolbar } = this.state;
    const id = parseInt(this.props.params.id, 10);
    const breadcrumb = { name: 'product', params: { id, name } };

    return (
        <Layout title={name} toolbar={toolbar} breadcrumb={breadcrumb}>
            <div className="container">
                <div className="section">
                    <Row gutter={48} type="flex">
                        <Col span={24} lg={13}>
                            <UpdateProduct productId={id} setPageName={this.setPageName} />
                        </Col>

                        <Col span={24} lg={11}>
                            <UpdateProductStock productId={id} />
                        </Col>
                    </Row>
                </div>
            </div>
        </Layout>
    );
}
