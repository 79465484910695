import React from 'react';
import PropTypes from 'prop-types';

const FormTabBar = ({ activeKey, panels, onTabClick }) => (
    <ul className="order-steps">
        {panels.map((panel, index) => (
            <a key={panel.key} onClick={() => onTabClick(panel.key)}>
                <li className={`order-step ${activeKey === panel.key ? 'is-active' : ''} ${activeKey > panel.key ? 'is-enabled' : ''}`}>
                    <span className="order-step__numb">{index + 1}</span>
                    <span className="order-step__caption">{panel.props.tab}</span>
                </li>
            </a>
        ))}
    </ul>
);

FormTabBar.defaultProps = {
    activeKey: '1',
};

FormTabBar.propTypes = {
    panels: PropTypes.array.isRequired,
    activeKey: PropTypes.string,
    onTabClick: PropTypes.func.isRequired,
};

export default FormTabBar;
