import { put, call, takeEvery } from 'redux-saga/effects';
import alertify from 'alertify.js';
import { browserHistory } from 'react-router';
import { mapObject, isUndefined } from 'underscore';
import { notification } from 'antd';
import { fetchApiAuth, formatParams } from '../utils/api';
import { addKeyProp, mapPropsToInts } from '../utils/helpers';

import {
    getSuppliersSuccess,
    getSuppliersFailure,

    getSupplierSuccess,
    getSupplierFailure,

    updateSupplierSuccess,
    updateSupplierFailure,

    getSupplierProductSuccess,
    getSupplierProductFailure,

    addSupplierProductSuccess,
    addSupplierProductFailure,

    removeSupplierProductSuccess,
    removeSupplierProductFailure,
} from './supplierActions';

const {
    GET_SUPPLIERS_REQUEST,
    GET_SUPPLIER_REQUEST,
    UPDATE_SUPPLIER_REQUEST,
    GET_SUPPLIER_PRODUCT_REQUEST,
    ADD_SUPPLIER_PRODUCT_REQUEST,
    REMOVE_SUPPLIER_PRODUCT_REQUEST,
} = require('./supplierActions').constants;

const { SUPPLIER_TYPE_SUPPLIER, SUPPLIER_TYPE_MANUFACTURER } = require('../utils/values').default;

const getSupplierData = (data) => {
    const params = data || {};
    mapObject(data, (value, key) => {
        if (key === 'type') {
            if (value.includes(1)) {
                params.is_supplier = 1;
            }
            if (value.includes(2)) {
                params.is_manufacturer = 1;
            }
        }
    });

    return params;
};

const makeSupplierData = (data) => {
    let supplier = data;
    if (data) {
        supplier.type = [];
        if (data.is_supplier) {
            supplier.type.push(SUPPLIER_TYPE_SUPPLIER);
        }
        if (data.is_manufacturer) {
            supplier.type.push(SUPPLIER_TYPE_MANUFACTURER);
        }
        supplier = mapPropsToInts(supplier, ['is_active']);
    }

    return supplier;
};

function* getSuppliers({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/supplier/search',
            params: getSupplierData(payload)
        });

        let data = !isUndefined(response.data) ? response.data : [];
        if (data) {
            data = mapPropsToInts(data, ['page', 'total']);
            data.rows = addKeyProp(data.rows);
        }

        yield put(getSuppliersSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getSuppliersFailure(e.response ? e.response.data.message : e));
    }
}

function* getSupplier({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (params.id !== 0) {
            response = yield call(fetchApiAuth, {
                method: 'GET',
                url: '/supplier/get',
                params
            });
        }

        const supplier = !isUndefined(response) ? response.data : {};
        yield put(getSupplierSuccess(makeSupplierData(supplier)));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getSupplierFailure(e.response ? e.response.data.message : e));
    }
}

function* updateSupplier({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/supplier/edit',
            body: payload
        });

        const supplier = !isUndefined(response.data) ? response.data : {};
        if (supplier) {
            browserHistory.push(`/supplier/${supplier.id}`);
            notification.success({ message: 'Success', description: 'Supplier has been updated' });
        }

        yield put(updateSupplierSuccess(makeSupplierData(supplier)));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(updateSupplierFailure(e.response ? e.response.data.message : e));
    }
}

function* getSupplierProduct({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (params.id !== 0) {
            response = yield call(fetchApiAuth, {
                method: 'GET',
                url: '/supplier/product',
                params
            });
        }

        const products = !isUndefined(response) ? response.data : [];
        yield put(getSupplierProductSuccess(addKeyProp(products, 'product_id')));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getSupplierProductFailure(e.response ? e.response.data.message : e));
    }
}

function* addSupplierProduct({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/supplier/product',
            body: getSupplierData(payload)
        });

        const products = !isUndefined(response.data) ? response.data : [];
        yield put(addSupplierProductSuccess(addKeyProp(products, 'product_id')));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(addSupplierProductFailure(e.response ? e.response.data.message : e));
    }
}

function* removeSupplierProduct({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'DELETE',
            url: '/supplier/product',
            params: payload
        });

        const products = !isUndefined(response.data) ? response.data : [];
        yield put(removeSupplierProductSuccess(addKeyProp(products, 'product_id')));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(removeSupplierProductFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* supplierSaga() {
    yield* [
        takeEvery(GET_SUPPLIERS_REQUEST, getSuppliers),
        takeEvery(GET_SUPPLIER_REQUEST, getSupplier),
        takeEvery(UPDATE_SUPPLIER_REQUEST, updateSupplier),
        takeEvery(GET_SUPPLIER_PRODUCT_REQUEST, getSupplierProduct),
        takeEvery(ADD_SUPPLIER_PRODUCT_REQUEST, addSupplierProduct),
        takeEvery(REMOVE_SUPPLIER_PRODUCT_REQUEST, removeSupplierProduct),
    ];
}
