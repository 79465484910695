import React, { useState } from 'react';
import moment from 'moment';
import { Spin, Input, InputNumber, Button, Modal, notification } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as customerActions from '../../../../../core/customer/customerActions';
import greenStatusIcon from '../../../../../assets/images/green-status-icon.png';
import yellowStatusIcon from '../../../../../assets/images/yellow-status-icon.png';
import greyStatusIcon from '../../../../../assets/images/grey-status-icon.png';
import './customerScripts.scss';
import ScriptsModal from './ScriptsModal';

const CustomerScripts = ({ customer, isFetching, actions }) => {
    const [searchValue, setSearchValue] = useState(customer?.reference || '');
    const [receivedObjects, setReceivedObjects] = useState([]);
    const [showScriptsModal, setShowScriptsModal] = useState(false);

    const ordersWithProductsRequiringPrescription = customer.customerOrders.filter(order =>
        order.orderItems.some(item =>
            item.script_status !== 0 && item.product.requires_prescription === 1
        )
    );

    const handleChangeCustomerClick = (e) => {
        e.preventDefault();
        if (searchValue !== '') {
            actions.getCustomerByReferenceRequest({ reference: searchValue });
        }
    };

    const handleProcessClick = () => {
        if (receivedObjects.length === 0) {
            notification.error({ message: 'Error detected', description: 'Please enter some received quantities' });
            return;
        }
        setShowScriptsModal(true);
    };

    const handleSubmit = (data) => {
        actions.processScriptRequest({ orderItems: receivedObjects, prescriptionData: data, customerId: customer.id });
        setShowScriptsModal(false);
        setReceivedObjects([]);
    };

    const getTableRow = (order, index) => {
        const { orderItems } = order;
        const itemsToInclude = orderItems.filter(item => item.product.requires_prescription === 1);
        const rowClass = index % 2 === 0 ? 'evenRow' : 'oddRow';
        const getItemLineValue = (item) => {
            const lineValue = item.quantity * item.product.price;
            return lineValue.toFixed(2);
        };

        const handleReceivedChange = (value, item) => {
            if (value === undefined || value === '' || value === null) return;

            if (value === 0) {
                const filteredObjects = receivedObjects.filter(obj => obj.itemId !== item.id);
                setReceivedObjects(filteredObjects);
                return;
            }

            const receivedObject = {
                orderId: item.order_id,
                itemId: item.id,
                quantityMatched: value,
            };

            const exists = receivedObjects.some(obj => obj.itemId === item.id);

            if (exists) {
                const updatedObjects = receivedObjects.map(obj =>
                    (obj.itemId === item.id ? receivedObject : obj)
                );
                setReceivedObjects(updatedObjects);
            } else {
                setReceivedObjects([...receivedObjects, receivedObject]);
            }
        };

        const getReceivedQuantity = (item) => {
            const receivedObject = receivedObjects.find(obj => obj.itemId === item.id);
            return receivedObject ? receivedObject.quantityMatched : item.quantity_matched;
        };

        const getStatusIcon = (item) => {
            const receivedQuantity = getReceivedQuantity(item);
            if (receivedQuantity === 0) return greyStatusIcon;
            return receivedQuantity.toString() === item.quantity.toString() ? greenStatusIcon : yellowStatusIcon;
        };

        return (
            <div key={order.id} className={`row-container ${rowClass}`}>
                <span style={{ width: '10%' }}>{order.id}</span>
                <span style={{ width: '10%' }}>{moment(order.order_date).format('DD/MM/YYYY')}</span>
                <div className="items-wrapper" style={{ width: '80%' }}>
                    {itemsToInclude.map(item =>
                        <div key={item.id} className="item-wrapper">
                            <span style={{ width: '10%' }}>{item.id}</span>
                            <span style={{ width: '37.5%' }}>{item.product.description}</span>
                            <span style={{ width: '7.5%' }}>{item.product.unit_measure}</span>
                            <span style={{ width: '10%' }}>{item.quantity}</span>
                            <span style={{ width: '10%' }}>{item.quantity}</span>
                            <div style={{ width: '8%' }}>
                                <InputNumber
                                    value={getReceivedQuantity(item)}
                                    min={0} defaultValue={0}
                                    max={item.quantity}
                                    onChange={value => handleReceivedChange(value, item)}
                                    style={{ width: '65px', marginLeft: '3px' }}
                                    disabled={item.script_status === 'processed'} />
                            </div>
                            <div className="icon-wrapper" style={{ width: '4.5%' }}>
                                <img
                                    style={{
                                        width: 15,
                                        height: 15,
                                        marginRight: 5,
                                        marginLeft: 5,
                                    }}
                                    src={getStatusIcon(item)}
                                    alt="status-icon" />
                            </div>
                            <span style={{ width: '12.5%' }}>£{getItemLineValue(item)}</span>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <Modal
                centered width={640}
                footer={null}
                visible={showScriptsModal}
                onCancel={() => setShowScriptsModal(false)}>
                <ScriptsModal
                    data={receivedObjects}
                    onCancel={() => setShowScriptsModal(false)}
                    onSubmit={handleSubmit} />
            </Modal>
            <div className="container">
                <div className="customer-scripts-container">
                    <div className="header-container">
                        <div className="change-customer-section">
                            <h3 className="h-4">Change Customer</h3>
                            <div className="change-customer">
                                <label htmlFor="reference-search">Customer Reference</label>
                                <div>
                                    <Input
                                        id="reference-search"
                                        type="text"
                                        onChange={e => setSearchValue(e.target.value)}
                                        value={searchValue} />
                                    <button onClick={handleChangeCustomerClick}>Go</button>
                                </div>
                            </div>
                        </div>
                        <div className="customer-details-section">
                            <h3 className="h-4">Customer Details</h3>
                            <Spin spinning={isFetching}>
                                <table className="customer-scripts-details-table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '25%' }}>Name</th>
                                            <th style={{ width: '15%' }}>NHS Number</th>
                                            <th style={{ width: '10%' }}>Exemption</th>
                                            <th style={{ width: '17%' }}>NI Number</th>
                                            <th style={{ width: '16%' }}>Certificate Number</th>
                                            <th style={{ width: '17%' }}>Expiry Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{customer.first_name} {customer.last_name}</td>
                                            <td>{customer.nhs_no}</td>
                                            <td>{customer.exemption_reason}</td>
                                            <td>{customer.national_insurance_no}</td>
                                            <td>{customer.certificate_number}</td>
                                            <td>{customer.certificate_expiry_date}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Spin>
                        </div>
                    </div>
                    <div className="orders-header">
                        <h3>Orders</h3>
                        <Button type="primary" onClick={handleProcessClick}>Process Prescriptions</Button>
                    </div>
                    <div className="script-processing-container">
                        <div className="orders-headers">
                            <h5 style={{ width: '10%' }}>Order Number</h5>
                            <h5 style={{ width: '10%' }}>Order Date</h5>
                            <h5 style={{ width: '8%' }}>Item</h5>
                            <h5 style={{ width: '30%' }}>Description</h5>
                            <h5 style={{ width: '6%' }}>UOM</h5>
                            <h5 style={{ width: '8%' }}>Quantity</h5>
                            <h5 style={{ width: '8%' }}>Balance</h5>
                            <h5 style={{ width: '10%' }}>Received</h5>
                            <h5 style={{ width: '10%' }}>Line Value</h5>
                        </div>
                        {ordersWithProductsRequiringPrescription.map((order, index) => getTableRow(order, index))}
                    </div>
                </div>
            </div>
        </>

    );
};

CustomerScripts.propTypes = {
    customer: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    customer: state.customer.customer,
    isFetching: state.customer.isFetching,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(customerActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerScripts);
