import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import DOM from './careHome';

class CareHomePage extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            name: parseInt(props.params.id, 10) ? `#${props.params.id}` : 'New Care Home',
            toolbar: [{ id: 'actionSave', caption: 'Save', icon: 'save', onClick: this.handleFormSubmit }]
        };
    }

    // Handle set page name
    setPageName = name => this.setState({ name });

    // Handle submit update carehome form
    handleFormSubmit = () => this.props.actions.submit('update-care-home');

    render() {
        return this.view();
    }
}

CareHomePage.propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ submit }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CareHomePage);
