import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { isUndefined } from 'underscore';

import * as productActions from '../../../core/product/productActions';
import * as supplierActions from '../../../core/supplier/supplierActions';

import DOM from './updateProduct';

const { EXCLUDE_FROM_AUTO, EXCLUDE_FROM_LETTER } = require('../../../core/utils/values').default;

class UpdateProductForm extends Component {
    constructor(props) {
        super(props);
        this.handleSetPageName(props);
        this.view = DOM;
        this.handleUpdateProductSubmit = this.handleUpdateProductSubmit.bind(this);
    }

    componentWillMount() {
        const { actions, productId } = this.props;
        actions.getProductRequest(productId);
        actions.getSuppliersRequest();
        actions.getProductCategoriesRequest();
    }

    componentWillReceiveProps(nextProps) {
        this.handleSetPageName(nextProps);
    }

    // Handle set page name
    handleSetPageName(props) {
        const { setPageName, initialValues, productId, isFetching } = props;
        if (!isFetching) {
            if (!isUndefined(initialValues.title)) {
                setPageName(`#${initialValues.id} - ${initialValues.title}`);
            } else if (productId === 0) {
                setPageName('New Product');
            }
        }
    }

    // Handle submit form
    handleUpdateProductSubmit = (values) => {
        const valuesCopy = { ...values };
        if (values.requires_prescription === 0 || values.requires_prescription === false || values.requires_prescription === '') {
            valuesCopy.requires_prescription = 0;
        } else {
            valuesCopy.requires_prescription = 1;
        }
        const { actions, productId } = this.props;
        const settings = values.settings || [];
        const formValues = {
            id: productId,
            exclude_from_auto: settings.includes(EXCLUDE_FROM_AUTO) ? 1 : 0,
            exclude_from_letter: settings.includes(EXCLUDE_FROM_LETTER) ? 1 : 0,
            ...valuesCopy
        };
        actions.updateProductRequest(formValues);
    };

    render() {
        return this.view();
    }
}

UpdateProductForm.propTypes = {
    actions: PropTypes.object.isRequired,
    productId: PropTypes.number.isRequired,
};

const UpdateProduct = reduxForm({
    form: 'update-product',
    enableReinitialize: true,
})(UpdateProductForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        initialValues: state.product.product,
        isFetching: state.product.isFetching,
        productCategories: state.product.productCategories,
        suppliers: state.supplier.suppliers.rows,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...productActions,
            ...supplierActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProduct);
