import React from 'react';
import { mapObject, isUndefined, isEmpty } from 'underscore';
import { PropTypes } from 'prop-types';
import { Row, Col, Button } from 'antd';

const SelectCustomer = ({ customer, handleOpenModal, readOnly }) => {
    let customerHeader = false;
    let customerTable = (
        <Row className="c-table__row">
            <Col span={24} md={16} className="no-data">No Customer Selected</Col>
            <Col span={24} md={8}>
                <div className="btn-bar">
                    <Button type="default" className="ant-btn-xs" onClick={() => { handleOpenModal('modalChooseCustomerVisible'); }}>Add Customer</Button>
                </div>
            </Col>
        </Row>
    );

    let doctorTable = [];
    let practiceTable = [];

    if (customer.id) {
        customerHeader = (
            <Row className="c-table__caption">
                <Col span={4} md={2}>ID</Col>
                <Col span={8} md={5}>Name</Col>
                <Col span={8} md={4}>Phone</Col>
                <Col span={8} md={4}>Postcode</Col>
            </Row>
        );

        customerTable = (
            <Row className="c-table__row">
                <Col key="id" span={4} md={2}>#{customer.id}</Col>
                <Col key="name" span={8} md={5}>{customer.first_name} {customer.last_name}</Col>
                <Col key="phone" span={8} md={4}>{customer.home_address && customer.home_address.telephone ? customer.home_address.telephone : '-'}</Col>
                <Col key="postcode" span={8} md={4}>{customer.home_address && customer.home_address.postcode ? customer.home_address.postcode : '-'}</Col>
                <Col span={24} md={9}>
                    <div className="btn-bar">
                        <Button type="default" className="ant-btn-xs" target="blank" href={`/customer/${customer.id}`}>View</Button>
                        {!readOnly ? <Button type="default" className="ant-btn-xs" onClick={() => { handleOpenModal('modalChooseCustomerVisible'); }}>Change</Button> : false}
                    </div>
                </Col>
            </Row>
        );

        if (!isEmpty(customer.doctor)) {
            mapObject({ id: 'Doctor Ref', name: 'Name', email: 'Email', phone_no: 'Phone' }, (value, key) => {
                doctorTable.push(
                    <Col className="break-work" key={key} span={6}>
                        <h6 className="c-table__sm-heading">{value}</h6>
                        { key === 'name' ?
                            <span>{`${customer.doctor.first_name} ${customer.doctor.last_name}`}</span>
                            : <span>{!isUndefined(customer.doctor[key]) ? `${key === 'id' ? '#' : ''}${customer.doctor[key]}` : '-'}</span>}
                    </Col>
                );
                return true;
            });
        }

        if (!isEmpty(customer.practice)) {
            mapObject({ gp_reference: 'GP Ref', title: 'Practice Name', postcode: 'Postcode', fake: '' }, (value, key) => {
                practiceTable.push(
                    <Col key={key} span={6}>
                        <h6 className="c-table__sm-heading">{value}</h6>
                        <span>{!isUndefined(customer.practice[key]) ? customer.practice[key] : false}</span>
                    </Col>
                );
                return true;
            });
        }
    }

    if (isEmpty(doctorTable)) {
        doctorTable = <Col className="no-data" span={12}>No Doctor Assigned</Col>;
    }

    if (isEmpty(practiceTable)) {
        practiceTable = <Col className="no-data" span={12}>No Practice Assigned</Col>;
    }

    return (
        <div className="section">
            <div className="container">
                <Row gutter={48} type="flex" justify="center">
                    <Col className="select_customer_tab" >
                        <div className="section__container">

                            <div className="section__header">
                                <h3 className="h-4">Selected customer</h3>
                            </div>

                            <div className="section-white small-padding">
                                <div className="c-table">
                                    <div className="c-table__header">
                                        {customerHeader}
                                        {customerTable}
                                    </div>

                                    <div className="c-table__block">
                                        <h5 className="h-5">Assigned doctor</h5>
                                        <Row className="c-table__row">
                                            {doctorTable}
                                        </Row>
                                    </div>

                                    <div className="c-table__block">
                                        <h5 className="h-5">Assigned practice</h5>
                                        <Row className="c-table__row">
                                            {practiceTable}
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

SelectCustomer.defaultProps = {
    readOnly: false,
};

SelectCustomer.propTypes = {
    customer: PropTypes.object.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
};

export default SelectCustomer;
