import React from 'react';
import { Checkbox } from 'antd';

import Gridview from '../../../elements/Gridview';
import { GridButton, displayName, displayId, displayNumber } from '../../../core/utils/helpers';

export default function () {
    const { doctors, doctorsTotal, doctorsPage, practices, practicesTotal, practicesPage } = this.props;
    const { doctorId, practiceId } = this.state;

    const renderPracticeCheckbox = (value, record) => (
        <Checkbox
            checked={record.id === practiceId}
            onChange={() => {
                this.handleCheckboxChange({
                    practiceId: record.id,
                    practiceAddressId: record.practice_address_id
                });
            }} />
    );

    const renderDoctorCheckbox = (value, record) => (
        <Checkbox
            checked={record.id === doctorId}
            onChange={() => { this.handleCheckboxChange({ doctorId: record.id }); }} />
    );

    const config = {
        practices: [
            { dataIndex: 'selected', title: 'Selected', className: 'text-center', render: renderPracticeCheckbox },
            { dataIndex: 'gp_reference', title: 'GP Ref', search: true, type: 'text' },
            { dataIndex: 'title', title: 'Practice Name', search: true, type: 'text' },
            { dataIndex: 'address_line_1', title: 'Line 1', search: true, type: 'text', visible: false },
            { dataIndex: 'address_line_2', title: 'Line 2', search: true, type: 'text', visible: false },
            { dataIndex: 'address_line_3', title: 'Line 3', search: true, type: 'text', visible: false },
            { dataIndex: 'city_region', title: 'City/Region', search: true, type: 'text', visible: false },
            { dataIndex: 'postcode', title: 'Postcode', search: true, type: 'text' },
            { dataIndex: 'num_doctors', title: 'Num Doctors', className: 'text-center', render: displayNumber },
            { dataIndex: 'telephone', title: 'Phone', search: true, type: 'text', visible: false },
            { dataIndex: 'fax', title: 'Fax', search: true, type: 'text', visible: false },
            { dataIndex: 'email', title: 'Email', search: true, type: 'text', visible: false },
            { dataIndex: 'key', align: 'right', render: (value, record) => <GridButton href={`/practice/${record.id}`} /> },
        ],
        doctors: [
            { dataIndex: 'selected', title: 'Selected', className: 'text-center', render: renderDoctorCheckbox },
            { dataIndex: 'id', title: 'Doctor Ref', render: displayId, width: 100 },
            { dataIndex: 'name', title: 'Name', render: (value, record) => displayName(record), width: 150 },
            { dataIndex: 'first_name', title: 'Forenames', search: true, type: 'text', visible: false },
            { dataIndex: 'last_name', title: 'Surname', search: true, type: 'text', visible: false },
            { dataIndex: 'email', title: 'Email' },
            { dataIndex: 'phone_no', title: 'Phone' },
            { dataIndex: 'key', align: 'right', render: (value, record) => <GridButton href={`/doctor/${record.id}`} /> },
        ],
    };

    return (
        <Gridview
            title="Search results"
            searchTitle="Doctor Details (search or add)"
            config={config}
            data={{ doctors, practices }}
            handleRow={this.handleRow}
            pagination={{
                doctors: {
                    simple: true,
                    onChange: this.handleDoctorsPagination,
                    pageSize: 10,
                    total: doctorsTotal,
                    current: doctorsPage,
                    className: 'custom-pagination',
                },
                practices: {
                    simple: true,
                    onChange: this.handlePracticesPagination,
                    pageSize: 10,
                    total: practicesTotal,
                    current: practicesPage,
                    className: 'custom-pagination',
                }
            }}
            postTable={this.getAssignButton}
            handleClearSearch={this.handleClearSearch}
            handleSearch={this.handleSearch}
            scroll={{ x: 'max-content' }} />
    );
}
