import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'antd';

import UploadProductStock from '../../forms/UploadProductStock';

const UploadStock = ({ visible, changeVisible }) => (
    <Modal centered width={540} footer={null} visible={visible} onOk={() => changeVisible(false)} onCancel={() => changeVisible(false)}>
        <UploadProductStock changeVisible={changeVisible} />
    </Modal>
);

UploadStock.propTypes = {
    visible: PropTypes.bool.isRequired,
    changeVisible: PropTypes.func.isRequired,
};

export default UploadStock;
