import React from 'react';
import { Form as AntForm, Button } from 'antd';

import Form from '../../../elements/Form';

const FormItem = Form.Item;
const formTypes = Form.formTypes;

export default function () {
    const { handleSubmit, readOnly, orderId } = this.props;
    return (
        <AntForm id="update-order-notes" onSubmit={handleSubmit(this.handleUpdateOrderNotesSubmit)} layout="vertical">
            <div>
                <div className="section__header">
                    <h3 className="h-4">Order admin notes</h3>
                </div>
                <FormItem type={formTypes.TEXTAREA} name="order_notes" label={false} disabled={readOnly} />
                <p className="form__notice">Order notes are specific to this order and every order has its own unique order notes</p>
            </div>
            {orderId ?
                <div className="mt-2">
                    <div className="section__header">
                        <h3 className="h-4">Customer if out / delivery notes</h3>
                    </div>
                    <FormItem type={formTypes.TEXTAREA} name="delivery_notes" label={false} readonly={readOnly} />
                    <p className="form__notice">Warning: editing the delivery notes will edit for every order this customer has, not just this one.</p>
                </div>
            :
                null
            }
            {!readOnly ?
                <div className="d-flex justify-content-center mt-3">
                    <Button type="primary" htmlType="submit" className="wide">Save</Button>
                </div>
            : false}
        </AntForm>
    );
}
