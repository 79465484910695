import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Button } from 'antd';

import successCheckbox from '../../../assets/images/green-checkbox.png';

const ConfirmDispatchOrder = ({ visible, changeVisible, confirmOrder, confirmedSelectedOrderId, customer }) => (
    <Modal centered width={540} footer={null} visible={visible} onOk={() => changeVisible(false)} onCancel={() => changeVisible(false)}>

        <div className="text-center">
            <p className="mt-3">Please confim if you want to mark order as dispatch.</p>
        </div>

        <div className="form-wrap">
            <div className="btn-bar justify-content-center">

                <Button
                    type="primary"
                    className="medium"
                    onClick={() => {
                        confirmOrder(confirmedSelectedOrderId);
                    }}>
                    Confirm
                </Button>

            </div>
            <div className="btn-bar justify-content-center">
                <Button href={`/customer/${customer?.id}`} type="default" className="medium">Patient summary</Button>
                <Button
                    type="default"
                    className="medium"
                    onClick={() => {
                        changeVisible(false);
                    }}>
                    Stay on current page
                </Button>
            </div>
        </div>
    </Modal>
);

ConfirmDispatchOrder.propTypes = {
    visible: PropTypes.bool.isRequired,
    changeVisible: PropTypes.func.isRequired,
    confirmOrder: PropTypes.func.isRequired,
    confirmedSelectedOrderId: PropTypes.any,
    customer: PropTypes.any,
};

export default ConfirmDispatchOrder;
