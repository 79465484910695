import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import DOM from './referrer';

class ReferrersPage extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            modalAddReferrersVisible: false,
            name: parseInt(props.params.id, 10) ? `#${props.params.id}` : 'New Referrer',
            toolbar: this.getToolbar(parseInt(props.params.id, 10))
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ toolbar: this.getToolbar(parseInt(nextProps.params.id, 10)) });
    }

    // Generate toolbar based on id
    getToolbar = (id) => {
        const toolbar = [];
        if (id) {
            toolbar.push({
                id: 'actionAssign',
                caption: 'Assign customer',
                icon: 'assign',
                onClick: () => { this.setModalAddReferrersVisible(true); }
            });
        }
        toolbar.push({ id: 'actionSave', caption: 'Save', icon: 'save', onClick: this.handleFormSubmit });

        return toolbar;
    }

    // Handle toggle modal add referrer customer visibility
    setModalAddReferrersVisible = modalAddReferrersVisible => (this.setState({ modalAddReferrersVisible }));

    // Handle set page name
    setPageName = name => this.setState({ name });

    // Handle submit update referrer form
    handleFormSubmit = () => this.props.actions.submit('update-referrer');

    render() {
        return this.view();
    }
}

ReferrersPage.propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ submit }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferrersPage);
