function generateOrderItems({ orderItemSearchResults }) {
    const valueLists = {
        orderItemIdList: [],
        orderIdList: [],
        productTitleList: [],
        deliveryStatusList: [],
        quantityMatchedList: [],
        quantityDispatchedList: [],
        valueList: [],
        matchList: [],
    };

    orderItemSearchResults.map((orderItem) => {
        valueLists.orderItemIdList.push(orderItem.id);
        valueLists.orderIdList.push(orderItem.order_id);
        valueLists.productTitleList.push(orderItem.product.title);
        valueLists.deliveryStatusList.push(orderItem.delivery_status);
        valueLists.quantityMatchedList.push([orderItem.quantity_matched, orderItem.quantityIncludingMissing]);
        valueLists.quantityDispatchedList.push([orderItem.quantity_dispatched, orderItem.quantityIncludingMissing]);
        valueLists.valueList.push((orderItem.product.price) ? `£${Math.round(orderItem.product.price * 100) / 100}` : 'N/a');
        valueLists.matchList.push(orderItem.prescription_id);
    });

    return [
        {
            fieldName: 'order_item_id',
            fieldLabel: '',
            fieldValues: valueLists.orderItemIdList,
        },
        {
            fieldName: 'order_id',
            fieldLabel: 'Order',
            fieldValues: valueLists.orderIdList,
        },
        {
            fieldName: 'title',
            fieldLabel: 'Item Name',
            fieldValues: valueLists.productTitleList,
        },
        {
            fieldName: 'delivery_status',
            fieldLabel: 'Delivery Status',
            fieldValues: valueLists.deliveryStatusList,
        },
        {
            fieldName: 'quantity_matched',
            fieldLabel: 'Matched / Linked',
            fieldValues: valueLists.quantityMatchedList,
        },
        {
            fieldName: 'quantity_dispatched',
            fieldLabel: 'Dispatched / Linked',
            fieldValues: valueLists.quantityDispatchedList,
        },
        {
            fieldName: 'price',
            fieldLabel: 'Value',
            fieldValues: valueLists.valueList,
        },
        {
            fieldName: 'match',
            fieldLabel: '',
            fieldValues: valueLists.matchList,
        },
    ];
}

function deliveryStatusOptions() {
    return [
        {
            label: 'Picking',
            value: 0,
        },
        {
            label: 'Dispatch',
            value: 1,
        },
        {
            label: 'Backorder',
            value: 2,
        },
        {
            label: 'Processed',
            value: 3,
        },
        {
            label: 'Cancelled',
            value: 4,
        },
    ];
}

export { generateOrderItems, deliveryStatusOptions };
