import React from 'react';

import Gridview from '../../../elements/Gridview';
import { displayId, displayName, GridButton } from '../../../core/utils/helpers';

export default function () {
    // Grideview config
    const config = [
        { dataIndex: 'id', title: 'Customer ID', width: 105, render: displayId },
        { dataIndex: 'first_name', title: 'Customer Name', width: 125, render: (value, record) => displayName(record) },
        { dataIndex: 'nurse_id', title: 'Nurse ID', width: 100, render: displayId },
        { dataIndex: 'nurse_first_name', title: 'Nurse Name', render: (value, record) => displayName(record, 'nurse_first_name', 'nurse_last_name') },
        { dataIndex: 'key', align: 'right', render: (text, record) => <GridButton href={`/customer/${record.id}`} /> },
    ];

    // Gridview title
    const title = (
        <div className="table__topnav">
            <h6 className="h-6">Customers associated with nurses assigned to this care home</h6>
        </div>
    );

    return (
        <Gridview
            scroll={{ y: 250 }}
            preHeader={title}
            config={config}
            data={this.props.customers}
            pagination={false} />
    );
}
