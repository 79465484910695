import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Input, Empty, Spin } from 'antd';
import { isEqual, isEmpty, isArray, findIndex } from 'underscore';

import styles from './styles.js';

import { generateNotes } from './dataGenerators';
import { checkDate, debounce } from '../../../../../../core/utils/helpers';
import { Grammarly, GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react';
import { Editor } from '@tinymce/tinymce-react';
import optionValues from '../../../../../../core/utils/values';

import * as customerActions from '../../../../../../core/customer/customerActions';

class GridviewSection extends Component {
    constructor(props) {
        super(props);
        const { customerNotes } = this.props;

        this.state = {
            filters: {},
            customerNotes,
            isEdit: false,
        };
    }

    componentDidMount() {
        this.handleSearchRequest();
    }

    componentDidUpdate(prevProps, prevState) {
        const { customerNotes, customer } = this.props;

        if(customer?.id !== prevProps?.customer?.id) {
            this.handleSearchRequest();
        } else if (!isEqual(customerNotes, prevProps.customerNotes)) {
            this.setState({ customerNotes });
        }
    }

    handleSearchRequest() {
        const { customer, actions } = this.props;
        const { filters, } = this.state;
        const filtersBuffer = JSON.parse(JSON.stringify(filters));

        actions.getCustomerNotesRequest({ customerId: customer.id, filters: filtersBuffer, limit: 10000, });
    }

    handleClear() {
        const { filters, } = this.state;
        const filtersBuffer = JSON.parse(JSON.stringify(filters));

        Object.keys(filtersBuffer).forEach((key) => {
            filtersBuffer[key] = null;
        });

        this.setState({ filters: filtersBuffer });
    }

    handleSetSearchInput({ event }) {
        const { filters } = this.state;
        this.setState({ filters: { ...filters, email: event.target.value, } });
    }

    handleEditButton({column,fieldName, noteId}) {
        const { customerNotes, editNoteCallback } = this.props;
        const filteredNoteIndex = findIndex(customerNotes, (note) => { return note.id === column; });
        if (filteredNoteIndex !== -1) {
            const selectedNote = customerNotes[filteredNoteIndex];
            editNoteCallback(selectedNote);
        }
    }

    _renderFilterBar() {
        const { filters } = this.state;
        // const { customer } = this.props;
        // const customerId = customer && customer.id;

        return (
            <div style={styles.filterBarContainer}>
                <p style={styles.filterBarTitle}>Customer Notes</p>
                <div style={styles.filterGroup}>
                    <p
                        style={styles.filterLabel}>User:
                    </p>
                    <Input
                        value={filters.email}
                        onChange={event => this.handleSetSearchInput({ event })}
                        style={{ width: 240, height: 24, }} />
                </div>
                <a onClick={() => this.handleClear()}>clear</a>
            </div>
        );
    }

    _renderTableHeaderElements({ field }) {
        switch (field.fieldName) {
        default:
            return (
                <th
                    key={field.fieldName}
                    style={styles.tableHeaderElement}>
                    {field.fieldLabel.toUpperCase()}
                </th>
            );
        }
    }

    transpose(a) {
        return Object.keys(a[0]).map(c => a.map(r => r[c]));
    }

    _renderTableBodyRowElement({ column, fieldName, noteId }) {
        switch (fieldName) {
        case 'text':
            return (
                <td
                    key={`${noteId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <div dangerouslySetInnerHTML={{__html: column}}></div> 
                </td>
            );
        case 'edit':
            return (
                <td
                    key={`${noteId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <div style={styles.editButton} onClick={() => this.handleEditButton({column,fieldName, noteId})} ><span style={styles.btnLabel}>Edit</span></div> 
                </td>
            );
        default:
            return (
                <td
                    key={`${noteId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    {column}
                </td>
            );
        }
    }

    _renderTableBody(data) {
        const matrix = [];
        const fieldNames = [];
        data.map((element) => {
            matrix.push(element.fieldValues);
            fieldNames.push(element.fieldName);
        });
        const transpose = this.transpose(matrix);

        return (
            <tbody>
                {transpose.map((row, rowIndex) => (
                    <tr
                        key={rowIndex}
                        style={styles.tableBodyRow}>
                        {row.map((column, columnIndex) => this._renderTableBodyRowElement({
                            column,
                            fieldName: fieldNames[columnIndex],
                            noteId: row[0],
                        }))}
                    </tr>))}
            </tbody>

        );
    }

    _renderTable() {
        const { customerNotes, filters } = this.state;
        const data = isArray(customerNotes) && !isEmpty(customerNotes) ? generateNotes({ customerNotes, filters }) : [];
        const shouldRenderTableContent = data && isArray(data) && data[0] && isArray(data[0].fieldValues) && !isEmpty(data[0].fieldValues);

        return (
            <table
                style={styles.table}>
                {shouldRenderTableContent ?
                    <React.Fragment>
                        <thead>
                            <tr style={styles.tableHeaderRow}>
                                {data.map(field => (
                                    this._renderTableHeaderElements({ field })
                                ))}
                            </tr>
                        </thead>
                        {this._renderTableBody(data)}
                    </React.Fragment>
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No customerNotes'} />
                }
            </table>
        );
    }

    render() {
        const { height, isGettingCustomerNotes, isCreatingUpdatingCustomerNote } = this.props;

        let gridviewContainerStyles = styles.gridviewContainer;
        if (height || height === 0) {
            gridviewContainerStyles = { ...gridviewContainerStyles, height, minHeight: height };
        }

        return (
            <div style={gridviewContainerStyles} className="customer_auto_orders_gridview">
                <Spin
                    spinning={
                        isGettingCustomerNotes ||
                    isCreatingUpdatingCustomerNote}>
                    {this._renderFilterBar()}
                    {this._renderTable()}
                </Spin>
            </div>
        );
    }
}

GridviewSection.defaultProps = {
    customer: {},
    // actions: {},
    customerNotes: [],
    // height: null,
    // isSearchingOrderItemRepeatInstructionsByFilters: false,
    // activeTab: '6',
    isGettingCustomerNotes: false,
    isCreatingUpdatingCustomerNote: false,
    editNoteCallback: () => {},
};

GridviewSection.propTypes = {
    customer: PropTypes.object,
    // actions: PropTypes.object,
    // height: PropTypes.number,
    customerNotes: PropTypes.array,
    // activeTab: PropTypes.string,
    // isSearchingOrderItemRepeatInstructionsByFilters: PropTypes.bool,
    isGettingCustomerNotes: PropTypes.bool,
    isCreatingUpdatingCustomerNote: PropTypes.bool,
    editNoteCallback: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        customerNotes: state.customer.customerNotes,
        customer: state.customer.customer,
        isGettingCustomerNotes: state.customer.isGettingCustomerNotes,
        isCreatingUpdatingCustomerNote: state.customer.isCreatingUpdatingCustomerNote,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GridviewSection);
