import PropTypes from 'prop-types';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DOM from './productsAdmin';

import {
    handleBasicGridviewMount,
    handleBasicGridviewSearch,
    handleBasicGridviewClearSearch,
    handleBasicGridviewPagination,
} from '../../../core/utils/helpers';

import * as productActions from '../../../core/product/productActions';

class ProductsAdmin extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentWillMount() {
        const { actions } = this.props;
        handleBasicGridviewMount(actions.getProductsRequest, 'searchProducts');
        actions.getProductCategoriesRequest();
    }

    // Handle gridview search
    handleSearch = (values) => {
        const { actions } = this.props;
        handleBasicGridviewSearch(actions.getProductsRequest, 'searchProducts', values);
    }

    // Handle gridview clear search
    handleClearSearch = (reset) => {
        const { actions } = this.props;
        handleBasicGridviewClearSearch(actions.getProductsRequest, 'searchProducts');
        reset();
    }

    // Handle gridview pagination
    handlePagination = (page, pageSize) => {
        const { actions } = this.props;
        handleBasicGridviewPagination(actions.getProductsRequest, 'searchProducts', page, pageSize);
    }

    render() {
        return this.view();
    }
}

ProductsAdmin.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.product.isFetching,
        page: state.product.products.page,
        productCategories: state.product.productCategories,
        products: state.product.products.rows,
        total: state.product.products.total,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...productActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsAdmin);
