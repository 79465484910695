import React, { Component, } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { DatePicker, Input, Checkbox, InputNumber, Button, Icon as AntdIcon, Empty, Spin } from 'antd';
import Select from 'react-select';
import { isEqual, isEmpty, isArray } from 'underscore';
import { browserHistory } from 'react-router';

import styles from './styles.js';

import { generateOrderItemRepeatInstructions, frequencyOptions } from './dataGenerators';
import { checkDate, debounce } from '../../../../../../core/utils/helpers';

import * as customerActions from '../../../../../../core/customer/customerActions';

class GridviewSection extends Component {
    constructor(props) {
        super(props);
        const { orderItemRepeatInstructionSearchResults } = this.props;

        this.state = {
            filters: {},
            orderItemRepeatInstructionSearchResults,
        };
    }

    componentDidMount() {
        this.handleSearchRequest();
    }

    componentDidUpdate(prevProps, prevState) {
        const { customer, orderItemRepeatInstructionSearchResults, activeTab, isSearchingOrderItemRepeatInstructionsByFilters } = this.props;

        // Update the state with new redux state values
        if (!isEqual(customer, prevProps.customer)) {
            this.handleSearchRequest();
            this.setState({ filters: {}, selectedItems: [], });
        }

        if (!isEqual(isSearchingOrderItemRepeatInstructionsByFilters, prevProps.isSearchingOrderItemRepeatInstructionsByFilters) && isSearchingOrderItemRepeatInstructionsByFilters === false) {
            this.setState({ orderItemRepeatInstructionSearchResults });
        }

        if (!isEqual(activeTab, prevProps.activeTab) && activeTab === '6') {
            this.handleSearchRequest();
            this.setState({ filters: {}, selectedItems: [], });
        }
    }

    handleSearchRequest() {
        const { customer, actions } = this.props;
        const { filters, } = this.state;
        const filtersBuffer = JSON.parse(JSON.stringify(filters));

        if (filters.interval && ['object'].includes(typeof filters.interval)) {
            filtersBuffer.interval = filters.interval.value || null;
            filtersBuffer.reorder_date = checkDate(filtersBuffer.reorder_date, 'output', 'YYYY-MM-DD hh:mm:ss');
        }

        actions.searchOrderItemRepeatInstructionsByFiltersRequest({ id: customer.id, filters: filtersBuffer, limit: 10000, });
    }

    handleClear() {
        const { filters, } = this.state;
        const filtersBuffer = JSON.parse(JSON.stringify(filters));

        Object.keys(filtersBuffer).forEach((key) => {
            filtersBuffer[key] = null;
        });

        this.setState({ filters: filtersBuffer }, () => {
            this.handleSearchRequest();
        });
    }

    handleSetItemQuantity({ value, itemId, }) {
        const { orderItemRepeatInstructionSearchResults } = this.state;
        const orderItemRepeatInstructionSearchResultsBuffer = JSON.parse(JSON.stringify(orderItemRepeatInstructionSearchResults));
        const { actions } = this.props;
        const index = orderItemRepeatInstructionSearchResultsBuffer.findIndex(orderItemRepeatInstructionSearchResult => orderItemRepeatInstructionSearchResult.id === itemId);
        orderItemRepeatInstructionSearchResultsBuffer[index].quantity = value;
        this.setState({ orderItemRepeatInstructionSearchResults: orderItemRepeatInstructionSearchResultsBuffer });
        actions.updateOrderItemRepeatInstructionRequest({
            ...orderItemRepeatInstructionSearchResultsBuffer[index],
        });
    }

    handleSetReorderDate({ date, dateString, itemId, }) {
        const { orderItemRepeatInstructionSearchResults } = this.state;
        const orderItemRepeatInstructionSearchResultsBuffer = JSON.parse(JSON.stringify(orderItemRepeatInstructionSearchResults));
        const { actions } = this.props;
        const index = orderItemRepeatInstructionSearchResultsBuffer.findIndex(orderItemRepeatInstructionSearchResult => orderItemRepeatInstructionSearchResult.id === itemId);
        orderItemRepeatInstructionSearchResultsBuffer[index].reorder_date = dateString;
        this.setState({ orderItemRepeatInstructionSearchResults: orderItemRepeatInstructionSearchResultsBuffer });
        actions.updateOrderItemRepeatInstructionRequest({
            ...orderItemRepeatInstructionSearchResultsBuffer[index],
        });
    }

    handleSetInterval({ option, itemId, }) { // Related to repeat orders
        const { orderItemRepeatInstructionSearchResults } = this.state;
        let orderItemRepeatInstructionSearchResultsBuffer = JSON.parse(JSON.stringify(orderItemRepeatInstructionSearchResults));
        const { actions } = this.props;
        const index = orderItemRepeatInstructionSearchResultsBuffer.findIndex(orderItemRepeatInstructionSearchResult => orderItemRepeatInstructionSearchResult.id === itemId);
        if (!isEmpty(orderItemRepeatInstructionSearchResultsBuffer[index].orderItemRepeatInstruction)) {
            const orderItemRepeatInstructionId = orderItemRepeatInstructionSearchResultsBuffer[index].orderItemRepeatInstruction.id;
            orderItemRepeatInstructionSearchResultsBuffer = orderItemRepeatInstructionSearchResultsBuffer.map((orderItemRepeatInstructionSearchResult, index) => {
                const orderItemRepeatInstructionSearchResultBuffer = orderItemRepeatInstructionSearchResult;
                if (orderItemRepeatInstructionSearchResult.orderItemRepeatInstruction.id === orderItemRepeatInstructionId) {
                    orderItemRepeatInstructionSearchResultBuffer.orderItemRepeatInstruction.interval = option.value;
                }
                return orderItemRepeatInstructionSearchResultBuffer;
            });
            this.setState({ orderItemRepeatInstructionSearchResults: orderItemRepeatInstructionSearchResultsBuffer });
            actions.updateOrderItemRepeatInstructionRequest({
                ...orderItemRepeatInstructionSearchResultsBuffer[index],
            });
        }
    }

    handleSetSpecialLineItem({ checked, itemId, }) {
        const { orderItemRepeatInstructionSearchResults } = this.state;
        const orderItemRepeatInstructionSearchResultsBuffer = JSON.parse(JSON.stringify(orderItemRepeatInstructionSearchResults));
        const { actions } = this.props;
        const index = orderItemRepeatInstructionSearchResultsBuffer.findIndex(orderItemRepeatInstructionSearchResult => orderItemRepeatInstructionSearchResult.id === itemId);
        orderItemRepeatInstructionSearchResultsBuffer[index].is_special = checked ? 1 : 0;
        this.setState({ orderItemRepeatInstructionSearchResults: orderItemRepeatInstructionSearchResultsBuffer });
        actions.updateOrderItemRepeatInstructionRequest({
            ...orderItemRepeatInstructionSearchResultsBuffer[index],
        });
    }

    handleSetOrderItemRepeatInstructionStatus({ itemId, column }) { // Related to repeat orders
        const { orderItemRepeatInstructionSearchResults } = this.state;
        let orderItemRepeatInstructionSearchResultsBuffer = JSON.parse(JSON.stringify(orderItemRepeatInstructionSearchResults));
        const { actions } = this.props;
        const index = orderItemRepeatInstructionSearchResultsBuffer.findIndex(orderItemRepeatInstructionSearchResult => orderItemRepeatInstructionSearchResult.id === itemId);
        if (!isEmpty(orderItemRepeatInstructionSearchResultsBuffer[index].orderItemRepeatInstruction)) {
            const orderItemRepeatInstructionId = orderItemRepeatInstructionSearchResultsBuffer[index].orderItemRepeatInstruction.id;
            orderItemRepeatInstructionSearchResultsBuffer = orderItemRepeatInstructionSearchResultsBuffer.map((orderItemRepeatInstructionSearchResult, index) => {
                const orderItemRepeatInstructionSearchResultBuffer = orderItemRepeatInstructionSearchResult;
                if (orderItemRepeatInstructionSearchResult.orderItemRepeatInstruction.id === orderItemRepeatInstructionId) {
                    orderItemRepeatInstructionSearchResultBuffer.orderItemRepeatInstruction.status = column ? 0 : 1;
                }
                return orderItemRepeatInstructionSearchResultBuffer;
            });
            this.setState({ orderItemRepeatInstructionSearchResults: orderItemRepeatInstructionSearchResultsBuffer });
            actions.updateOrderItemRepeatInstructionRequest({
                ...orderItemRepeatInstructionSearchResultsBuffer[index],
            });
        }
    }

    handleRemoveOrderItemRepeatInstruction({ itemId, column }) { // Related to repeat orders
        const { orderItemRepeatInstructionSearchResults } = this.state;
        let orderItemRepeatInstructionSearchResultsBuffer = JSON.parse(JSON.stringify(orderItemRepeatInstructionSearchResults));
        const { actions } = this.props;
        const index = orderItemRepeatInstructionSearchResultsBuffer.findIndex(orderItemRepeatInstructionSearchResult => orderItemRepeatInstructionSearchResult.id === itemId);
        if (!isEmpty(orderItemRepeatInstructionSearchResultsBuffer[index].orderItemRepeatInstruction)) {
            const orderItemRepeatInstructionId = orderItemRepeatInstructionSearchResultsBuffer[index].orderItemRepeatInstruction.id;
            orderItemRepeatInstructionSearchResultsBuffer = orderItemRepeatInstructionSearchResultsBuffer.map((orderItemRepeatInstructionSearchResult, index) => {
                const orderItemRepeatInstructionSearchResultBuffer = orderItemRepeatInstructionSearchResult;
                if (orderItemRepeatInstructionSearchResult.orderItemRepeatInstruction.id === orderItemRepeatInstructionId) {
                    if (orderItemRepeatInstructionSearchResult.is_repeat === 1) {
                        delete orderItemRepeatInstructionSearchResultBuffer.orderItemRepeatInstruction;
                    } else {
                        return null;
                    }
                }
                return orderItemRepeatInstructionSearchResultBuffer;
            }).filter(element => element);
            this.setState({ orderItemRepeatInstructionSearchResults: orderItemRepeatInstructionSearchResultsBuffer });
            actions.deleteOrderItemRepeatInstructionRequest({ order_item_repeat_instruction_id: column, });
        }
    }

    handleSetSearchInput({ event }) {
        const { filters } = this.state;
        this.setState({ filters: { ...filters, sku: event.target.value, } });
    }

    _renderFilterBar() {
        const { filters } = this.state;
        const { customer } = this.props;
        const customerId = customer && customer.id;

        return (
            <div style={styles.filterBarContainer}>
                <p style={styles.filterBarTitle}>Auto-Order Items</p>
                <div style={styles.filterGroup}>
                    <p
                        style={styles.filterLabel}>Re-order Date:
                    </p>
                    <DatePicker
                        format="DD/MM/YYYY"
                        value={checkDate(filters.reorder_date, 'input', 'DD/MM/YYYY')}
                        onChange={(date, dateString) => {
                            this.setState({ filters: { ...filters, reorder_date: dateString, } }, () => {
                                this.handleSearchRequest();
                            });
                        }}
                        style={{ width: 120, height: 24, }} />
                </div>
                <div style={styles.filterGroup}>
                    <p
                        style={styles.filterLabel}>{'Frequency:'}
                    </p>
                    <Select
                        styles={styles.selectInput}
                        isSearchable={false}
                        value={filters.interval}
                        onChange={(option) => {
                            this.setState({ filters: { ...filters, interval: option, } }, () => {
                                this.handleSearchRequest();
                            });
                        }}
                        options={
                            frequencyOptions().map(option => ({
                                label: option.label,
                                value: option.value,
                            }))
                        } />
                </div>
                <div style={styles.filterGroup}>
                    <p
                        style={styles.filterLabel}>Product sku:
                    </p>
                    <Input
                        value={filters.sku}
                        onChange={event => this.handleSetSearchInput({ event })}
                        style={{ width: 240, height: 24, }} />
                </div>
                <a onClick={() => this.handleClear()}>clear</a>
                <Button
                    style={styles.searchBarButton}
                    onClick={() => {
                        browserHistory.push({
                            pathname: `/order/0/customer/${customerId}`,
                            state: {
                                from: 'auto-order-items'
                            }
                        });
                    }}>New Auto-Order
                </Button>
            </div>
        );
    }

    _renderTableHeaderElements({ field }) {
        const allRemoved = field.fieldValues.every(fieldValue => fieldValue === 'order_item_repeat_instruction_removed');
        if (!allRemoved) {
            switch (field.fieldName) {
            default:
                return (
                    <th
                        key={field.fieldName}
                        style={styles.tableHeaderElement}>
                        {field.fieldLabel.toUpperCase()}
                    </th>
                );
            }
        } else {
            return (<div />);
        }
    }

    transpose(a) {
        return Object.keys(a[0]).map(c => a.map(r => r[c]));
    }

    _renderTableBodyRowElement({ column, fieldName, itemId, }) {
        switch (fieldName) {
        case 'order_item_id':
            return (
                <td
                    key={`${itemId}_${fieldName}`}
                    style={styles.tableBodyElement} />
            );
        case 'product_sku':
            return (
                <td
                    key={`${itemId}_${fieldName}`}
                    style={{ ...styles.tableBodyElement, fontWeight: 'bold', }}>
                    {column}
                </td>
            );
        case 'quantity':
            return (
                <td
                    key={`${itemId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <InputNumber
                        value={column}
                        size="small"
                        style={{ width: 75, height: 24, }}
                        onChange={value => this.handleSetItemQuantity({ value, itemId, })}
                        min={1}
                        max={10}
                        defaultValue={3} />
                </td>
            );
        case 'reorder_date':
            return (
                <td
                    key={`${itemId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <DatePicker
                        placeholder={'Pick'}
                        format={'DD/MM/YYYY'}
                        value={checkDate(column, 'input', 'DD/MM/YYYY')}
                        onChange={(date, dateString) => this.handleSetReorderDate({ date, dateString, itemId, })}
                        style={{ width: 120, height: 24, }} />
                </td>
            );
        case 'frequency':
            if (column !== 'order_item_repeat_instruction_removed') {
                const orderItemRepeatInstructionInterval = frequencyOptions().find(option => option.value === column);
                return (
                    <td
                        key={`${itemId}_${fieldName}`}
                        style={styles.tableBodyElement}>
                        <Select
                            styles={styles.selectInput}
                            isSearchable={false}
                            value={orderItemRepeatInstructionInterval}
                            autosize
                            onClick={e => e.preventDefault()}
                            onChange={option => this.handleSetInterval({ option, itemId, })}
                            options={frequencyOptions().map(option => (
                                {
                                    label: option.label,
                                    value: option.value,
                                }
                            ))} />
                    </td>
                );
            }
            return (<div />);
        case 'special_line_item':
            return (
                <td
                    key={`${itemId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <Checkbox
                        checked={!!column}
                        onChange={event => this.handleSetSpecialLineItem({ checked: event.target.checked, itemId, })}
                        style={{ width: 24, height: 24, }} />
                </td>
            );
        case 'pause_play':
            if (column !== 'repeated_order_item_removed') {
                return (
                    <td
                        key={`${itemId}_${fieldName}`}
                        style={styles.tableBodyElement}>
                        <Button
                            onClick={() => this.handleSetOrderItemRepeatInstructionStatus({ itemId, column })}
                            style={styles.searchBarButton}>
                            <AntdIcon style={{ fontSize: '150%' }} type={(column === 0) ? 'play-circle' : 'pause-circle'} />{(column === 0) ? 'Resume' : 'Pause'}
                        </Button>
                    </td>
                );
            }
            return (<div />);
        case 'cancel':
            if (column !== 'repeated_order_item_removed') {
                return (
                    <td
                        key={`${itemId}_${fieldName}`}
                        style={styles.tableBodyElement}>
                        <Button
                            style={styles.searchBarButton}
                            onClick={() => this.handleRemoveOrderItemRepeatInstruction({ itemId, column })}>
                            <AntdIcon style={{ fontSize: '150%' }} type="stop" />Cancel
                        </Button>
                    </td>
                );
            }
            return (<div />);
        default:
            return (
                <td
                    key={`${itemId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    {column}
                </td>
            );
        }
    }

    _renderTableBody(data) {
        const matrix = [];
        const fieldNames = [];
        data.map((element) => {
            matrix.push(element.fieldValues);
            fieldNames.push(element.fieldName);
        });
        const transpose = this.transpose(matrix);

        return (
            <tbody>
                {transpose.map((row, rowIndex) => (
                    <tr
                        key={rowIndex}
                        style={styles.tableBodyRow}>
                        {row.map((column, columnIndex) => this._renderTableBodyRowElement({
                            column,
                            fieldName: fieldNames[columnIndex],
                            itemId: row[0],
                        }))}
                    </tr>))}
            </tbody>

        );
    }

    _renderTable() {
        const { orderItemRepeatInstructionSearchResults, filters } = this.state;
        const data = isArray(orderItemRepeatInstructionSearchResults) && !isEmpty(orderItemRepeatInstructionSearchResults) ? generateOrderItemRepeatInstructions({ orderItemRepeatInstructionSearchResults, filters }) : [];
        const shouldRenderTableContent = data && isArray(data) && data[0] && isArray(data[0].fieldValues) && !isEmpty(data[0].fieldValues);

        return (
            <table
                style={styles.table}>
                {shouldRenderTableContent ?
                    <React.Fragment>
                        <thead>
                            <tr style={styles.tableHeaderRow}>
                                {data.map(field => (
                                    this._renderTableHeaderElements({ field })
                                ))}
                            </tr>
                        </thead>
                        {this._renderTableBody(data)}
                    </React.Fragment>
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No auto-order items'} />
                }
            </table>
        );
    }

    render() {
        const { height, isSearchingOrderItemRepeatInstructionsByFilters, isUpdatingOrderItemRepeatInstruction, isDeletingOrderItemRepeatInstruction } = this.props;

        let gridviewContainerStyles = styles.gridviewContainer;
        if (height || height === 0) {
            gridviewContainerStyles = { ...gridviewContainerStyles, height, minHeight: height };
        }

        return (
            <div style={gridviewContainerStyles} className="customer_auto_orders_gridview">
                <Spin
                    spinning={
                        isSearchingOrderItemRepeatInstructionsByFilters ||
                    isUpdatingOrderItemRepeatInstruction ||
                    isDeletingOrderItemRepeatInstruction}>
                    {this._renderFilterBar()}
                    {this._renderTable()}
                </Spin>
            </div>
        );
    }
}

GridviewSection.defaultProps = {
    customer: {},
    actions: {},
    orderItemRepeatInstructionSearchResults: [],
    height: null,
    isSearchingOrderItemRepeatInstructionsByFilters: false,
    activeTab: '6',
    isUpdatingOrderItemRepeatInstruction: false,
    isDeletingOrderItemRepeatInstruction: false,
};

GridviewSection.propTypes = {
    customer: PropTypes.object,
    actions: PropTypes.object,
    height: PropTypes.number,
    orderItemRepeatInstructionSearchResults: PropTypes.array,
    activeTab: PropTypes.string,
    isSearchingOrderItemRepeatInstructionsByFilters: PropTypes.bool,
    isUpdatingOrderItemRepeatInstruction: PropTypes.bool,
    isDeletingOrderItemRepeatInstruction: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        orderItemRepeatInstructionSearchResults: state.customer.orderItemRepeatInstructionSearchResults,
        customer: state.customer.customer,
        isSearchingOrderItemRepeatInstructionsByFilters: state.customer.isSearchingOrderItemRepeatInstructionsByFilters,
        isUpdatingOrderItemRepeatInstruction: state.customer.isUpdatingOrderItemRepeatInstruction,
        isDeletingOrderItemRepeatInstruction: state.customer.isDeletingOrderItemRepeatInstruction,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GridviewSection);
