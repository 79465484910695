import React from 'react';
import { Row, Col } from 'antd';

import Layout from '../../components/layout/Layout';
import UpdateNurse from '../../components/forms/UpdateNurse';
import HomesNurse from '../../components/gridviews/HomesNurse';
import CustomersNurse from '../../components/gridviews/CustomersNurse';

export default function () {
    const { name, toolbar } = this.state;
    const id = parseInt(this.props.params.id, 10);
    const breadcrumb = { name: 'nurse', params: { id, name } };

    return (
        <Layout title={name} toolbar={toolbar} breadcrumb={breadcrumb}>
            {/* FORM */}
            <UpdateNurse nurseId={id} setPageName={this.setPageName} />

            {/* GRIDVIEWS */}
            {id ?
                <div className="container">
                    <div className="mt-3 mb-2">
                        <div className="section__header">
                            <h3 className="h-4">Nurse Associations</h3>
                        </div>
                        <Row gutter={80} type="flex" justify="center" className="section-p-0">
                            <Col span={24} lg={12}>
                                <HomesNurse nurseId={id} />
                            </Col>
                            <Col span={24} lg={12}>
                                <CustomersNurse nurseId={id} />
                            </Col>
                        </Row>
                    </div>
                </div>
            : false}
        </Layout>
    );
}
