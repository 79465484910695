function generateProducts({ products }) {
    const valueLists = {
        skuList: [],
        titleList: [],
        quantityList: [],
        productIdList: [],
        valueLists: [],
    };

    products.map((product) => {
        valueLists.productIdList.push(product.id);
        valueLists.skuList.push(`#${product.sku}`);
        valueLists.titleList.push(product.title);
        valueLists.quantityList.push([product.item_quantity, product.quantity]);
        valueLists.valueLists.push(product.price);
    });

    return [
        {
            fieldName: 'product_id',
            fieldLabel: 'Id',
            fieldValues: valueLists.productIdList,
        },
        {
            fieldName: 'title',
            fieldLabel: 'Title',
            fieldValues: valueLists.titleList,
        },
        {
            fieldName: 'quantity',
            fieldLabel: 'Quantity / Current Stock',
            fieldValues: valueLists.quantityList,
        },
        {
            fieldName: 'price',
            fieldLabel: 'Value',
            fieldValues: valueLists.valueLists,
        },
        {
            fieldName: 'remove',
            fieldLabel: '',
            fieldValues: valueLists.productIdList,
        },
    ];
}

export { generateProducts };
