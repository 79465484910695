import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import DOM from './referrersSearch';

import {
    handleBasicGridviewMount,
    handleBasicGridviewSearch,
    handleBasicGridviewClearSearch,
    handleBasicGridviewPagination,
} from '../../../core/utils/helpers';

import * as referrerActions from '../../../core/referrer/referrerActions';

class ReferrersSearch extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentWillMount() {
        const { actions } = this.props;
        handleBasicGridviewMount(actions.getReferrersRequest, 'searchReferrers');
    }

    // Handle gridview search
    handleSearch = (values) => {
        const { actions } = this.props;
        handleBasicGridviewSearch(actions.getReferrersRequest, 'searchReferrers', values);
    }

    // Handle gridview clear search
    handleClearSearch = (reset) => {
        const { actions } = this.props;
        handleBasicGridviewClearSearch(actions.getReferrersRequest, 'searchReferrers');
        reset();
    }

    // Handle gridview pagination
    handlePagination = (page, pageSize) => {
        const { actions } = this.props;
        handleBasicGridviewPagination(actions.getReferrersRequest, 'searchReferrers', page, pageSize);
    }

    render() {
        return this.view();
    }
}

ReferrersSearch.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.referrer.isFetching,
        page: state.referrer.referrers.page,
        referrers: state.referrer.referrers.rows,
        total: state.referrer.referrers.total,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...referrerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferrersSearch);
