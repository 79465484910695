const buttonWidth = 200;

const styles = {
    text2: {
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2d3748',
    },
    searchBarButton: {
        width: buttonWidth,
        borderRadius: (2.67 * buttonWidth) / 2,
        backgroundColor: '#149566',
        height: undefined,
        aspectRatio: 2.67 * buttonWidth
    },
    footer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    }
};

export default styles;
