const styles = {
    mainContainer: {
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '6px 12px',
    },
    headerContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    footerContainer: {
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    submitButton: {
        width: 64,
        borderRadius: 12,
        border: 'solid',
        borderColor: '#149566',
        borderWidth: 1,
        fontSize: 10,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#149566',
        height: 29,
    },
    title: {
        width: 125,
        height: 25,
        flexGrow: 0,
        margin: 0,
        fontSize: 18,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#2d3748',
    },
    textArea: {
        width: '90%',
        flexGrow: 0,
        margin: '10px 7px 10px 0',
        fontSize: 12,
        fontWeight: 300,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.7,
        letterSpacing: 0.48,
        textAlign: 'left',
        color: '#2d3748',
    },
    nameText: {
        flexGrow: 0,
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#149566',
    },
    contactDetailsText: {
        flexGrow: 0,
        fontSize: '12px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#718096'
    },

    // Simple Attributes
    searchBarContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
        marginBottom: 10,
        width: '100%',
        alignItems: 'center',
        height: 24,
        minWidth: 250,
    },
    searchBarTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#718096',
        margin: 0,
        verticalAlign: 'center',
        minWidth: 77,
    },
    searchBarButton: {
        width: 64,
        borderRadius: 12,
        border: 'solid',
        borderColor: '#149566',
        borderWidth: 1,
        fontSize: 10,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#149566',
        height: 24,
    },
    selectInput: {
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '25px',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: 'inherit',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: 'inherit',
        }),
    },

};

export default styles;
