import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import Select from 'react-select';
import { Checkbox, Input, Button, Modal, notification, Spin } from 'antd';
import { isEqual } from 'underscore';
import { browserHistory } from 'react-router';

import line from '../../../assets/images/line.png';

import { debounce } from '../../../core/utils/helpers';

import * as customerActions from '../../../core/customer/customerActions';

import styles from './styles.js';

const activeOptions = [
    {
        label: 'Active',
        value: 1,
    },
    {
        label: 'Inactive',
        value: 0,
    },
    {
        label: 'All',
        value: null,
    },
];

class Patient extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {},
            customerSearchResults: [],
            searchInput: '',
            selectedInput: null,
            customerSearchModalIsActive: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        const { customerSearchResults } = this.state;

        // Update the state with new redux state values
        if (!isEqual(nextProps.customerSearchResults, customerSearchResults)) {
            this.setState({ customerSearchResults: nextProps.customerSearchResults });
        }
    }

    handleSetSearchInput({ value, action }) {
        if (action.action === 'input-change') {
            const { filters } = this.state;
            const { activeStatus } = filters;

            const actions = this.props.actions;

            // Set state
            const stringValue = value.toString();
            this.setState({
                searchInput: stringValue,
            });


            debounce(
                actions.searchCustomersByAttributesRequest({
                    searchInput: stringValue,
                    activeStatus: activeStatus && activeStatus.value,
                })
                , 50);
        } else {
            this.setState({ searchInput: action.prevInputValue, });
        }
    }

    handleSetSelectedInput({ value, action }) {
        if (action.action === 'select-option') {
            this.setState({
                selectedInput: value,
            });
        }
    }

    handleOnChangeStatusInput({ option, action }) {
        const { actions } = this.props;
        const { searchInput } = this.state;
        const { filters } = this.state;
        this.setState({ filters: { ...filters, activeStatus: option, } });
        actions.searchCustomersByAttributesRequest({
            searchInput,
            activeStatus: option && option.value,
        });
    }

    handleOnClick() {
        const actions = this.props.actions;
        const { selectedInput } = this.state;

        if (selectedInput) {
            const customerId = selectedInput.id;
            actions.getCustomerRequest({ id: customerId });
            this.setState({
                selectedInput: null,
                customerSearchResults: [],
                customerSearchModalIsActive: false,
            });
            browserHistory.push('/prescription-matcher');
        } else {
            notification.error({ message: 'Error: You must select a customer before clicking "Go".' });
        }
    }

    _renderSearchBar() {
        const { filters } = this.state;
        const customerSearchResults = (typeof this.state.customerSearchResults !== 'string') ? this.state.customerSearchResults : [];

        const options = customerSearchResults.map(value => (
            {
                label: `${value.full_name} (ID - ${value.id})`,
                id: value.id,
                value: this.state.searchInput,
            }
        ));

        return (
            <div
                style={styles.searchBarContainer}
                className={'prescription_matcher_patient'}>
                <Select
                    className={'customer_search_input'}
                    styles={{
                        ...styles.selectInput,
                        valueContainer: (provided, state) => ({
                            ...provided,
                            padding: '0px 8px',
                        }),
                        control: (provided, state) => ({
                            ...provided,
                            background: '#fff',
                            borderColor: '#9e9e9e',
                            minHeight: '25px',
                            height: '25px',
                            boxShadow: state.isFocused ? null : null,
                            '&:hover': {
                                borderColor: '#149566',
                                color: '#149566'
                            },
                        }),
                    }}
                    onFocus={() => this.setState({ searchInput: '', selectedInput: null })}
                    value={this.state.selectedInput}
                    inputValue={!this.state.selectedInput && this.state.searchInput}
                    placeholder={'Search'}
                    onInputChange={(value, action) => this.handleSetSearchInput({ value, action })}
                    onChange={(value, action) => this.handleSetSelectedInput({ value, action })}
                    options={options} />
                <Select
                    styles={{
                        ...styles.selectInput,
                        valueContainer: (provided, state) => ({
                            ...provided,
                            padding: '0px 8px',
                        }),
                        control: (provided, state) => ({
                            ...provided,
                            background: '#fff',
                            borderColor: '#9e9e9e',
                            height: '25px',
                            minHeight: undefined,
                            boxShadow: state.isFocused ? null : null,
                            '&:hover': {
                                borderColor: '#149566',
                                color: '#149566'
                            },
                            minWidth: '120px',
                        }),
                    }}
                    isSearchable={false}
                    placeholder={'Status'}
                    value={filters.status}
                    onChange={(option, action) => this.handleOnChangeStatusInput({ option, action })}
                    options={activeOptions.map(option => ({
                        label: option.label,
                        value: option.value,
                    }))} />
                <Button
                    style={styles.searchBarButton}
                    onClick={() => this.handleOnClick()}
                    success="true">Go
                </Button>
            </div >
        );
    }

    _renderSearchModal() {
        const { customerSearchModalIsActive } = this.state;

        return (
            <Modal
                className={'customer_search_modal'}
                visible={customerSearchModalIsActive}
                onCancel={() => this.setState({ customerSearchModalIsActive: false, })}
                maskClosable
                closable={false}
                closeIcon={false}
                footer={null}
                header={null}>
                {this._renderSearchBar()}
            </Modal>
        );
    }

    render() {
        const { customer, isGettingCustomer } = this.props;
        const { first_name, last_name } = customer || {};

        const contactDetails = [
            customer?.home_address?.address_line_1,
            customer?.home_address?.address_line_2,
            customer?.home_address?.address_line_3,
            customer?.home_address?.address_line_4,
            customer?.home_address?.postcode,
        ];

        return (
            <div
                style={styles.mainContainer}
                className={'prescription_matcher_patient'}>
                <Spin
                    spinning={isGettingCustomer}>
                    <div>
                        <div style={styles.headerContainer}>
                            <div style={{ marginRight: 64, }} />
                            <p style={styles.title}>Patient</p>
                            <Button
                                onClick={() => this.setState({ customerSearchModalIsActive: true, })}
                                style={styles.submitButton}>Change
                            </Button>
                        </div>
                        <img src={line} style={{ width: '100%' }} />
                    </div>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', }}>
                        <a onClick={() => browserHistory.push(`/customer/${customer.id}`)}>
                            <p style={styles.nameText}>{`${first_name} ${last_name}`}</p>
                        </a>
                        <p style={styles.contactDetailsText}>{contactDetails.map((value, index) => `${value || ''}${(value && index !== contactDetails.length - 1) ? ', ' : ''}`)}
                        </p>
                        <p style={styles.contactDetailsText}>{`${customer?.home_address?.telephone || ''}`}
                        </p>
                    </div>
                    {this._renderSearchModal()}
                </Spin >
            </div>
        );
    }
}

Patient.defaultProps = {
    customer: {},
    customerSearchResults: [],
    isGettingCustomer: false,
};

Patient.propTypes = {
    customer: PropTypes.object.isRequired,
    customerSearchResults: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    isGettingCustomer: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        customer: state.customer.customer,
        customerSearchResults: state.customer.customerSearchResults,
        isGettingCustomer: state.customer.isGettingCustomer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            submit,
            ...customerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Patient);
