import React from 'react';

import Layout from '../../components/layout/Layout';
import AddProduct from '../../components/modals/AddProduct';
import UpdateSupplier from '../../components/forms/UpdateSupplier';
import ProductsSupplier from '../../components/gridviews/ProductsSupplier';

export default function () {
    const id = parseInt(this.props.params.id, 10);
    const breadcrumb = { name: 'supplier', params: { id, name: this.state.name } };

    return (
        <Layout title={this.state.name} toolbar={this.state.toolbar} breadcrumb={breadcrumb}>
            {/* FORM */}
            <UpdateSupplier supplierId={id} setPageName={this.setPageName} />

            {/* GRIDVIEW */}
            {id ? <ProductsSupplier supplierId={id} /> : false}

            {/* MODAL */}
            <AddProduct
                supplierId={id}
                visible={this.state.modalAddProductVisible}
                changeVisible={this.setModalAddProductVisible} />
        </Layout>
    );
}
