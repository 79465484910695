/* global document:true */

import alertify from 'alertify.js';
import lockr from 'lockr';
import React from 'react';
import ReactDOM from 'react-dom';
import { isUndefined, isNull } from 'underscore';
import { Provider } from 'react-redux';
import { Router, Route, browserHistory } from 'react-router';

// Svg Polyfill For IE
import svg4everybody from 'svg4everybody';

import 'antd/dist/antd.css';
import './styles/style.css';

import RouteLayout from './components/layout/RouteLayout';
import Login from './pages/Login';
import Orders from './pages/Orders';
import Order from './pages/Order';
import Customers from './pages/Customers';
import Customer from './pages/Customer';
import Admin from './pages/Admin';
import Product from './pages/Product';
import Practice from './pages/Practice';
import Nurse from './pages/Nurse';
import CareHome from './pages/CareHome';
import Referrer from './pages/Referrer';
import Supplier from './pages/Supplier';
import Reports from './pages/Reports';
import PrescriptionMatcher from './pages/PrescriptionMatcher';
import MarkingAsDispatch from './pages/MarkingAsDispatch';
import ExemptionLetter from './pages/ExemptionLetter';

import NotFound from './pages/NotFound';

/**
 * ## Actions
 *  The necessary actions for dispatching our bootstrap values
 */
import { logoutRequest } from './core/auth/authActions';
import { setVersion } from './core/global/globalActions';

/**
 *  The version of the app but not displayed yet
 */
import pack from '../package.json';

/**
 * ### configureStore
 */
import configureStore from './core/store';

alertify.logPosition('top right');
// Svg Polyfill For IE
svg4everybody();

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
    LOCALSTORAGE_EXPIRY_TIME,
} = require('./core/constants').default;

const store = configureStore({});

const VERSION = pack.version;

store.dispatch(setVersion(VERSION));

const scrollToTop = () => {
    const pusher = document.getElementById('app');
    if (pusher !== null) {
        pusher.scrollIntoView();
    }
};

function isLoggedIn() {
    const response = lockr.get(AUTH_KEY);

    if (!isUndefined(response)) {
        const authDate = lockr.get(LOCALSTORAGE_EXPIRES_KEY);

        if (!isUndefined(authDate)) {
            const aDate = new Date(authDate);
            const aNow = new Date();
            if (aNow < aDate) {
                aNow.setDate(aNow.getDate() + 1);
            }
            const milliseconds = aNow - aDate;
            const difference = Math.floor(milliseconds / 1000 / 60);
            if (difference < LOCALSTORAGE_EXPIRY_TIME) {
                // Logged in
                return true;
            }
        }
        // Expired
        return null;
    }

    // Not logged in
    return false;
}

function onlyGuestsAllowed() {
    if (isLoggedIn() === true) {
        browserHistory.push('/orders');
    }
}

function onlyAuthorisedAllowed() {
    const checkLoggedIn = isLoggedIn();
    if (!checkLoggedIn || isNull(checkLoggedIn)) {
        alertify.error(isNull(checkLoggedIn) ? 'Session expired. Please log in again.' : 'You need to login first.');
        store.dispatch(logoutRequest());
    }
}

ReactDOM.render(
    <Provider store={store}>
        <Router history={browserHistory} onUpdate={scrollToTop}>
            <Route component={RouteLayout} onEnter={onlyGuestsAllowed}>
                <Route path="/" component={Login} />
                <Route path="login" component={Login} />
            </Route>

            <Route component={RouteLayout} onEnter={onlyAuthorisedAllowed}>
                <Route path="/orders" component={Orders} />
                <Route path="/order/:id(/customer/:customerId)" component={Order} />
                <Route path="/customers" component={Customers} />
                <Route path="/customer/:id" component={Customer} />
                <Route path="/admin" component={Admin} />
                <Route path="/product/:id" component={Product} />
                <Route path="/practice/:id" component={Practice} />
                <Route path="/nurse/:id" component={Nurse} />
                <Route path="/care-home/:id" component={CareHome} />
                <Route path="/referrer/:id" component={Referrer} />
                <Route path="/supplier/:id" component={Supplier} />
                <Route path="/reports" component={Reports} />
                <Route path="/exemption-letter(/:customerId)" component={ExemptionLetter} />
                <Route path="/prescription-matcher(/:prescriptionId)" component={PrescriptionMatcher} />
                <Route path="/marking-as-dispatch" component={MarkingAsDispatch} />
            </Route>

            <Route path="*" component={NotFound} />
        </Router>
    </Provider>,
    document.getElementById('root')
);
