import { put, call, takeEvery } from 'redux-saga/effects';
import alertify from 'alertify.js';
import { browserHistory } from 'react-router';
import { isUndefined } from 'underscore';
import { notification } from 'antd';
import { fetchApiAuth, formatParams } from '../utils/api';
import { addKeyProp, mapPropsToInts } from '../utils/helpers';

import {
    getNursesSuccess,
    getNursesFailure,

    getNurseSuccess,
    getNurseFailure,

    updateNurseSuccess,
    updateNurseFailure,

    getNurseCarehomeSuccess,
    getNurseCarehomeFailure,

    addNurseCarehomeSuccess,
    addNurseCarehomeFailure,

    removeNurseCarehomeSuccess,
    removeNurseCarehomeFailure,

    getNurseCustomerSuccess,
    getNurseCustomerFailure,

    addNurseCustomerSuccess,
    addNurseCustomerFailure,
} from './nurseActions';

const {
    GET_NURSES_REQUEST,
    GET_NURSE_REQUEST,
    UPDATE_NURSE_REQUEST,
    GET_NURSE_CAREHOME_REQUEST,
    ADD_NURSE_CAREHOME_REQUEST,
    REMOVE_NURSE_CAREHOME_REQUEST,
    GET_NURSE_CUSTOMER_REQUEST,
    ADD_NURSE_CUSTOMER_REQUEST,
} = require('./nurseActions').constants;

function* getNurses({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/nurse/search',
            params: formatParams(payload)
        });

        let data = !isUndefined(response.data) ? response.data : [];
        if (data) {
            data = mapPropsToInts(data, ['page', 'total']);
            data.rows = addKeyProp(data.rows);
        }

        yield put(getNursesSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getNursesFailure(e.response ? e.response.data.message : e));
    }
}

function* getNurse({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (params.id !== 0) {
            response = yield call(fetchApiAuth, {
                method: 'GET',
                url: '/nurse/get',
                params
            });
        }

        const nurse = !isUndefined(response) ? response.data : {};
        yield put(getNurseSuccess(nurse));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getNurseFailure(e.response ? e.response.data.message : e));
    }
}

function* updateNurse({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/nurse/edit',
            body: payload
        });

        const nurse = !isUndefined(response.data) ? response.data : {};
        if (nurse) {
            browserHistory.push(`/nurse/${nurse.id}`);
            notification.success({ message: 'Success', description: 'Nurse has been updated' });
        }

        yield put(updateNurseSuccess(nurse));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(updateNurseFailure(e.response ? e.response.data.message : e));
    }
}

function* getNurseCarehome({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (params.id !== 0) {
            response = yield call(fetchApiAuth, {
                method: 'GET',
                url: '/nurse/carehome',
                params
            });
        }

        let data = !isUndefined(response.data) ? response.data : [];
        if (data) {
            data = addKeyProp(data);
        }

        yield put(getNurseCarehomeSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getNurseCarehomeFailure(e.response ? e.response.data.message : e));
    }
}

function* addNurseCarehome({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/nurse/carehome',
            body: {
                id: payload.nurseId,
                carehome_id: payload.carehomeId,
            }
        });

        let data = !isUndefined(response.data) ? response.data : [];
        if (data) {
            data = addKeyProp(data);
        }

        yield put(addNurseCarehomeSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(addNurseCarehomeFailure(e.response ? e.response.data.message : e));
    }
}

function* removeNurseCarehome({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'DELETE',
            url: '/nurse/carehome',
            params: {
                id: payload.nurseId,
                carehome_id: payload.carehomeId,
            }
        });

        let data = !isUndefined(response.data) ? response.data : [];
        if (data) {
            data = addKeyProp(data);
        }

        yield put(removeNurseCarehomeSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(removeNurseCarehomeFailure(e.response ? e.response.data.message : e));
    }
}

function* getNurseCustomer({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (params.id !== 0) {
            response = yield call(fetchApiAuth, {
                method: 'GET',
                url: '/nurse/customer',
                params
            });
        }

        let data = !isUndefined(response.data) ? response.data : [];
        if (data) {
            data = addKeyProp(data);
        }

        yield put(getNurseCustomerSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getNurseCustomerFailure(e.response ? e.response.data.message : e));
    }
}

function* addNurseCustomer({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/nurse/customer',
            body: {
                id: payload.nurseId,
                customer_id: payload.customerId
            }
        });

        let data = !isUndefined(response.data) ? response.data : [];
        if (data) {
            data = addKeyProp(data);
        }

        yield put(addNurseCustomerSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(addNurseCustomerFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* nurseSaga() {
    yield* [
        takeEvery(GET_NURSES_REQUEST, getNurses),
        takeEvery(GET_NURSE_REQUEST, getNurse),
        takeEvery(UPDATE_NURSE_REQUEST, updateNurse),
        takeEvery(GET_NURSE_CAREHOME_REQUEST, getNurseCarehome),
        takeEvery(ADD_NURSE_CAREHOME_REQUEST, addNurseCarehome),
        takeEvery(REMOVE_NURSE_CAREHOME_REQUEST, removeNurseCarehome),
        takeEvery(GET_NURSE_CUSTOMER_REQUEST, getNurseCustomer),
        takeEvery(ADD_NURSE_CUSTOMER_REQUEST, addNurseCustomer),
    ];
}
