import { Component } from 'react';
import { isEmpty, isObject, mapObject } from 'underscore';

import DOM from './breadcrumb';

const tree = [
    {
        name: 'home',
        label: 'JEMSYS',
        href: '/customers',
        children: [
            // Orders
            {
                name: 'orders',
                label: 'Orders',
                href: '/orders',
                children: [
                    { name: 'order', label: ':name', href: '/order/:id' }
                ]
            },
            // Customers
            {
                name: 'customers',
                label: 'Customers',
                href: '/customers',
                children: [
                    { name: 'customer', label: ':name', href: '/customer/:id' }
                ]
            },
            // Admin
            {
                name: 'admin',
                label: 'Admin',
                href: '/admin',
                children: [
                    {
                        name: 'products',
                        label: 'Products',
                        children: [
                            { name: 'product', label: ':name', href: '/product/:id' }
                        ]
                    },
                    {
                        name: 'practices',
                        label: 'Practices',
                        children: [
                            { name: 'practice', label: ':name', href: '/practice/:id' }
                        ]
                    },
                    {
                        name: 'nurses-care-homes',
                        label: 'Nurses & Care Homes',
                        children: [
                            { name: 'nurse', label: ':name', href: '/nurse/:id' },
                            { name: 'care-home', label: ':name', href: '/care-home/:id' }
                        ]
                    },
                    {
                        name: 'referrers',
                        label: 'Referrers',
                        children: [
                            { name: 'referrer', label: ':name', href: '/referrer/:id' }
                        ]
                    },
                    {
                        name: 'suppliers',
                        label: 'Suppliers & Manufacturers',
                        children: [
                            { name: 'supplier', label: ':name', href: '/supplier/:id' }
                        ]
                    },
                ]
            },
            // Reports
            { name: 'reports', label: 'Reports', href: '/reports', },
        ]
    }
];

class Breadcrumb extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    getBreadcrumbTree(breadcrumbParams, subTree) {
        let name = breadcrumbParams;
        if (isObject(breadcrumbParams)) {
            name = breadcrumbParams.name;
        }

        const branches = subTree || tree;
        let breadcrumb = [];

        branches.forEach((branch) => {
            let label = branch.label;
            let href = branch.href;

            if (isObject(breadcrumbParams.params)) {
                // replace params with values
                mapObject(breadcrumbParams.params, (value, key) => {
                    const pattern = new RegExp(`:${key}`, 'g');
                    label = label.replace(pattern, value);
                    href = href ? href.replace(pattern, value) : href;
                });
            } else {
                // replace :id with values
                label = label.replace(/:id/g, breadcrumbParams.params);
                href = href ? href.replace(/:id/g, breadcrumbParams.params) : href;
            }

            if (name === branch.name) {
                // If names match return object
                breadcrumb.push({ label, href });
            } else if (branch.children) {
                // Else check further down tree
                const children = this.getBreadcrumbTree(breadcrumbParams, branch.children);
                // If children is not empty then add to breadcrumbs array
                if (!isEmpty(children)) {
                    breadcrumb.push({ label, href });
                    breadcrumb = breadcrumb.concat(children);
                }
            }
        });

        return breadcrumb;
    }

    render() {
        return this.view(this.props);
    }
}

export default Breadcrumb;
