/* eslint-disable */
import PropTypes from 'prop-types';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DOM from './layout';

class Layout extends Component {

    constructor(props) {
        super(props);
        this.view = DOM;
    }

    render() {
        return this.view(this.props);
    }
}

Layout.defaultProps = {
    breadcrumb: {},
    button: '',
    toolbar: [],
    type: 'normal',
};

Layout.propTypes = {
    breadcrumb: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    button: PropTypes.node,
    children: PropTypes.node.isRequired,
    title: PropTypes.node.isRequired,
    toolbar: PropTypes.array,
    type: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
