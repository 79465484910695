import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty, isUndefined } from 'underscore';
import {
    Checkbox,
    DatePicker,
    Input,
    InputNumber,
    Radio,
    Select,
    Switch,
} from 'antd';

import Icon from '../../elements/Icon';
import * as formTypes from './FormItem';

const { DISPLAY_DATE_FORMAT } = require('../../core/utils/values').default;

const CheckboxGroup = Checkbox.Group;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const TextArea = Input.TextArea;

class FormField extends Component {

    // Update field value
    updateValue = (currentValue) => {
        const { props: { input }, config: { handle } } = this.props;
        input.onChange(currentValue);
        if (this.props.config && this.props.config.onChange) this.props.config.onChange(currentValue);
        handle(currentValue);
    }

    // Update date field value
    updateDateValue = (currentValue) => {
        let value = currentValue;
        if (currentValue) {
            value = currentValue.format(DISPLAY_DATE_FORMAT);
        }

        this.updateValue(value);
    }

    render() {
        const { props: { input, meta: { touched, error } }, config } = this.props;
        const { type, label, name, defaultValue, placeholder, disabled, options, handleSearch, filterOption, min, max } = config;
        let formItem = false;

        // Make form field based on type
        switch (type) {

        case formTypes.CUSTOM:
            formItem = name;
            break;

        case formTypes.TEXT:
        case formTypes.EMAIL:
        case formTypes.PASSWORD:
        case formTypes.HIDDEN:
            formItem = (
                <Input
                    {...input}
                    disabled={disabled}
                    onChange={this.updateValue}
                    placeholder={placeholder}
                    type={type}
                    value={input.value} />
        );
            break;

        case formTypes.TEXTAREA:
            formItem = (
                <TextArea
                    {...input}
                    autosize={{ minRows: 8, maxRows: 20 }}
                    disabled={disabled}
                    onChange={this.updateValue}
                    placeholder={placeholder}
                    value={input.value} />
            );
            break;

        case formTypes.NUMBER:
            formItem = (
                <InputNumber
                    {...input}
                    className="form__inputnumber"
                    defaultValue={1}
                    disabled={disabled}
                    max={max || 100}
                    min={min || 0}
                    onChange={this.updateValue}
                    placeholder={placeholder}
                    value={input.value} />
            );
            break;

        case formTypes.SELECT: {

            let value = input.value;
            if (!input.value && input.value !== 0) {
                value = undefined;
                delete input.value;
            }
            if (input.name == 'status') {
                input.defaultValue = 1;
            }
            formItem = (
                <Select
                    {...input}
                    disabled={disabled}
                    filterOption={filterOption}
                    onChange={this.updateValue}
                    optionFilterProp="children"
                    placeholder={placeholder}
                    onSearch={handleSearch}
                    showSearch
                    defaultActiveFirstOption
                    // value={value}
                    suffixIcon={<Icon name="arrow-down" className="anticon anticon-down ant-select-arrow-icon" />}>
                    {options.map(option => <Option key={`select-${name}-${option.value}`} value={option.value}>{option.label}</Option>)}
                </Select>
            );
            break;
        }

        case formTypes.DATE:
            formItem = (
                <DatePicker
                    disabled={disabled}
                    format="DD/MM/YYYY"
                    onChange={this.updateDateValue}
                    onOk={this.updateDateValue}
                    placeholder={placeholder || '   /   /   '}
                    suffixIcon={<Icon name="calendar" className="anticon anticon-calendar ant-calendar-picker-icon" />}
                    value={input.value ? moment(input.value, 'DD/MM/YYYY') : null} />
            );
            break;

        case formTypes.RADIO:
            formItem = (
                <RadioGroup disabled={disabled} onChange={this.updateValue} value={input.value || defaultValue} {...input}>
                    {options.map(option => <Radio key={`radio-${name}-${option.value}`} value={option.value}>{option.label}</Radio>)}
                </RadioGroup>
            );
            break;

        case formTypes.CHECKBOX:
            if (!isEmpty(options)) {
                formItem = (
                    <CheckboxGroup
                        disabled={disabled}
                        onChange={this.updateValue}
                        options={options}
                        value={input.value || []} />
                );
            } else {
                formItem = <Checkbox checked={input.value || false} disabled={disabled} onChange={this.updateValue} {...input}>{label}</Checkbox>;
            }
            break;

        case formTypes.SWITCH: {
            let value = input.value || false;
            if (typeof input.value === 'string') {
                value = input.value === 'true';
            }

            formItem = (
                <Switch
                    {...input}
                    checked={value}
                    className="form__swith"
                    defaultChecked={defaultValue}
                    disabled={disabled}
                    onChange={this.updateValue} />
            );
            break;
        }

        case formTypes.MULTISELECT:
            // TODO: add suffix icon
            formItem = (
                <Select
                    disabled={disabled}
                    filterOption={(search, option) => option.props.children.toLowerCase().indexOf(search.toLowerCase()) >= 0}
                    mode="multiple"
                    onChange={this.updateValue}
                    optionFilterProp="children"
                    placeholder={placeholder}
                    showSearch
                    value={input.value || []}>
                    {options.map(option => <Option key={`multiselect-${name}-${option.value}`} value={option.value}>{option.label}</Option>)}
                </Select>
            );
            break;

        default:
            break;
        }

        return (
            <div className={touched && error ? 'has-error' : ''}>
                {formItem}
                {touched && (error && <div className="ant-form-explain"><span>{error}</span></div>)}
            </div>
        );
    }
}

FormField.defaultProps = {
    props: {
        meta: {
            touched: false,
            error: false,
            warning: false
        }
    },
};

FormField.propTypes = {
    config: PropTypes.object.isRequired,
    props: PropTypes.object,
};

export default FormField;
