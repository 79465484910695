import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { findIndex, isUndefined, isEmpty } from 'underscore';
import DOM from './ExemptionLetter';
import { browserHistory } from 'react-router';
import * as customerActions from '../../core/customer/customerActions';
import alertify from 'alertify.js';

const EXEMPTION_REASONS = [
    { label: 'A - Is aged 60 or older, or under 16 (unless the birthdate is on the form)', value: 'A', },
    { label: 'B - Is 16, 17 or 18 years old and in full-time education', value: 'B', },
    { label: 'D - Materntity exemption certificate', value: 'D', },
    { label: 'E - Medical exemption certificate', value: 'E', },
    { label: 'F - Prescription pre-payment certificate', value: 'F', },
    { label: 'G - Prescription exemption certificate issued by the Ministry of Defence', value: 'G', },
    { label: 'L - HC2 (full help) certificate', value: 'L', },
    { label: 'H - Income Support or Income-related Employment and Support Allowance', value: 'H', },
    { label: 'K - Income-based Jobseekers Allowance', value: 'K', },
    { label: 'M - Tax Credit Exemption Certificate', value: 'M', },
    { label: 'S - Pension Credit guarantee credit (including partners)', value: 'S', },
    { label: 'U - Universal Credit and meets the criteria. Learn more at nhsbsa.nhs.uk/UC', value: 'U', },
];

class ExemptionLetter extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {

            name: 'Payment/Exemption Letter',
            toolbar: this.getToolbar(props.params.id),
            letterList: [
                {
                    text: null,
                    required: null,
                    email: null,
                    letter: null,
                    fax: null,
                    price: null,
                    type: 1,
                    customer_id: null,
                },
                {
                    text: null,
                    required: null,
                    email: null,
                    letter: null,
                    fax: null,
                    price: null,
                    type: 2,
                    customer_id: null,
                },
            ],
        };
    }

    setletter = ({ type, inputType, value }) => {
        const { letterList } = this.state;
        const letterIndex = letterList.findIndex(letter => letter.type === type);

        switch (inputType) {
        case 0:
            letterList[letterIndex].required = (value) ? 1 : 0;
            this.setState({ letterList: [...letterList], });
            break;
        case 1:
            letterList[letterIndex].email = (value) ? 1 : 0;
            this.setState({ letterList: [...letterList], });
            break;
        case 2:
            letterList[letterIndex].letter = (value) ? 1 : 0;
            this.setState({ letterList: [...letterList], });
            break;
        case 3:
            letterList[letterIndex].fax = (value) ? 1 : 0;
            this.setState({ letterList: [...letterList], });
            break;
        case 4:
            letterList[letterIndex].text = value;
            this.setState({ letterList: [...letterList], });
            break;
        case 5:
            letterList[letterIndex].price = value;
            this.setState({ letterList: [...letterList], });
            break;
        default:
            break;
        }
    }

    componentDidMount() {
        const { actions } = this.props;
        
        const customerId = this.props.params.customerId ? parseInt(this.props.params.customerId, 10) : null;
        actions.getCustomerRequest({ id: customerId });
        this.handleSetLettersAndInfo();
    }

    componentDidUpdate(prevProps) {
        this.handleSetLettersAndInfo(prevProps);
    }

    safelyExtractProperty(object, property) {
        return (object && typeof object[property] !== 'undefined') && object[property];
    }

    handleSetLettersAndInfo(prevProps = null) {
        const { selectedCustomer } = this.props;
        
        const exemption_reason = EXEMPTION_REASONS.find(reason => reason.value === selectedCustomer.exemption_reason);
        const paymentLetter = selectedCustomer.customer_letters && selectedCustomer?.customer_letters?.paymentLetter;
        const exemptionLetter = selectedCustomer.customer_letters && selectedCustomer?.customer_letters?.exemptionLetter;

        if (!prevProps || (prevProps && ((prevProps.selectedCustomer 
                && (selectedCustomer.id !== prevProps.selectedCustomer.id) )))) {
                this.setState({
                    letterList: [
                        {
                            text: this.safelyExtractProperty(paymentLetter, 'text'),
                            required: this.safelyExtractProperty(paymentLetter, 'required') || 0,
                            email: this.safelyExtractProperty(paymentLetter, 'email'),
                            letter: this.safelyExtractProperty(paymentLetter, 'letter'),
                            fax: this.safelyExtractProperty(paymentLetter, 'fax'),
                            price: this.safelyExtractProperty(paymentLetter, 'price'),
                            type: 1,
                            id: this.safelyExtractProperty(paymentLetter, 'id'),
                            bypassOrder: true,
                            customer_id: selectedCustomer.id,
                        },
                        {
                            text: this.safelyExtractProperty(exemptionLetter, 'text') || (exemption_reason && exemption_reason.label),
                            required: this.safelyExtractProperty(exemptionLetter, 'required') || 0,
                            email: this.safelyExtractProperty(exemptionLetter, 'email') || 0,
                            letter: this.safelyExtractProperty(exemptionLetter, 'letter') || 0,
                            fax: this.safelyExtractProperty(exemptionLetter, 'fax') || 0,
                            price: this.safelyExtractProperty(exemptionLetter, 'price'),
                            type: 2,
                            id: this.safelyExtractProperty(exemptionLetter, 'id'),
                            bypassOrder: true,
                            customer_id: selectedCustomer.id,
                        },
                    ],
                });
            }
    }

    // Handle submit update order form
    handleUpdateOrderSubmit = (values) => {
        const { actions, selectedCustomer, } = this.props;
        const { letterList } = this.state;
        const browserHistoryState = browserHistory.getCurrentLocation().state;
        const letterListB = letterList.filter(letter => !isEmpty(letter.text));
        const paymentLetter = letterListB.find(letter => letter.type === 1);
        if(isEmpty(letterListB)) {
            alertify.error('You need to add some text');
            return null;
        }
        if (paymentLetter && (paymentLetter.price == null)) {
            alertify.error('You have written a payment letter without specifying a price');
            return null;
        }
        if (!letterList.every(letter => (letter.text == null) || !(letter.required == null))) {
            alertify.error('You have written at least one letter without setting its required status');
            return null;
        }
        
        actions.upsertLettersRequest(letterListB);
        
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ toolbar: this.getToolbar(parseInt(nextProps.params.id, 10)) });
    }

    // Handle get toolbar based on tab
    getToolbar = (id, key) => {
        const tab = key || 1;
        const toolbar = [
            { id: 'actionSave', caption: 'Save', icon: 'save', onClick: this.handleUpdateOrderSubmit }
        ];

        return toolbar;
    };

    // Handle set page name
    setPageName = name => this.setState({ name });

    render() {
        return this.view();
    }
}

ExemptionLetter.propTypes = {
    // actions: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        order: state.order.order,
        selectedCustomer: state.customer.customer,
        isFetching: state.order.isFetching,
        isGettingCustomer: state.customer.isGettingCustomer,
        isUpdatingLetter: state.customer.isUpdatingLetter
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ ...customerActions, submit }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExemptionLetter);
