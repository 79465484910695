import React from 'react';
import { Modal, Button } from 'antd';
import PropTypes from 'prop-types';
import './confirmModal.scss';

const ConfirmModal = ({ visible, handleCancel, confirmOrder, orderIdToConfirm, customer, backorderWarning }) => (
    <Modal centered width={540} footer={null} visible={visible} onCancel={handleCancel}>

        <div className="text-center">
            <p className="mt-3">Please confim if you want to mark order as dispatch.</p>
        </div>

        {backorderWarning && <div className="text-center warning-message">
            <p className="mt-3">Warning:  Not all order quanitity will be dispatched and status will be set to backorder.</p>
        </div>}

        <div className="form-wrap">
            <div className="btn-bar justify-content-center">

                <Button
                    type="primary"
                    className="medium"
                    onClick={() => {
                        confirmOrder(orderIdToConfirm);
                    }}>
                    Confirm
                </Button>

            </div>
            <div className="btn-bar justify-content-center">
                <Button href={`/customer/${customer?.id}`} type="default" className="medium">Patient summary</Button>
                <Button
                    type="default"
                    className="medium"
                    onClick={handleCancel}>
                    Stay on current page
                </Button>
            </div>
        </div>
    </Modal>
);

ConfirmModal.defaultProps = {
    customer: {},
    order: {},
    actions: {},
    selectedOrderItemsArray: [],
    orderIdToConfirm: null,
    backorderWarning: false,
};

ConfirmModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    confirmOrder: PropTypes.func.isRequired,
    orderIdToConfirm: PropTypes.any,
    customer: PropTypes.any,
    backorderWarning: PropTypes.bool,
};

export default ConfirmModal;
