import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DOM from './homesNurse';

import * as nurseActions from '../../../core/nurse/nurseActions';

class HomesNurse extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            modalAddHomeVisible: false,
        };
    }

    componentWillMount() {
        const { actions, nurseId } = this.props;
        actions.getNurseCarehomeRequest(nurseId);
    }

    // Handle toggle add carehome nurse modal visibility
    setModalAddHomeVisible = modalAddHomeVisible => this.setState({ modalAddHomeVisible });

    // Handle remove carehome nurse
    handleRemoveCarehomeNurse = (carehomeId) => {
        const { actions, nurseId } = this.props;
        actions.removeNurseCarehomeRequest({ carehomeId, nurseId });
    }

    render() {
        return this.view();
    }
}

HomesNurse.propTypes = {
    actions: PropTypes.object.isRequired,
    nurseId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        carehomes: state.nurse.carehomes,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...nurseActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomesNurse);
