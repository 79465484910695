import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import * as chaserActions from '../../core/chaser/chaserActions';
import * as backorderChaserActions from '../../core/backorderChaser/backorderChaserActions';
import * as dispatchActions from '../../core/dispatch/dispatchActions';

import DOM from './orders';

class OrdersPage extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            title: 'Orders',
            toolbar: this.getToolbar()
        };
    }

    // Generate toolbar based on tab
    getToolbar = (key) => {
        const tab = key || 1;
        const toolbar = [];

        if (tab === 1) {
            toolbar.push({ id: 'actionRunChasers', caption: "Run today's chasers", icon: 'bell', onClick: this.handleRunChaser });
        } else if (tab === 2) {
            toolbar.push({ id: 'actionAuthoriseDispatch', caption: 'Authorise for Dispatch', icon: 'check', onClick: this.handleAuthorise });
        } else if (tab === 3) {
            toolbar.push({ id: 'actionBackorderChaser', caption: 'Print Despatch/Picking Notes', icon: 'check', onClick: this.handleBackorder });
        }
        toolbar.push({ id: 'actionNewOrder', caption: 'New order', icon: 'new-order', href: '/order/0' });

        return toolbar;
    }

    // Handle tab change
    handleTabChange = key => this.setState({ toolbar: this.getToolbar(parseInt(key, 10)) });

    // Handle run chasers
    handleRunChaser = () => {
        const { chasers, actions } = this.props;
        actions.runChasersRequest({ post: chasers.post, fax: chasers.fax, email: chasers.email });
    }

    handleBackorder = () => {
        const { backorderChasers, actions } = this.props;
        actions.runBackorderChaserRequest({ post: backorderChasers.post, backorderpost: backorderChasers.backorderPost, backorderfax: backorderChasers.backorderFax, backorderEmail: backorderChasers.backorderEmail });
    }

    // Handle authorise orders
    handleAuthorise = () => {
        const { dispatches, actions } = this.props;
        actions.authoriseDispatchesRequest(dispatches);
    }

    render() {
        return this.view();
    }
}

OrdersPage.propTypes = {
    actions: PropTypes.object.isRequired,
    chasers: PropTypes.object.isRequired,
    backorderChasers: PropTypes.object.isRequired,
    dispatches: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        chasers: state.chaser,
        backorderChasers: state.backorderChaser,
        dispatches: state.dispatch.dispatches,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...backorderChaserActions,
                ...chaserActions,
                ...dispatchActions
            },
            dispatch
        )
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPage);
