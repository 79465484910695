import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import DOM from './suppliersSearch';

import {
    handleBasicGridviewMount,
    handleBasicGridviewSearch,
    handleBasicGridviewClearSearch,
    handleBasicGridviewPagination,
} from '../../../core/utils/helpers';

import * as productActions from '../../../core/product/productActions';
import * as supplierActions from '../../../core/supplier/supplierActions';

class SuppliersSearch extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentWillMount() {
        const { actions } = this.props;
        handleBasicGridviewMount(actions.getSuppliersRequest, 'searchSuppliers');
        actions.getProductCategoriesRequest();
    }

    // Handle gridview search
    handleSearch = (values) => {
        const { actions } = this.props;
        handleBasicGridviewSearch(actions.getSuppliersRequest, 'searchSuppliers', values);
    }

    // Handle gridview clear search
    handleClearSearch = (reset) => {
        const { actions } = this.props;
        handleBasicGridviewClearSearch(actions.getSuppliersRequest, 'searchSuppliers');
        reset();
    }

    // Handle gridview pagination
    handlePagination = (page, pageSize) => {
        const { actions } = this.props;
        handleBasicGridviewPagination(actions.getSuppliersRequest, 'searchSuppliers', page, pageSize);
    }

    render() {
        return this.view();
    }
}

SuppliersSearch.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        productCategories: state.product.productCategories,
        suppliers: state.supplier.suppliers.rows,
        total: state.supplier.suppliers.total,
        page: state.supplier.suppliers.page,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...productActions,
            ...supplierActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SuppliersSearch);
