import React from 'react';

import Gridview from '../../../elements/Gridview';
import Icon from '../../../elements/Icon';
import { displayId, GridButton } from '../../../core/utils/helpers';

export default function () {
    // Render gridview buttons
    const renderButtons = (text, record) => (
        <div className="btn-bar">
            <GridButton href={`/product/${record.product_id}`} />
            <GridButton href={() => this.handleRemoveSupplierProduct(record.product_id)} title="Remove" />
        </div>
    );

    // Gridview config
    const config = [
        { dataIndex: 'sku', title: 'ID', width: 170, render: displayId },
        { dataIndex: 'description', title: 'Product Name', width: 400 },
        { dataIndex: 'is_supplier', title: 'Supplier', align: 'center', width: 150, render: (value, record) => <Icon name="check" visible={record.is_supplier === '1'} /> },
        { dataIndex: 'is_manufacturer', title: 'Manufacturer', align: 'center', width: 150, render: (value, record) => <Icon name="check" visible={record.is_manufacturer === '1'} /> },
        { dataIndex: 'key', align: 'right', render: renderButtons },
    ];

    return (
        <Gridview
            title={'Supplier & Manufacturer\'s Products'}
            scroll={{ y: 250 }}
            config={config}
            data={this.props.products}
            pagination={false} />
    );
}
