import React from 'react';
import { Link } from 'react-router';

import Icon from '../../../elements/Icon';
import LogoMain from '../../../elements/LogoMain';
import NavLink from '../../../elements/NavLink';
import { getBaseUrl } from '../../../core/utils/api';

import userAvatar from '../../../assets/images/user-photo.png';

import './menu.css';

export default function () {
    const { user } = this.props;
    const menuItems = [
        { id: 1, linkname: 'Customers', href: '/customers', iconName: 'customers' },
        { id: 2, linkname: 'Order', href: '/orders', iconName: 'order' },
        { id: 3, linkname: 'Admin', href: '/admin', iconName: 'admin' },
        { id: 6, linkname: 'MARKING AS DISPATCHED', href: '/marking-as-dispatch', iconName: 'order' },
        { id: 4, linkname: 'Reports', href: '/reports', iconName: 'report' },
        { id: 5, linkname: 'Log out', href: null, iconName: 'log-out', class: 'menu__logout link-green', onClick: this.handleLogout }
    ];

    return (
        <div className="menu">
            <button type="button" className="btn btn-empty" onClick={this.handleMenuClick}>
                <Icon name="menu" />
                <span className="text-semibold">MENU</span>
            </button>

            <div className={`menu__wrap ${this.state.menuOpen}`}>
                <span className="menu__backdrop" onClick={this.handleMenuBackdropClick} />
                <div className="menu__inner">

                    <button type="button" className="btn-empty menu__close" onClick={this.handleMenuClick}>
                        <Icon name="close" />
                    </button>

                    <div className="menu__header">
                        <Link to="/">
                            <LogoMain color="white" width="120" />
                        </Link>
                        <div className="profile">
                            <div className="profile__avatar">
                                <img src={user.profile ? `${getBaseUrl()}${user.profile}` : userAvatar} alt="Username" />
                            </div>
                            <h6 className="profile__username">{user.name}</h6>
                        </div>
                    </div>

                    <ul className="menu__nav">
                        {menuItems.map(item => (
                            <NavLink to={item.href || null} onClick={item.onClick || null} className="menu__navlink" key={item.id}>
                                <div className="menu__nav-icon">
                                    <Icon name={item.iconName} />
                                </div>
                                <span>{item.linkname}</span>
                            </NavLink>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}
