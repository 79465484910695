import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import DOM from './practicesSearch';

import {
    handleBasicGridviewMount,
    handleBasicGridviewSearch,
    handleBasicGridviewClearSearch,
    handleBasicGridviewPagination,
} from '../../../core/utils/helpers';

import * as practiceActions from '../../../core/practice/practiceActions';

class PracticesSearch extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentWillMount() {
        const { actions } = this.props;
        handleBasicGridviewMount(actions.getPracticesRequest, 'searchPractices');
    }

    // Handle gridview search
    handleSearch = (values) => {
        const { actions } = this.props;
        handleBasicGridviewSearch(actions.getPracticesRequest, 'searchPractices', values);
    }

    // Handle gridview clear search
    handleClearSearch = (reset) => {
        const { actions } = this.props;
        handleBasicGridviewClearSearch(actions.getPracticesRequest, 'searchPractices');
        reset();
    }

    // Handle gridview pagination
    handlePagination = (page, pageSize) => {
        const { actions } = this.props;
        handleBasicGridviewPagination(actions.getPracticesRequest, 'searchPractices', page, pageSize);
    }

    render() {
        return this.view();
    }
}

PracticesSearch.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        page: state.practice.practices.page,
        practices: state.practice.practices.rows,
        total: state.practice.practices.total,
        isFetching: state.practice.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...practiceActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PracticesSearch);
