import { checkDate } from '../../../../../../core/utils/helpers';

function generateOrderItemRepeatInstructions({ orderItemRepeatInstructionSearchResults, filters }) {
    const valueLists = {
        idList: [],
        productSkuList: [],
        quantityList: [],
        intervalList: [],
        reorderDateList: [],
        isSpecialList: [],
        statusList: [],
        canCancelList: [],
    };

    orderItemRepeatInstructionSearchResults.map((orderItem) => {
        if (!filters.sku || orderItem.product.sku.indexOf(filters.sku) === 0) {
            valueLists.idList.push(orderItem.id);
            valueLists.productSkuList.push(orderItem.product.sku);
            valueLists.quantityList.push(orderItem.quantity || null); //
            valueLists.reorderDateList.push((orderItem.reorder_date) ? checkDate(orderItem.reorder_date, 'output', 'DD/MM/YYYY') : null);
            valueLists.intervalList.push((orderItem.orderItemRepeatInstruction) ? parseInt(orderItem.orderItemRepeatInstruction.interval, 10) : 'order_item_repeat_instruction_removed'); //
            valueLists.isSpecialList.push(orderItem.is_special || null); //
            valueLists.statusList.push((orderItem.orderItemRepeatInstruction) ? parseInt(orderItem.orderItemRepeatInstruction.status, 10) : 'order_item_repeat_instruction_removed');
            valueLists.canCancelList.push((orderItem.orderItemRepeatInstruction) ? orderItem.orderItemRepeatInstruction.id : 'order_item_repeat_instruction_removed');
        }
    });

    return [
        {
            fieldName: 'order_item_id',
            fieldLabel: '',
            fieldValues: valueLists.idList,
        },
        {
            fieldName: 'product_sku',
            fieldLabel: 'Product SKU',
            fieldValues: valueLists.productSkuList,
        },
        {
            fieldName: 'quantity',
            fieldLabel: 'Quantity',
            fieldValues: valueLists.quantityList,
        },
        {
            fieldName: 'reorder_date',
            fieldLabel: 'Re-order Date',
            fieldValues: valueLists.reorderDateList,
        },
        {
            fieldName: 'frequency',
            fieldLabel: 'Frequency',
            fieldValues: valueLists.intervalList,
        },
        {
            fieldName: 'special_line_item',
            fieldLabel: 'Special Line Item',
            fieldValues: valueLists.isSpecialList,
        },
        {
            fieldName: 'pause_play',
            fieldLabel: '',
            fieldValues: valueLists.statusList,
        },
        {
            fieldName: 'cancel',
            fieldLabel: '',
            fieldValues: valueLists.canCancelList,
        }
    ];
}

function frequencyOptions() {
    return [
        {
            label: '4 weeks',
            value: 4,
        },
        {
            label: '6 weeks',
            value: 6,
        },
        {
            label: '8 weeks',
            value: 8,
        },
        {
            label: '10 weeks',
            value: 10,
        },
        {
            label: '12 weeks',
            value: 12,
        },
        {
            label: '16 weeks',
            value: 16,
        },
        {
            label: '20 weeks',
            value: 20,
        },
        {
            label: '24 weeks',
            value: 24,
        },
        {
            label: '52 weeks',
            value: 52,
        },
    ];
}

export { generateOrderItemRepeatInstructions, frequencyOptions };
