import React from 'react';
import { Form as ReduxForm } from 'redux-form';

import Icon from '../../../elements/Icon';
import Form from '../../../elements/Form';
import { isRequired } from '../../../core/utils/validators';

const { INTERVAL_OPTIONS } = require('../../../core/utils/options').default;

const FormGroup = Form.Group;
const FormItem = Form.Item;
const formTypes = Form.formTypes;

export default function () {
    // Custom items for form
    const customFormItem = (
        <div className="d-flex justify-content-center flex-center mt-1 w-50" style={{ margin: 'auto' }}>
            <span className="mr-1">Every</span>
            <div className="mr-1">
                <FormItem type={formTypes.NUMBER} name="interval" label={false} className="m-0 pb-0" validate={[isRequired]} />
            </div>
            <FormItem
                type={formTypes.SELECT}
                name="interval_type"
                label={false}
                className="m-0 pb-0 w-100"
                options={INTERVAL_OPTIONS}
                validate={[isRequired]} />
        </div>
    );

    return (
        <ReduxForm onSubmit={this.props.handleSubmit(this.handleUpdateAutoOrderSubmit)} className="ant-form ant-form-vertical">
            <Icon name="calendar" className="calendar-grey-large" />
            <p className="mt-2">Please specify the new schedule for the order:</p>
            <FormGroup
                config={[{ type: formTypes.CUSTOM, name: customFormItem }]}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }} />
        </ReduxForm>
    );
}
