import React from 'react';
import { Tabs, Button, Spin } from 'antd';
import { isUndefined, isEmpty } from 'underscore';
import { Form } from 'redux-form';

import ChooseCustomer from '../../modals/ChooseCustomer';
import FormTabBar from '../../../elements/FormTabBar';
import Icon from '../../../elements/Icon';
import SelectCustomer from '../../modals/SelectCustomer';
import SplitOrderModal from '../../modals/SplitOrderModal';
import { SelectCustomer as SelectCustomerTab, AddProductsAndAutoOrders, Letters, PrescriptionInfo } from './tabs';

const TabPane = Tabs.TabPane;

const antIcon = <Icon type="loading-3-quarters" style={{ fontSize: 32, color: '#149566', width: 32, height: 32 }} spin />;

export default function () {
    const { order, products, selectedCustomer, selectedCustomerDoctor, selectedCustomerPractice, handleSubmit, autoOrder, orderStatus, fundingMethod, splitModalVisible, handleModalVisible, isFetching, } = this.props;
    const { orderProducts, productsSearch, activeTab, modalSelectCustomerVisible, modalChooseCustomerVisible, letterList, orderPickingAndDispatchNote, prescriptionInformation } = this.state;

    // Should the user be allowed to edit
    const readOnly = order.is_dispatched;

    // Make customer object based on request and state
    let customer = !isUndefined(order.customer) ? order.customer : {};
    if (!isEmpty(selectedCustomer)) {
        customer = selectedCustomer;
        customer.doctor = !isEmpty(selectedCustomerDoctor) ? selectedCustomerDoctor : {};
        customer.practice = !isEmpty(selectedCustomerPractice) ? selectedCustomerPractice : {};
    }


    const renderCustomerTab = () => (
        <TabPane tab="Select customer" key="1">
            {/* Select customer form */}
            <SelectCustomerTab
                customer={customer}
                handleOpenModal={this.handleOpenModal}
                readOnly={readOnly} />
        </TabPane>
    );

    const renderProductTab = () => (
        <TabPane tab="Add products and auto-orders" key="2">
            {/* Add products and auto-orders form */}
            <AddProductsAndAutoOrders
                products={products}
                added={orderProducts || []}
                recommended={customer.recommended_product_ids || []}
                search={productsSearch}
                callbacks={{
                    updateProduct: this.updateProduct,
                    searchProduct: this.searchProduct,
                    setRepeatOrder: this.setRepeatOrder,
                }}
                readOnly={readOnly}
                activeTab={activeTab} />
        </TabPane>
    );

    const renderLetterTab = () => (
        <TabPane tab="Letters" key="3">
            {/* Auto orders form */}
            <Letters
                letterList={letterList}
                orderPickingAndDispatchNote={orderPickingAndDispatchNote}
                setletter={this.setletter}
                activeTab={activeTab}
                setOrderPickingAndDispatchNote={this.setOrderPickingAndDispatchNote} />
        </TabPane>
    );

    const renderPrescription = () => (
        <TabPane tab="Prescription info" key="4">
            {/* Prescription info form */}
            <PrescriptionInfo
                handleSetPrescriptionInformation={this.handleSetPrescriptionInformation.bind(this)}
                prescriptionInformation={prescriptionInformation}
                currentStatus={orderStatus}
                activeTab={activeTab}
                fundingMethod={fundingMethod}
                readOnly={readOnly} />
        </TabPane>
    );

    const renderTabs = () => {
        if (!isEmpty(selectedCustomer)) {
            return (<Tabs activeKey={activeTab} renderTabBar={FormTabBar} onTabClick={this.handleTabChange}>
                {renderCustomerTab()}
                {renderProductTab()}
                {renderLetterTab()}
                {/* {renderPrescription()} */}
            </Tabs>
            );
        }
        return (<Tabs activeKey={activeTab} renderTabBar={FormTabBar} onTabClick={this.handleTabChange}>
            {renderCustomerTab()}
            {renderProductTab()}
            {renderLetterTab()}
            {/* {renderPrescription()} */}
        </Tabs>
        );
    };

    return (
        <div className="update_order">
            <Spin spinning={isFetching} indicator={antIcon}>
                <Form onSubmit={handleSubmit(this.handleUpdateOrderSubmit)} className="ant-form ant-form-vertical" style={{ width: '100%' }}>
                    {renderTabs()}
                    <div className="nav-buttons">
                        <Button type="default" onClick={this.handleTabBack} disabled={activeTab === '1'}>Back</Button>
                        <Button type="primary" style={activeTab === '3' ? { display: 'none' } : {}} onClick={this.handleTabNext}>Next</Button>
                        <Button type="primary" style={activeTab !== '3' ? { display: 'none' } : {}} className="average m-0" htmlType="submit">
                            <Icon name="save" />Save & Submit
                        </Button>
                    </div>
                </Form>
            </Spin>
            {/* Select customer modals */}
            <SelectCustomer visible={modalSelectCustomerVisible} changeVisible={this.setModalSelectCustomerVisible} handleSubmit={this.handleSelectCustomerSubmit} />
            <ChooseCustomer visible={modalChooseCustomerVisible} changeVisible={this.setModalChooseCustomerVisible} handleSubmit={this.handleChooseCustomerSubmit} />
            <SplitOrderModal
                customerId={parseInt(customer.id, 10) || 0}
                products={products}
                orderProducts={orderProducts || []}
                orderId={parseInt(order.id, 10) || 0}
                visible={splitModalVisible}
                letterList={letterList}
                orderPickingAndDispatchNote={orderPickingAndDispatchNote}
                prescriptionInformation={prescriptionInformation}
                changeVisible={value => handleModalVisible('modalSplitVisible', value)} />
        </div>
    );
}
