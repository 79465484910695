import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_ORDERS_REQUEST: null,
    GET_ORDERS_SUCCESS: null,
    GET_ORDERS_FAILURE: null,

    GET_ORDERS_WAITING_REQUEST: null,
    GET_ORDERS_WAITING_SUCCESS: null,
    GET_ORDERS_WAITING_FAILURE: null,

    GET_ORDERS_READY_REQUEST: null,
    GET_ORDERS_READY_SUCCESS: null,
    GET_ORDERS_READY_FAILURE: null,

    GET_ORDERS_BACKORDER_REQUEST: null,
    GET_ORDERS_BACKORDER_SUCCESS: null,
    GET_ORDERS_BACKORDER_FAILURE: null,

    GET_ORDER_REQUEST: null,
    GET_ORDER_SUCCESS: null,
    GET_ORDER_FAILURE: null,

    UPDATE_ORDER_REQUEST: null,
    UPDATE_ORDER_SUCCESS: null,
    UPDATE_ORDER_FAILURE: null,

    SET_ORDER_PRODUCTS_REQUEST: null,
    SET_ORDER_PRODUCTS_SUCCESS: null,
    SET_ORDER_PRODUCTS_FAILURE: null,

    RESET_ORDER_PRODUCTS: null,

    UPDATE_MISSING_PRESCRIPTION_ITEMS_OFFLINE_REQUEST: null,
    SELECTED_ORDER_ITEMS_REQUEST: null,

    CHECK_FOR_REPEAT_ORDER_ITEM_ERRORS_REQUEST: null,
    CHECK_FOR_REPEAT_ORDER_ITEM_ERRORS_SUCCESS: null,
    CHECK_FOR_REPEAT_ORDER_ITEM_ERRORS_FAILURE: null,

    ORDER_ITEMS_DISPATCH_REQUEST: null,
    ORDER_ITEMS_DISPATCH_SUCCESS: null,
    ORDER_ITEMS_DISPATCH_FAILURE: null,
});

export const {
    getOrdersRequest,
    getOrdersSuccess,
    getOrdersFailure
} = createActions(
    constants.GET_ORDERS_REQUEST,
    constants.GET_ORDERS_SUCCESS,
    constants.GET_ORDERS_FAILURE
);

export const {
    getOrdersWaitingRequest,
    getOrdersWaitingSuccess,
    getOrdersWaitingFailure
} = createActions(
    constants.GET_ORDERS_WAITING_REQUEST,
    constants.GET_ORDERS_WAITING_SUCCESS,
    constants.GET_ORDERS_WAITING_FAILURE
);

export const {
    getOrdersReadyRequest,
    getOrdersReadySuccess,
    getOrdersReadyFailure
} = createActions(
    constants.GET_ORDERS_READY_REQUEST,
    constants.GET_ORDERS_READY_SUCCESS,
    constants.GET_ORDERS_READY_FAILURE
);

export const {
    getOrdersBackorderRequest,
    getOrdersBackorderSuccess,
    getOrdersBackorderFailure,
} = createActions(
    constants.GET_ORDERS_BACKORDER_REQUEST,
    constants.GET_ORDERS_BACKORDER_SUCCESS,
    constants.GET_ORDERS_BACKORDER_FAILURE,
);

export const {
    getOrderRequest,
    getOrderSuccess,
    getOrderFailure
} = createActions(
    constants.GET_ORDER_REQUEST,
    constants.GET_ORDER_SUCCESS,
    constants.GET_ORDER_FAILURE
);

export const {
    updateOrderRequest,
    updateOrderSuccess,
    updateOrderFailure
} = createActions(
    constants.UPDATE_ORDER_REQUEST,
    constants.UPDATE_ORDER_SUCCESS,
    constants.UPDATE_ORDER_FAILURE
);

export const {
    updateMissingPrescriptionItemsOfflineRequest,
} = createActions(
    constants.UPDATE_MISSING_PRESCRIPTION_ITEMS_OFFLINE_REQUEST,
);

export const {
    checkForRepeatOrderItemErrorsRequest,
    checkForRepeatOrderItemErrorsSuccess,
    checkForRepeatOrderItemErrorsFailure
} = createActions(
    constants.CHECK_FOR_REPEAT_ORDER_ITEM_ERRORS_REQUEST,
    constants.CHECK_FOR_REPEAT_ORDER_ITEM_ERRORS_SUCCESS,
    constants.CHECK_FOR_REPEAT_ORDER_ITEM_ERRORS_FAILURE
);

export const {
    selectedOrderItemsRequest,
} = createActions(
    constants.SELECTED_ORDER_ITEMS_REQUEST,
);

export const {
    orderItemsDispatchRequest,
    orderItemsDispatchSuccess,
    orderItemsDispatchFailure
} = createActions(
    constants.ORDER_ITEMS_DISPATCH_REQUEST,
    constants.ORDER_ITEMS_DISPATCH_SUCCESS,
    constants.ORDER_ITEMS_DISPATCH_FAILURE
);
