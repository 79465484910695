import React, { Component, } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Button, Modal, Input, Icon as AntdIcon, Checkbox, notification } from 'antd';

import styles from './styles';

import blackAtIcon from '../../../../../../../assets/images/black-at-icon.png';
import blackKeypad from '../../../../../../../assets/images/black-keypad.png';
import blackMailIcon from '../../../../../../../assets/images/black-mail-icon.png';

import * as customerActions from '../../../../../../../core/customer/customerActions';

const TextArea = Input.TextArea;

class IssueLetterModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formValues: {
                letter: {
                    text: {
                        label: 'Text',
                        value: null,
                    },
                    email: {
                        label: 'Email',
                        value: null || 0,
                    },
                    letter: {
                        label: 'Letter',
                        value: null || 0,
                    },
                    fax: {
                        label: 'Fax',
                        value: null || 0,
                    },
                    type: {
                        label: 'Type',
                        value: 3,
                    },
                    required: {
                        label: 'Required',
                        value: null || 1
                    },
                },
            },
            isActive: false,
        };
    }

    componentDidMount() {
        this.props.setIssueReminder(this.setIssueReminder.bind(this));
    }

    componentDidUpdate(prevProps) {
        const { isCreatingLetter, onSubmit } = this.props;
        if (!isCreatingLetter && isCreatingLetter !== prevProps.isCreatingLetter) {
            onSubmit();
            this.setState({ isActive: false, });
        }
    }

    setIssueReminder() {
        this.setState({ isActive: true, });
    }

    handleSubmitLetter() {
        const { actions, issueLetterOrderId } = this.props;
        const { formValues } = this.state;
        const { letter } = formValues;

        if (!/\S/.test(letter.text.value)) {
            notification.error({ message: 'Error detected', description: 'Letters must contain characters.' });
        } else if ([letter.email.value, letter.letter.value, letter.fax.value].every(val => val === 0)) {
            notification.error({ message: 'Error detected', description: 'Letters must have an output stream.' });
        } else {
            actions.createLetterRequest({
                orderId: issueLetterOrderId,
                text: letter.text.value,
                email: letter.email.value,
                letter: letter.letter.value,
                fax: letter.fax.value,
                type: letter.type.value,
                required: letter.required.value,
            });
        }
    }

    handleSetFormValue(value, form, input) {
        const formValues = JSON.parse(JSON.stringify(this.state.formValues));

        formValues[form][input].value = value;
        this.setState({ formValues });
    }

    render() {
        const {
            formValues,
            isActive
        } = this.state;

        const { letter } = formValues;

        return (
            <Modal
                visible={isActive}
                maskClosable
                onCancel={() => this.setState({ isActive: false, })}
                className="issue_letter_modal"
                closable={false}
                title={<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', }}>
                        <AntdIcon type={'align-center'} style={{ fontSize: '150%' }} />
                    </div>
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', }}>
                        <p style={{ display: 'flex' }}>Issue Letter</p>
                    </div>
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', }}>
                        <Button
                            onClick={() => this.handleSubmitLetter()}
                            style={styles.searchBarButton}>Submit</Button>
                    </div>
                </div>}
                footer={<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                        }}>
                        <div>
                            <img
                                src={blackAtIcon}
                                style={{
                                    height: 20,
                                    width: 20,
                                    marginRight: 5,
                                    marginLeft: 20,
                                }}
                                alt={'Black At Icon'} />
                            <Checkbox
                                checked={letter.email.value}
                                onChange={e => this.handleSetFormValue(e.target.checked ? 1 : 0, 'letter', 'email')} />
                        </div>
                        <div>
                            <img
                                src={blackMailIcon}
                                style={{
                                    height: 20,
                                    width: 20,
                                    marginRight: 5,
                                    marginLeft: 20,
                                }}
                                alt={'Black Mail Icon'} />
                            <Checkbox
                                checked={letter.letter.value}
                                onChange={e => this.handleSetFormValue(e.target.checked ? 1 : 0, 'letter', 'letter')} />
                        </div>
                        <div>
                            <img
                                src={blackKeypad}
                                style={{
                                    height: 20,
                                    width: 20,
                                    marginRight: 5,
                                    marginLeft: 20,
                                }}
                                alt={'Black Keypad'} />
                            <Checkbox
                                checked={letter.fax.value}
                                onChange={e => this.handleSetFormValue(e.target.checked ? 1 : 0, 'letter', 'fax')} />
                        </div>
                    </div>
                </div>}>
                <TextArea
                    value={letter.text.value}
                    onChange={e => this.handleSetFormValue(e.target.value, 'letter', 'text')} />
            </Modal>
        );
    }
}

IssueLetterModal.defaultProps = {
    actions: {},
    issueLetterOrderId: null,
    isCreatingLetter: false,
    onSubmit: () => {},
};

IssueLetterModal.propTypes = {
    actions: PropTypes.object,
    issueLetterOrderId: PropTypes.number,
    isCreatingLetter: PropTypes.bool,
    onSubmit: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isCreatingLetter: state.customer.isCreatingLetter,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueLetterModal);
