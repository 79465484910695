/* global window: true */
import { put, call, takeEvery } from 'redux-saga/effects';
import alertify from 'alertify.js';
import { browserHistory } from 'react-router';
import { isUndefined } from 'underscore';
import { notification } from 'antd';
import { fetchApiAuth, getBaseUrl, formatParams } from '../utils/api';
import { addKeyProp, mapPropsToInts } from '../utils/helpers';

import {
    getProductsRequest,
    getProductsSuccess,
    getProductsFailure,

    getProductSuccess,
    getProductFailure,

    updateProductSuccess,
    updateProductFailure,

    getProductCategoriesSuccess,
    getProductCategoriesFailure,

    getProductStockSuccess,
    getProductStockFailure,

    updateProductStockSuccess,
    updateProductStockFailure,

    getProductCustomersSuccess,
    getProductCustomersFailure,
} from './productActions';

const {
    GET_PRODUCTS_REQUEST,
    GET_PRODUCT_REQUEST,
    UPDATE_PRODUCT_REQUEST,
    GET_PRODUCT_CATEGORIES_REQUEST,
    GET_PRODUCT_STOCK_REQUEST,
    UPDATE_PRODUCT_STOCK_REQUEST,
    GET_PRODUCT_CUSTOMERS_REQUEST,
} = require('./productActions').constants;

const makeProductData = (data) => {
    let product = data;
    if (data) {
        product.settings = [];
        if (data.exclude_from_auto) {
            product.settings.push(1);
        }
        if (data.exclude_from_letter) {
            product.settings.push(2);
        }
        product = mapPropsToInts(product, ['unit', 'status', 'product_category_id', 'supplier_id', 'manufacturer_id']);
    }

    return product;
};

function* getProducts({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/product/search',
            params: formatParams(payload)
        });

        let data = !isUndefined(response.data) ? response.data : [];
        if (data) {
            data = mapPropsToInts(data, ['page', 'total']);
            data.rows = addKeyProp(data.rows);
        }

        yield put(getProductsSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getProductsFailure(e.response ? e.response.data.message : e));
    }
}

function* getProduct({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (params.id !== 0) {
            response = yield call(fetchApiAuth, {
                method: 'GET',
                url: '/product/get',
                params
            });
        }

        const product = !isUndefined(response) ? response.data : {};
        yield put(getProductSuccess(makeProductData(product)));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getProductFailure(e.response ? e.response.data.message : e));
    }
}

function* updateProduct({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/product/edit',
            body: payload
        });

        const product = !isUndefined(response.data) ? response.data : {};
        if (product) {
            browserHistory.push(`/product/${product.id}`);
            notification.success({ message: 'Success', description: 'Product has been updated' });
        }

        yield put(updateProductSuccess(makeProductData(product)));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(updateProductFailure(e.response ? e.response.data.message : e));
    }
}

function* getProductCategories() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/product/categories'
        });

        const categories = !isUndefined(response.data) ? response.data : [];
        yield put(getProductCategoriesSuccess(categories));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getProductCategoriesFailure(e.response ? e.response.data.message : e));
    }
}

function* getProductStock({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/product/stock',
            body: payload
        });

        if (response.data) {
            window.open(`${getBaseUrl()}/${response.data}`);
        } else {
            alertify.error('Unable to get sample file');
        }

        yield put(getProductStockSuccess());
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getProductStockFailure(e.response ? e.response.data.message : e));
    }
}

function* updateProductStock({ payload }) {
    try {
        yield call(fetchApiAuth, {
            method: 'POST',
            url: '/product/stock',
            body: payload
        });

        notification.success({ message: 'Success', description: 'Updated product stock counts' });
        yield put(getProductsRequest());
        yield put(updateProductStockSuccess());
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(updateProductStockFailure(e.response ? e.response.data.message : e));
    }
}

function* getProductCustomers({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/product/customers',
            params: formatParams(payload),
        });

        if (response.data) {
            window.open(`${getBaseUrl()}/${response.data}`);
        } else {
            alertify.error('Unable to get users information');
        }

        yield put(getProductCustomersSuccess());
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getProductCustomersFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* productSaga() {
    yield* [
        takeEvery(GET_PRODUCTS_REQUEST, getProducts),
        takeEvery(GET_PRODUCT_REQUEST, getProduct),
        takeEvery(UPDATE_PRODUCT_REQUEST, updateProduct),
        takeEvery(GET_PRODUCT_CATEGORIES_REQUEST, getProductCategories),
        takeEvery(GET_PRODUCT_STOCK_REQUEST, getProductStock),
        takeEvery(UPDATE_PRODUCT_STOCK_REQUEST, updateProductStock),
        takeEvery(GET_PRODUCT_CUSTOMERS_REQUEST, getProductCustomers),
    ];
}
