import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import DOM from './customersReferrer';

import * as referrerActions from '../../../core/referrer/referrerActions';

class CustomersReferrer extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentWillMount() {
        const { actions, referrerId } = this.props;
        actions.getReferrerCustomerRequest(referrerId);
    }

    render() {
        return this.view();
    }
}

CustomersReferrer.propTypes = {
    actions: PropTypes.object.isRequired,
    referrerId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        customers: state.referrer.customers,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...referrerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomersReferrer);
