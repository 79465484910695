import React, { Component, } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Button, Empty, Spin, InputNumber } from 'antd';
import Select from 'react-select';
import { isEqual, isEmpty, isArray } from 'underscore';
import { browserHistory } from 'react-router';

import styles from './styles';

import { generateOrderItems, deliveryStatusOptions } from './dataGenerators';
import values from '../../../core/utils/values';

import * as customerActions from '../../../core/customer/customerActions';

class MatchedOrderItem extends Component {
    constructor(props) {
        super(props);
        const { orderItemSearchResults } = this.props;

        this.state = {
            orderItemSearchResults,
            rowsToRender: 20,
        };

        this.gridView = React.createRef();
    }

    componentDidMount() {
        this.handleSearchRequest();
    }

    componentDidUpdate(prevProps, prevState) {
        const { customer, orderItemSearchResults } = this.props;

        // Update the state with new redux state values
        if (!isEqual(customer, prevProps.customer)) {
            this.handleSearchRequest();
            this.setState({ filters: {}, selectedItems: [], });
        }

        if (!isEqual(orderItemSearchResults, prevProps.orderItemSearchResults)) {
            this.setState({ orderItemSearchResults });
        }
    }

    onScroll() {
        // If scrolled to the bottom.
        if (this.gridView.current) {
            const { scrollTop, scrollHeight, clientHeight } = this.gridView.current;
            if (Math.abs((scrollTop + clientHeight) - scrollHeight) < 5) {
                this.setState({ rowsToRender: this.state.rowsToRender + 20 });
            }
        }
    }

    handleSearchRequest() {
        const { customer, actions } = this.props;
        actions.searchCustomerOrderItemsByFiltersRequest({ id: customer.id, filters: {}, limit: 100000, });
    }

    handleSetDeliveryStatus({ option, orderItemId, }) {
        const { orderItemSearchResults } = this.state;
        const { actions } = this.props;
        const orderItemSearchResultsBuffer = JSON.parse(JSON.stringify(orderItemSearchResults));
        const index = orderItemSearchResultsBuffer.findIndex(orderItemSearchResult => orderItemSearchResult.id === orderItemId);

        orderItemSearchResultsBuffer[index].delivery_status = option.value;

        // Send request to change item
        actions.updateOrderItemRequest({
            ...orderItemSearchResultsBuffer[index],
        });
        actions.updateOrderItemSearchResultsOfflineRequest(orderItemSearchResultsBuffer);
    }

    handleSetQuantityMatched({ value, orderItemId, }) {
        const { orderItemSearchResults } = this.state;
        const { actions } = this.props;
        const orderItemSearchResultsBuffer = JSON.parse(JSON.stringify(orderItemSearchResults));
        const index = orderItemSearchResultsBuffer.findIndex(orderItemSearchResult => orderItemSearchResult.id === orderItemId);

        orderItemSearchResultsBuffer[index].quantity_matched = value;

        // Send request to change item
        actions.updateOrderItemRequest({
            ...orderItemSearchResultsBuffer[index],
        });
        actions.updateOrderItemSearchResultsOfflineRequest(orderItemSearchResultsBuffer);
    }

    handleSetQuantityDispatched({ value, orderItemId, }) {
        const { orderItemSearchResults } = this.state;
        const { actions } = this.props;
        const orderItemSearchResultsBuffer = JSON.parse(JSON.stringify(orderItemSearchResults));
        const index = orderItemSearchResultsBuffer.findIndex(orderItemSearchResult => orderItemSearchResult.id === orderItemId);

        orderItemSearchResultsBuffer[index].quantity_dispatched = value;

        // Send request to change item
        actions.updateOrderItemRequest({
            ...orderItemSearchResultsBuffer[index],
        });
        actions.updateOrderItemSearchResultsOfflineRequest(orderItemSearchResultsBuffer);
    }

    handleMatch({ orderItemId, }) {
        const { orderItemSearchResults } = this.state;
        const { actions } = this.props;
        const orderItemSearchResultsBuffer = JSON.parse(JSON.stringify(orderItemSearchResults));
        const index = orderItemSearchResultsBuffer.findIndex(orderItemSearchResult => orderItemSearchResult.id === orderItemId);

        if (orderItemSearchResultsBuffer[index].prescription_id) {
            orderItemSearchResultsBuffer[index].prescription_id = null;
            orderItemSearchResultsBuffer[index].quantity_matched = 0;
            orderItemSearchResultsBuffer[index].script_status = null;
        } else {
            orderItemSearchResultsBuffer[index].prescription_id = this.props.params.prescriptionId;
            orderItemSearchResultsBuffer[index].quantity_matched = orderItemSearchResultsBuffer[index].quantity;
            orderItemSearchResultsBuffer[index].script_status = 0;
        }

        // Send request to change item
        actions.updateOrderItemRequest({
            ...orderItemSearchResultsBuffer[index],
        });
        actions.updateOrderItemSearchResultsOfflineRequest(orderItemSearchResultsBuffer);
    }

    _renderFilterBar() {
        return (
            <div style={styles.filterBarContainer}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'flex-start',
                    }}>
                    <p style={styles.filterBarTitle}>Matched Order Items</p>
                </div>
            </div>
        );
    }

    _renderTableHeaderElements({ field }) {
        switch (field.fieldName) {
        case 'order_item_id':
            return null;
        default:
            return (
                <th
                    key={field.fieldName}
                    style={styles.tableHeaderElement}>
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                        {field.fieldLabel.toUpperCase()}
                    </div>
                </th>
            );
        }
    }

    transpose(a) {
        return Object.keys(a[0]).map(c => a.map(r => r[c]));
    }

    _renderTableBodyRowElement({ column, fieldName, orderItemId, }) {
        const { customer } = this.props;

        switch (fieldName) {
        case 'order_item_id':
            return null;
        case 'order_id':
            return (
                <td
                    key={`${orderItemId}_${fieldName}`}
                    style={{ ...styles.tableBodyElement, fontWeight: 'bold', }}>
                    <a onClick={() => browserHistory.push(`/order/${column}/customer/${customer.id}`)}>{column}</a>
                </td>
            );
        case 'delivery_status':
            const deliveryStatusValue = deliveryStatusOptions().filter(status => status.value !== 3).find(option => option.value === column);
            return (
                <td
                    key={`${orderItemId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <Select
                        value={deliveryStatusValue}
                        styles={{
                            ...styles.selectInput,
                            valueContainer: (provided, state) => ({
                                ...provided,
                                height: '25px',
                                padding: '0px 8px',
                            }),
                            control: (provided, state) => ({
                                ...provided,
                                background: '#fff',
                                borderColor: '#9e9e9e',
                                minHeight: undefined,
                                height: '25px',
                                boxShadow: state.isFocused ? null : null,
                                '&:hover': {
                                    borderColor: '#149566',
                                    color: '#149566'
                                },
                            }),
                            container: (provided, state) => ({
                                ...provided,
                                width: '150px',
                            }),
                        }}
                        onChange={option => this.handleSetDeliveryStatus({ option, orderItemId, })}
                        isSearchable={false}
                        options={
                            deliveryStatusOptions().filter(status => status.value !== 3).map(option => ({
                                label: option.label,
                                value: option.value,
                            }))
                        } />
                </td>
            );
        case 'quantity_matched':
            return (
                <td
                    key={`${orderItemId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <InputNumber
                        value={column[0]}
                        size="small"
                        style={{ width: 45, height: 24, }}
                        onChange={value => this.handleSetQuantityMatched({ value, orderItemId, })}
                        min={0}
                        max={parseInt(column[1])}
                        defaultValue={3} />{` / ${column[1]}`}
                </td>
            );
        case 'quantity_dispatched':
            return (
                <td
                    key={`${orderItemId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <InputNumber
                        value={column[0]}
                        size="small"
                        style={{ width: 45, height: 24, }}
                        onChange={value => this.handleSetQuantityDispatched({ value, orderItemId, })}
                        min={0}
                        max={parseInt(column[1])}
                        defaultValue={3} />{` / ${column[1]}`}
                </td>
            );
        case 'match':
            return (
                <td
                    key={`${orderItemId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <Button
                        onClick={() => this.handleMatch({ orderItemId, })}
                        style={styles.matchButton}>
                        {column ? 'Unmatch' : 'Match'}
                    </Button>
                </td>
            );
        default:
            return (
                <td
                    key={`${orderItemId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    {column}
                </td>
            );
        }
    }

    _renderTableBody(data) {
        const matrix = [];
        const fieldNames = [];
        data.map((element) => {
            matrix.push(element.fieldValues);
            fieldNames.push(element.fieldName);
        });
        const transpose = this.transpose(matrix);

        return (
            <tbody style={styles.tableBody}>
                {transpose.map((row, rowIndex) => (
                    <tr
                        key={rowIndex}
                        style={styles.tableBodyRow}>
                        {row.map((column, columnIndex) => this._renderTableBodyRowElement({
                            column,
                            fieldName: fieldNames[columnIndex],
                            orderItemId: row[0],
                        }))}
                    </tr>
                ))}
                <tr style={{ height: 200, }} />
            </tbody>
        );
    }

    _renderTable() {
        const { orderItemSearchResults, rowsToRender } = this.state;

        const orderItemSearchResultsBuffer = orderItemSearchResults;

        let data = [];
        if (isArray(orderItemSearchResultsBuffer) && !isEmpty(orderItemSearchResultsBuffer)) {
            data = generateOrderItems({
                orderItemSearchResults: orderItemSearchResultsBuffer.filter(orderItem => (
                    +orderItem.prescription_id === +this.props.params.prescriptionId
                    && +orderItem.script_status !== values.ORDER_ITEM_STATUS_ITEM_CANCELLED
                ))
                    .slice(0, rowsToRender),
            });
        }

        const shouldRenderTableContent = data && isArray(data) && data[0] && isArray(data[0].fieldValues) && !isEmpty(data[0].fieldValues);

        return (
            <table
                style={styles.table}>
                {shouldRenderTableContent ?
                    <React.Fragment>
                        <thead style={styles.tableHeader}>
                            <tr style={styles.tableHeaderRow}>
                                {data.map(field => (
                                    this._renderTableHeaderElements({ field })
                                ))}
                            </tr>
                        </thead>
                        {this._renderTableBody(data)}
                    </React.Fragment>
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No order items'} />
                }
            </table>
        );
    }

    render() {
        const { height, isSearchingCustomerOrderItemsByFilters, isUpdatingOrderItem } = this.props;

        let gridviewContainerStyles = styles.gridviewContainer;
        if (height || height === 0) {
            gridviewContainerStyles = { ...gridviewContainerStyles, height, minHeight: height };
        }

        return (
            <div
                ref={this.gridView}
                style={gridviewContainerStyles}
                className="prescription_matched_order_item">
                <Spin
                    spinning={
                        isUpdatingOrderItem ||
                        isSearchingCustomerOrderItemsByFilters}>
                    {this._renderFilterBar()}
                    {this._renderTable()}
                </Spin>
            </div>
        );
    }
}

MatchedOrderItem.defaultProps = {
    customer: {},
    actions: {},
    orderItemSearchResults: [],
    missingPrescriptionItems: [],
    height: null,
    isUpdatingOrderItem: false,
    isSearchingCustomerOrderItemsByFilters: false,
};

MatchedOrderItem.propTypes = {
    customer: PropTypes.object,
    actions: PropTypes.object,
    height: PropTypes.number,
    missingPrescriptionItems: PropTypes.array,
    orderItemSearchResults: PropTypes.array,
    isUpdatingOrderItem: PropTypes.bool,
    isSearchingCustomerOrderItemsByFilters: PropTypes.bool,
    params: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        orderItemSearchResults: state.customer.orderItemSearchResults,
        missingPrescriptionItems: state.order.missingPrescriptionItems,
        customer: state.customer.customer,
        isUpdatingOrderItem: state.customer.isUpdatingOrderItem,
        isSearchingCustomerOrderItemsByFilters: state.customer.isSearchingCustomerOrderItemsByFilters,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchedOrderItem);
