import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import DOM from './product';

import * as productActions from '../../core/product/productActions';

class ProductPage extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            name: parseInt(props.params.id, 10) ? `#${props.params.id}` : 'New Product',
            toolbar: [
                { id: 'actionWho', caption: 'Who use this product?', icon: 'download', onClick: this.handleDownloadUsers },
                { id: 'actionSave', caption: 'Save', icon: 'save', onClick: this.handlUpdateProduct }
            ]
        };
    }

    // Handle set page name
    setPageName = name => this.setState({ name });

    // Handle submit update product form
    handlUpdateProduct = () => this.props.actions.submit('update-product');

    // Handle get who uses this product button
    handleDownloadUsers = () => {
        const { actions, params } = this.props;
        actions.getProductCustomersRequest(parseInt(params.id, 10));
    }

    render() {
        return this.view();
    }
}

ProductPage.propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            submit,
            ...productActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
