import { isEmpty } from 'underscore';
import { Record } from 'immutable';

const {
    GET_PRACTICES_REQUEST,
    GET_PRACTICES_SUCCESS,
    GET_PRACTICES_FAILURE,

    GET_PRACTICE_REQUEST,
    GET_PRACTICE_SUCCESS,
    GET_PRACTICE_FAILURE,

    UPDATE_PRACTICE_REQUEST,
    UPDATE_PRACTICE_SUCCESS,
    UPDATE_PRACTICE_FAILURE,

    GET_PRACTICE_DOCTOR_REQUEST,
    GET_PRACTICE_DOCTOR_SUCCESS,
    GET_PRACTICE_DOCTOR_FAILURE,

    ADD_PRACTICE_DOCTOR_REQUEST,
    ADD_PRACTICE_DOCTOR_SUCCESS,
    ADD_PRACTICE_DOCTOR_FAILURE,

    REMOVE_PRACTICE_DOCTOR_REQUEST,
    REMOVE_PRACTICE_DOCTOR_SUCCESS,
    REMOVE_PRACTICE_DOCTOR_FAILURE,
} = require('./practiceActions').constants;

const { STATUS_ACTIVE } = require('../utils/values').default;

const initialPractices = {
    rows: [],
    page: 1,
    total: 0,
};

const InitialState = Record({
    practices: initialPractices,
    practice: {},
    doctors: [],
    isFetching: false,
    error: null,
});

const initialState = new InitialState();
const initialFormValues = { is_active: STATUS_ACTIVE };

/**
 * ## practiceReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function practiceReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {
    case GET_PRACTICES_REQUEST:
    case GET_PRACTICE_REQUEST:
    case UPDATE_PRACTICE_REQUEST:
    case GET_PRACTICE_DOCTOR_REQUEST:
    case ADD_PRACTICE_DOCTOR_REQUEST:
    case REMOVE_PRACTICE_DOCTOR_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case GET_PRACTICES_SUCCESS:
        return state.set('practices', payload).set('isFetching', false).set('error', null);

    case GET_PRACTICE_SUCCESS:
    case UPDATE_PRACTICE_SUCCESS:
        return state.set('practice', isEmpty(payload) ? initialFormValues : payload).set('isFetching', false).set('error', null);

    case GET_PRACTICE_DOCTOR_SUCCESS:
    case ADD_PRACTICE_DOCTOR_SUCCESS:
    case REMOVE_PRACTICE_DOCTOR_SUCCESS:
        return state.set('doctors', payload).set('isFetching', false).set('error', null);

    case GET_PRACTICES_FAILURE:
    case GET_PRACTICE_FAILURE:
    case UPDATE_PRACTICE_FAILURE:
    case GET_PRACTICE_DOCTOR_FAILURE:
    case ADD_PRACTICE_DOCTOR_FAILURE:
    case REMOVE_PRACTICE_DOCTOR_FAILURE:
        return state.set('error', payload).set('isFetching', false);

    default:
        return state;
    }
}
