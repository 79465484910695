const styles = {
    gridLayout: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr 1fr 1fr',
        gap: 2,
        minHeight: 900,
    },
    gridLayout: {
        width: '75%',
        margin: '2% 15%',
    
    },
};

export default styles;
