import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { notification } from 'antd';
import { checkDate } from '../../../../core/utils/helpers';
import ConfirmModal from '../ConfirmModal';
import * as customerActions from '../../../../core/customer/customerActions';
import * as orderActions from '../../../../core/order/orderActions';
import greyStatusIcon from '../../../../assets/images/grey-status-icon.png';
import orangeStatusIcon from '../../../../assets/images/orange-status-icon.png';
import redStatusIcon from '../../../../assets/images/red-status-icon.png';
import greenStatusIcon from '../../../../assets/images/green-status-icon.png';
import yellowStatusIcon from '../../../../assets/images/yellow-status-icon.png';
import whiteStatusIcon from '../../../../assets/images/white-status-icon.png';
import orderVisibleIcon from '../../../../assets/images/order-visible-icon.png';
import orderInvisibleIcon from '../../../../assets/images/order-invisible-icon.png';
import './orderList.scss';

const OrderList = (props) => {
    const { selectedOrderItemsArray } = props;
    const { activeOrderId, customerDispatchBasket, customer } = props?.customer;
    const { actions } = props;
    const { customerOrders } = customer;

    const [visibleOrders, setVisibleOrders] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [orderIdToConfirm, setOrderIdToConfirm] = useState(null);
    const [backorderWarning, setBackorderWarning] = useState(false);

    const displayOrderStatuses = [0, 1, 12, 13, 14, 15, 16, 17];

    useEffect(() => {
        actions.getCustomerRequest({ id: customer?.id });
    }, [showConfirmModal]);

    useEffect(() => {
        if (Array.isArray(customerOrders)) {
            setVisibleOrders(customerOrders.filter(order => displayOrderStatuses.includes(order.order_status)).reverse());
        }
    }, [customer]);

    const handleConfirmOrder = () => {
        const orderItems = customerDispatchBasket.filter(item => selectedOrderItemsArray.includes(item.itemId));
        const remainingItems = customerDispatchBasket.filter(item => !selectedOrderItemsArray.includes(item.itemId));
        actions.orderItemsDispatchRequest(orderItems);
        actions.selectedOrderItemsRequest([]);
        actions.updateCustomerDispatchBasketRequest(remainingItems);
        actions.getCustomerRequest({ id: customer?.id });
        setShowConfirmModal(false);
        setBackorderWarning(false);
    };

    const handleCloseModal = () => {
        setShowConfirmModal(false);
        setBackorderWarning(false);
    };

    const validateBackorders = () => {
        const order = customerOrders.find(searchOrder => searchOrder.id === activeOrderId);
        const selectedItems = order?.orderItems.filter(item => selectedOrderItemsArray.includes(item.id));
        selectedItems.forEach((item) => {
            const dispatchBasketItem = customerDispatchBasket.find(dispatchItem => dispatchItem.itemId === item.id);
            if (dispatchBasketItem) {
                const totalQuantityDispatched = dispatchBasketItem.quantityDispatched + item.quantity_dispatched;
                if (totalQuantityDispatched < item.quantity) {
                    setBackorderWarning(true);
                }
            }
        });
    };

    const renderTableRow = (order, index) => {
        const getOrderStatus = () => {
            let statusText = '';
            let statusIcon = whiteStatusIcon;
            switch (order?.order_status) {
            case 12:
                statusText = 'No Script';
                statusIcon = redStatusIcon;
                break;
            case 13:
                statusText = 'Script Partly Received';
                statusIcon = orangeStatusIcon;
                break;
            case 14:
                statusText = 'Script Received';
                statusIcon = greenStatusIcon;
                break;
            case 15:
                statusText = 'Partly Processed';
                statusIcon = yellowStatusIcon;
                break;
            case 16:
                statusText = 'Processed';
                statusIcon = greyStatusIcon;
                break;
            case 17:
                statusText = 'Cancelled';
                statusIcon = whiteStatusIcon;
                break;
            default:
                statusText = 'No Script';
                statusIcon = redStatusIcon;
                break;
            }
            return (
                <>
                    <span>{statusText}</span>
                    <img
                        style={{
                            width: 15,
                            height: 15,
                            marginRight: 5,
                            marginLeft: 5,
                        }}
                        src={statusIcon}
                        alt="status icon" />
                </>
            );
        };

        const handleViewOrderClick = () => {
            if (activeOrderId !== order.id) {
                actions.selectedOrderItemsRequest([]);
                actions.setActiveSearchOrderIdOfflineRequest(order.id);
            }
        };

        const handleConfirmDispatchItems = () => {
            if (selectedOrderItemsArray.length === 0) {
                notification.error({
                    message: 'Select Items',
                    description: 'Please select items to dispatch.',
                });
                return;
            }
            validateBackorders();
            setOrderIdToConfirm(order.id);
            setShowConfirmModal(true);
        };

        return (
            <tr key={index}>
                <td className="order-id">
                    <span>{order?.id}</span>
                    <button className="view-order-items-button" onClick={handleViewOrderClick}>
                        <img
                            className="view-order-items-icon"
                            src={activeOrderId === order.id ? orderVisibleIcon : orderInvisibleIcon}
                            alt="view order items" />
                    </button>
                </td>
                <td>{getOrderStatus()}</td>
                <td>{order?.created_at ? checkDate(order.created_at, 'output', 'DD/MM/YYYY') : ''}</td>
                <td>{order?.due_date ? checkDate(order.due_date, 'output', 'DD/MM/YYYY') : ''}</td>
                <td>£{order?.orderValue.toFixed(2)}</td>
                <td>
                    <button
                        className="dispatch-order-items-confirm-button"
                        onClick={handleConfirmDispatchItems}
                        disabled={activeOrderId !== order.id}>
                        confirm
                    </button>
                </td>
            </tr>
        );
    };

    return (
        <>
            <div className="container" style={{ overflow: 'auto', minWidth: '100%', maxHeight: '100%' }}>
                <table>
                    <thead>
                        <tr>
                            <th style={{ minWidth: '60px' }}>order id</th>
                            <th style={{ minWidth: '90px' }}>status</th>
                            <th style={{ minWidth: '70px' }}>created at</th>
                            <th style={{ minWidth: '70px' }}>due at</th>
                            <th style={{ minWidth: '70px' }}>value</th>
                            <th style={{ width: '130px' }} />
                        </tr>
                    </thead>
                    <tbody>
                        {visibleOrders.map((order, index) => renderTableRow(order, index))}
                    </tbody>
                </table>
            </div>
            <ConfirmModal
                visible={showConfirmModal}
                handleCancel={handleCloseModal}
                confirmOrder={handleConfirmOrder}
                orderIdToConfirm={orderIdToConfirm}
                backorderWarning={backorderWarning}
                customer={customer} />
        </>
    );
};

OrderList.defaultProps = {
    customer: {},
    actions: {},
    selectedOrderItemsArray: [],
};

OrderList.propTypes = {
    customer: PropTypes.object,
    selectedOrderItemsArray: PropTypes.array,
    actions: PropTypes.object,
};

const mapStateToProps = state => ({
    customer: state.customer,
    selectedOrderItemsArray: state.order.selectedOrderItemsArray,
});

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
            ...orderActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
