import { isEmpty } from 'underscore';
import { Record } from 'immutable';

const {
    GET_CAREHOMES_REQUEST,
    GET_CAREHOMES_SUCCESS,
    GET_CAREHOMES_FAILURE,

    GET_CAREHOME_REQUEST,
    GET_CAREHOME_SUCCESS,
    GET_CAREHOME_FAILURE,

    UPDATE_CAREHOME_REQUEST,
    UPDATE_CAREHOME_SUCCESS,
    UPDATE_CAREHOME_FAILURE,

    GET_CAREHOME_NURSE_REQUEST,
    GET_CAREHOME_NURSE_SUCCESS,
    GET_CAREHOME_NURSE_FAILURE,

    ADD_CAREHOME_NURSE_REQUEST,
    ADD_CAREHOME_NURSE_SUCCESS,
    ADD_CAREHOME_NURSE_FAILURE,

    REMOVE_CAREHOME_NURSE_REQUEST,
    REMOVE_CAREHOME_NURSE_SUCCESS,
    REMOVE_CAREHOME_NURSE_FAILURE,

    GET_CAREHOME_CUSTOMER_REQUEST,
    GET_CAREHOME_CUSTOMER_SUCCESS,
    GET_CAREHOME_CUSTOMER_FAILURE,

    RESET_CAREHOMES,
} = require('./carehomeActions').constants;

const { STATUS_ACTIVE } = require('../utils/values').default;

const initialCarehomes = {
    rows: [],
    page: 1,
    total: 0,
};

const InitialState = Record({
    carehomes: initialCarehomes,
    carehome: {},
    nurses: [],
    customers: [],
    isFetching: false,
    error: null,
});

const initialState = new InitialState();
const initialFormValues = { is_active: STATUS_ACTIVE };

/**
 * ## carehomeReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function carehomeReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {
    case GET_CAREHOMES_REQUEST:
    case GET_CAREHOME_REQUEST:
    case UPDATE_CAREHOME_REQUEST:
    case GET_CAREHOME_NURSE_REQUEST:
    case ADD_CAREHOME_NURSE_REQUEST:
    case REMOVE_CAREHOME_NURSE_REQUEST:
    case GET_CAREHOME_CUSTOMER_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case GET_CAREHOMES_SUCCESS:
        return state.set('carehomes', payload).set('isFetching', false).set('error', null);

    case GET_CAREHOME_SUCCESS:
    case UPDATE_CAREHOME_SUCCESS:
        return state.set('carehome', isEmpty(payload) ? initialFormValues : payload).set('isFetching', false).set('error', null);

    case GET_CAREHOME_NURSE_SUCCESS:
    case ADD_CAREHOME_NURSE_SUCCESS:
    case REMOVE_CAREHOME_NURSE_SUCCESS:
        return state.set('nurses', payload).set('isFetching', false).set('error', null);

    case GET_CAREHOME_CUSTOMER_SUCCESS:
        return state.set('customers', payload).set('isFetching', false).set('error', null);

    case GET_CAREHOMES_FAILURE:
    case GET_CAREHOME_FAILURE:
    case UPDATE_CAREHOME_FAILURE:
    case GET_CAREHOME_NURSE_FAILURE:
    case ADD_CAREHOME_NURSE_FAILURE:
    case REMOVE_CAREHOME_NURSE_FAILURE:
    case GET_CAREHOME_CUSTOMER_FAILURE:
        return state.set('error', payload).set('isFetching', false);

    case RESET_CAREHOMES:
        return state.set('carehomes', initialCarehomes).set('error', null).set('isFetching', false);
    default:
        return state;
    }
}
