/* global Blob:true */
import { isUndefined, isEmpty } from 'underscore';
import { put, call, takeEvery } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import alertify from 'alertify.js';
import moment from 'moment';
import { notification } from 'antd';
import { fetchApiAuth } from '../../core/utils/api';
import { mapPropsToDbDates } from '../../core/utils/helpers';

import {
    getReportSuccess,
    getReportFailure,
} from './reportActions';

const {
    GET_REPORT_REQUEST,
} = require('./reportActions').constants;

const { DB_DATE_FORMAT } = require('../utils/values').default;

function* getReport({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/report/generate',
            body: mapPropsToDbDates(payload, ['report_from', 'report_to']),
            responseType: 'blob',

        });

        if (isUndefined(response) || isEmpty(response)) {
            notification.error({ message: 'Error detected', description: 'No data for dates specified' });
        } else {
            const blob = new Blob([response.data], { type: response.data.type });
            let fext;
            switch (response.data.type) {
            case 'text/csv':
                fext = 'csv';
                break;
            case 'application/pdf':
                fext = 'pdf';
                break;
            default:
                fext = 'csv';
            }
            saveAs(blob, `${payload.report_type}${moment().format(DB_DATE_FORMAT)}.${fext}`);
        }

        yield put(getReportSuccess());
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getReportFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* reportSaga() {
    yield* [
        takeEvery(GET_REPORT_REQUEST, getReport),
    ];
}
