import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import DOM from './productsCustomer';

import {
    handleBasicGridviewMount,
    handleBasicGridviewPagination,
} from '../../../core/utils/helpers';

import * as customerActions from '../../../core/customer/customerActions';

class ProductsCustomer extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        const { actions, customerId } = this.props;
        handleBasicGridviewMount(actions.getCustomerProductsRequest, 'searchCustomerProducts', { id: customerId });
    }

    handlePagination = (page, pageSize) => {
        const { actions } = this.props;
        handleBasicGridviewPagination(actions.getCustomerProductsRequest, 'searchCustomerProducts', page, pageSize);
    }

    render() {
        return this.view();
    }
}

ProductsCustomer.propTypes = {
    actions: PropTypes.object.isRequired,
    customerId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        products: state.customer.products.rows,
        total: state.customer.products.total,
        page: state.customer.products.page,
        isFetching: state.customer.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsCustomer);
