import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import * as customerActions from '../../../core/customer/customerActions';
import * as referrerActions from '../../../core/referrer/referrerActions';

import DOM from './addCustomerReferrer';

class AddCustomerReferrerForm extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            customerId: null,
            customerError: false,
        };
    }

    componentWillMount() {
        const { actions } = this.props;
        actions.resetCustomers();
    }

    // Handle change of customer select
    handleChangeCustomers = (customerId) => {
        this.setState({ customerId });
    }

    // Handle customer select search
    handleSearchCustomers = (value) => {
        const { actions } = this.props;
        if (value.length >= 3) {
            actions.getCustomersRequest({ id_name: value });
        } else if (value.length < 2) {
            actions.resetCustomers();
        }
    }

    // Handle submit form
    handleAddCustomerReferrerSubmit = () => {
        const { customerId } = this.state;
        const customerError = !customerId;

        if (customerError) {
            this.setState({ customerError });
        } else {
            const { actions, referrerId, changeVisible } = this.props;
            actions.addReferrerCustomerRequest({
                referrerId,
                customerId,
            });
            changeVisible(false);
        }
    };

    render() {
        return this.view();
    }
}

AddCustomerReferrerForm.propTypes = {
    actions: PropTypes.object.isRequired,
    changeVisible: PropTypes.func.isRequired,
    referrerId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        customers: state.customer.customers.rows,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
            ...referrerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomerReferrerForm);
