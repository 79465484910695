import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'underscore';

import DOM from './menu';

import * as authActions from '../../../core/auth/authActions';
import * as userActions from '../../../core/user/userActions';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false
        };
        this.view = DOM;
    }

    componentDidMount() {
        const { actions, user } = this.props;
        if (isEmpty(user)) {
            actions.getUserRequest();
        }
    }

    handleMenuBackdropClick = () => {
        this.setState({ menuOpen: !this.state.menuOpen });
    };

    handleMenuClick = () => {
        this.setState({ menuOpen: !this.state.menuOpen });
    };

    handleLinkClick = () => {
        this.setState({ menuOpen: false });
    };

    handleLogout = () => {
        const { actions } = this.props;
        actions.logoutRequest();
    };

    render() {
        return this.view(this.props);
    }
}

Menu.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        user: state.user.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...authActions,
            ...userActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
