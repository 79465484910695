import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import DOM from './customer';

class CustomerPage extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            modalAssignVisible: false,

            modalCancelAutoOrderVisible: false,
            modalCancelAutoOrderId: 0,

            modalUpdateAutoOrderVisible: false,
            modalUpdateAutoOrderId: 0,
            modalUpdateAutoOrderInterval: 0,
            modalUpdateAutoOrderIntervalType: 'Day',

            name: parseInt(props.params.id, 10) ? `#${props.params.id}` : 'New Customer',
            toolbar: this.getToolbar(props.params.id),
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ toolbar: this.getToolbar(parseInt(nextProps.params.id, 10)) });
    }

    // Handle get toolbar based on tab
    getToolbar = (id, key) => {
        const tab = key || 1;
        const toolbar = [
            { id: 'actionNewOrder', caption: 'New order', icon: 'new-order', href: `/order/0/customer/${id}`, target: 'blank' },
        ];

        if (tab === 3) {
            toolbar.push({ id: 'actionAssignDoctorPractice', caption: 'Assign doctor or practice', icon: 'new-order', onClick: () => { this.setModalAssignVisible(true); } });
        }

        if (![4, 5].includes(tab)) {
            toolbar.push({ id: 'actionSave', caption: 'Save', icon: 'save', onClick: this.handleUpdateCustomerSubmit });
        }

        return toolbar;
    };

    // Handle set page name
    setPageName = name => this.setState({ name });

    // Handle open doctor practice modal
    setModalAssignVisible = modalAssignVisible => this.setState({ modalAssignVisible });

    // Handle open cancel auto order modal
    setModalCancelAutoOrderVisible = modalCancelAutoOrderVisible => this.setState({ modalCancelAutoOrderVisible });

    // Handle open update auto order modal
    setModalUpdateAutoOrderVisible = modalUpdateAutoOrderVisible => this.setState({ modalUpdateAutoOrderVisible });

    // Handle open modal
    handleOpenModal = (modal, id, interval, type) => {
        const newState = {};
        newState[`${modal}Visible`] = true;
        newState[`${modal}Id`] = id;
        if (interval && type) {
            newState[`${modal}Interval`] = interval;
            newState[`${modal}IntervalType`] = type;
        }
        this.setState(newState);
    }

    // Handle submit form
    handleUpdateCustomerSubmit = () => {
        this.props.actions.submit('update-customer');
    };

    // Handle tab change
    handleTabChange = key => this.setState({ toolbar: this.getToolbar(this.props.params.id, parseInt(key, 10)) });

    render() {
        return this.view();
    }
}

CustomerPage.propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ submit }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPage);
