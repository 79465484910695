import React, { Component, } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Input, Button, Empty, Spin } from 'antd';
import { isEqual, isEmpty, isArray } from 'underscore';

import styles from './styles.js';

import { generateProducts } from './dataGenerators';
import { debounce } from '../../../../../core/utils/helpers.js';

import * as productActions from '../../../../../core/product/productActions';
import * as orderActions from '../../../../../core/order/orderActions';
import * as customerActions from '../../../../../core/customer/customerActions';

class Products extends Component {
    constructor(props) {
        super(props);
        const { products } = this.props;

        this.state = {
            filters: {},
            products,
            rowsToRender: 20,
        };

        this.gridView = React.createRef();
    }

    componentDidMount() {
        this.handleSearchRequest();
    }

    componentDidUpdate(prevProps, prevState) {
        const { products } = this.props;

        // Update the state with new redux state values
        if (!isEqual(products, prevProps.products)) {
            this.setState({ products });
        }
    }

    handleSearchRequest() {
        const { actions } = this.props;
        const { filters, } = this.state;
        const filtersBuffer = JSON.parse(JSON.stringify(filters));

        actions.getProductsRequest({ id_name: filtersBuffer.product_id_name });
    }

    handleSetSearchInput({ event }) {
        const { filters } = this.state;

        debounce(this.setState({ filters: { ...filters, product_id_name: event.target.value, } }, () => {
            this.handleSearchRequest();
        }), 50);
    }

    handleClear() {
        const { filters, } = this.state;
        const filtersBuffer = JSON.parse(JSON.stringify(filters));

        Object.keys(filtersBuffer).forEach((key) => {
            filtersBuffer[key] = null;
        });

        this.setState({ filters: filtersBuffer }, () => {
            this.handleSearchRequest();
        });
    }

    onScroll() {
        // If scrolled to the bottom.
        if (this.gridView.current) {
            const { scrollTop, scrollHeight, clientHeight } = this.gridView.current;
            if (Math.abs((scrollTop + clientHeight) - scrollHeight) < 5) {
                this.setState({ rowsToRender: this.state.rowsToRender + 20 });
            }
        }
    }

    handleAdd({ productId, }) {
        const { actions, missingPrescriptionItems, orderItemSearchResults, currentPresciption } = this.props;
        const prescriptionId = currentPresciption.id;

        const missingPrescriptionItemsBuffer = JSON.parse(JSON.stringify(missingPrescriptionItems));
        missingPrescriptionItemsBuffer.push({
            product_id: productId,
            prescription_id: prescriptionId,
            quantity: 0,
        });

        actions.updateMissingPrescriptionItemsOfflineRequest(missingPrescriptionItemsBuffer);
        actions.createupdatePrescriptionItemRequest({
            product_id: productId,
            prescription_id: prescriptionId,
            quantity: 0,
        });
    }

    _renderFilterBar() {
        const { filters } = this.state;

        return (
            <div style={styles.filterBarContainer}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}>
                    <div style={styles.filterGroup}>
                        <p
                            style={styles.filterLabel}>Search:
                        </p>
                        <Input
                            value={filters.product_id_name}
                            onChange={event => this.handleSetSearchInput({ event })}
                            style={{ width: 240, height: 24, }} />
                    </div>
                    <a onClick={() => this.handleClear()}>clear</a>
                </div>
            </div>
        );
    }

    _renderTableHeaderElements({ field }) {
        switch (field.fieldName) {
        default:
            return (
                <th
                    key={field.fieldName}
                    style={styles.tableHeaderElement}>
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                        {field.fieldLabel.toUpperCase()}
                    </div>
                </th>
            );
        }
    }

    transpose(a) {
        return Object.keys(a[0]).map(c => a.map(r => r[c]));
    }

    _renderTableBodyRowElement({ column, fieldName, productId, }) {
        switch (fieldName) {
        case 'add':
            return (
                <td
                    key={`${productId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <Button
                        onClick={() => this.handleAdd({ productId, })}
                        style={styles.addButton}>
                        {'Add'}
                    </Button>
                </td>
            );
        default:
            return (
                <td
                    key={`${productId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    {column}
                </td>
            );
        }
    }

    _renderTableBody(data) {
        const matrix = [];
        const fieldNames = [];
        data.map((element) => {
            matrix.push(element.fieldValues);
            fieldNames.push(element.fieldName);
        });
        const transpose = this.transpose(matrix);

        return (
            <tbody style={styles.tableBody}>
                {transpose.map((row, rowIndex) => (
                    <tr
                        key={rowIndex}
                        style={styles.tableBodyRow}>
                        {row.map((column, columnIndex) => this._renderTableBodyRowElement({
                            column,
                            fieldName: fieldNames[columnIndex],
                            productId: row[3],
                        }))}
                    </tr>))}
                <tr style={{ height: 200, }} />
            </tbody>
        );
    }

    _renderTable() {
        const { products, rowsToRender, } = this.state;
        const productsBuffer = products.rows.flat(1);

        const data = isArray(productsBuffer) && !isEmpty(productsBuffer) ? generateProducts({ products: productsBuffer.slice(0, rowsToRender) }) : [];
        const shouldRenderTableContent = data && isArray(data) && data[0] && isArray(data[0].fieldValues) && !isEmpty(data[0].fieldValues);

        return (
            <table
                style={styles.table}>
                {shouldRenderTableContent ?
                    <React.Fragment>
                        <thead style={styles.tableHeader}>
                            <tr style={styles.tableHeaderRow}>
                                {data.map(field => (
                                    this._renderTableHeaderElements({ field })
                                ))}
                            </tr>
                        </thead>
                        {this._renderTableBody(data)}
                    </React.Fragment>
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No products'} />
                }
            </table>
        );
    }

    render() {
        const { height, isFetchingProducts, isCreatingupdatingPrescriptionItem } = this.props;

        let gridviewContainerStyles = styles.gridviewContainer;
        if (height || height === 0) {
            gridviewContainerStyles = { ...gridviewContainerStyles, height, minHeight: height };
        }

        return (
            <div
                ref={this.gridView}
                onScroll={() => this.onScroll()}
                style={gridviewContainerStyles}
                className="prescription_matcher_order_item_quickfind">
                <Spin
                    spinning={isFetchingProducts || isCreatingupdatingPrescriptionItem}>
                    {this._renderFilterBar()}
                    {this._renderTable()}
                </Spin>
            </div>
        );
    }
}

Products.defaultProps = {
    actions: {},
    products: [],
    missingPrescriptionItems: [],
    orderItemSearchResults: [],
    height: null,
};

Products.propTypes = {
    actions: PropTypes.object,
    height: PropTypes.number,
    products: PropTypes.array,
    missingPrescriptionItems: PropTypes.array,
    orderItemSearchResults: PropTypes.array,
    isFetchingProducts: PropTypes.bool.isRequired,
    isCreatingupdatingPrescriptionItem: PropTypes.bool.isRequired,
    currentPresciption: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        products: state.product.products,
        missingPrescriptionItems: state.order.missingPrescriptionItems,
        isFetchingProducts: state.product.isFetchingProducts,
        isCreatingupdatingPrescriptionItem: state.customer.isCreatingupdatingPrescriptionItem,
        orderItemSearchResults: state.customer.orderItemSearchResults,
        currentPresciption: state.customer.currentPresciption,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...productActions,
            ...orderActions,
            ...customerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);
