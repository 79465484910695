import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import {
    Row,
    Col,
    Input,
    Button,
} from 'antd';

import styles from './styles.js';

const Search = Input.Search;

class SearchForProductsSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    _renderProduct({ item }) {
        const {
            callbacks: {
                updateProduct,
            },
        } = this.props;

        return (
            <Row key={item.key} type="flex">
                <Col span={3}>
                    <span className="table-cell">#{item.sku}</span>
                </Col>
                <Col span={9}>
                    <span className="table-cell">{item.description}</span>
                </Col>
                <Col span={8}>
                    <span className="table-cell">
                        <span className="italic-grey">{item.description && item.description.length > 20 ? `${item.description.substr(0, 20)}...` : item.description}</span>
                    </span>
                </Col>
                <Col span={4}>
                    <div className="table-cell">
                        <span className="btn-bar">
                            <Button onClick={() => { updateProduct(item.id); }} type="default" className="ant-btn-xs">Add</Button>
                        </span>
                    </div>
                </Col>
            </Row>
        );
    }

    render() {
        const { searchProducts, callbacks: { searchProduct, } } = this.props;

        return (
            <div className="section__container">
                <div className="section__header">
                    <h3 className="h-4">Search for products</h3>
                </div>
                <div className="section-white small-padding">
                    <Search placeholder="Search" className="form__search" onChange={(e) => { searchProduct(e.target.value); }} onSearch={(value) => { searchProduct(value); }} style={{ width: 360 }} />
                    <div className="light-table bordered overflow-y-scroll">
                        { searchProducts.map(item => this._renderProduct({ item })) }
                    </div>
                </div>
            </div>
        );
    }
}

SearchForProductsSection.defaultProps = {
};

SearchForProductsSection.propTypes = {
    searchProducts: PropTypes.array.isRequired,
    callbacks: PropTypes.object.isRequired,
};

export default SearchForProductsSection;
