import { isEmpty } from 'underscore';
import { Record } from 'immutable';

const {
    GET_NURSES_REQUEST,
    GET_NURSES_SUCCESS,
    GET_NURSES_FAILURE,

    GET_NURSE_REQUEST,
    GET_NURSE_SUCCESS,
    GET_NURSE_FAILURE,

    UPDATE_NURSE_REQUEST,
    UPDATE_NURSE_SUCCESS,
    UPDATE_NURSE_FAILURE,

    GET_NURSE_CAREHOME_REQUEST,
    GET_NURSE_CAREHOME_SUCCESS,
    GET_NURSE_CAREHOME_FAILURE,

    ADD_NURSE_CAREHOME_REQUEST,
    ADD_NURSE_CAREHOME_SUCCESS,
    ADD_NURSE_CAREHOME_FAILURE,

    REMOVE_NURSE_CAREHOME_REQUEST,
    REMOVE_NURSE_CAREHOME_SUCCESS,
    REMOVE_NURSE_CAREHOME_FAILURE,

    GET_NURSE_CUSTOMER_REQUEST,
    GET_NURSE_CUSTOMER_SUCCESS,
    GET_NURSE_CUSTOMER_FAILURE,

    ADD_NURSE_CUSTOMER_REQUEST,
    ADD_NURSE_CUSTOMER_SUCCESS,
    ADD_NURSE_CUSTOMER_FAILURE,

    RESET_NURSES,
} = require('./nurseActions').constants;

const { STATUS_ACTIVE } = require('../utils/values').default;

const initialNurses = {
    rows: [],
    page: 1,
    total: 0,
};

const InitialState = Record({
    nurses: initialNurses,
    nurse: {},
    carehomes: [],
    customers: [],
    isFetching: false,
    error: null,
});

const initialState = new InitialState();
const initialFormValues = { is_active: STATUS_ACTIVE };

/**
 * ## nurseReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function nurseReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {
    case GET_NURSES_REQUEST:
    case GET_NURSE_REQUEST:
    case UPDATE_NURSE_REQUEST:
    case GET_NURSE_CAREHOME_REQUEST:
    case ADD_NURSE_CAREHOME_REQUEST:
    case REMOVE_NURSE_CAREHOME_REQUEST:
    case GET_NURSE_CUSTOMER_REQUEST:
    case ADD_NURSE_CUSTOMER_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case GET_NURSES_SUCCESS:
        return state.set('nurses', payload).set('isFetching', false).set('error', null);

    case GET_NURSE_SUCCESS:
    case UPDATE_NURSE_SUCCESS:
        return state.set('nurse', isEmpty(payload) ? initialFormValues : payload).set('isFetching', false).set('error', null);

    case GET_NURSE_CAREHOME_SUCCESS:
    case ADD_NURSE_CAREHOME_SUCCESS:
    case REMOVE_NURSE_CAREHOME_SUCCESS:
        return state.set('carehomes', payload).set('isFetching', false).set('error', null);

    case GET_NURSE_CUSTOMER_SUCCESS:
    case ADD_NURSE_CUSTOMER_SUCCESS:
        return state.set('customers', payload).set('isFetching', false).set('error', null);

    case GET_NURSES_FAILURE:
    case GET_NURSE_FAILURE:
    case UPDATE_NURSE_FAILURE:
    case GET_NURSE_CAREHOME_FAILURE:
    case ADD_NURSE_CAREHOME_FAILURE:
    case REMOVE_NURSE_CAREHOME_FAILURE:
    case GET_NURSE_CUSTOMER_FAILURE:
    case ADD_NURSE_CUSTOMER_FAILURE:
        return state.set('error', payload).set('isFetching', false);

    case RESET_NURSES:
        return state.set('nurses', initialNurses).set('isFetching', false).set('error', null);

    default:
        return state;
    }
}
