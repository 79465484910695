import { Record } from 'immutable';

const {
    GET_DOCTORS_REQUEST,
    GET_DOCTORS_SUCCESS,
    GET_DOCTORS_FAILURE,
} = require('./doctorActions').constants;

const initialDoctors = {
    rows: [],
    page: 1,
    total: 0,
};

const InitialState = Record({
    doctors: initialDoctors,
    isFetching: false,
    error: null,
});

const initialState = new InitialState();

/**
 * ## doctorReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function doctorReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {
    case GET_DOCTORS_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case GET_DOCTORS_SUCCESS:
        return state.set('doctors', payload).set('isFetching', false).set('error', null);

    case GET_DOCTORS_FAILURE:
        return state.set('error', payload).set('isFetching', false);

    default:
        return state;
    }
}
