import React from 'react';
import Helmet from 'react-helmet';

const { APP_NAME } = require('../../../core/constants').default;

export default function () {
    const { children } = this.props;

    return (
        <div id="app">
            <Helmet titleTemplate={`%s - ${APP_NAME}`} defaultTitle={`${APP_NAME}`} meta={[{ name: 'description', content: `${APP_NAME} website` }, { property: 'og:type', content: 'website' }]} />
            {children}
        </div>
    );
}
