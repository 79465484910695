import { Record } from 'immutable';

const {
    DELETE_TOKEN_REQUEST,
    DELETE_TOKEN_SUCCESS,

    SAVE_TOKEN_REQUEST,
    SAVE_TOKEN_SUCCESS,

    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,

    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,

    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAILURE,
} = require('./authActions').constants;

const InitialState = Record({
    isLoggedIn: false,
    isFetching: false,
    error: null,
});

const initialState = new InitialState();

/**
 * ## authReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function authReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {
    case DELETE_TOKEN_REQUEST:
    case SAVE_TOKEN_REQUEST:
    case LOGIN_REQUEST:
    case LOGOUT_REQUEST:
    case PASSWORD_RESET_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case LOGIN_SUCCESS:
        return state.set('isLoggedIn', true).set('isFetching', false).set('error', null);

    case LOGOUT_SUCCESS:
        return state.set('isLoggedIn', false).set('isFetching', false).set('error', null);

    case PASSWORD_RESET_SUCCESS:
        return state.set('isFetching', false);

    case LOGIN_FAILURE:
    case LOGOUT_FAILURE:
    case PASSWORD_RESET_FAILURE:
        return state.set('error', payload).set('isFetching', false);

    case DELETE_TOKEN_SUCCESS:
    case SAVE_TOKEN_SUCCESS:
    default:
        return state;
    }
}
