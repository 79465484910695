import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import DOM from './ordersWaiting';

import {
    handleBasicGridviewMount,
    handleBasicGridviewPagination,
} from '../../../core/utils/helpers';

import * as chaserActions from '../../../core/chaser/chaserActions';
import * as orderActions from '../../../core/order/orderActions';

class OrdersWaiting extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        const { actions } = this.props;
        handleBasicGridviewMount(actions.getOrdersWaitingRequest, 'searchOrdersWaiting');
    }

    // Get value for checkbox based on redux state
    getCheckboxValue = (type, id) => this.props.chasers[type].includes(id);

    // Handle checkbox change
    handleCheckboxChange = (type, id, value) => {
        const { chasers, actions } = this.props;
        let ids = chasers[type];
        if (value) {
            ids.push(id);
        } else {
            ids = ids.filter(item => id !== item);
        }

        actions.setChasersRequest({ type, ids });
    }

    // Handle mark comm as received
    handleMarkCommAsReceived = (type, id) => {
        const { actions } = this.props;
        actions.markCommAsReceivedRequest({ type, id });
    }

    // Handle table pagination
    handlePagination = (page, pageSize) => {
        const { actions } = this.props;
        handleBasicGridviewPagination(actions.getOrdersWaitingRequest, 'searchOrdersWaiting', page, pageSize);
    }

    render() {
        return this.view();
    }
}

OrdersWaiting.propTypes = {
    actions: PropTypes.object.isRequired,
    chasers: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.order.isFetching,
        orders: state.order.ordersWaiting.rows,
        page: state.order.ordersWaiting.page,
        total: state.order.ordersWaiting.total,

        chasers: state.chaser,
        emailChasers: state.chaser.email,
        postChasers: state.chaser.post,
        faxChasers: state.chaser.fax,
        chaserIsFetching: state.chaser.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...chaserActions,
            ...orderActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersWaiting);
