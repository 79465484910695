import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import DOM from './ordersReady';

import {
    handleBasicGridviewMount,
    handleBasicGridviewPagination,
} from '../../../core/utils/helpers';

import * as dispatchActions from '../../../core/dispatch/dispatchActions';
import * as orderActions from '../../../core/order/orderActions';

class OrdersReady extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            ordersCount: 0,
        };
    }

    componentDidMount() {
        const { actions } = this.props;
        handleBasicGridviewMount(actions.getOrdersReadyRequest, 'searchOrdersReady');
    }

    componentWillReceiveProps(nextProps) {
        const { dispatches, orders } = nextProps;
        let ordersCount = 0;
        orders.forEach((item) => {
            if (dispatches.includes(item.id)) {
                ordersCount += item.items;
            }
        });

        this.setState({ ordersCount });
    }

    // Get checkbox value
    getCheckedValue = id => this.props.dispatches.includes(id);

    // Handle checkbox change
    handleCheckboxChange = (id, value) => {
        const { dispatches, actions } = this.props;
        let checkboxes = dispatches;
        if (value) {
            checkboxes.push(id);
        } else {
            checkboxes = checkboxes.filter(item => id !== item);
        }

        actions.setDispatchesRequest(checkboxes);
    }

    // Handle gridview pagination
    handlePagination = (page, pageSize) => {
        const { actions } = this.props;
        handleBasicGridviewPagination(actions.getOrdersReadyRequest, 'searchOrdersReady', page, pageSize);
    }

    render() {
        return this.view();
    }
}

OrdersReady.propTypes = {
    actions: PropTypes.object.isRequired,
    dispatches: PropTypes.array.isRequired,
    orders: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.order.isFetching,
        orders: state.order.ordersReady.rows,
        page: state.order.ordersReady.page,
        total: state.order.ordersReady.total,

        dispatches: state.dispatch.dispatches,
        dispatchesFetching: state.dispatch.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...dispatchActions,
            ...orderActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersReady);
