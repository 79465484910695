import React from 'react';

import Layout from '../../components/layout/Layout';
import CustomersSearch from '../../components/gridviews/CustomersSearch';
import { GridButton } from '../../core/utils/helpers';

export default function () {
    return (
        <Layout title={this.state.title} toolbar={this.state.toolbar} breadcrumb="customers">
            <CustomersSearch
                renderButtons={(text, record) => (
                    <div className="btn-bar">
                        <GridButton href={`/order/0/customer/${record.id}`} title="Add Order" />
                        <GridButton href={`/customer/${record.id}`} />
                    </div>
                )} />
        </Layout>
    );
}
