const styles = {
    // Main
    gridviewContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        padding: 12,
        height: '100%',
        backgroundColor: 'white',
    },
    selectInput: {
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '25px',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: 'inherit',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: 'inherit',
        }),
    },

    // Filter
    filterBarTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2d3748',
        verticalAlign: 'center',
        margin: 0,
        whiteSpace: 'nowrap',
    },
    filterLabel: {
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2d3748',
        verticalAlign: 'center',
        margin: 0,
        marginRight: 5,
        whiteSpace: 'nowrap',
    },
    filterGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    filterBarContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    matchButton: {
        borderRadius: 12,
        border: 'solid',
        borderColor: '#149566',
        borderWidth: 1,
        fontSize: 10,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#149566',
        height: 24,
    },

    // Table
    tableHeaderRow: {
    },
    tableHeaderElement: {
        fontSize: 10,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#a0aec0',
        verticalAlign: 'center',
        padding: 5,
    },
    tableBodyRow: {
        borderTop: '1px solid #a0aec0',
    },
    tableBodyElement: {
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2d3748',
        verticalAlign: 'center',
        padding: 5,
    },
    table: {
        width: '100%',
        alignSelf: 'center',
        marginTop: 20,
    },
    tableBody: {

    },
    tableHeader: {

    },
};

export default styles;
