import { useState, useEffect, useRef } from 'react';

const RENDER_DELAY = 10;

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const lastChange = useRef(new Date());

    useEffect(() => {
        function handleResize() {
            const now = new Date();
            const diffTime = Math.abs(now - lastChange.current);
            if (diffTime > RENDER_DELAY) {
                setWindowDimensions(getWindowDimensions());
            } else {
                lastChange.current = now;
            }
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [windowDimensions]);

    return windowDimensions;
}
