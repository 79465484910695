import React from 'react';

import Gridview from '../../../elements/Gridview';
import { GridButton } from '../../../core/utils/helpers';

export default function () {
    const config = [
        { dataIndex: 'full_name', title: 'Referrer Name' },
        { dataIndex: 'first_name', title: 'Forenames', search: true, type: 'text', visible: false },
        { dataIndex: 'last_name', title: 'Surnames', search: true, type: 'text', visible: false },
        { dataIndex: 'key', align: 'right', render: (value, record) => <GridButton href={`/referrer/${record.id}`} /> },
    ];

    const { isFetching, total, page, referrers } = this.props;
    return (
        <Gridview
            config={config}
            data={referrers}
            handleClearSearch={this.handleClearSearch}
            handlePagination={this.handlePagination}
            handleSearch={this.handleSearch}
            loading={isFetching}
            searchTitle="Find a Referrer"
            title="Search Results"
            pagination={{
                simple: true,
                onChange: this.handlePagination,
                pageSize: 10,
                total,
                current: page,
                className: 'custom-pagination',
            }} />
    );
}
