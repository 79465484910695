import PropTypes from 'prop-types';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DOM from './ordersSearch';

import {
    handleBasicGridviewMount,
    handleBasicGridviewSearch,
    handleBasicGridviewClearSearch,
    handleBasicGridviewPagination,
    handleBasicGridviewSort,
} from '../../../core/utils/helpers';

import * as orderActions from '../../../core/order/orderActions';
import * as nurseActions from '../../../core/nurse/nurseActions';
import * as referrerActions from '../../../core/referrer/referrerActions';

class OrdersSearch extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.getNursesRequest();
        actions.getReferrersRequest();
        handleBasicGridviewMount(actions.getOrdersRequest, 'searchOrders');
    }

    // Handle gridview search
    handleSearch = (values) => {
        const { actions } = this.props;
        handleBasicGridviewSearch(actions.getOrdersRequest, 'searchOrders', values);
    }

    // Handle gridview sort
    handleSort = (value) => {
        const { actions } = this.props;
        handleBasicGridviewSort(actions.getOrdersRequest, 'searchOrders', value);
    }

    // Handle gridview clear search
    handleClearSearch = (reset) => {
        const { actions } = this.props;
        handleBasicGridviewClearSearch(actions.getOrdersRequest, 'searchOrders');
        reset();
    }

    // Handle gridview pagination
    handlePagination = (page, pageSize) => {
        const { actions } = this.props;
        handleBasicGridviewPagination(actions.getOrdersRequest, 'searchOrders', page, pageSize);
    }

    render() {
        return this.view();
    }
}

OrdersSearch.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.order.isFetching,
        nurses: state.nurse.nurses.rows,
        orders: state.order.orders.rows,
        page: state.order.orders.page,
        referrers: state.referrer.referrers.rows,
        total: state.order.orders.total,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...orderActions,
            ...nurseActions,
            ...referrerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersSearch);
