import { Record } from 'immutable';

const {
    SET_DISPATCHES_REQUEST,
    SET_DISPATCHES_SUCCESS,
    SET_DISPATCHES_FAILURE,

    AUTHORISE_DISPATCHES_REQUEST,
    AUTHORISE_DISPATCHES_SUCCESS,
    AUTHORISE_DISPATCHES_FAILURE,
} = require('./dispatchActions').constants;

const InitialState = Record({
    dispatches: [],
    isFetching: false,
    error: null,
});

const initialState = new InitialState();

/**
 * ## dispatchReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function dispatchReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {
    case SET_DISPATCHES_REQUEST:
    case AUTHORISE_DISPATCHES_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case SET_DISPATCHES_SUCCESS:
        return state.set('dispatches', payload).set('isFetching', false);

    case AUTHORISE_DISPATCHES_SUCCESS:
        return state.set('dispatches', []).set('isFetching', false);

    case SET_DISPATCHES_FAILURE:
    case AUTHORISE_DISPATCHES_FAILURE:
        return state.set('error', payload).set('isFetching', false);

    default:
        return state;
    }
}
