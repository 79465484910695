// APP CONSTATNS
const {
    REACT_APP_ENV,
    REACT_APP_API_URL,
    REACT_APP_BUGSNAG_KEY,
    REACT_APP_GOOGLE_MAPS_KEY
} = process.env;

const app = {
    APP_ENV: REACT_APP_ENV,
    DEBUG: REACT_APP_ENV === 'local',
    MOCK: false,
    APP_NAME: 'Jemsys',
    API_URL: REACT_APP_API_URL,
    GOOGLE_MAPS_KEY: REACT_APP_GOOGLE_MAPS_KEY,

    // Auth
    AUTH_KEY: 'access-token',
    LOCALSTORAGE_EXPIRES_KEY: 'session-token-expiry',
    LOCALSTORAGE_EXPIRY_TIME: '30', // minutes
};

export default app;
