import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { isUndefined } from 'underscore';
import { reduxForm } from 'redux-form';

import * as carehomeActions from '../../../core/carehome/carehomeActions';

import DOM from './updateCareHome';

class UpdateCareHomeForm extends Component {
    constructor(props) {
        super(props);
        this.handleSetPageName(props);
        this.view = DOM;
    }

    componentWillMount() {
        const { actions, carehomeId } = this.props;
        actions.getCarehomeRequest(carehomeId);
    }

    componentWillReceiveProps(nextProps) {
        this.handleSetPageName(nextProps);
    }

    // Handle set page name
    handleSetPageName(props) {
        const { setPageName, initialValues, carehomeId, isFetching } = props;
        if (!isFetching) {
            if (!isUndefined(initialValues.title)) {
                setPageName(`#${initialValues.id} - ${initialValues.title}`);
            } else if (carehomeId === 0) {
                setPageName('New Carehome');
            }
        }
    }

    // Handle submit form
    handleUpdateCareHomeSubmit = (values) => {
        const { actions, carehomeId } = this.props;
        actions.updateCarehomeRequest({ id: carehomeId, ...values });
    };

    render() {
        return this.view();
    }
}

UpdateCareHomeForm.propTypes = {
    actions: PropTypes.object.isRequired,
    carehomeId: PropTypes.number.isRequired,
};

const UpdateCareHome = reduxForm({
    form: 'update-care-home',
    enableReinitialize: true,
})(UpdateCareHomeForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        initialValues: state.carehome.carehome,
        isFetching: state.carehome.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...carehomeActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCareHome);
