import React from 'react';
import { Button, Spin } from 'antd';
import Layout from '../../components/layout/Layout';
import Icon from '../../elements/Icon';
import UpdateOrder from '../../components/forms/UpdateOrder';
import LeaveUpdateOrder from '../../components/modals/LeaveUpdateOrder';
import NotesUpdateOrder from '../../components/modals/NotesUpdateOrder';

import './order.css';

export default function () {
    const { name, toolbar, modalSplitVisible, modalUnsavedVisible, modalNotesVisible, readOnly } = this.state;
    const { isFetching } = this.props;
    const id = parseInt(this.props.params.id, 10);
    const customerId = this.props.params.customerId ? parseInt(this.props.params.customerId, 10) : null;
    const breadcrumb = { name: 'order', params: { id, name } };

    // Header back link
    const backlink = (
        <Button type="primary" size="large" className="transparent backlink" onClick={() => this.setModalUnsavedVisible(true)}>
            <Icon name="arrow-left" />
            <span>Back to Order search</span>
        </Button>
    );

    return (
        <Layout button={backlink} title={name} toolbar={toolbar} breadcrumb={breadcrumb}>
            <Spin spinning={isFetching}>
                {/* FORM: Update order */}
                <UpdateOrder
                    orderId={id}
                    customerId={customerId}
                    setReadOnly={this.setReadOnly}
                    splitModalVisible={modalSplitVisible}
                    handleTabChange={this.handleTabChange}
                    handleModalVisible={this.handleModalVisible} />
            </Spin>
            {/* MODALS */}
            <LeaveUpdateOrder visible={modalUnsavedVisible} changeVisible={this.setModalUnsavedVisible} />
            <NotesUpdateOrder orderId={id} readOnly={readOnly} visible={modalNotesVisible} changeVisible={this.setModalNotesVisible} />
        </Layout>
    );
}
