const styles = {
    rowName: {
        height: 20,
        flexGrow: 0,
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2d3748',
    },
    rowDropdown: {
        flexGrow: 0,
        backgroundColor: '#fff',
        height: 24,
        width: '100%'
    },
    gridLayout: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
        padding: 12,
    },
    textArea: {
        flexGrow: 0,
        marginBottom: 15,
        fontSize: 12,
        fontWeight: 300,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.7,
        letterSpacing: 0.48,
        textAlign: 'left',
        color: '#2d3748',
    },
    capturePictureWrapper: {
        all: 'unset',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    text3: {
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2d3748',
    },
    uploadButton: {
        width: 64,
        borderRadius: 12,
        border: 'solid',
        borderColor: '#149566',
        borderWidth: 1,
        fontSize: 10,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#149566',
        height: 29,
    },

    // Form
    formFieldLabel: {
        textAlign: 'left',
        minWidth: 100,
        fontSize: 12,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        color: '#718096',
        margin: 5,
        verticalAlign: 'center',
    },
    formFieldInput: {
        textAlign: 'left',
        flex: 1,
        fontWeight: 'normal',
        fontSize: 12,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        color: '#718096',
        margin: 5,
        verticalAlign: 'center',
        height: 24,
        display: 'flex',
    },
};

export default styles;
