import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Form } from 'antd';

import FormField from './FormField';

const Item = Form.Item;

// Form input types
export const CUSTOM = 'custom';
export const TEXT = 'text';
export const EMAIL = 'email';
export const PASSWORD = 'password';
export const HIDDEN = 'hidden';
export const TEXTAREA = 'textarea';
export const NUMBER = 'number';
export const SELECT = 'select';
export const DATE = 'date';
export const RADIO = 'radio';
export const CHECKBOX = 'checkbox';
export const SWITCH = 'switch';
export const MULTISELECT = 'multiselect';

class FormItem extends Component {

    // Renders form field
    renderComponent = props => {
        return (<FormField config={this.props} props={props} />);
    }

    render() {

        const { type, name, label, itemDisabled, labelCol, wrapperCol, className, validate } = this.props;
        let item = false;
        // Only render non custom form items as redux form field
        if (![CUSTOM].includes(type)) {
            item = (
                <Item labelCol={labelCol} wrapperCol={wrapperCol} label={type === CHECKBOX ? false : label} className={className}>
                    <Field
                        name={name}
                        component={this.renderComponent}
                        validate={validate} />
                </Item>
            );
        } else {
            item = <FormField config={this.props} />;
        }

        if (itemDisabled) {
            item = <div className="section-disabled">{item}</div>;
        }

        return item;
    }
}

FormItem.defaultProps = {
    className: '',
    disabled: false,
    filterOption: (search, option) => option.props.children.toLowerCase().indexOf(search.toLowerCase()) >= 0,
    handle: () => {},
    handleSearch: () => {},
    itemDisabled: false,
    label: '',
    labelCol: {},
    options: [],
    placeholder: '',
    validate: [],
    wrapperCol: {},
};

FormItem.propTypes = {
    name: PropTypes.node.isRequired,
    type: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    filterOption: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    handle: PropTypes.func,
    handleSearch: PropTypes.func,
    itemDisabled: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    labelCol: PropTypes.object,
    options: PropTypes.array,
    validate: PropTypes.arrayOf(PropTypes.func),
    wrapperCol: PropTypes.object,
};

export default FormItem;
