import React, { Component, } from 'react';
import { Row, Col, Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Grammarly, GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react';
import { Editor } from '@tinymce/tinymce-react';
import optionValues from '../../../../../core/utils/values';

import Icon from '../../../../../elements/Icon';
import GridviewSection from './GridviewSection';

import useWindowDimensions from '../../../../../core/utils/useWindowDimensions';
import * as customerActions from '../../../../../core/customer/customerActions';

class CustomerNotes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            text: '',
            id: '',
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        const { isCreatingUpdatingCustomerNote, error } = this.props;

        if (isCreatingUpdatingCustomerNote === false &&
            prevProps.isCreatingUpdatingCustomerNote === true &&
            error === null
        ) {
            this.setState({ text: '', id: '' });
        }
    }

    handleSubmit() {
        const { actions, customer } = this.props;

        const addNotes = {
            text: this.state.text,
            customerId: customer?.id,
            noteId: this?.state?.id || ''
        };
        actions.createupdateCustomerNoteRequest(addNotes);
    }

    updateTextEditor(noteData) {
        this.setState({ text: noteData?.text, id: noteData?.id  });
    }

    render() {
        const { isCreatingUpdatingCustomerNote } = this.props;

        return (
            <div className="section" style={{overflowY: 'scroll', height: '100%'}}>
                <div className="section">
                    <div className="container">
                        <Row type="flex" justify="center">
                            <Col span={24} md={20} lg={17}>
                                <div className="section__container">
                                    <div className="section__header">
                                        <h3 className="h-4">Create Customer Note</h3>
                                    </div>
                                    <div className="section-white">
                                        <div className="ant-row-flex ant-row-flex-center">
                                            <div className="ant-col ant-col-18">
                                                <div>
                                                    <div className="">
                                                        <div className="ant-row ant-form-item pb-4">
                                                            <div className="ant-col ant-form-item-label">
                                                                <label className="" title="Text">Text</label>
                                                            </div>
                                                            <div className="ant-col ant-form-item-control-wrapper">
                                                                <div className="ant-form-item-control">
                                                                    <span className="ant-form-item-children">
                                                                        <div className="">
                                                                            <Grammarly clientId={optionValues.GRAMMARLY_CLIENT_ID}>
                                                                                <GrammarlyEditorPlugin >
                                                                                    <Editor
                                                                                        onEditorChange={(val) => { this.setState({ text: val }); }}
                                                                                        textareaName="customer_note_text"
                                                                                        name="customer_note_text"
                                                                                        placeholder=""
                                                                                        className="ant-input"
                                                                                        apiKey={optionValues.TINYMCE_API_KEY}
                                                                                        value={this.state.text}
                                                                                        style={{
                                                                                            height: '178px',
                                                                                            minHeight: '178px',
                                                                                            maxHeight: '430px',
                                                                                            overflowY: 'hidden',
                                                                                        }}
                                                                                        init={{
                                                                                            height: 350,
                                                                                            plugins: [
                                                                                                'wordcount',
                                                                                            ],
                                                                                            toolbar: 'bold italic underline fontsize fontfamily  forecolor fontsizeselect | spellchecker ' +
                                                                                ' backcolor | alignleft aligncenter ' +
                                                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                                'removeformat | help',
                                                                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                                        }} />
                                                                                </GrammarlyEditorPlugin>
                                                                            </Grammarly>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Button
                                                loading={isCreatingUpdatingCustomerNote}
                                                disabled={this.state.text === '' || !this.state.text || isCreatingUpdatingCustomerNote}
                                                onClick={() => this.handleSubmit()}
                                                type="primary"
                                                className="average m-0"
                                                htmlType="button">
                                                <Icon name="save" />Submit
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <GridviewSection  
                                    activeTab={3} 
                                    editNoteCallback={(editNote) => this.updateTextEditor(editNote)}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

CustomerNotes.defaultProps = {
    isCreatingUpdatingCustomerNote: false,
    actions: {},
};

CustomerNotes.propTypes = {
    isCreatingUpdatingCustomerNote: PropTypes.bool,
    actions: PropTypes.object,
    customer: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        customer: state.customer.customer,
        isCreatingUpdatingCustomerNote: state.customer.isCreatingUpdatingCustomerNote,
        error: state.customer.error,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerNotes);
