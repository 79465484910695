import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'antd';

import DoctorsPracticesSearch from '../../../components/gridviews/DoctorsPracticesSearch';

const SearchDoctorsPractice = ({ customerId, visible, changeVisible }) => (
    <Modal centered className="shrinked" width={1152} footer={null} visible={visible} onOk={() => changeVisible(false)} onCancel={() => changeVisible(false)}>
        <div className="">
            <div className="section__header justify-content-center">
                <h3 className="h-4">Assign doctor or practice to customer</h3>
            </div>
            <DoctorsPracticesSearch customerId={customerId} toggleModal={changeVisible} />
        </div>
    </Modal>
);

SearchDoctorsPractice.propTypes = {
    customerId: PropTypes.number.isRequired,
    visible: PropTypes.bool.isRequired,
    changeVisible: PropTypes.func.isRequired,
};

export default SearchDoctorsPractice;
