import React from 'react';
import { Button } from 'antd';

import AddCustomer from '../../modals/AddCustomer';
import Gridview from '../../../elements/Gridview';
import { displayId, displayName, GridButton } from '../../../core/utils/helpers';

export default function () {
    // Gridview config
    const config = [
        { dataIndex: 'id', title: 'ID', width: 100, render: displayId },
        { dataIndex: 'first_name', title: 'Customer Name', render: (value, record) => displayName(record) },
        { dataIndex: 'key', align: 'right', render: (text, record) => <GridButton href={`/customer/${record.id}`} /> },
    ];

    // Gridview title
    const title = (
        <div className="table__topnav">
            <h6 className="h-6">Responsible for customers</h6>
            <Button type="default" className="ant-btn-xs grey" onClick={() => { this.setModalAddCustomerVisible(true); }}>Assign customer to nurse</Button>
        </div>
    );

    const customers = this.props.customers.filter((value, index, self) =>
        index === self.findIndex(t => (
            t.id === value.id
        ))
    );

    return (
        <div>
            {/* TABLE */}
            <Gridview
                scroll={{ y: 250 }}
                preHeader={title}
                config={config}
                data={customers}
                pagination={false} />

            {/* MODAL */}
            <AddCustomer nurseId={this.props.nurseId} visible={this.state.modalAddCustomerVisible} changeVisible={this.setModalAddCustomerVisible} />
        </div>
    );
}
