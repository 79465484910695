/* global window:true */
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import lockr from 'lockr';
import { browserHistory } from 'react-router';
import global from './global/globalReducer';
import auth from './auth/authReducer';
import carehome from './carehome/carehomeReducer';
import chaser from './chaser/chaserReducer';
import customer from './customer/customerReducer';
import dispatch from './dispatch/dispatchReducer';
import doctor from './doctor/doctorReducer';
import nurse from './nurse/nurseReducer';
import order from './order/orderReducer';
import practice from './practice/practiceReducer';
import product from './product/productReducer';
import referrer from './referrer/referrerReducer';
import report from './report/reportReducer';
import supplier from './supplier/supplierReducer';
import user from './user/userReducer';
import backorderChaser from './backorderChaser/backorderChaserReducer';

const {
    AUTH_KEY,
} = require('../core/constants').default;


/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */

const appReducers = combineReducers({
    // ... your other reducers here ...
    global,
    auth,
    carehome,
    chaser,
    customer,
    dispatch,
    doctor,
    nurse,
    order,
    practice,
    product,
    referrer,
    report,
    supplier,
    user,
    backorderChaser,
    form: formReducer
});

const rootReducer = (state, action) => {
    let isSessionExpired = false;
    // ... your other reducers here ...
    if ((action.type).indexOf('_FAILURE') !== -1) {
        // eslint-disable-next-line
        if (action.payload == 'Your request was made with invalid credentials.' || action.payload == 'Token expired. Please login again.') {
            isSessionExpired = true;
        }
    }
    // eslint-disable-next-line
    if (isSessionExpired == true) {
        lockr.rm(AUTH_KEY);
        localStorage.clear();
        browserHistory.push('/login');
    }

    return appReducers(state, action);
};


export default rootReducer;
