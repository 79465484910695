const {
    API_URL
} = require('../../core/constants.js').default;

function generateFormValues(prescription) {
    const formValues = {
        prescription: {
            notes: {
                label: 'Script Notes',
                value: (prescription && prescription.notes),
            },
            issue_date: {
                label: 'Issue Date',
                value: (prescription && prescription.issue_date),
            },
            status: {
                label: 'Update Status',
                value: (prescription && prescription.status),
            },
            type: {
                label: 'Type',
                value: (prescription && prescription.type),
            },
        },
        balance_letter: {
            text: {
                label: 'Text',
                value: (prescription && prescription.letter && prescription.letter.text),
            },
            email: {
                label: 'Email',
                value: (prescription && prescription.letter && prescription.letter.email) || 0,
            },
            letter: {
                label: 'Letter',
                value: (prescription && prescription.letter && prescription.letter.letter) || 0,
            },
            fax: {
                label: 'Fax',
                value: (prescription && prescription.letter && prescription.letter.fax) || 0,
            },
            type: {
                label: 'Type',
                value: (prescription && prescription.letter && prescription.letter.type) || 3
            },
            required: {
                label: 'Required',
                value: (prescription && prescription.letter && prescription.letter.required) || 1
            },
        }
    };

    return formValues;
}

function generateFileList(prescription) {
    const fileList = (prescription && prescription.mediaModels) ? prescription.mediaModels.map(media => ({
        uid: media.id,
        name: media.filename,
        url: `${API_URL.substring(0, API_URL.length - 4)}/${media.publicImage}`,
    })) : [];

    return fileList;
}

export { generateFormValues, generateFileList };
