import React from 'react';

import Gridview from '../../../elements/Gridview';
import { GridButton, displayDate, displayName, displayId } from '../../../core/utils/helpers';

export default function () {
    const { doctors } = this.props;
    const config = [
        { dataIndex: 'id', title: 'Doctor Ref', width: 150, render: displayId },
        { dataIndex: 'first_name', title: 'Name', width: 170, render: (value, record) => displayName(record) },
        { dataIndex: 'email', title: 'Email', width: 250 },
        { dataIndex: 'phone_no', title: 'Phone', width: 170 },
        { dataIndex: 'created_at', title: 'Date Added', render: value => displayDate(value) },
        { dataIndex: 'key', align: 'right', render: (value, record) => <GridButton href={() => this.handleRemoveDoctorPractice(record.id)} title="Remove" /> },
    ];
    return (
        <Gridview
            scroll={{ y: 250 }}
            title={`Doctors at this Practice (${(doctors).length})`}
            config={config}
            data={doctors}
            pagination={false} />
    );
}
