import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Col, Row } from 'antd';
import { connect } from 'react-redux';
import { Form as ReduxForm, reduxForm } from 'redux-form';
import { isArray, keys, values, mapObject } from 'underscore';

import Form from '../../elements/Form';

const FormGroup = Form.Group;

class SearchForm extends Component {
    // Handle search submit, remove '_search' from input names
    handleSearchSubmit = (formValues) => {
        const { onSubmit } = this.props;
        const newFormValues = {};
        mapObject(formValues, (value, key) => {
            const newKey = key.split('_search')[0];
            newFormValues[newKey] = value;
        });

        onSubmit(newFormValues);
    }

    // Map config to have correct config data for form
    // Add '_search' onto input names to keep uniqueness
    updateConfig = data => data.map((item) => {
        const newItem = item;
        newItem.name = `${item.dataIndex}_search`;
        if (!this.props.column) {
            newItem.label = item.title;
        }
        return newItem;
    });

    render() {
        const { title, config, column, onClear, handleSubmit } = this.props;

        let form = false;
        let formTitle = false;
        let formButtons = false;

        // Generate form groups
        if (!isArray(config)) {
            // If config is object ther are multiple forms groups
            const formNames = keys(config);
            form = values(config).map((childConfig, index) => (
                <div key={formNames[index]}>
                    <h5 className="h-5 mb-2 capitalise">{formNames[index]}</h5>
                    <FormGroup config={this.updateConfig(childConfig)} layout={column ? 'horizontal' : 'vertical'} />
                    <br />
                </div>
            ));
        } else {
            form = <FormGroup config={this.updateConfig(config)} layout={column ? 'horizontal' : 'vertical'} />;
        }

        // Form is above gridview
        if (column) {
            // If column is true then add form styling to be horizontal
            form = (
                <Row gutter={24} type="flex" justify="center" align="middle" className="search-row">
                    <Col xl={16} md={14} sm={24} xs={24}>
                        {form}
                    </Col>
                    <Col xl={4} md={5} sm={12} xs={12}>
                        <Button type="primary" htmlType="submit" icon="search" className="medium">Search</Button>
                    </Col>
                    <Col xl={4} md={5} sm={12} xs={12} className="text-center" style={{ paddingLeft: 25, paddingRight: 25 }}>
                        <a className="section__label" onClick={() => { onClear(this.props.reset); }}>Clear search</a>
                    </Col>
                </Row>
            );

        // Form and gridview are side by side
        } else {
            // Add form title
            formTitle = (
                <div className="section__header">
                    <h3 className="h-4">{title}</h3>
                    <a className="section__label" onClick={() => { onClear(this.props.reset); }}>clear search criteria</a>
                </div>
            );

            // Add form buttons
            formButtons = (
                <div className="text-center mt-2">
                    <Button type="primary" htmlType="submit" icon="search" className="wide">Search</Button>
                </div>
            );
        }

        // Generate form
        return (
            <div>
                {formTitle}
                <ReduxForm onSubmit={handleSubmit(this.handleSearchSubmit)} className={`ant-form ant-form-${column ? 'horizontal' : 'vertical'}`}>
                    <div className={`section-white ${column ? 'pb-2 pt-2 mt-1 mb-2' : ''}`} style={column ? { marginRight: '5%', marginLeft: '5%' } : {}}>
                        {form}
                    </div>
                    {formButtons}
                </ReduxForm>
            </div>
        );
    }
}

SearchForm.propTypes = {
    reset: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    config: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    column: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
};

const Search = reduxForm({})(SearchForm);

// Name form based on title
const mapStateToProps = (state, { title }) => ({
    form: `search-gridview-${title.replace(/\s+/g, '')}`,
});

export default connect(mapStateToProps)(Search);
