import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    SET_CHASERS_REQUEST: null,
    SET_CHASERS_SUCCESS: null,
    SET_CHASERS_FAILURE: null,

    RUN_CHASERS_REQUEST: null,
    RUN_CHASERS_SUCCESS: null,
    RUN_CHASERS_FAILURE: null,

    MARK_COMM_AS_RECEIVED_REQUEST: null,
    MARK_COMM_AS_RECEIVED_SUCCESS: null,
    MARK_COMM_AS_RECEIVED_FAILURE: null,
});

export const {
    setChasersRequest,
    setChasersSuccess,
    setChasersFailure,
} = createActions(
    constants.SET_CHASERS_REQUEST,
    constants.SET_CHASERS_SUCCESS,
    constants.SET_CHASERS_FAILURE,
);

export const {
    runChasersRequest,
    runChasersSuccess,
    runChasersFailure,
} = createActions(
    constants.RUN_CHASERS_REQUEST,
    constants.RUN_CHASERS_SUCCESS,
    constants.RUN_CHASERS_FAILURE,
);

export const {
    markCommAsReceivedRequest,
    markCommAsReceivedSuccess,
    markCommAsReceivedFailure,
} = createActions(
    constants.MARK_COMM_AS_RECEIVED_REQUEST,
    constants.MARK_COMM_AS_RECEIVED_SUCCESS,
    constants.MARK_COMM_AS_RECEIVED_FAILURE,
);
