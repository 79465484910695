import React from 'react';
import { Form as ReduxForm } from 'redux-form';
import { Row, Col } from 'antd';

import Form from '../../../elements/Form';
import Loading from '../../../elements/Loading';
import { isRequired, isRequiredOrZero, charLessThan } from '../../../core/utils/validators';

const { STATUS_OPTIONS } = require('../../../core/utils/options').default;

const FormGroup = Form.Group;
const formTypes = Form.formTypes;

export default function () {
    const formLeftConfig = [
        { type: formTypes.TEXT, name: 'first_name', label: 'Forenames', validate: [isRequired] },
        { type: formTypes.TEXT, name: 'telephone', label: 'Phone', validate: [value => charLessThan(value, 15)] },
        { type: formTypes.SELECT, name: 'status', label: 'Status', options: STATUS_OPTIONS, validate: [isRequiredOrZero] },
    ];

    const formRightConfig = [
        { type: formTypes.TEXT, name: 'last_name', label: 'Surname', validate: [isRequired] },
        { type: formTypes.EMAIL, name: 'email', label: 'Email' },
    ];

    return (
        <div className="container">
            <div className="section">
                <ReduxForm onSubmit={this.props.handleSubmit(this.handleUpdateReferrerSubmit)} className="ant-form ant-form-vertical">
                    <div className="section__header">
                        <h3 className="h-4">Referrer Details</h3>
                    </div>
                    <div className="section-white">
                        {!this.props.isFetching ?
                            <Row gutter={80} type="flex" justify="center">
                                <Col span={24} md={12} lg={10}>
                                    <FormGroup config={formLeftConfig} />
                                </Col>
                                <Col span={24} md={12} lg={10}>
                                    <FormGroup config={formRightConfig} />
                                </Col>
                            </Row>
                        : <Loading className="flex-center-middle" style={{ minHeight: 245 }} />}
                    </div>
                </ReduxForm>
            </div>
        </div>
    );
}
