import axios from 'axios';
import qs from 'qs';
import lockr from 'lockr';
import { isUndefined, isEmpty, isObject } from 'underscore';

// load mock data if enabled
import './mock';

const {
    API_URL,
    AUTH_KEY,
    DEBUG,
    GOOGLE_MAPS_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = require('../constants').default;

const API_BASE_URL = API_URL;

// ----- Helpers ----- //

// Get Api Url
const getApiUrl = () => API_BASE_URL;

// Get hostname url
const getBaseUrl = () => {
    const apiUrl = API_BASE_URL;
    return apiUrl.split('/api')[0];
};

// defaults for axios
const api = axios.create({
    baseURL: API_BASE_URL
});

// Get locational data
const locationData = name => axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${name}&key=${GOOGLE_MAPS_KEY}`);

// Format params for api call
const formatParams = (payload, key) => {
    let params = payload;
    if (!isObject(payload)) {
        params = {};
        params[key || 'id'] = payload;
    }

    return params;
};

// ----- Auth Key Helpers -----//

/**
 * Get Bearer token from storage
 * @return string
 */
const getAuthKey = () => lockr.get(AUTH_KEY);

/**
 * Get header object for auth token
 * @return object
 */
const getAuthHeaders = () => ({ Authorization: `Bearer ${getAuthKey()}` });

// ----- Api Functions ----- //

function fetchApi(opts, headers) {
    const data = (opts.method === 'get' || opts.method === 'GET') ? null : qs.stringify(opts.body);
    const responseType = opts.responseType ? opts.responseType : 'json';
    const options = {
        method: opts.method,
        url: opts.url,
        params: opts.params,
        data,
        responseType
    };

    if (!isEmpty(headers)) {
        options.headers = headers;
    }

    const fetch = api;

    if (DEBUG === true) {

        // fetch.interceptors.request.use((config) => {
        //     // Do something before request is sent
        //     console.log(`${config.url} REQUEST:`, config);
        //     return config;
        // }, (error) => {
        //     // Do something with request error
        //     console.log('REQUEST ERROR:', error);
        //     return Promise.reject(error);
        // });

        // fetch.interceptors.response.use((response) => {
        //     console.log(`${response.config.url} RESPONSE:`, response);
        //     return response;
        // }, (error) => {
        //     console.log('RESPONSE ERROR', error);
        //     return Promise.reject(error);
        // });
    }

    return fetch(options);
}

function fetchApiAuth(opts) {
    return new Promise((resolve, reject) => {
        if (isUndefined(getAuthKey())) {
            reject('not-authorised');
        } else {
            const authDate = new Date();
            lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
            resolve(fetchApi(opts, getAuthHeaders()));
        }
    });
}

export {
    getApiUrl,
    getBaseUrl,
    locationData,
    formatParams,

    // -- Auth Key Helpers --//
    getAuthKey,
    getAuthHeaders,

    fetchApi,
    fetchApiAuth,
};
