import React from 'react';
import { isEmpty } from 'underscore';

import { addKeyProp, GridButton } from '../../../core/utils/helpers';

import Gridview from '../../../elements/Gridview';

export default function () {
    const { practice } = this.props;

    // Practice gridview config
    const config = [
        { dataIndex: 'gp_reference', title: 'GP Ref' },
        { dataIndex: 'title', title: 'Practice Name' },
        { dataIndex: 'postcode', title: 'Postcode' },
        {
            dataIndex: 'key',
            align: 'right',
            render: () => (
                <div className="btn-bar">
                    <GridButton href={`/practice/${practice.id}`} />
                    <GridButton href={this.handleRemovePractice} title="Remove" />
                </div>
            )
        },
    ];

    return (
        <Gridview
            title="Assigned Practice"
            config={config}
            data={!isEmpty(practice) ? addKeyProp([practice]) : []}
            pagination={false} />
    );
}
