import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import DOM from './productsSupplier';

import * as productActions from '../../../core/product/productActions';
import * as supplierActions from '../../../core/supplier/supplierActions';

class ProductsSupplier extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentWillMount() {
        const { actions, supplierId } = this.props;
        actions.getSupplierProductRequest(supplierId);
    }

    // Handle remove supplier product
    handleRemoveSupplierProduct = (productId) => {
        const { actions, supplierId } = this.props;
        actions.removeSupplierProductRequest({ id: supplierId, product_id: productId });
    }

    render() {
        return this.view();
    }
}

ProductsSupplier.propTypes = {
    actions: PropTypes.object.isRequired,
    supplierId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        products: state.supplier.products,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...productActions,
            ...supplierActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsSupplier);
