import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_DOCTORS_REQUEST: null,
    GET_DOCTORS_SUCCESS: null,
    GET_DOCTORS_FAILURE: null,
});

export const {
    getDoctorsRequest,
    getDoctorsSuccess,
    getDoctorsFailure
} = createActions(
    constants.GET_DOCTORS_REQUEST,
    constants.GET_DOCTORS_SUCCESS,
    constants.GET_DOCTORS_FAILURE
);
