import { Component } from 'react';

import DOM from './login';

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            modalForgotPasswordVisible: false,
        };
    }

    // Handle toggle reset password modal
    setModalForgotPasswordVisible = modalForgotPasswordVisible => this.setState({ modalForgotPasswordVisible });

    render() {
        return this.view();
    }
}

export default LoginPage;
