import { isEmpty } from 'underscore';
import { Record } from 'immutable';

const {
    GET_SUPPLIERS_REQUEST,
    GET_SUPPLIERS_SUCCESS,
    GET_SUPPLIERS_FAILURE,

    GET_SUPPLIER_REQUEST,
    GET_SUPPLIER_SUCCESS,
    GET_SUPPLIER_FAILURE,

    UPDATE_SUPPLIER_REQUEST,
    UPDATE_SUPPLIER_SUCCESS,
    UPDATE_SUPPLIER_FAILURE,

    GET_SUPPLIER_PRODUCT_REQUEST,
    GET_SUPPLIER_PRODUCT_SUCCESS,
    GET_SUPPLIER_PRODUCT_FAILURE,

    ADD_SUPPLIER_PRODUCT_REQUEST,
    ADD_SUPPLIER_PRODUCT_SUCCESS,
    ADD_SUPPLIER_PRODUCT_FAILURE,

    REMOVE_SUPPLIER_PRODUCT_REQUEST,
    REMOVE_SUPPLIER_PRODUCT_SUCCESS,
    REMOVE_SUPPLIER_PRODUCT_FAILURE,
} = require('./supplierActions').constants;

const { STATUS_ACTIVE, SUPPLIER_TYPE_SUPPLIER } = require('../utils/values').default;

const initialSuppliers = {
    rows: [],
    page: 1,
    total: 0,
};

const InitialState = Record({
    suppliers: initialSuppliers,
    supplier: {},
    products: [],
    isFetching: false,
    error: null,
});

const initialState = new InitialState();

const initialFormValues = {
    is_active: STATUS_ACTIVE,
    type: [SUPPLIER_TYPE_SUPPLIER]
};

/**
 * ## supplierReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function supplierReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {
    case GET_SUPPLIERS_REQUEST:
    case GET_SUPPLIER_REQUEST:
    case UPDATE_SUPPLIER_REQUEST:
    case GET_SUPPLIER_PRODUCT_REQUEST:
    case ADD_SUPPLIER_PRODUCT_REQUEST:
    case REMOVE_SUPPLIER_PRODUCT_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case GET_SUPPLIERS_SUCCESS:
        return state.set('suppliers', payload).set('isFetching', false).set('error', null);

    case GET_SUPPLIER_SUCCESS:
    case UPDATE_SUPPLIER_SUCCESS:
        return state.set('supplier', isEmpty(payload) ? initialFormValues : payload).set('isFetching', false).set('error', null);

    case GET_SUPPLIER_PRODUCT_SUCCESS:
    case ADD_SUPPLIER_PRODUCT_SUCCESS:
    case REMOVE_SUPPLIER_PRODUCT_SUCCESS:
        return state.set('products', payload).set('isFetching', false).set('error', null);

    case GET_SUPPLIERS_FAILURE:
    case GET_SUPPLIER_FAILURE:
    case UPDATE_SUPPLIER_FAILURE:
    case GET_SUPPLIER_PRODUCT_FAILURE:
    case ADD_SUPPLIER_PRODUCT_FAILURE:
    case REMOVE_SUPPLIER_PRODUCT_FAILURE:
        return state.set('error', payload).set('isFetching', false);

    default:
        return state;
    }
}
