function generateProducts({ products }) {
    const valueLists = {
        skuList: [],
        titleList: [],
        descriptionList: [],
        productIdList: [],
    };

    products.map((product) => {
        valueLists.productIdList.push(product.id);
        valueLists.skuList.push(`#${product.sku}`);
        valueLists.titleList.push(product.title);
        valueLists.descriptionList.push(product.description);
    });

    return [
        {
            fieldName: 'sku',
            fieldLabel: 'Sku',
            fieldValues: valueLists.skuList,
        },
        {
            fieldName: 'order_id',
            fieldLabel: 'Title',
            fieldValues: valueLists.titleList,
        },
        {
            fieldName: 'title',
            fieldLabel: 'Description',
            fieldValues: valueLists.descriptionList,
        },
        {
            fieldName: 'add',
            fieldLabel: '',
            fieldValues: valueLists.productIdList,
        },
    ];
}

export { generateProducts };
