import React from 'react';
import { Row, Col, Button } from 'antd';

import Menu from '../Menu';
import Icon from '../../../elements/Icon';

import './header.css';

export default function () {
    return (
        <div className="header">
            <div className="header__inner">
                <Row type="flex" justify="space-between" align="middle">
                    <Col>
                        {this.props.backlink || <Menu />}
                    </Col>
                    <Col>
                        <h3 className="header__title h-4">{this.props.title}</h3>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-end">
                            {this.props.toolbar.map(button => (
                                <Button
                                    key={button.id}
                                    type="primary"
                                    form={button.form || ''}
                                    size="large"
                                    className="transparent"
                                    href={button.href || '#'}
                                    onClick={button.onClick ? button.onClick : () => {}}
                                    target={button.target || null}>
                                    <Icon name={button.icon} />
                                    <span>{button.caption}</span>
                                </Button>
                            ))}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
