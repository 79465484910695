import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import * as carehomeActions from '../../../core/carehome/carehomeActions';
import * as nurseActions from '../../../core/nurse/nurseActions';

import DOM from './addCarehomeNurse';

class AddCarehomeNurseForm extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            addToNurse: false,
            selectedNurseId: false,
            selectedCarehomeId: false,
            error: false,
        };
    }

    componentWillMount() {
        const { actions, nurseId } = this.props;
        if (nurseId) {
            this.setState({ addToNurse: true });
            actions.resetCarehomes();
        } else {
            actions.resetNurses();
        }
    }

    // Handle change of id from select
    handleChangeId = (id) => {
        if (this.state.addToNurse) {
            this.setState({ selectedCarehomeId: id });
        } else {
            this.setState({ selectedNurseId: id });
        }
    }

    // Handle select search
    handleSearch = (value) => {
        const { actions } = this.props;

        // Search if value has 3 or more characters
        if (value.length >= 3) {
            if (this.state.addToNurse) {
                actions.getCarehomesRequest({ id_name: value });
            } else {
                actions.getNursesRequest({ id_name: value });
            }

        // Reset if value has less than 2 characters
        } else if (value.length < 2) {
            if (this.state.addToNurse) {
                actions.resetCarehomes();
            } else {
                actions.resetNurses();
            }
        }
    }

    // Handle submit form
    handleAddCarehomeNurseSubmit = () => {
        const { addToNurse, selectedNurseId, selectedCarehomeId } = this.state;

        // Check carehome or nurse is selected
        let error = false;
        if (addToNurse) {
            error = !selectedCarehomeId;
        } else {
            error = !selectedNurseId;
        }

        if (error) {
            this.setState({ error });
        } else {
            const { actions, carehomeId, nurseId, changeVisible } = this.props;
            const params = {
                carehomeId: selectedCarehomeId || carehomeId,
                nurseId: selectedNurseId || nurseId,
            };

            if (addToNurse) {
                actions.addNurseCarehomeRequest(params);
            } else {
                actions.addCarehomeNurseRequest(params);
            }

            changeVisible(false);
        }
    };

    render() {
        return this.view();
    }
}

AddCarehomeNurseForm.defaultProps = {
    carehomeId: 0,
    nurseId: 0,
};

AddCarehomeNurseForm.propTypes = {
    actions: PropTypes.object.isRequired,
    carehomeId: PropTypes.number,
    changeVisible: PropTypes.func.isRequired,
    nurseId: PropTypes.number,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        nurses: state.nurse.nurses.rows,
        carehomes: state.carehome.carehomes.rows,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...carehomeActions,
            ...nurseActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCarehomeNurseForm);
