import { checkDate } from '../../../../../../core/utils/helpers';

function generateNotes({ customerNotes, filters }) {
    const valueLists = {
        userList: [],
        textList: [],
        createdAtList: [],
        userData: [],
    };

    customerNotes.map((customerNote) => {
        if (!filters.email || customerNote.user.email.indexOf(filters.email) === 0) {
            valueLists.userData.push(customerNote?.id);
            valueLists.userList.push(customerNote.user.email);
            valueLists.textList.push(customerNote.text);
            valueLists.createdAtList.push((customerNote.created_at) ? checkDate(customerNote.created_at, 'output', 'DD/MM/YYYY') : null);            
        }
    });

    return [
        {
            fieldName: 'user',
            fieldLabel: 'User',
            fieldValues: valueLists.userList,
        },
        {
            fieldName: 'text',
            fieldLabel: 'Text',
            fieldValues: valueLists.textList,
        },
        {
            fieldName: 'created_at',
            fieldLabel: 'Created At',
            fieldValues: valueLists.createdAtList,
        },
        {
            fieldName: 'edit',
            fieldLabel: '',
            fieldValues: valueLists.userData,
        },
    ];
}

export { generateNotes };
