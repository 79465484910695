import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, stopSubmit, } from 'redux-form';
import { isUndefined, isEmpty } from 'underscore';
import { notification } from 'antd';
import { browserHistory } from 'react-router';

import * as customerActions from '../../../core/customer/customerActions';
import * as nurseActions from '../../../core/nurse/nurseActions';
import * as productActions from '../../../core/product/productActions';
import * as referrerActions from '../../../core/referrer/referrerActions';

import { validateCustomer } from '../../../core/utils/validators';

import DOM from './updateCustomer';

class UpdateCustomerForm extends Component {
    constructor(props) {
        super(props);
        this.handleSetPageName(props);
        this.view = DOM;
        this.state = {
            activeTab: '1',
        };
    }

    componentDidMount() {
        const { actions, customerId } = this.props;
        const browserHistoryState = browserHistory.getCurrentLocation().state;

        if (browserHistoryState && browserHistoryState.from === 'prescription-matcher') {
            this.setState({
                activeTab: '7'
            });
        }

        actions.getCustomerRequest({ id: customerId });
        actions.getCustomerDoctorRequest({ id: customerId });
        actions.getCustomerPracticeRequest({ id: customerId });
        actions.getNursesRequest({ showAll: true });
        actions.getProductsRequest();
        actions.getReferrersRequest({ showAll: true });
    }

    componentWillReceiveProps(nextProps) {
        this.handleSetPageName(nextProps);
    }

    // Handle tab change
    handleTabChange = (tab) => {
        this.setState({ activeTab: tab });
        this.props.handleTabChange(parseInt(tab, 10));
    }

    // Handle set page name
    handleSetPageName(props) {
        const { setPageName, initialValues, customerId, isFetching } = props;
        if (!isFetching) {
            if (!isUndefined(initialValues.first_name)) {
                setPageName(`#${initialValues.id} - ${initialValues.first_name} ${initialValues.last_name}`);
            } else if (customerId === 0) {
                setPageName('New Customer');
            }
        }
    }

    // Handle submit form
    handleUpdateCustomerSubmit = (values) => {
        const { actions, customerId } = this.props;
        const errors = validateCustomer(values);
        if (!isEmpty(errors)) {
            // Only necessary since we are switching fields on and off
            actions.stopSubmit('update-customer', errors);
            notification.error({ message: 'Error(s) detected', description: (JSON.stringify(errors)) });
        } else {
            const formValues = {
                id: customerId,
                ...values,
            };
            if (formValues.is_active === 1) {
                // remove deactivation reason
                delete formValues.deactivation_reason;
            }
            actions.updateCustomerRequest(formValues);
        }
    };

    render() {
        return this.view();
    }
}

UpdateCustomerForm.propTypes = {
    actions: PropTypes.object.isRequired,
    customerId: PropTypes.number.isRequired,
    params: PropTypes.object.isRequired,
};

const selector = formValueSelector('update-customer');
const UpdateCustomer = reduxForm({
    form: 'update-customer',
    enableReinitialize: true,
    validate: validateCustomer,
})(UpdateCustomerForm);

function mapStateToProps(state, ownProps) {
    const differentCare = selector(state, 'is_care_of_address_different') ? 1 : 0;
    let customerStatus = selector(state, 'is_active');
    if (!customerStatus && customerStatus !== 0) {
        customerStatus = 1;
    }
    return {
        ...ownProps,
        differentCare,
        customerStatus,
        initialValues: state.customer.customer,
        customer: state.customer.customer,
        customerSearchResults: state.customer.customerSearchResults,
        doctor: state.customer.doctor,
        practice: state.customer.practice,
        isFetching: state.customer.isFetching,
        nurses: state.nurse.nurses.rows,
        products: state.product.products.rows,
        referrers: state.referrer.referrers.rows,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...customerActions,
                ...nurseActions,
                ...productActions,
                ...referrerActions,
                stopSubmit,
            },
            dispatch
        )
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCustomer);
