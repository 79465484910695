import { put, call, takeEvery } from 'redux-saga/effects';
import alertify from 'alertify.js';
import { isEmpty } from 'underscore';
import { notification } from 'antd';
import { fetchApiAuth } from '../utils/api';

import { getOrdersReadyRequest } from '../order/orderActions';
import {
    setDispatchesSuccess,
    setDispatchesFailure,

    authoriseDispatchesSuccess,
    authoriseDispatchesFailure,
} from './dispatchActions';

const {
    SET_DISPATCHES_REQUEST,
    AUTHORISE_DISPATCHES_REQUEST,
} = require('./dispatchActions').constants;

function* setDispatches({ payload }) {
    try {
        yield put(setDispatchesSuccess(payload));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(setDispatchesFailure(e.response ? e.response.data.message : e));
    }
}

function* authoriseDispatches({ payload }) {
    try {
        if (!isEmpty(payload)) {
            yield call(fetchApiAuth, {
                method: 'POST',
                url: '/orders/dispatch',
                body: { order_ids: payload }
            });
        }

        const search = {
            page: 1,
            page_size: 10,
        };

        notification.success({ message: 'Success', description: 'Successfully authorised orders for dispatch' });
        yield put(getOrdersReadyRequest(search));
        yield put(authoriseDispatchesSuccess());
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(authoriseDispatchesFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* dispatchSaga() {
    yield* [
        takeEvery(SET_DISPATCHES_REQUEST, setDispatches),
        takeEvery(AUTHORISE_DISPATCHES_REQUEST, authoriseDispatches),
    ];
}
