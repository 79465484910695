import React from 'react';
import { Tooltip } from 'antd';

import Icon from '../../../elements/Icon';

const CommIcon = ({ icon, type, order, onClick }) => {
    let commIcon = (
        <Icon
            name={icon}
            visible={order.comms_sent[type]}
            className={order.comms_received[type] ? 'svg-green' : 'svg-grey svg-hover-green pointer'} />
    );

    if (!order.comms_received[type] && order.comms_sent[type]) {
        commIcon = (
            <Tooltip title="Mark comm as received">
                <div onClick={() => onClick(type, order.id)}>{commIcon}</div>
            </Tooltip>
        );
    }
    return commIcon;
};

export default CommIcon;
