import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Button } from 'antd';
import { submit } from 'redux-form';

import UpdateAutoOrder from '../../forms/UpdateAutoOrder';

import * as customerActions from '../../../core/customer/customerActions';

class UpdateAutoOrderModal extends Component {
    handleUpdateAutoOrder = () => {
        const { actions, changeVisible } = this.props;
        actions.submit('update-auto-order');
        changeVisible(false);
    }

    render() {
        const { id, customerId, interval, intervalType, visible, changeVisible } = this.props;
        return (
            <Modal centered className="shrinked" width={540} footer={null} visible={visible} onOk={() => changeVisible(false)} onCancel={() => changeVisible(false)}>
                <div className="text-center">
                    <UpdateAutoOrder id={id} customerId={customerId} interval={interval} intervalType={intervalType} />
                </div>
                <div className="form-wrap">
                    <div className="btn-bar justify-content-center mt-2">
                        <Button type="primary" className="medium" icon="save" onClick={this.handleUpdateAutoOrder}>Save</Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

UpdateAutoOrderModal.propTypes = {
    actions: PropTypes.object.isRequired,
    changeVisible: PropTypes.func.isRequired,
    customerId: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    interval: PropTypes.number.isRequired,
    intervalType: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
            submit,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAutoOrderModal);
