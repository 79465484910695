import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_PRACTICES_REQUEST: null,
    GET_PRACTICES_SUCCESS: null,
    GET_PRACTICES_FAILURE: null,

    GET_PRACTICE_REQUEST: null,
    GET_PRACTICE_SUCCESS: null,
    GET_PRACTICE_FAILURE: null,

    UPDATE_PRACTICE_REQUEST: null,
    UPDATE_PRACTICE_SUCCESS: null,
    UPDATE_PRACTICE_FAILURE: null,

    GET_PRACTICE_DOCTOR_REQUEST: null,
    GET_PRACTICE_DOCTOR_SUCCESS: null,
    GET_PRACTICE_DOCTOR_FAILURE: null,

    ADD_PRACTICE_DOCTOR_REQUEST: null,
    ADD_PRACTICE_DOCTOR_SUCCESS: null,
    ADD_PRACTICE_DOCTOR_FAILURE: null,

    REMOVE_PRACTICE_DOCTOR_REQUEST: null,
    REMOVE_PRACTICE_DOCTOR_SUCCESS: null,
    REMOVE_PRACTICE_DOCTOR_FAILURE: null,
});

export const {
    getPracticesRequest,
    getPracticesSuccess,
    getPracticesFailure
} = createActions(
    constants.GET_PRACTICES_REQUEST,
    constants.GET_PRACTICES_SUCCESS,
    constants.GET_PRACTICES_FAILURE
);

export const {
    getPracticeRequest,
    getPracticeSuccess,
    getPracticeFailure
} = createActions(
    constants.GET_PRACTICE_REQUEST,
    constants.GET_PRACTICE_SUCCESS,
    constants.GET_PRACTICE_FAILURE
);

export const {
    updatePracticeRequest,
    updatePracticeSuccess,
    updatePracticeFailure
} = createActions(
    constants.UPDATE_PRACTICE_REQUEST,
    constants.UPDATE_PRACTICE_SUCCESS,
    constants.UPDATE_PRACTICE_FAILURE
);

export const {
    getPracticeDoctorRequest,
    getPracticeDoctorSuccess,
    getPracticeDoctorFailure
} = createActions(
    constants.GET_PRACTICE_DOCTOR_REQUEST,
    constants.GET_PRACTICE_DOCTOR_SUCCESS,
    constants.GET_PRACTICE_DOCTOR_FAILURE
);

export const {
    addPracticeDoctorRequest,
    addPracticeDoctorSuccess,
    addPracticeDoctorFailure
} = createActions(
    constants.ADD_PRACTICE_DOCTOR_REQUEST,
    constants.ADD_PRACTICE_DOCTOR_SUCCESS,
    constants.ADD_PRACTICE_DOCTOR_FAILURE
);

export const {
    removePracticeDoctorRequest,
    removePracticeDoctorSuccess,
    removePracticeDoctorFailure
} = createActions(
    constants.REMOVE_PRACTICE_DOCTOR_REQUEST,
    constants.REMOVE_PRACTICE_DOCTOR_SUCCESS,
    constants.REMOVE_PRACTICE_DOCTOR_FAILURE
);
