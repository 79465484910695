import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Button } from 'antd';

import Icon from '../../../elements/Icon';

import * as customerActions from '../../../core/customer/customerActions';

const { AUTO_ORDER_CANCELLED } = require('../../../core/utils/values').default;

class CancelAutoOrder extends Component {
    handleCancelAutoOrder = () => {
        const { id, customerId, actions, changeVisible } = this.props;
        actions.updateCustomerAutoOrderRequest({
            id: customerId,
            order_repeat_id: id,
            status: AUTO_ORDER_CANCELLED
        });
        changeVisible(false);
    }

    render() {
        const { visible, changeVisible } = this.props;
        return (
            <Modal centered className="shrinked" width={540} footer={null} visible={visible} onOk={() => changeVisible(false)} onCancel={() => changeVisible(false)}>
                <div className="text-center">
                    <Icon name="cancel" className="cancel-red-large" />
                    <p className="mt-3">Once an order has been cancelled, it cannot be reinstated.</p>
                    <p className="mt-2 text-bold">Are you sure you want to cancel this order?</p>
                </div>
                <div className="form-wrap">
                    <div className="btn-bar justify-content-center">
                        <Button type="default" className="medium" onClick={() => changeVisible(false)}>No</Button>
                        <Button type="default" className="medium" onClick={this.handleCancelAutoOrder}>Yes</Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

CancelAutoOrder.propTypes = {
    actions: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    customerId: PropTypes.number.isRequired,
    visible: PropTypes.bool.isRequired,
    changeVisible: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelAutoOrder);
