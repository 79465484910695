// SELECT OPTION VALUES
const values = {
    YES: 1,
    NO: 0,

    STATUS_ACTIVE: 1,
    STATUS_INACTIVE: 0,

    ORDER_STATUS_PENDING: 0,
    ORDER_STATUS_SCRIPT_WAITING: 1,
    ORDER_STATUS_WAITING_FOR_DISPATCH: 2,
    ORDER_STATUS_DISPATCHED: 3,
    ORDER_STATUS_SCRIPT_SUPPLIED: 4,
    ORDER_STATUS_WITHOUT_SCRIPT: 5,
    ORDER_STATUS_DISPATCH: 6,
    ORDER_STATUS_DISPATCH_WITHOUT_SCRIPT: 7,
    ORDER_STATUS_SCRIPT_WAITING_DOCTOR: 8,
    ORDER_STATUS_SCRIPT_DUE_CLIENT: 9,
    ORDER_STATUS_SCRIPT_DUE_DOCTOR: 10,
    ORDER_STATUS_ELECTRONIC_PRESCRIPTION: 11,
    ORDER_STATUS_NO_SCRIPT: 12,
    ORDER_STATUS_SCRIPT_PARTLY_RECEIVED: 13,
    ORDER_STATUS_SCRIPT_RECEIVED: 14,
    ORDER_STATUS_PARTLY_PROCESSED: 15,
    ORDER_STATUS_PROCESSED: 16,
    ORDER_STATUS_CANCELLED: 17,

    PRODUCT_STATUS_DRAFT: 0,
    PRODUCT_STATUS_ACTIVE: 1,
    PRODUCT_STATUS_DISCONTINUED: 2,
    PRODUCT_STATUS_INACTIVE: 3,

    ORDER_ITEM_STATUS_MATCHED: 0,
    ORDER_ITEM_STATUS_CLEAR: 1,
    ORDER_ITEM_STATUS_WRITTEN_OFF: 2,
    ORDER_ITEM_STATUS_ITEM_CANCELLED: 3,
    ORDER_ITEM_STATUS_REMINDER_ISSUED: 4,

    ITEM_DELIVERY_STATUS_PICKING: 0,
    ITEM_DELIVERY_STATUS_DISPATCHED: 1,
    ITEM_DELIVERY_STATUS_BACKORDER: 2,

    SCRIPT_STATUS_NO_SCRIPT: 0,
    SCRIPT_STATUS_SCRIPT_PARTLY_RECEIVED: 1,
    SCRIPT_STATUS_SCRIPT_RECEIVED: 2,
    SCRIPT_STATUS_PARTLY_PROCESSED: 3,
    SCRIPT_STATUS_PROCESSED: 4,
    SCRIPT_STATUS_WRITTEN_OFF: 5,
    SCRIPT_STATUS_CANCELLED: 6,

    INTERVAL_DAY: 'Day',
    INTERVAL_WEEK: 'Week',
    INTERVAL_MONTH: 'Month',
    INTERVAL_YEAR: 'Year',

    GENDER_MALE: 'Male',
    GENDER_FEMALE: 'Female',
    GENDER_OTHER: 'Other',

    SUPPLIER_TYPE_SUPPLIER: 1,
    SUPPLIER_TYPE_MANUFACTURER: 2,

    DEACTIVATION_REASON_DECEASED: 'Deceased',
    DEACTIVATION_REASON_ARCHIVED: 'Archived',
    DEACTIVATION_REASON_ARCHIVED_NO_LONGER_USING: 'Archived - No longer using',
    DEACTIVATION_REASON_ARCHIVED_DUPLICATE: 'Archived - Duplicate',

    AUTO_ORDER_ACTIVE: 'Active',
    AUTO_ORDER_PAUSED: 'Paused',
    AUTO_ORDER_CANCELLED: 'Cancelled',

    EXCLUDE_FROM_AUTO: 1,
    EXCLUDE_FROM_LETTER: 2,

    DB_DATE_FORMAT: 'YYYY-MM-DD HH:mm:ss',
    DISPLAY_DATE_FORMAT: 'DD/MM/YYYY',

    REPORT_TYPE_CREATED: 'status-all',
    REPORT_TYPE_SCRIPT_WAITING: 'status-waiting-on-script',
    REPORT_TYPE_READY: 'status-ready-to-authorise-dispatch',
    REPORT_TYPE_DISPATCHED: 'status-dispatched',
    REPORT_TYPE_PROCESSED: 'status-processed',
    REPORT_TYPE_NURSE: 'nurse-report',
    REPORT_TYPE_CAREHOME: 'care-home-report',
    REPORT_TYPE_REFERRER: 'referrers-report',
    REPORT_TYPE_SUPPLIER: 'supplier-manufacturer-report',
    REPORT_TYPE_PRODUCT: 'individual-product-report',
    REPORT_TYPE_PROCESSED_PRESCRIPTIONS: 'processed-prescriptions',
    REPORT_TYPE_AUTO_ORDER_ITEMS_PER_PATIENT: 'auto-order-items-per-patient',
    REPORT_TYPE_ALL_AUTO_ORDER_ITEMS: 'all-auto-order-items',
    REPORT_TYPE_PAYMENT_EXAMPTION_LETTER: 'payment-examption-letter',

    FUNDING_METHOD_PAID: '-',
    FUNDING_METHOD_CHILD: 'A',
    FUNDING_METHOD_EDUCATION: 'B',
    FUNDING_METHOD_SENIOR: 'C',
    FUNDING_METHOD_MATERNITY: 'D',
    FUNDING_METHOD_MEDICAL: 'E',
    FUNDING_METHOD_PRE_PAYMENT: 'F',
    FUNDING_METHOD_WAR_PENSION: 'G',
    FUNDING_METHOD_HC: 'L',
    FUNDING_METHOD_INCOME_SUPPORT: 'H',
    FUNDING_METHOD_JOBSEEKER: 'K',
    FUNDING_METHOD_TAX_EXEMTION: 'M',
    FUNDING_METHOD_PENSION_CREDIT: 'S',

    TINYMCE_API_KEY: 'q3dwnv0z3945cigruae3ojr9a35ws75ks62j5f7faedp88cw',
    GRAMMARLY_CLIENT_ID: 'client_QfPrn3CspMZWKdreaY41HC',
};

export default values;
