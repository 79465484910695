import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import Loading from '../Loading';
import FormGroup from './FormGroup';

/**
 * Generates a form section with redux form, has container styling for white section
 * @param {array} config            - Array of form config, each row corresponds to a field, options include any normal input props
 * @param {number|object} itemSpan  - Number or object of span of individual form items, see antd docs for Row
 * @param {number|object} itemsSpan - Number or object of span of group of form items, see antd docs for Row
 * @param {string} justify          - Justification of form container, see antd docs
 * @param {bool|object} labelCol    - Span of input label, see antd docs for Col
 * @param {string} layout           - Layout type of form 'horizontal', 'vertical', 'inline'
 * @param {bool} loading            - Whether form data is loading
 * @param {string|bool} title       - Title of form
 * @param {bool|object} wrapperCol  - Span of input, see antd docs for Col
 */
const FormSection = ({ config, itemSpan, itemsSpan, justify, labelCol, layout, loading, title, wrapperCol }) => {
    // Make form title
    const formTitle = title ? <div className="section__header"><h3 className="h-4">{title}</h3></div> : false;
    // Make form group
    let formItems = (
        <FormGroup
            config={config}
            itemSpan={itemSpan}
            labelCol={labelCol}
            layout={layout}
            wrapperCol={wrapperCol} />
    );
    // Container props based on layout
    const containerProps = {
        className: 'section-white',
    };

    // If layout is horizontal update containerProps and render formitems inside column
    if (layout === 'horizontal') {
        containerProps.style = { marginRight: '5%', marginLeft: '5%' };
        containerProps.className = 'section-white pb-2 pt-2 mt-1 mb-2';
    } else {
        formItems = <Col span={itemsSpan}>{formItems}</Col>;
    }

    // Make container content based on loading
    let containerContent;
    if (loading) {
        containerContent = <Loading />;
    } else {
        containerContent = <Row type="flex" justify={justify}>{formItems}</Row>;
    }

    return (
        <div className="section__container">
            {formTitle}
            <div {...containerProps}>
                {containerContent}
            </div>
        </div>
    );
};

FormSection.defaultProps = {
    itemSpan: 12,
    itemsSpan: 18,
    justify: 'center',
    labelCol: false,
    layout: 'vertical',
    loading: false,
    title: false,
    wrapperCol: false,
};

FormSection.propTypes = {
    config: PropTypes.array.isRequired,
    itemSpan: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    itemsSpan: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    justify: PropTypes.string,
    labelCol: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    layout: PropTypes.string,
    loading: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    wrapperCol: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default FormSection;
