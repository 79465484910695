import { Component } from 'react';

import DOM from './admin';

class AdminPage extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            title: 'Admin',
            modalUploadStockVisible: false,
            toolbar: this.getToolbar()
        };
    }

    // Get toolbar config based on tab
    getToolbar = (key) => {
        const toolbar = [];
        const tab = key || 1;

        switch (tab) {
        case 1:
            toolbar.push({ id: 'actionUploadStock', caption: 'Update Stock', icon: 'upload', onClick: () => { this.setModalUploadStockVisible(true); } });
            toolbar.push({ id: 'actionAddProduct', caption: 'Add new product', icon: 'new-order', href: '/product/0' });
            break;

        case 2:
            toolbar.push({ id: 'actionAddPractice', caption: 'Add new practice', icon: 'new-order', href: '/practice/0' });
            break;


        case 3:
            toolbar.push({ id: 'actionAddNurse', caption: 'Add Nurse', icon: 'new-order', href: '/nurse/0' });
            toolbar.push({ id: 'actionAddCareHome', caption: 'Add Care Home', icon: 'new-order', href: '/care-home/0' });
            break;

        case 4:
            toolbar.push({ id: 'actionAddReferrer', caption: 'Add Referrer', icon: 'new-order', href: '/referrer/0' });
            break;

        case 5:
            toolbar.push({ id: 'actionNewOrder', caption: 'Add Supplier/Manufacturer', icon: 'new-order', href: '/supplier/0' });
            break;

        default:
            break;
        }

        return toolbar;
    }

    // Handle toggle upload stock modal
    setModalUploadStockVisible = modalUploadStockVisible => this.setState({ modalUploadStockVisible });

    // Handle tab change
    handleTabChange = key => this.setState({ toolbar: this.getToolbar(parseInt(key, 10)) });

    render() {
        return this.view();
    }
}

export default AdminPage;
