import React from 'react';

import Layout from '../../components/layout/Layout';
import AddCustomerReferrer from '../../components/modals/AddCustomerReferrer';
import UpdateReferrer from '../../components/forms/UpdateReferrer';
import CustomersReferrer from '../../components/gridviews/CustomersReferrer';

export default function () {
    const { name, toolbar } = this.state;
    const id = parseInt(this.props.params.id, 10);
    const breadcrumb = { name: 'referrer', params: { id, name } };

    return (
        <Layout title={name} toolbar={toolbar} breadcrumb={breadcrumb}>
            {/* FORM */}
            <UpdateReferrer referrerId={id} setPageName={this.setPageName} />

            {/* GRIDVIEW */}
            {id ? <CustomersReferrer referrerId={id} /> : false}

            {/* MODAL */}
            {id ?
                <AddCustomerReferrer
                    referrerId={id}
                    visible={this.state.modalAddReferrersVisible}
                    changeVisible={this.setModalAddReferrersVisible} />
            : false}
        </Layout>
    );
}
