import React from 'react';
import { Button, Row, Col } from 'antd';
import { Form as ReduxForm } from 'redux-form';
import { isEmpty } from 'underscore';

import Form from '../../../elements/Form';
import Icon from '../../../elements/Icon';

import { isRequired } from '../../../core/utils/validators';

const FormItem = Form.Item;
const formTypes = Form.formTypes;

export default function () {
    const { products } = this.props;

    // Map products to spilt product row component
    const mapSplitProducts = (item, index) => (
        <Row key={item.key} type="flex">
            <Col span={4}><span className="table-cell">#{item.id}</span></Col>
            <Col span={11}><span className="table-cell">{item.title}</span></Col>
            <Col span={4}>
                <span className="table-cell justify-content-center">
                    <FormItem type={formTypes.HIDDEN} name={`split_order.products[${index}].id`} />
                    <FormItem type={formTypes.NUMBER} name={`split_order.products[${index}].quantity`} label={false} className="m-0 p-0" />
                </span>
            </Col>
            <Col span={1}>
                <div className="table-cell justify-content-center">
                    <div className="double-arrow">
                        <Icon name="arrow-right" />
                        <Icon name="arrow-right" />
                    </div>
                </div>
            </Col>
            <Col span={4}>
                <div className="table-cell justify-content-center">
                    <FormItem type={formTypes.NUMBER} name={`split_order.products[${index}].split_quantity`} label={false} className="m-0 p-0" />
                </div>
            </Col>
        </Row>
    );

    return (
        <ReduxForm onSubmit={this.props.handleSubmit(this.handleSplitOrderSubmit)} className="ant-form ant-form-vertical">
            <div className="section--bordered mt-3">
                <div className="light-table no-side-padding">

                    <h5 className="h-5">Products to move to the new order:</h5>

                    {!isEmpty(products) ?
                        <div className="light-table__header">
                            <Row type="flex">
                                <Col span={4} />
                                <Col span={11} />
                                <Col span={4}>
                                    <span className="table-cell justify-content-center">Current order</span>
                                </Col>
                                <Col span={1} />
                                <Col span={4}>
                                    <div className="table-cell justify-content-center">New Split order</div>
                                </Col>
                            </Row>
                        </div>
                    : false}

                    {!isEmpty(products) ? products.map(mapSplitProducts) : <div className="no-data mt-2">No Products Added</div>}
                </div>
            </div>

            <div className="mt-2">
                <Row type="flex" align="middle">
                    <Col span={12} md={7}><p>Script ID/Ref for new order*</p></Col>
                    <Col span={12} md={8}><FormItem type={formTypes.TEXT} name="split_order.script_reference" label={false} validate={[isRequired]} /></Col>
                </Row>
            </div>

            <div className="btn-bar justify-content-center">
                <Button type="primary" htmlType="submit" className="wide">Split order/part script</Button>
            </div>
        </ReduxForm>
    );
}
