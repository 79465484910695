import React from 'react';
import moment from 'moment';
import { Checkbox } from 'antd';

import Gridview from '../../../elements/Gridview';
import Icon from '../../../elements/Icon';
import CommIcon from './commIcon';

import { displayId, displayDate, displayName, displayNumber, GridButton } from '../../../core/utils/helpers';

const { DB_DATE_FORMAT } = require('../../../core/utils/values').default;

export default function () {
    const { orders, total, page, postChasers, faxChasers, emailChasers, isFetching, chaserIsFetching } = this.props;

    // Render checkbox
    const getCheckbox = (value, record, type) => {
        if (record.available_comms[type]) {
            return (
                <Checkbox
                    checked={this.getCheckboxValue(type, record.id)}
                    onChange={(e) => { this.handleCheckboxChange(type, record.id, e.target.checked); }} />
            );
        }
        return false;
    };

    // Render comms recieved
    const getCommsReceived = (value, record) => (
        <div className="table-iconset">
            <CommIcon icon="mail" type="post" order={record} onClick={this.handleMarkCommAsReceived} />
            <CommIcon icon="phone" type="fax" order={record} onClick={this.handleMarkCommAsReceived} />
            <CommIcon icon="at" type="email" order={record} onClick={this.handleMarkCommAsReceived} />
        </div>
    );

    // Render days since last chaser
    const getDaysSinceLastChaser = (value, record) => {
        if (record.comm_last_sent) {
            const chaserDate = moment(record.comm_last_sent, DB_DATE_FORMAT);
            const now = moment();

            return displayNumber(now.diff(chaserDate, 'days'));
        }
        return '-';
    };

    // Pre header commponent
    const preHeader = (
        <div className="table__selection mb-2">
            <h5 className="h-5">Your selection</h5>
            <div className="table__selection-items">
                <div className="table__selection-item"><Icon name="mail" /> {displayNumber(postChasers.length)}</div>
                <div className="table__selection-item"><Icon name="phone" /> {displayNumber(faxChasers.length)}</div>
                <div className="table__selection-item"><Icon name="at" /> {displayNumber(emailChasers.length)}</div>
            </div>
        </div>
    );

    // Gridview config
    const config = [
        { dataIndex: 'is_auto_order', title: 'Auto order', className: 'text-center', render: value => (value ? <Icon name="auto-order" /> : false) },
        { dataIndex: 'ref', title: 'Order ID', width: 100, render: displayId },
        { dataIndex: 'created_at', title: 'Order Created', render: value => displayDate(value) },
        { dataIndex: 'name', title: 'Customer Name', className: 'text-semibold', render: (value, record) => displayName(record, 'customer_first_name', 'customer_last_name') },
        { dataIndex: 'chaser_date', title: 'Days since last chaser', className: 'text-center', render: getDaysSinceLastChaser },
        { dataIndex: 'key', title: 'Comms Sent/Received', className: 'text-center', render: getCommsReceived },
        { dataIndex: 'send_post', title: <Icon name="mail" />, className: 'text-center', render: (value, record) => getCheckbox(value, record, 'post') },
        { dataIndex: 'send_fax', title: <Icon name="phone" />, className: 'text-center', render: (value, record) => getCheckbox(value, record, 'fax') },
        { dataIndex: 'send_email', title: <Icon name="at" />, className: 'text-center', render: (value, record) => getCheckbox(value, record, 'email') },
        { dataIndex: 'action', align: 'right', render: (value, record) => <GridButton href={`/order/${record.id}`} /> },
    ];

    return (
        <Gridview
            config={config}
            data={orders}
            loading={chaserIsFetching ? 'floating' : isFetching}
            pagination={{
                simple: true,
                onChange: this.handlePagination,
                pageSize: 10,
                total,
                current: page,
                className: 'custom-pagination',
            }}
            preHeader={preHeader} />
    );
}
