const styles = {
    // Main
    gridviewContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        paddingTop: 15,
    },
    editButton: {
        background: 'green',
        padding: 2,
        textAlign: 'center',
        borderRadius: 4,
        cursor: 'pointer',
    },
    btnLabel: {
        color: 'white',
        fontSize: 12,
    },
    // Filter
    filterBarTitle: {
        fontSize: 18,
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2d3748',
        verticalAlign: 'center',
        margin: 0,
        whiteSpace: 'nowrap',
    },
    filterLabel: {
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2d3748',
        verticalAlign: 'center',
        margin: 0,
        marginRight: 5,
        whiteSpace: 'nowrap',
    },
    filterGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    filterBarContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 10,
        paddingRight: 40,
        width: '100%',
    },

    // Table
    tableHeaderRow: {
    },
    tableHeaderElement: {
        fontSize: 10,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#a0aec0',
        verticalAlign: 'center',
        padding: 5,
    },
    tableBodyRow: {
        borderTop: '1px solid #a0aec0',
    },
    tableBodyElement: {
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2d3748',
        verticalAlign: 'top',
        padding: 5,
    },
    table: {
        width: '100%',
        alignSelf: 'center',
        marginTop: 20,
    },
};

export default styles;
