import React from 'react';
import Layout from '../../components/layout/Layout';
import MarkAsDispatch from '../../components/forms/MarkAsDispatch/index';


export default function () {
    const {
        name,
        toolbar,
    } = this.state;
    const { params } = this.props;
    const id = parseInt(this.props.params.id, 10);

    return (
        <Layout title={name} toolbar={toolbar} >
            <MarkAsDispatch
                customerId={id}
                handleTabChange={this.handleTabChange}
                handleOpenModal={this.handleOpenModal}
                setPageName={this.setPageName}
                handleDispatchOrderModal={this.setDispachOrderModal}
                params={params} />
        </Layout>
    );
}
