import React from 'react';
import { find } from 'underscore';

import Gridview from '../../../elements/Gridview';
import { mapToOptions, displayId, displayDate, displayName, GridButton } from '../../../core/utils/helpers';

const { ORDER_STATUS_OPTIONS, FULL_ORDER_STATUS_OPTIONS } = require('../../../core/utils/options').default;

export default function () {
    const { orders, total, page, nurses, referrers, isFetching } = this.props;
    const nurseOptions = mapToOptions(nurses, 'id', displayName);
    const referrerOptions = mapToOptions(referrers, 'id', displayName);

    const renderOrderStatus = (value) => {
        const option = find(FULL_ORDER_STATUS_OPTIONS, item => item.value === value);
        return option ? option.label : '-';
    };

    const config = [
        { dataIndex: 'id', title: 'Order ID', search: true, type: 'text', width: 100, render: displayId },
        { dataIndex: 'customer_name', title: 'Customer Name', className: 'text-semibold', sort: true, render: (value, record) => `${record.customer_first_name} ${record.customer_last_name}` },
        { dataIndex: 'product', title: 'Includes Product', search: true, type: 'text', visible: false },
        { dataIndex: 'created_at', title: 'Order Created', sort: true, sortDirection: 'desc', render: value => displayDate(value), width: 100 },
        { dataIndex: 'created_at_from', title: 'Order Date From', search: true, type: 'date', visible: false },
        { dataIndex: 'created_at_to', title: 'Order Date To', search: true, type: 'date', visible: false },
        { dataIndex: 'order_status', title: 'Status', search: true, sort: true, type: 'select', options: ORDER_STATUS_OPTIONS, render: renderOrderStatus },
        { dataIndex: 'key', align: 'right', render: (text, record) => <GridButton href={`/order/${record.id}`} /> },
        { dataIndex: 'customer_id', title: 'Customer ID', search: true, type: 'text', visible: false },
        { dataIndex: 'first_name', title: 'Forenames', search: true, type: 'text', visible: false },
        { dataIndex: 'last_name', title: 'Surnames', search: true, type: 'text', visible: false },
        { dataIndex: 'postcode', title: 'Postcode', search: true, type: 'text', visible: false },
        { dataIndex: 'nhs_number', title: 'NHS Number', search: true, type: 'text', visible: false },
        { dataIndex: 'nurse', title: 'Nurse', search: true, type: 'select', options: nurseOptions, visible: false },
        { dataIndex: 'referrer', title: 'Referrer', search: true, type: 'select', options: referrerOptions, visible: false },
        { dataIndex: 'gp_reference', title: 'GP Ref', search: true, type: 'text', visible: false },
    ];

    return (
        <Gridview
            title="Search Results"
            searchTitle="Find an Order"
            config={config}
            data={orders}
            handleSort={this.handleSort}
            handleSearch={this.handleSearch}
            handleClearSearch={this.handleClearSearch}
            loading={isFetching}
            pagination={{
                simple: true,
                onChange: this.handlePagination,
                pageSize: 25,
                total,
                current: page,
                className: 'custom-pagination',
            }} />
    );
}
