import PropTypes, { number } from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { isEqual, string } from 'underscore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';

import Form from '../../../../../elements/Form';

const { STATUS_OPTIONS, DEACTIVATION_REASON_OPTIONS } = require('../../../../../core/utils/options').default;
const { STATUS_ACTIVE } = require('../../../../../core/utils/values').default;

const EXEMPTION_REASONS = [
    { label: 'A - Is aged 60 or older, or under 16 (unless the birthdate is on the form)', value: 'A', },
    { label: 'B - Is 16, 17 or 18 years old and in full-time education', value: 'B', },
    { label: 'D - Materntity exemption certificate', value: 'D', },
    { label: 'E - Medical exemption certificate', value: 'E', },
    { label: 'F - Prescription pre-payment certificate', value: 'F', },
    { label: 'G - Prescription exemption certificate issued by the Ministry of Defence', value: 'G', },
    { label: 'L - HC2 (full help) certificate', value: 'L', },
    { label: 'H - Income Support or Income-related Employment and Support Allowance', value: 'H', },
    { label: 'K - Income-based Jobseekers Allowance', value: 'K', },
    { label: 'M - Tax Credit Exemption Certificate', value: 'M', },
    { label: 'S - Pension Credit guarantee credit (including partners)', value: 'S', },
    { label: 'U - Universal Credit and meets the criteria. Learn more at nhsbsa.nhs.uk/UC', value: 'U', },
];


const formTypes = Form.formTypes;
const FormSection = Form.Section;

const selector = formValueSelector('update-customer');

class CustomerSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            exemptionReasonDisabled: !this.props.exemptionReason,
            displayFormSection: true,
        };
    }

    componentDidMount() {
        const { exemptionReason } = this.props;
        this.props.change('update-customer', 'exempt_from_prescription_fee', !!exemptionReason);
    }

    componentDidUpdate(prevProps, prevState) {
        const { exemptionReasonDisabled, displayFormSection } = this.state;
        const { exemptionReason } = this.props;

        if (!isEqual(exemptionReasonDisabled, prevState.exemptionReasonDisabled)) {
            this.setState({ displayFormSection: !displayFormSection }, () => {
                const { displayFormSection } = this.state;

                this.setState({ displayFormSection: !displayFormSection });
            });
        }
    }

    handleToggle(value) {
        this.setState({ exemptionReasonDisabled: !value });

        if (value === false) {
            this.props.change('update-customer', 'exemption_reason', null);
        }
    }

    render() {
        const { customerStatus, loading, multiselectOptions } = this.props;

        // Customer settings form1 config
        const formConfig1 = [
            { type: formTypes.SELECT, name: 'is_active', label: 'Customer Status', options: STATUS_OPTIONS },
            { type: formTypes.SELECT, name: 'deactivation_reason', label: 'Deactivate Reason', options: DEACTIVATION_REASON_OPTIONS, itemDisabled: customerStatus === STATUS_ACTIVE },
            { type: formTypes.MULTISELECT, name: 'recommended_product_ids', label: 'Recommended Products', options: multiselectOptions, className: 'pb-4' },
        ];

        // Customer settings form2 config
        const formConfig2 = [
            { type: formTypes.SWITCH, name: 'exempt_from_prescription_fee', label: 'Exempt From Prescription Fee', onChange: this.handleToggle.bind(this) },
            {
                type: formTypes.SELECT,
                name: 'exemption_reason',
                label: 'Certificate Exemption Reason',
                options: EXEMPTION_REASONS,
                className: 'pb-4',
                disabled: this.state.exemptionReasonDisabled,
                onChange: value => this.props.change('update-customer', 'exempt_from_prescription_fee', true) },
            { type: formTypes.DATE, name: 'certificate_expiry_date', label: 'Certificate Expiry Date', className: 'pb-4', placeholder: '', },
            { type: formTypes.TEXT, name: 'certificate_number', label: 'Certification Number', className: 'pb-4' },
            { type: formTypes.TEXT, name: 'national_insurance_no', label: 'National Insurance Number', className: 'pb-4' },
            { type: formTypes.TEXTAREA, name: 'prescription_info', label: 'Prescription Information', className: 'pb-4' },
        ];

        return (
            <div className="section">
                <div className="section">
                    <div className="container">
                        <Row type="flex" justify="center">
                            <Col span={24} md={20} lg={17}>
                                <FormSection loading={loading} config={formConfig1} title="Manage Customer Settings" span={14} />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="container">
                    <Row type="flex" justify="center">
                        <Col span={24} md={20} lg={17}>
                            { this.state.displayFormSection && <FormSection title="Prescription Information" loading={this.props.loading} config={formConfig2} span={14} /> }
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            change
        },
        dispatch
    );
}

CustomerSettings.defaultProps = {
    loading: false,
};

CustomerSettings.propTypes = {
    loading: PropTypes.bool,
    customerId: PropTypes.number.isRequired,
    activeTab: PropTypes.string.isRequired,
};

export default connect((state) => {
    const exemptionReason = selector(state, 'exemption_reason');
    // null, mapDispatchToProps
    return {
        exemptionReason,
    };
}, mapDispatchToProps)(CustomerSettings);
