import React from 'react';

import Gridview from '../../../elements/Gridview';
import { mapToOptions, GridButton } from '../../../core/utils/helpers';

const { SUPPLIER_TYPE_OPTIONS } = require('../../../core/utils/options').default;

export default function () {
    const { productCategories, suppliers, isFetching, total, page } = this.props;
    const config = [
        { dataIndex: 'title', title: 'Supplier/Manufacturer Name', search: true, type: 'text' },
        // { dataIndex: 'id', title: 'Supplier/Manufacturer ID', search: true, type: 'text', visible: false },
        { dataIndex: 'type', search: true, type: 'checkbox', options: SUPPLIER_TYPE_OPTIONS, visible: false },
        { dataIndex: 'product_id', title: 'Product Code', search: true, type: 'text', visible: false },
        { dataIndex: 'product_category_id', title: 'Supplies Product Category', search: true, type: 'select', options: mapToOptions(productCategories), placeholder: 'Any', visible: false },
        { dataIndex: 'key', align: 'right', render: (value, record) => <GridButton href={`/supplier/${record.id}`} /> },
    ];

    return (
        <Gridview
            config={config}
            data={suppliers}
            handleClearSearch={this.handleClearSearch}
            handleSearch={this.handleSearch}
            loading={isFetching}
            searchTitle="Find a Supplier/Manufacturer"
            title="Search Results"
            pagination={{
                simple: true,
                onChange: this.handlePagination,
                pageSize: 10,
                total,
                current: page,
                className: 'custom-pagination',
            }} />
    );
}
