import { isEmpty } from 'underscore';
import { Record } from 'immutable';

const {
    GET_PRODUCTS_REQUEST,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAILURE,

    GET_PRODUCT_REQUEST,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_FAILURE,

    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAILURE,

    GET_PRODUCT_CATEGORIES_REQUEST,
    GET_PRODUCT_CATEGORIES_SUCCESS,
    GET_PRODUCT_CATEGORIES_FAILURE,

    GET_PRODUCT_STOCK_REQUEST,
    GET_PRODUCT_STOCK_SUCCESS,
    GET_PRODUCT_STOCK_FAILURE,

    UPDATE_PRODUCT_STOCK_REQUEST,
    UPDATE_PRODUCT_STOCK_SUCCESS,
    UPDATE_PRODUCT_STOCK_FAILURE,

    GET_PRODUCT_CUSTOMERS_REQUEST,
    GET_PRODUCT_CUSTOMERS_SUCCESS,
    GET_PRODUCT_CUSTOMERS_FAILURE,

    RESET_PRODUCTS,
} = require('./productActions').constants;

const { STATUS_ACTIVE } = require('../utils/values').default;

const initialProducts = {
    rows: [],
    page: 1,
    total: 0,
};

const InitialState = Record({
    products: initialProducts,
    product: {},
    productCategories: [],
    isFetching: false,
    error: null,

    // Progress markers
    isFetchingProducts: false,
});

const initialState = new InitialState();
const initialFormValues = { unit_measure: 1, status: STATUS_ACTIVE };

/**
 * ## productReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function productReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {
    // --------------------------------------- REQUESTS
    case GET_PRODUCT_REQUEST:
    case UPDATE_PRODUCT_REQUEST:
    case GET_PRODUCT_CATEGORIES_REQUEST:
    case GET_PRODUCT_STOCK_REQUEST:
    case UPDATE_PRODUCT_STOCK_REQUEST:
    case GET_PRODUCT_CUSTOMERS_REQUEST:
        return state.set('isFetching', true).set('error', null);
    case GET_PRODUCTS_REQUEST:
        return state.set('isFetching', true).set('isFetchingProducts', true).set('error', null);

        // --------------------------------------- SUCCESS

    case GET_PRODUCTS_SUCCESS:
        return state.set('products', payload).set('isFetching', false).set('isFetchingProducts', false).set('error', null);
    case GET_PRODUCT_SUCCESS:
    case UPDATE_PRODUCT_SUCCESS:
        return state.set('product', isEmpty(payload) ? initialFormValues : payload).set('isFetching', false).set('error', null);
    case GET_PRODUCT_CATEGORIES_SUCCESS:
        return state.set('productCategories', payload).set('isFetching', false).set('error', null);
    case GET_PRODUCT_STOCK_SUCCESS:
    case UPDATE_PRODUCT_STOCK_SUCCESS:
    case GET_PRODUCT_CUSTOMERS_SUCCESS:
        return state.set('isFetching', false).set('error', null);

        // --------------------------------------- FAILURE

    case GET_PRODUCT_FAILURE:
    case UPDATE_PRODUCT_FAILURE:
    case GET_PRODUCT_CATEGORIES_FAILURE:
    case GET_PRODUCT_STOCK_FAILURE:
    case UPDATE_PRODUCT_STOCK_FAILURE:
    case GET_PRODUCT_CUSTOMERS_FAILURE:
        return state.set('error', payload).set('isFetchingProducts', false).set('isFetching', false);
    case GET_PRODUCTS_FAILURE:
        return state.set('error', payload).set('isFetching', false);
    case RESET_PRODUCTS:
        return state.set('products', initialProducts).set('product', {}).set('isFetching', false);

    default:
        return state;
    }
}
