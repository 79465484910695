import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import DOM from './doctorsPractice';

import * as practiceActions from '../../../core/practice/practiceActions';

class DoctorsPractice extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentWillMount() {
        const { actions, practiceId } = this.props;
        actions.getPracticeDoctorRequest(practiceId);
    }

    // Handle remove doctor practice
    handleRemoveDoctorPractice = (doctorId) => {
        const { actions, practiceId } = this.props;
        actions.removePracticeDoctorRequest({ practiceId, doctorId });
    }

    render() {
        return this.view();
    }
}

DoctorsPractice.propTypes = {
    actions: PropTypes.object.isRequired,
    practiceId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        doctors: state.practice.doctors,
        isFetching: state.practice.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...practiceActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorsPractice);
