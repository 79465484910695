import moment from 'moment';
import React from 'react';
import { Button } from 'antd';
import { isUndefined, isNull } from 'underscore';

import Icon from '../../../elements/Icon';
import UpdateStock from '../../modals/UpdateStock';

export default function () {
    const { productId, product } = this.props;

    // Initialise values
    let lastUpdated = 'never';
    let stockCount = 0;
    let stockIssuedCount = 0;

    // Update values if product data exists
    if (!isUndefined(product.quantity) && !isNull(product.quantity)) {
        lastUpdated = moment(product.quantity_last_updated).format('L');
        stockCount = product.quantity;
        stockIssuedCount = product.issued_stock;
    }

    return (
        <div>
            <div className="section__header">
                <h3 className="h-4">Stock summary</h3>
            </div>
            <div className="section-grey">
                <div className="stock">

                    <div className="stock-row">
                        <h6 className="h-6 mr-1">
                            <span className="mr-1">Units in stock</span>
                            <span className="italic-grey fz-small">(last updated {lastUpdated})</span>
                        </h6>

                        <div className="stock-row__value">
                            <div className="stock__action">
                                {productId ?
                                    <Button type="default" className="btn-empty" onClick={() => { this.setModalUpdateStockVisible(true); }}>
                                        <Icon name="edit" />
                                    </Button>
                                : false}
                            </div>
                            <span>{stockCount}</span>
                        </div>
                    </div>

                    <div className="stock-row">
                        <h6 className="h-6 mr-1">
                            <span>Issued since last stock update</span>
                        </h6>
                        <div className="stock-row__value">
                            <span>{stockIssuedCount}</span>
                        </div>
                    </div>

                    <div className="divider" />

                    <div className="stock-row">
                        <h6 className="h-5 mr-1">
                            <span>Estimated</span>
                        </h6>
                        <div className="stock-row__value">
                            <span>{stockCount - stockIssuedCount}</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* MODALS */}
            <UpdateStock
                newStockCount={this.state.stock}
                stockCount={parseInt(stockCount, 10)}
                stockCountUpdated={lastUpdated}
                visible={this.state.modalUpdateStockVisible}
                changeVisible={this.setModalUpdateStockVisible}
                handleInputChange={this.handleStockInputChange}
                handleSubmit={this.handleUpdateStockSubmit} />
        </div>
    );
}
