/* global localStorage:true */
import alertify from 'alertify.js';
import lockr from 'lockr';
import { browserHistory } from 'react-router';
import { put, call, takeEvery } from 'redux-saga/effects';
import { notification } from 'antd';
import { fetchApi } from '../utils/api';
import { getUserRequest } from '../user/userActions';
import {
    deleteTokenSuccess,
    saveTokenSuccess,

    loginSuccess,
    loginFailure,

    logoutSuccess,
    logoutFailure,

    passwordResetSuccess,
    passwordResetFailure,
} from './authActions';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = require('../constants').default;

const {
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    PASSWORD_RESET_REQUEST,
} = require('./authActions').constants;

function* deleteSessionToken() {
    lockr.rm(AUTH_KEY);
    localStorage.clear();
    yield put(deleteTokenSuccess());
}

function* saveSessionToken({ data }) {
    const authDate = new Date();
    lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
    lockr.set(AUTH_KEY, data.auth_token);
    yield put(saveTokenSuccess());
}

function* login({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/login',
            body: { username: payload.email, password: payload.password }
        });

        yield call(saveSessionToken, response);
        yield put(loginSuccess(response));

        // load user data
        yield put(getUserRequest());

        if (payload.email && payload.password) {
            browserHistory.push('/customers');
        }
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(loginFailure(e.response ? e.response.data.message : e));
    }
}

function* logout() {
    try {
        yield call(deleteSessionToken);
        yield put(logoutSuccess());
        browserHistory.push('/login');
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(logoutFailure(e.response ? e.response.data.message : e));
    }
}

function* passwordReset({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: '/reset-password',
            params: { email: payload.email }
        });

        if (response.data.success) {
            notification.success({ message: 'Success', description: 'Reset password link has been sent!' });
        }

        yield put(passwordResetSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(passwordResetFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* authSaga() {
    yield* [
        takeEvery(LOGIN_REQUEST, login),
        takeEvery(LOGOUT_REQUEST, logout),
        takeEvery(PASSWORD_RESET_REQUEST, passwordReset),
    ];
}
