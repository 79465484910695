import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_PRODUCTS_REQUEST: null,
    GET_PRODUCTS_SUCCESS: null,
    GET_PRODUCTS_FAILURE: null,

    GET_PRODUCT_REQUEST: null,
    GET_PRODUCT_SUCCESS: null,
    GET_PRODUCT_FAILURE: null,

    UPDATE_PRODUCT_REQUEST: null,
    UPDATE_PRODUCT_SUCCESS: null,
    UPDATE_PRODUCT_FAILURE: null,

    GET_PRODUCT_CATEGORIES_REQUEST: null,
    GET_PRODUCT_CATEGORIES_SUCCESS: null,
    GET_PRODUCT_CATEGORIES_FAILURE: null,

    GET_PRODUCT_STOCK_REQUEST: null,
    GET_PRODUCT_STOCK_SUCCESS: null,
    GET_PRODUCT_STOCK_FAILURE: null,

    UPDATE_PRODUCT_STOCK_REQUEST: null,
    UPDATE_PRODUCT_STOCK_SUCCESS: null,
    UPDATE_PRODUCT_STOCK_FAILURE: null,

    GET_PRODUCT_CUSTOMERS_REQUEST: null,
    GET_PRODUCT_CUSTOMERS_SUCCESS: null,
    GET_PRODUCT_CUSTOMERS_FAILURE: null,

    RESET_PRODUCTS: null,
});

export const {
    getProductsRequest,
    getProductsSuccess,
    getProductsFailure
} = createActions(
    constants.GET_PRODUCTS_REQUEST,
    constants.GET_PRODUCTS_SUCCESS,
    constants.GET_PRODUCTS_FAILURE
);

export const {
    getProductRequest,
    getProductSuccess,
    getProductFailure
} = createActions(
    constants.GET_PRODUCT_REQUEST,
    constants.GET_PRODUCT_SUCCESS,
    constants.GET_PRODUCT_FAILURE
);

export const {
    updateProductRequest,
    updateProductSuccess,
    updateProductFailure
} = createActions(
    constants.UPDATE_PRODUCT_REQUEST,
    constants.UPDATE_PRODUCT_SUCCESS,
    constants.UPDATE_PRODUCT_FAILURE
);

export const {
    getProductCategoriesRequest,
    getProductCategoriesSuccess,
    getProductCategoriesFailure
} = createActions(
    constants.GET_PRODUCT_CATEGORIES_REQUEST,
    constants.GET_PRODUCT_CATEGORIES_SUCCESS,
    constants.GET_PRODUCT_CATEGORIES_FAILURE
);

export const {
    getProductStockRequest,
    getProductStockSuccess,
    getProductStockFailure
} = createActions(
    constants.GET_PRODUCT_STOCK_REQUEST,
    constants.GET_PRODUCT_STOCK_SUCCESS,
    constants.GET_PRODUCT_STOCK_FAILURE
);

export const {
    updateProductStockRequest,
    updateProductStockSuccess,
    updateProductStockFailure
} = createActions(
    constants.UPDATE_PRODUCT_STOCK_REQUEST,
    constants.UPDATE_PRODUCT_STOCK_SUCCESS,
    constants.UPDATE_PRODUCT_STOCK_FAILURE
);

export const {
    getProductCustomersRequest,
    getProductCustomersSuccess,
    getProductCustomersFailure
} = createActions(
    constants.GET_PRODUCT_CUSTOMERS_REQUEST,
    constants.GET_PRODUCT_CUSTOMERS_SUCCESS,
    constants.GET_PRODUCT_CUSTOMERS_FAILURE
);

export const {
    resetProducts,
} = createActions(
    constants.RESET_PRODUCTS,
);
