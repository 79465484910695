import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import DOM from './ordersBackorder';

import {
    handleBasicGridviewMount,
    handleBasicGridviewPagination,
} from '../../../core/utils/helpers';

import * as chaserActions from '../../../core/chaser/chaserActions';
import * as orderActions from '../../../core/order/orderActions';
import * as backorderChaserActions from '../../../core/backorderChaser/backorderChaserActions';

class OrdersBackorder extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        const { actions } = this.props;
        handleBasicGridviewMount(actions.getOrdersBackorderRequest, 'searchOrdersBackorder');
    }

    // Get value for checkbox based on redux state
    getCheckboxValue = (type, id) => this.props.backorderChasers[type].includes(id);

    // Handle checkbox change
    handleCheckboxChange = (type, id, value) => {
        const { backorderChasers, actions } = this.props;
        let ids = backorderChasers[type];

        if (value) {
            ids.push(id);
        } else {
            ids = ids.filter(item => id !== item);
        }
        actions.setBackorderChaserRequest({ type, ids });
    }

    // Handle select all checkbox
    handleSelectAll = (type, selectStatus) => {
        const { actions, orders } = this.props;
        const nextSelectAll = !selectStatus;

        actions.setBackorderChaserAllRequest({ status: nextSelectAll });

        const ids = nextSelectAll ? orders.map(item => item.id) : [];

        actions.setBackorderChaserRequest({ type, ids });
    }


    // Handle table pagination
    handlePagination = (page, pageSize) => {
        const { actions } = this.props;
        handleBasicGridviewPagination(actions.getOrdersBackorderRequest, 'searchOrdersBackorder', page, pageSize);
    }

    render() {
        return this.view();
    }
}

OrdersBackorder.propTypes = {
    actions: PropTypes.object.isRequired,
    backorderChasers: PropTypes.object.isRequired,
    orders: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.order.isFetching,
        orders: state.order.ordersBackorder.rows,
        page: state.order.ordersBackorder.page,
        total: state.order.ordersBackorder.total,

        backorderChasers: state.backorderChaser,
        emailBackorderChasers: state.backorderChaser.backorderEmail,
        postBackorderChasers: state.backorderChaser.post,
        faxBackorderChasers: state.backorderChaser.backorderFax,
        backordersChaserIsFetching: state.backorderChaser.isFetching,
        backorderSelectAll: state.backorderChaser.setBackorderChaserAllRequest,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...chaserActions,
            ...orderActions,
            ...backorderChaserActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersBackorder);
