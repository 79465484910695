import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { InputNumber, Input, Radio, Checkbox } from 'antd';

import styles from './styles.js';

import blackAtIcon from '../../../../../../assets/images/black-at-icon.png';
import blackKeypad from '../../../../../../assets/images/black-keypad.png';
import blackMailIcon from '../../../../../../assets/images/black-mail-icon.png';
import blackATMCard from '../../../../../../assets/images/black-atm-card.png';

const { TextArea } = Input;

class Letter extends Component {
    constructor(props) {
        super(props);
        const { letter } = this.props;
        letter.required = 1;
        this.state = {
        };
    }

    _renderTitle() {
        const { letter, title } = this.props;

        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                }}>
                <div
                    style={{
                        width: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}>
                    <p style={{ ...styles.text1, marginTop: 20, }}>
                        {title}
                    </p>
                    {(letter.type !== 1) &&
                        <p>
                            This will show in the letter generated after a new order is created.
                        </p>
                    }
                </div>
            </div>
        );
    }

    _renderStamp() {
        const { letter, setletter } = this.props;

        return (
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                }}>
                <div
                    style={{
                        width: 300,
                        height: 100,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <Radio.Group
                            onChange={e => setletter({ type: letter.type, inputType: 0, value: e.target.value })}
                            value={letter.required}>
                            <Radio value={0}>Not Required</Radio>
                            <Radio value={1}>Required</Radio>
                        </Radio.Group>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <div>
                            <img
                                src={blackAtIcon}
                                style={{
                                    height: 25,
                                    width: 25,
                                    marginRight: 5,
                                    marginLeft: 20,
                                }}
                                alt={'Black At Icon'} />
                            <Checkbox
                                checked={letter.email}
                                onChange={e => setletter({ type: letter.type, inputType: 1, value: e.target.checked })} />
                        </div>
                        <div>
                            <img
                                src={blackMailIcon}
                                style={{
                                    height: 25,
                                    width: 25,
                                    marginRight: 5,
                                    marginLeft: 20,
                                }}
                                alt={'Black Mail Icon'} />
                            <Checkbox
                                checked={letter.letter}
                                onChange={e => setletter({ type: letter.type, inputType: 2, value: e.target.checked })} />
                        </div>
                        <div>
                            <img
                                src={blackKeypad}
                                style={{
                                    height: 25,
                                    width: 25,
                                    marginRight: 5,
                                    marginLeft: 20,
                                }}
                                alt={'Black Keypad'} />
                            <Checkbox
                                checked={letter.fax}
                                onChange={e => setletter({ type: letter.type, inputType: 3, value: e.target.checked })} />
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    _renderTextArea() {
        const { letter, setletter } = this.props;

        return (
            <div style={{ width: '100%', }}>
                <TextArea
                    value={letter.text}
                    onChange={e => ((e.target.value.length <= 500) ? setletter({ type: letter.type, inputType: 4, value: e.target.value }) : null)} />
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', }}>
                    <p>{letter.text && letter.text !== '' ? letter.text.length : 0}/500</p>
                </div>
            </div>
        );
    }

    _renderBottomContent() {
        const { letter, setletter } = this.props;

        switch (letter.type) {
        case 1:
        case 2:
            return (
                <div style={{ width: '100%', }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', }}>
                            <img src={blackATMCard} style={{ height: 25, width: 25, marginRight: 15 }} alt={'Black ATM Card'} />
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', }}>
                                <p style={{ margin: 0, padding: 0, marginRight: 5, }}>Price (£):</p>
                                <InputNumber
                                    value={letter.price}
                                    onChange={val => setletter({ type: letter.type, inputType: 5, value: val })}
                                    style={{ width: 100, height: 24, }} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        default:
            return null;
        }
    }

    _renderTopContent() {
        const { letter } = this.props;

        switch (letter.type) {
        case 1:
            return (
                <div style={{ width: '100%', }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginBottom: '5px', }}>
                        <p style={{ margin: 0, padding: 0, marginRight: 5, }}>Description:</p>
                    </div>
                </div>
            );
        default:
            return null;
        }
    }

    _renderBody() {
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <div
                    style={{
                        margin: '20px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                    }}>
                    {this._renderTopContent()}
                    {this._renderTextArea()}
                    {this._renderBottomContent()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div style={{ width: '100%', position: 'relative', marginBottom: 20, backgroundColor: 'white', boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.07)', }}>
                <div style={{ position: 'relative', minHeight: 100, }}>
                    {this._renderTitle()}
                    {this._renderStamp()}
                </div>
                {this._renderBody()}
            </div>
        );
    }
}

Letter.defaultProps = {
    letter: { required: 1 },
    title: '',
    setletter: () => { },
};

Letter.propTypes = {
    letter: PropTypes.object,
    title: PropTypes.string,
    setletter: PropTypes.func,
};

export default Letter;
