import React from 'react';

import Layout from '../../components/layout/Layout';
import styles from './styles';
import Patient from '../PrescriptionMatcher/Patient';
import Letter from '../../components/forms/UpdateOrder/tabs/letters/letter';
import { Button, Spin } from 'antd';
import Icon from '../../elements/Icon';

export default function () {

    const { name, toolbar, letterList } = this.state;
    const { isFetching, selectedCustomer, isGettingCustomer, isUpdatingLetter } = this.props;
    const id = parseInt(this.props.params.id, 10);
    const customerId = this.props.params.customerId ? parseInt(this.props.params.customerId, 10) : null;
    const breadcrumb = { name: 'order', params: { id, name } };

    
    return (
        <Layout title={name} toolbar={toolbar} breadcrumb={breadcrumb} >    
            <Spin spinning={isGettingCustomer || isUpdatingLetter }>
                <div style={styles.gridLayout}>
                    <div className="letter-box">
                        <Letter title={'Payment Letter'} 
                            letter={letterList.find(letter => letter.type === 1)}
                            setletter={this.setletter}                        
                        />
                    </div>
                    <div className="letter-box">
                        <Letter title={'Exemption Letter'} 
                                letter={letterList.find(letter => letter.type === 2)}
                                setletter={this.setletter}
                        />
                    </div>
                    <div style={{ textAlign: 'center'}}>
                    <Button type="primary"  className="average m-0" onClick={this.handleUpdateOrderSubmit}>
                            <Icon name="save" />Save
                    </Button>
                    </div>
                </div>
            </Spin>
          
        </Layout>
    );
}
