import { Component } from 'react';

import DOM from './reports';

class ReportsPage extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            title: 'Reports',
        };
    }

    render() {
        return this.view();
    }
}

export default ReportsPage;
