import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import * as orderActions from '../../../core/order/orderActions';

import DOM from './updateOrderNotes';

class UpdateOrderNotesForm extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentWillMount() {
        const { actions, orderId } = this.props;
        actions.getOrderRequest(orderId);
    }

    // ----- Submit Form ----- //

    handleUpdateOrderNotesSubmit = (values) => {
        const { actions, changeVisible, orderId } = this.props;
        actions.updateOrderRequest({ id: orderId, order: values, products: [] });
        changeVisible(false);
    };

    render() {
        return this.view();
    }
}

UpdateOrderNotesForm.propTypes = {
    actions: PropTypes.object.isRequired,
    changeVisible: PropTypes.func.isRequired,
    orderId: PropTypes.number.isRequired,
};

const UpdateOrderNotes = reduxForm({
    form: 'update-order-notes',
    enableReinitialize: true,
})(UpdateOrderNotesForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        initialValues: state.order.order,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...orderActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateOrderNotes);
