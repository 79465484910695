import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'antd';

import UpdateOrderNotes from '../../forms/UpdateOrderNotes';

const NotesUpdateOrder = ({ orderId, visible, changeVisible, readOnly }) => (
    <Modal centered className="modal--copy" width={800} footer={null} visible={visible} onOk={() => changeVisible(false)} onCancel={() => changeVisible(false)}>
        <div className="container">
            <UpdateOrderNotes orderId={orderId} readOnly={readOnly} changeVisible={changeVisible} />
        </div>
    </Modal>
);

NotesUpdateOrder.defaultProps = {
    readOnly: false,
};

NotesUpdateOrder.propTypes = {
    orderId: PropTypes.number.isRequired,
    readOnly: PropTypes.bool,
    visible: PropTypes.bool.isRequired,
    changeVisible: PropTypes.func.isRequired,
};

export default NotesUpdateOrder;
