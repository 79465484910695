import { Record } from 'immutable';

const {
    GET_REPORT_REQUEST,
    GET_REPORT_SUCCESS,
    GET_REPORT_FAILURE,
} = require('./reportActions').constants;

const InitialState = Record({
    isFetching: false,
    error: null,
});

const initialState = new InitialState();

/**
 * ## nurseReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function nurseReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {
    case GET_REPORT_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case GET_REPORT_SUCCESS:
        return state.set('isFetching', false);

    case GET_REPORT_FAILURE:
        return state.set('error', payload).set('isFetching', false);

    default:
        return state;
    }
}
