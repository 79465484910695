const styles = {
    // Simple Attributes
    searchBarContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
        marginBottom: 10,
        width: 'calc(100% - 1px)',
        alignItems: 'center',
        height: 24,
        minWidth: 250,
    },
    searchBarTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#718096',
        margin: 0,
        verticalAlign: 'center',
        minWidth: 77,
    },
    searchBarButton: {
        width: 64,
        borderRadius: 12,
        border: 'solid',
        borderColor: '#149566',
        borderWidth: 1,
        fontSize: 10,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#149566',
        height: 24,
        marginLeft: 2,
    },
    selectInput: {
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '25px',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: 'inherit',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: 'inherit',
        }),
    },

    // Form
    formFieldLabel: {
        textAlign: 'left',
        minWidth: 100,
        fontSize: 12,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        color: '#718096',
        margin: 5,
        verticalAlign: 'center',
    },
    formFieldInput: {
        textAlign: 'left',
        flex: 1,
        fontWeight: 'normal',
        fontSize: 12,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        color: '#718096',
        margin: 5,
        verticalAlign: 'center',
        height: 24,
        display: 'flex',
    },
    formSectionTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2d3748',
        verticalAlign: 'center',
        margin: 0,
        whiteSpace: 'nowrap',
    },
    formSectionHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    formSectionContainer: {
        width: 'calc(100% - 1px)',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 15,
        marginBottom: 5,
        minWidth: 250,
    },
    formSectionStatusText: {
        whiteSpace: 'nowrap',
        textAlign: 'left',
        fontSize: 12,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        color: 'white',
        verticalAlign: 'center',
        margin: 0,
        marginLeft: 10,
        height: '100%',
        border: 'solid',
        borderColor: 'black',
        paddingLeft: 5,
        paddingRight: 5,
    },

    // Main
    mainContainer: {
        overflowY: 'scroll',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 15,
        paddingBottom: 70,
    },
    buttonWrapper: {
        all: 'unset',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        cursor: 'pointer',
    },
};

export default styles;
