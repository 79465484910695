import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import DOM from './customersNurse';

import * as nurseActions from '../../../core/nurse/nurseActions';

class CustomersNurse extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            modalAddCustomerVisible: false,
            selectedCustomerId: 0,
        };
    }

    componentWillMount() {
        const { actions, nurseId } = this.props;
        actions.getNurseCustomerRequest(nurseId);
    }

    // Handle toggle add customer modal visibility
    setModalAddCustomerVisible = modalAddCustomerVisible => this.setState({ modalAddCustomerVisible });

    // Handle remove customer nurse
    handleRemoveCustomerNurse = (customerId) => {
        const { actions, nurseId } = this.props;
        actions.removeNurseCustomerRequest({ customerId, nurseId });
    }

    render() {
        return this.view();
    }
}

CustomersNurse.propTypes = {
    actions: PropTypes.object.isRequired,
    nurseId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        customers: state.nurse.customers,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...nurseActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomersNurse);
