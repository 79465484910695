import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import {
    Row,
    Col,
} from 'antd';
import { isEmpty, isEqual, pluck } from 'underscore';

import SearchForProductsSection from './searchForProductsSection';
import OrderSummaryAndAutoOrderSettingsSection from './orderSummaryAndAutoOrderSettingsSection';

import styles from './styles.js';


class AddProductsAndAutoOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.activeTab !== '2') {
            return false;
        }
        return true;
    }

    render() {
        const {
            products,
            readOnly,
            added,
            recommended,
            search,
            callbacks,
        } = this.props;

        // Filter products by search
        let searchProducts = products;
        if (search) {
            searchProducts = products.filter(product => (`#${product.sku}${product.description}`.toLowerCase().includes(search.toLowerCase())));
        }

        // Filter products by added ids
        let addedProducts = [];
        if (!isEmpty(added)) {
            addedProducts = products.filter(product => pluck(added, 'id').includes(product.id));
        }

        // Filter products by customer recommended product ids
        let recommendedProducts = [];
        if (!isEmpty(recommended)) {
            recommendedProducts = products.filter(product => recommended.includes(product.id));
        }

        return (
            <div className="section">
                <div className="container">
                    <Row gutter={48} type="flex" justify="center">
                        <Col span={20}>
                            {!readOnly &&
                            <SearchForProductsSection
                                callbacks={callbacks}
                                searchProducts={searchProducts} />
                            }
                            <OrderSummaryAndAutoOrderSettingsSection
                                readOnly={readOnly}
                                added={added}
                                addedProducts={addedProducts}
                                callbacks={callbacks} />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

AddProductsAndAutoOrders.defaultProps = {
    readOnly: false,
};

AddProductsAndAutoOrders.propTypes = {
    callbacks: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
    added: PropTypes.array.isRequired,
    recommended: PropTypes.array.isRequired,
    search: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    activeTab: PropTypes.string.isRequired,
};

export default AddProductsAndAutoOrders;
