import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Row, Col } from 'antd';

import AddProductSupplier from '../../forms/AddProductSupplier';

const AddProduct = ({ supplierId, visible, changeVisible }) => (
    <Modal centered width={540} footer={null} visible={visible} onOk={() => changeVisible(false)} onCancel={() => changeVisible(false)}>
        <div className="text-center">
            <h4 className="h-4">Add product to supplier/manufacturers</h4>
        </div>
        <div className="mt-2">
            <Row type="flex" justify="center">
                <Col span={24} md={20}>
                    <AddProductSupplier supplierId={supplierId} changeVisible={changeVisible} />
                </Col>
            </Row>
        </div>
    </Modal>
);

AddProduct.propTypes = {
    changeVisible: PropTypes.func.isRequired,
    supplierId: PropTypes.number.isRequired,
    visible: PropTypes.bool.isRequired,
};

export default AddProduct;
