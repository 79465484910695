import PropTypes from 'prop-types';
import alertify from 'alertify.js';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { findIndex, isUndefined, isEmpty } from 'underscore';
import { reduxForm, formValueSelector, stopSubmit } from 'redux-form';
import { browserHistory } from 'react-router';

import * as customerActions from '../../../core/customer/customerActions';
import * as orderActions from '../../../core/order/orderActions';
import * as productActions from '../../../core/product/productActions';

import { validateOrder } from '../../../core/utils/validators';

import DOM from './updateOrder';

const { ORDER_STATUS_SCRIPT_WAITING } = require('../../../core/utils/values').default;

const EXEMPTION_REASONS = [
    { label: 'A - Is aged 60 or older, or under 16 (unless the birthdate is on the form)', value: 'A', },
    { label: 'B - Is 16, 17 or 18 years old and in full-time education', value: 'B', },
    { label: 'D - Materntity exemption certificate', value: 'D', },
    { label: 'E - Medical exemption certificate', value: 'E', },
    { label: 'F - Prescription pre-payment certificate', value: 'F', },
    { label: 'G - Prescription exemption certificate issued by the Ministry of Defence', value: 'G', },
    { label: 'L - HC2 (full help) certificate', value: 'L', },
    { label: 'H - Income Support or Income-related Employment and Support Allowance', value: 'H', },
    { label: 'K - Income-based Jobseekers Allowance', value: 'K', },
    { label: 'M - Tax Credit Exemption Certificate', value: 'M', },
    { label: 'S - Pension Credit guarantee credit (including partners)', value: 'S', },
    { label: 'U - Universal Credit and meets the criteria. Learn more at nhsbsa.nhs.uk/UC', value: 'U', },
];

class UpdateOrderForm extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            activeTab: '1',
            modalChooseCustomerVisible: false,
            modalSelectCustomerVisible: false,
            orderProducts: false,
            productsSearch: '',
            letterList: [
                {
                    text: null,
                    required: null,
                    email: null,
                    letter: null,
                    fax: null,
                    price: null,
                    type: 0,
                },
                {
                    text: null,
                    required: null,
                    email: null,
                    letter: null,
                    fax: null,
                    price: null,
                    type: 1,
                },
                {
                    text: null,
                    required: null,
                    email: null,
                    letter: null,
                    fax: null,
                    price: null,
                    type: 2,
                },
            ],
            orderPickingAndDispatchNote: {
                requested_by: null,
                comments: null,
                other_instructions: null,
                additional_comments: null,
            },
            prescriptionInformation: {
                exempt_from_prescription_fee: null,
                exemption_reason: null,
                certificate_no: null,
                certificate_expiry_date: null,
                national_insurance_no: null,
                prescription_info: null,
            },
        };
    }

    componentWillMount() {
        const { orderId, customerId, actions } = this.props;
        actions.getOrderRequest({ id: orderId, customer_id: customerId });
        actions.getProductsRequest();
    }

    componentDidMount() {
        const { customerId, actions } = this.props;
        const browserHistoryState = browserHistory.getCurrentLocation().state;

        if (customerId) this.setState({ activeTab: '2', });

        if (browserHistoryState && browserHistoryState.from === 'auto-order-items') this.setState({ activeTab: '2', });

        if (browserHistoryState && browserHistoryState.from === 'customer-summary-repeat' && browserHistoryState.orderId) {
            actions.getOrderRequest({ id: browserHistoryState.orderId, customer_id: customerId });
        }

        this.handleSetLettersAndInfo();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.order && nextProps.order.orderItems) {
            const orderItemWithRepeatInstructionList = nextProps.order.orderItems.filter(item => item.orderItemRepeatInstruction);
            orderItemWithRepeatInstructionList.map((orderItem) => {
                const index = nextProps.order.products.findIndex(product => product.id === orderItem.product_id);
                nextProps.order.products[index].orderItemRepeatInstruction = {
                    interval: orderItem.orderItemRepeatInstruction.interval,
                    end_date: orderItem.orderItemRepeatInstruction.end_date,
                };
            });
        }

        const newState = { orderProducts: [] };
        const firstTime = this.state.orderProducts === false;
        if (!firstTime) {
            // If this is not first time update orderProducts
            newState.orderProducts = nextProps.order.products || this.state.orderProducts;
        }
        this.setState(newState);

        // Update readonly status in orders page
        if (nextProps.order.is_dispatched) {
            this.props.setReadOnly(nextProps.order.is_dispatched);
        }
    }

    componentDidUpdate(prevProps) {
        this.handleSetLettersAndInfo(prevProps);
    }

    // Handle toggle choose customer modal
    setModalChooseCustomerVisible = modalChooseCustomerVisible => this.setState({ modalChooseCustomerVisible });

    // Handle toggle customer search modal
    setModalSelectCustomerVisible = modalSelectCustomerVisible => this.setState({ modalSelectCustomerVisible });

    // Handle tab change
    handleTabChange = (tab) => {
        this.setState({ activeTab: tab });
        this.props.handleTabChange(parseInt(tab, 10));
    }

    // Handle tab back button
    handleTabBack = () => {
        const newTab = parseInt(this.state.activeTab, 10) - 1;
        this.setState({ activeTab: `${newTab}` });
        this.props.handleTabChange(newTab);
    }

    // Handle tab next button
    handleTabNext = () => {
        const newTab = parseInt(this.state.activeTab, 10) + 1;
        this.setState({ activeTab: `${newTab}` });
        this.props.handleTabChange(newTab);
    }

    // Handle select choose customer
    handleChooseCustomerSubmit = () => {
        this.setState({
            modalChooseCustomerVisible: false,
            modalSelectCustomerVisible: true
        });
    }

    // Handle select customer for order
    handleSelectCustomerSubmit = (selectedCustomerId) => {
        const { actions } = this.props;
        actions.getCustomerRequest({ id: selectedCustomerId });
        actions.getCustomerDoctorRequest({ id: selectedCustomerId });
        actions.getCustomerPracticeRequest({ id: selectedCustomerId });
        this.setState({ modalSelectCustomerVisible: false });
    }

    // Handle open modal based on state property
    handleOpenModal = (modal) => {
        const newState = {};
        newState[modal] = true;
        this.setState(newState);
    }

    // Handle search product
    searchProduct = productsSearch => this.setState({ productsSearch });

    // Handle update added products
    updateProduct = (productId, quantity) => {
        const orderProducts = this.state.orderProducts;
        const productIndex = findIndex(orderProducts, product => product.id === productId);

        // If product has already been added
        if (productIndex !== -1) {
            // If quantity is undefined we add 1 to the current total
            if (isUndefined(quantity)) {
                orderProducts[productIndex].quantity += 1;
                // If quantity is 0 we remove product
            } else if (quantity === 0) {
                orderProducts.splice(productIndex, 1);
                // Else we set product to new quantity given
            } else {
                orderProducts[productIndex].quantity = quantity;
            }
            // If quantity is undefined add new product
        } else if (isUndefined(quantity)) {
            orderProducts.push({ id: productId, quantity: 1 });
            // Else we add new product with quantity given
        } else {
            orderProducts.push({ id: productId, quantity });
        }

        this.setState({ orderProducts: [...orderProducts] });
    }

    // Handle set repeat order
    setRepeatOrder = ({ productId, input }) => {
        const orderProducts = this.state.orderProducts;
        const productIndex = orderProducts.findIndex(product => product.id === productId);

        switch (input.action) {
        case 'toggle':
            if (orderProducts[productIndex].orderItemRepeatInstruction) {
                orderProducts[productIndex].orderItemRepeatInstruction = null;
            } else {
                orderProducts[productIndex].orderItemRepeatInstruction = {};
            }
            break;
        case 'setInterval':
            if (orderProducts[productIndex].orderItemRepeatInstruction) {
                orderProducts[productIndex].orderItemRepeatInstruction.interval = input.value;
            }
            break;
        case 'setEndDate':
            if (orderProducts[productIndex].orderItemRepeatInstruction) {
                orderProducts[productIndex].orderItemRepeatInstruction.end_date = input.value;
            }
            break;
        default:
            break;
        }

        this.setState({ orderProducts: [...orderProducts] });
    }

    // Handle submit update order form
    handleUpdateOrderSubmit = (values) => {
        const { actions, orderId, selectedCustomer, } = this.props;
        const { prescriptionInformation, orderProducts, letterList, orderPickingAndDispatchNote } = this.state;
        const browserHistoryState = browserHistory.getCurrentLocation().state;
        const errors = validateOrder(values);
        const letterListB = letterList.filter(letter => !isEmpty(letter.text));

        if (!isEmpty(errors)) {
            // Only necessary since we are switching fields on and off
            actions.stopSubmit('update-order', errors);
            return null;
        }

        if (!selectedCustomer.id) {
            alertify.error('Please select a customer');
            actions.stopSubmit('update-order');
            return null;
        }

        if (isEmpty(orderProducts)) {
            alertify.error('Please select a product');
            actions.stopSubmit('update-order');
            return null;
        }

        if (!orderProducts.every((product =>
            !product.orderItemRepeatInstruction
            ||
            (
                !(product.orderItemRepeatInstruction == null)
                &&
                (
                    !(product.orderItemRepeatInstruction.end_date == null)
                    &&
                    !(product.orderItemRepeatInstruction.interval == null)
                )
            ))
        )) {
            alertify.error('You have configured at least one repeat order item without fully defining the repeat instructions');
            actions.stopSubmit('update-order');
            return null;
        }

        if (!letterList.every(letter => (letter.text == null) || !(letter.required == null))) {
            alertify.error('You have written at least one letter without setting its required status');
            actions.stopSubmit('update-order');
            return null;
        }

        const paymentLetter = letterListB.find(letter => letter.type === 1);
        if (paymentLetter && (paymentLetter.price == null)) {
            alertify.error('You have written a payment letter without specifying a price');
            actions.stopSubmit('update-order');
            return null;
        }

        if (selectedCustomer.id && !isEmpty(orderProducts)) {
            const data = {
                id: (browserHistoryState && browserHistoryState.from === 'customer-summary-repeat' && browserHistoryState.orderId) ? 0 : orderId,
                order: {
                    ...values,
                    customer_id: selectedCustomer.id,
                    letterList: letterListB,
                    prescriptionInformation,
                    orderPickingAndDispatchNote: !Object.keys(orderPickingAndDispatchNote).map(key => orderPickingAndDispatchNote[key]).every(text => isEmpty(text)) ? orderPickingAndDispatchNote : null,
                },
                products: orderProducts,
                activeTab: this.state.activeTab,
            };

            actions.updateOrderRequest(data);
        }
    };

    setletter = ({ type, inputType, value }) => {
        const { letterList } = this.state;
        const letterIndex = letterList.findIndex(letter => letter.type === type);

        switch (inputType) {
        case 0:
            letterList[letterIndex].required = (value) ? 1 : 0;
            this.setState({ letterList: [...letterList], });
            break;
        case 1:
            letterList[letterIndex].email = (value) ? 1 : 0;
            this.setState({ letterList: [...letterList], });
            break;
        case 2:
            letterList[letterIndex].letter = (value) ? 1 : 0;
            this.setState({ letterList: [...letterList], });
            break;
        case 3:
            letterList[letterIndex].fax = (value) ? 1 : 0;
            this.setState({ letterList: [...letterList], });
            break;
        case 4:
            letterList[letterIndex].text = value;
            this.setState({ letterList: [...letterList], });
            break;
        case 5:
            letterList[letterIndex].price = value;
            this.setState({ letterList: [...letterList], });
            break;
        default:
            break;
        }
    }

    setOrderPickingAndDispatchNote = ({ fieldName, value }) => {
        const { orderPickingAndDispatchNote } = this.state;
        orderPickingAndDispatchNote[fieldName] = value;
        this.setState({ orderPickingAndDispatchNote });
    }

    handleSetPrescriptionInformation(value, type) {
        const { prescriptionInformation } = this.state;
        const prescriptionInformationBuffer = JSON.parse(JSON.stringify(prescriptionInformation));
        if (type === 'exempt_from_prescription_fee' && value === 0) prescriptionInformationBuffer.exemption_reason = null;
        prescriptionInformationBuffer[type] = value;
        this.setState({ prescriptionInformation: prescriptionInformationBuffer });
    }

    safelyExtractProperty(object, property) {
        return (object && typeof object[property] !== 'undefined') && object[property];
    }

    handleSetLettersAndInfo(prevProps = null) {
        const { selectedCustomer, order } = this.props;

        const exemption_reason = EXEMPTION_REASONS.find(reason => reason.value === selectedCustomer.exemption_reason);

        if (!isEmpty(selectedCustomer) && !isEmpty(order)) {
            if (!prevProps
                || (prevProps
                    &&
                    ((prevProps.selectedCustomer && (selectedCustomer.id !== prevProps.selectedCustomer.id)
                    || (prevProps.order && (order.id !== prevProps.order.id))
                    )))) {
                const additionalTextLetter = order.letters && order.letters.find(letter => letter.type === 0);
                const paymentLetter = order.letters && order.letters.find(letter => letter.type === 1);
                const orderPickingAndDispatchNote = order.orderPickingAndDispatchNote;
                const exemptionLetter = order.letters && order.letters.find(letter => letter.type === 2);

                this.setState({
                    letterList: [
                        {
                            text: this.safelyExtractProperty(additionalTextLetter, 'text'),
                            required: this.safelyExtractProperty(additionalTextLetter, 'required') || 0,
                            email: this.safelyExtractProperty(additionalTextLetter, 'email'),
                            letter: this.safelyExtractProperty(additionalTextLetter, 'letter'),
                            fax: this.safelyExtractProperty(additionalTextLetter, 'fax'),
                            price: this.safelyExtractProperty(additionalTextLetter, 'price'),
                            type: 0,
                        },
                        {
                            text: this.safelyExtractProperty(paymentLetter, 'text'),
                            required: this.safelyExtractProperty(paymentLetter, 'required') || 0,
                            email: this.safelyExtractProperty(paymentLetter, 'email'),
                            letter: this.safelyExtractProperty(paymentLetter, 'letter'),
                            fax: this.safelyExtractProperty(paymentLetter, 'fax'),
                            price: this.safelyExtractProperty(paymentLetter, 'price'),
                            type: 1,
                        },
                        {
                            text: this.safelyExtractProperty(exemptionLetter, 'text') || (exemption_reason && exemption_reason.label),
                            required: this.safelyExtractProperty(exemptionLetter, 'required') || 0,
                            email: this.safelyExtractProperty(exemptionLetter, 'email') || 0,
                            letter: this.safelyExtractProperty(exemptionLetter, 'letter') || 0,
                            fax: this.safelyExtractProperty(exemptionLetter, 'fax') || 0,
                            price: this.safelyExtractProperty(exemptionLetter, 'price'),
                            type: 2,
                        },
                    ],
                    orderPickingAndDispatchNote: {
                        requested_by: this.safelyExtractProperty(orderPickingAndDispatchNote, 'requested_by'),
                        comments: this.safelyExtractProperty(orderPickingAndDispatchNote, 'comments'),
                        other_instructions: this.safelyExtractProperty(orderPickingAndDispatchNote, 'other_instructions'),
                        additional_comments: this.safelyExtractProperty(orderPickingAndDispatchNote, 'additional_comments'),
                    },
                    prescriptionInformation: {
                        exempt_from_prescription_fee: this.safelyExtractProperty(order, 'funding_method') || this.safelyExtractProperty(selectedCustomer, 'exemption_reason') ? 1 : 0,
                        exemption_reason: this.safelyExtractProperty(order, 'funding_method') || this.safelyExtractProperty(selectedCustomer, 'exemption_reason'),
                        certificate_no: this.safelyExtractProperty(order, 'certificate_no') || this.safelyExtractProperty(selectedCustomer, 'certificate_number'),
                        certificate_expiry_date: this.safelyExtractProperty(order, 'certificate_expiry_date') || this.safelyExtractProperty(selectedCustomer, 'certificate_expiry_date'),
                        national_insurance_no: this.safelyExtractProperty(order, 'national_insurance_no') || this.safelyExtractProperty(selectedCustomer, 'national_insurance_no'),
                        prescription_info: this.safelyExtractProperty(order, 'letter_notes') || this.safelyExtractProperty(selectedCustomer, 'prescription_info'),
                    },
                });
            }
        }
    }

    render() {
        return this.view();
    }
}

UpdateOrderForm.defaultProps = {
    customerId: 0,
    setReadOnly: () => { },
};

UpdateOrderForm.propTypes = {
    actions: PropTypes.object.isRequired,
    handleTabChange: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired, // eslint-disable-line
    orderId: PropTypes.number.isRequired,
    orderStatus: PropTypes.number.isRequired, // eslint-disable-line
    selectedCustomer: PropTypes.object.isRequired,
    customerId: PropTypes.number,
    setReadOnly: PropTypes.func,
};

const selector = formValueSelector('update-order');
const UpdateOrder = reduxForm({
    form: 'update-order',
    enableReinitialize: true,
    validate: validateOrder,
})(UpdateOrderForm);

function mapStateToProps(state, ownProps) {
    const orderStatus = selector(state, 'order_status') || ORDER_STATUS_SCRIPT_WAITING;
    let autoOrder = selector(state, 'auto_order');
    if (typeof autoOrder === 'string') {
        autoOrder = autoOrder === 'true';
    }

    return {
        ...ownProps,
        autoOrder,
        orderStatus,
        initialValues: state.order.order,
        order: state.order.order,
        isFetching: state.order.isFetching,
        products: state.product.products.rows,
        selectedCustomer: state.customer.customer,
        selectedCustomerDoctor: state.customer.doctor,
        selectedCustomerPractice: state.customer.practice,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
            ...orderActions,
            ...productActions,
            stopSubmit,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateOrder);
