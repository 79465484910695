import React from 'react';

import GridviewSection from './GridviewSection';

import useWindowDimensions from '../../../../../core/utils/useWindowDimensions';

const CustomerAutoOrders = ({ activeTab }) => {
    const { height, width } = useWindowDimensions();
    const heightOfGridviewSection = height - 154;
    return (
        <GridviewSection height={heightOfGridviewSection} activeTab={activeTab} />
    );
};

export default CustomerAutoOrders;
