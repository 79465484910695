import React from 'react';
import { Form as AntForm, Button } from 'antd';

import Form from '../../../elements/Form';
import { isRequired } from '../../../core/utils/validators';

const FormGroup = Form.Group;
const formTypes = Form.formTypes;

export default function () {
    const formConfig = [
        { type: formTypes.EMAIL, name: 'email', label: 'Email', validate: [isRequired] },
    ];

    return (
        <AntForm id="forgot-password" onSubmit={this.props.handleSubmit(this.handleForgotPasswordSubmit)} layout="vertical">
            <FormGroup config={formConfig} />
            <Button type="primary" htmlType="submit" className="wide">Reset my password</Button>
            <div>
                <Button className="link-green btn-empty underlined" onClick={() => this.props.closeModal()}>cancel</Button>
            </div>
        </AntForm>
    );
}
