import { put, call, takeEvery } from 'redux-saga/effects';
import alertify from 'alertify.js';
import { browserHistory } from 'react-router';
import { isUndefined } from 'underscore';
import { notification } from 'antd';
import { fetchApiAuth, formatParams } from '../utils/api';
import { addKeyProp, mapPropsToInts } from '../utils/helpers';

import {
    getCarehomesSuccess,
    getCarehomesFailure,

    getCarehomeSuccess,
    getCarehomeFailure,

    updateCarehomeSuccess,
    updateCarehomeFailure,

    getCarehomeNurseSuccess,
    getCarehomeNurseFailure,

    addCarehomeNurseSuccess,
    addCarehomeNurseFailure,

    removeCarehomeNurseSuccess,
    removeCarehomeNurseFailure,

    getCarehomeCustomerRequest,
    getCarehomeCustomerSuccess,
    getCarehomeCustomerFailure,
} from './carehomeActions';

const {
    GET_CAREHOMES_REQUEST,
    GET_CAREHOME_REQUEST,
    UPDATE_CAREHOME_REQUEST,
    GET_CAREHOME_NURSE_REQUEST,
    ADD_CAREHOME_NURSE_REQUEST,
    REMOVE_CAREHOME_NURSE_REQUEST,
    GET_CAREHOME_CUSTOMER_REQUEST,
} = require('./carehomeActions').constants;

function* getCarehomes({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/carehome/search',
            params: formatParams(payload)
        });

        let data = !isUndefined(response) ? response.data : [];
        if (data) {
            data = mapPropsToInts(data, ['page', 'total']);
            data.rows = addKeyProp(data.rows);
        }

        yield put(getCarehomesSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getCarehomesFailure(e.response ? e.response.data.message : e));
    }
}

function* getCarehome({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (params.id !== 0) {
            response = yield call(fetchApiAuth, {
                method: 'GET',
                url: '/carehome/get',
                params
            });
        }

        const carehome = !isUndefined(response) ? response.data : {};
        yield put(getCarehomeSuccess(carehome));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getCarehomeFailure(e.response ? e.response.data.message : e));
    }
}

function* updateCarehome({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/carehome/edit',
            body: payload
        });

        const carehome = !isUndefined(response) ? response.data : {};
        if (carehome) {
            browserHistory.push(`/care-home/${carehome.id}`);
            notification.success({ message: 'Success', description: 'Care home has been updated' });
        }

        yield put(updateCarehomeSuccess(carehome));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(updateCarehomeFailure(e.response ? e.response.data.message : e));
    }
}

function* getCarehomeNurse({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (params.id !== 0) {
            response = yield call(fetchApiAuth, {
                method: 'GET',
                url: '/carehome/nurse',
                params
            });
        }

        let data = !isUndefined(response) ? response.data : [];
        if (data) {
            data = addKeyProp(data);
        }

        yield put(getCarehomeNurseSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getCarehomeNurseFailure(e.response ? e.response.data.message : e));
    }
}

function* addCarehomeNurse({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/carehome/nurse',
            body: {
                id: payload.carehomeId,
                nurse_id: payload.nurseId,
            }
        });

        let data = !isUndefined(response) ? response.data : [];
        if (data) {
            data = addKeyProp(data);
        }

        yield put(getCarehomeCustomerRequest(payload.carehomeId));
        yield put(addCarehomeNurseSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(addCarehomeNurseFailure(e.response ? e.response.data.message : e));
    }
}

function* removeCarehomeNurse({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'DELETE',
            url: '/carehome/nurse',
            params: {
                id: payload.carehomeId,
                nurse_id: payload.nurseId,
            }
        });

        let data = !isUndefined(response) ? response.data : [];
        if (data) {
            data = addKeyProp(data);
        }

        yield put(getCarehomeCustomerRequest(payload.carehomeId));
        yield put(removeCarehomeNurseSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(removeCarehomeNurseFailure(e.response ? e.response.data.message : e));
    }
}

function* getCarehomeCustomer({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (params.id !== 0) {
            response = yield call(fetchApiAuth, {
                method: 'GET',
                url: '/carehome/customer',
                params: { id: payload }
            });
        }

        let data = !isUndefined(response) ? response.data : [];
        if (data) {
            data = addKeyProp(data);
        }

        yield put(getCarehomeCustomerSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getCarehomeCustomerFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* carehomeSaga() {
    yield* [
        takeEvery(GET_CAREHOMES_REQUEST, getCarehomes),
        takeEvery(GET_CAREHOME_REQUEST, getCarehome),
        takeEvery(UPDATE_CAREHOME_REQUEST, updateCarehome),
        takeEvery(GET_CAREHOME_NURSE_REQUEST, getCarehomeNurse),
        takeEvery(ADD_CAREHOME_NURSE_REQUEST, addCarehomeNurse),
        takeEvery(REMOVE_CAREHOME_NURSE_REQUEST, removeCarehomeNurse),
        takeEvery(GET_CAREHOME_CUSTOMER_REQUEST, getCarehomeCustomer),
    ];
}
