import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Button } from 'antd';

import CustomersSearch from '../../gridviews/CustomersSearch';

const SelectCustomer = ({ visible, changeVisible, handleSubmit }) => (
    <Modal centered className="shrinked" width={1152} footer={null} visible={visible} onOk={() => changeVisible(false)} onCancel={() => changeVisible(false)}>
        <div className="container">
            <div className="section__header justify-content-center">
                <h3 className="h-4">Find a customer </h3>
            </div>
            <CustomersSearch
                renderButtons={(text, record) => (
                    <div className="btn-bar">
                        <Button href={`/customer/${record.id}`} type="default" className="ant-btn-xs" target="blank">View</Button>
                        <Button onClick={() => handleSubmit(record.id)} type="primary" className="ant-btn-xs">Select</Button>
                    </div>
                )} />
        </div>
    </Modal>
);

SelectCustomer.propTypes = {
    visible: PropTypes.bool.isRequired,
    changeVisible: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default SelectCustomer;
