import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    SET_DISPATCHES_REQUEST: null,
    SET_DISPATCHES_SUCCESS: null,
    SET_DISPATCHES_FAILURE: null,

    AUTHORISE_DISPATCHES_REQUEST: null,
    AUTHORISE_DISPATCHES_SUCCESS: null,
    AUTHORISE_DISPATCHES_FAILURE: null,
});

export const {
    setDispatchesRequest,
    setDispatchesSuccess,
    setDispatchesFailure,
} = createActions(
    constants.SET_DISPATCHES_REQUEST,
    constants.SET_DISPATCHES_SUCCESS,
    constants.SET_DISPATCHES_FAILURE,
);

export const {
    authoriseDispatchesRequest,
    authoriseDispatchesSuccess,
    authoriseDispatchesFailure,
} = createActions(
    constants.AUTHORISE_DISPATCHES_REQUEST,
    constants.AUTHORISE_DISPATCHES_SUCCESS,
    constants.AUTHORISE_DISPATCHES_FAILURE,
);
