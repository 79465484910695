import { put, call, takeEvery } from 'redux-saga/effects';
import alertify from 'alertify.js';
import { isUndefined } from 'underscore';
import { notification } from 'antd';
import { fetchApiAuth, formatParams } from '../utils/api';
import { addKeyProp, mapPropsToInts } from '../utils/helpers';

import {
    getDoctorsSuccess,
    getDoctorsFailure,
} from './doctorActions';

const {
    GET_DOCTORS_REQUEST,
} = require('./doctorActions').constants;

function* getDoctors({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/doctor/search',
            params: formatParams(payload)
        });

        let data = !isUndefined(response.data) ? response.data : [];
        if (data) {
            data = mapPropsToInts(data, ['page', 'total']);
            data.rows = addKeyProp(data.rows);
        }

        yield put(getDoctorsSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getDoctorsFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* doctorSaga() {
    yield* [
        takeEvery(GET_DOCTORS_REQUEST, getDoctors),
    ];
}
