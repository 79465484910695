import React, { Component, } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Input, Button, Empty, Spin } from 'antd';
import Select from 'react-select';
import { isEqual, isEmpty, isArray } from 'underscore';
import moment from 'moment';
import { browserHistory } from 'react-router';

import styles from './styles';

import { generateOrderItems, deliveryStatusOptions, periodOptions } from './dataGenerators';
import { checkDate, debounce } from '../../../core/utils/helpers';
import values from '../../../core/utils/values';

import * as customerActions from '../../../core/customer/customerActions';

class OrderItemQuickfind extends Component {
    constructor(props) {
        super(props);
        const { orderItemSearchResults } = this.props;

        this.state = {
            filters: {},
            orderItemSearchResults,
            rowsToRender: 20,
        };

        this.gridView = React.createRef();
    }

    componentDidMount() {
        this.handleSearchRequest();
    }

    componentDidUpdate(prevProps, prevState) {
        const { customer, orderItemSearchResults } = this.props;

        // Update the state with new redux state values
        if (!isEqual(customer, prevProps.customer)) {
            this.handleSearchRequest();
            this.setState({ filters: {}, selectedItems: [], });
        }

        if (!isEqual(orderItemSearchResults, prevProps.orderItemSearchResults)) {
            this.setState({ orderItemSearchResults });
        }
    }

    onScroll() {
        // If scrolled to the bottom.
        if (this.gridView.current) {
            const { scrollTop, scrollHeight, clientHeight } = this.gridView.current;
            if (Math.abs((scrollTop + clientHeight) - scrollHeight) < 5) {
                this.setState({ rowsToRender: this.state.rowsToRender + 20 });
            }
        }
    }

    handleSearchRequest() {
        const { customer, actions } = this.props;
        const { filters, } = this.state;
        const filtersBuffer = JSON.parse(JSON.stringify(filters));

        if (filters.order_id) filtersBuffer.product_title = filtersBuffer.order_id;
        if (filters.delivery_status && ['object'].includes(typeof filters.delivery_status)) filtersBuffer.delivery_status = filtersBuffer.delivery_status.value;
        if (filters.period && ['object'].includes(typeof filters.period)) filtersBuffer.from_date = filtersBuffer.period.value ? checkDate(moment().subtract(filtersBuffer.period.value, 'weeks').format('DD/MM/YYYY'), 'output', 'YYYY-MM-DD hh:mm:ss') : null;
        if (this.props.params) filtersBuffer.prescription_id = this.props.params.prescriptionId;

        actions.searchCustomerOrderItemsByFiltersRequest({ id: customer.id, filters: filtersBuffer, limit: 100000, });
    }

    handleClear() {
        const { filters, } = this.state;
        const filtersBuffer = JSON.parse(JSON.stringify(filters));

        Object.keys(filtersBuffer).forEach((key) => {
            filtersBuffer[key] = null;
        });

        this.setState({ filters: filtersBuffer }, () => {
            this.handleSearchRequest();
        });
    }

    handleSetDeliveryStatus({ option, orderItemId, }) {
        const { orderItemSearchResults } = this.state;
        const { actions } = this.props;
        const orderItemSearchResultsBuffer = JSON.parse(JSON.stringify(orderItemSearchResults));
        const index = orderItemSearchResultsBuffer.findIndex(orderItemSearchResult => orderItemSearchResult.id === orderItemId);

        orderItemSearchResultsBuffer[index].delivery_status = option.value;

        // Send request to change item
        actions.updateOrderItemRequest({
            ...orderItemSearchResultsBuffer[index],
        });
        actions.updateOrderItemSearchResultsOfflineRequest(orderItemSearchResultsBuffer);
    }

    handleMatch({ orderItemId, }) {
        const { orderItemSearchResults } = this.state;
        const { actions } = this.props;
        const orderItemSearchResultsBuffer = JSON.parse(JSON.stringify(orderItemSearchResults));
        const index = orderItemSearchResultsBuffer.findIndex(orderItemSearchResult => orderItemSearchResult.id === parseInt(orderItemId, 10));

        if (orderItemSearchResultsBuffer[index].prescription_id) {
            orderItemSearchResultsBuffer[index].prescription_id = null;
            orderItemSearchResultsBuffer[index].quantity_matched = 0;
            orderItemSearchResultsBuffer[index].script_status = null;
        } else {
            orderItemSearchResultsBuffer[index].prescription_id = this.props.params.prescriptionId;
            orderItemSearchResultsBuffer[index].quantity_matched = orderItemSearchResultsBuffer[index].quantity;
            orderItemSearchResultsBuffer[index].script_status = 0;
        }

        // Send request to change item
        actions.updateOrderItemRequest({
            ...orderItemSearchResultsBuffer[index],
        });
        actions.updateOrderItemSearchResultsOfflineRequest(orderItemSearchResultsBuffer);
    }

    handleSetSearchInput({ event }) {
        const { filters } = this.state;

        debounce(this.setState({ filters: { ...filters, order_id: event.target.value, } }, () => {
            this.handleSearchRequest();
        }).bind(this), 50);
    }

    _renderFilterBar() {
        const { filters } = this.state;

        return (
            <div style={styles.filterBarContainer}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}>
                    <p style={styles.filterBarTitle}>Order Item Quickfind</p>
                    <div style={styles.filterGroup}>
                        <p
                            style={styles.filterLabel}>Order Id / Product Name:
                        </p>
                        <Input
                            value={filters.order_id}
                            onChange={event => this.handleSetSearchInput({ event })}
                            style={{ width: 240, height: 24, }} />
                    </div>
                    <div style={styles.filterGroup}>
                        <p
                            style={styles.filterLabel}>Status:
                        </p>
                        <Select
                            styles={{
                                ...styles.selectInput,
                                valueContainer: (provided, state) => ({
                                    ...provided,
                                    height: '25px',
                                    padding: '0px 8px',
                                    minWidth: '100px',
                                }),
                                control: (provided, state) => ({
                                    ...provided,
                                    background: '#fff',
                                    borderColor: '#9e9e9e',
                                    minHeight: undefined,
                                    height: '25px',
                                    boxShadow: state.isFocused ? null : null,
                                    '&:hover': {
                                        borderColor: '#149566',
                                        color: '#149566'
                                    },
                                }),
                                container: (provided, state) => ({
                                    ...provided,
                                }),
                            }}
                            value={filters.delivery_status}
                            onChange={(option) => {
                                this.setState({ filters: { ...filters, delivery_status: option, } }, this.handleSearchRequest);
                            }}
                            isSearchable={false}
                            options={
                                deliveryStatusOptions().map(option => ({
                                    label: option.label,
                                    value: option.value,
                                }))
                            } />
                    </div>
                    <div style={styles.filterGroup}>
                        <p
                            style={styles.filterLabel}>Period:
                        </p>
                        <Select
                            styles={{
                                ...styles.selectInput,
                                valueContainer: (provided, state) => ({
                                    ...provided,
                                    height: '25px',
                                    padding: '0px 8px',
                                    minWidth: '100px',
                                }),
                                control: (provided, state) => ({
                                    ...provided,
                                    background: '#fff',
                                    borderColor: '#9e9e9e',
                                    minHeight: undefined,
                                    height: '25px',
                                    boxShadow: state.isFocused ? null : null,
                                    '&:hover': {
                                        borderColor: '#149566',
                                        color: '#149566'
                                    }
                                }),
                            }}
                            value={filters.period}
                            onChange={(option) => {
                                this.setState({ filters: { ...filters, period: option, } }, this.handleSearchRequest);
                            }}
                            isSearchable={false}
                            options={
                                periodOptions().map(option => ({
                                    label: option.label,
                                    value: option.value,
                                }))
                            } />
                    </div>
                    <a onClick={() => this.handleClear()}>clear</a>
                </div>
            </div>
        );
    }

    _renderTableHeaderElements({ field }) {
        switch (field.fieldName) {
        case 'order_item_id':
            return null;
        default:
            return (
                <th
                    key={field.fieldName}
                    style={styles.tableHeaderElement}>
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                        {field.fieldLabel.toUpperCase()}
                    </div>
                </th>
            );
        }
    }

    transpose(a) {
        return Object.keys(a[0]).map(c => a.map(r => r[c]));
    }

    _renderTableBodyRowElement({ column, fieldName, orderItemId, }) {
        const { customer, orderItemSearchResults } = this.props;

        switch (fieldName) {
        case 'order_item_id':
            return null;
        case 'order_id': {
            const orderItem = orderItemSearchResults.find(value => value.id === orderItemId);
            return (
                <td
                    key={`${orderItemId}_${fieldName}`}
                    style={{ ...styles.tableBodyElement, fontWeight: 'bold', textDecoration: (orderItem && orderItem.prescription_id) ? 'line-through' : 'undefined', }}>
                    <a onClick={() => browserHistory.push(`/order/${column}/customer/${customer.id}`)}>{column}</a>
                </td>
            );
        }
        case 'delivery_status':
            const deliveryStatusValue = deliveryStatusOptions().filter(status => status.value !== 3).find(option => option.value === column);
            return (
                <td
                    key={`${orderItemId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <Select
                        value={deliveryStatusValue}
                        styles={{
                            ...styles.selectInput,
                            valueContainer: (provided, state) => ({
                                ...provided,
                                height: '25px',
                                padding: '0px 8px',
                            }),
                            control: (provided, state) => ({
                                ...provided,
                                background: '#fff',
                                borderColor: '#9e9e9e',
                                minHeight: undefined,
                                height: '25px',
                                boxShadow: state.isFocused ? null : null,
                                '&:hover': {
                                    borderColor: '#149566',
                                    color: '#149566'
                                },
                            }),
                            container: (provided, state) => ({
                                ...provided,
                                width: '150px',
                            }),
                        }}
                        onChange={option => this.handleSetDeliveryStatus({ option, orderItemId, })}
                        isSearchable={false}
                        options={
                            deliveryStatusOptions().filter(status => status.value !== 3).map(option => ({
                                label: option.label,
                                value: option.value,
                            }))
                        } />
                </td>
            );
        case 'match':
            return (
                <td
                    key={`${orderItemId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <Button
                        onClick={() => this.handleMatch({ orderItemId, })}
                        style={styles.matchButton}>
                        {column ? 'Unmatch' : 'Match'}
                    </Button>
                </td>
            );
        default:
            return (
                <td
                    key={`${orderItemId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    {column}
                </td>
            );
        }
    }

    _renderTableBody(data) {
        const matrix = [];
        const fieldNames = [];
        data.map((element) => {
            matrix.push(element.fieldValues);
            fieldNames.push(element.fieldName);
        });
        const transpose = this.transpose(matrix);

        return (
            <tbody style={styles.tableBody}>
                {transpose.map((row, rowIndex) => (
                    <tr
                        key={rowIndex}
                        style={styles.tableBodyRow}>
                        {row.map((column, columnIndex) => this._renderTableBodyRowElement({
                            column,
                            fieldName: fieldNames[columnIndex],
                            orderItemId: row[0],
                        }))}
                    </tr>))}
                <tr style={{ height: 200, }} />
            </tbody>
        );
    }

    _renderTable() {
        const { orderItemSearchResults, rowsToRender } = this.state;
        const { params } = this.props;
        const orderItemSearchResultsBuffer = orderItemSearchResults;
        const data = isArray(orderItemSearchResultsBuffer) && !isEmpty(orderItemSearchResultsBuffer)
            ? generateOrderItems({
                orderItemSearchResults: orderItemSearchResultsBuffer.filter(i => (+i.script_status !== +values.ORDER_ITEM_STATUS_ITEM_CANCELLED)).slice(0, rowsToRender),
                prescriptionId: +params.prescriptionId,
            }) : [];
        const shouldRenderTableContent = data && isArray(data) && data[0] && isArray(data[0].fieldValues) && !isEmpty(data[0].fieldValues);

        return (
            <table
                style={styles.table}>
                {shouldRenderTableContent ?
                    <React.Fragment>
                        <thead style={styles.tableHeader}>
                            <tr style={styles.tableHeaderRow}>
                                {data.map(field => (
                                    this._renderTableHeaderElements({ field })
                                ))}
                            </tr>
                        </thead>
                        {this._renderTableBody(data)}
                    </React.Fragment>
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No order items'} />
                }
            </table>
        );
    }

    render() {
        const { height, isUpdatingOrderItem, isSearchingCustomerOrderItemsByFilters } = this.props;

        let gridviewContainerStyles = styles.gridviewContainer;
        if (height || height === 0) {
            gridviewContainerStyles = { ...gridviewContainerStyles, height, minHeight: height };
        }

        return (
            <div
                ref={this.gridView}
                onScroll={() => this.onScroll()}
                style={gridviewContainerStyles}
                className="prescription_matcher_order_item_quickfind">
                <Spin
                    spinning={
                        isUpdatingOrderItem ||
                        isSearchingCustomerOrderItemsByFilters}>
                    {this._renderFilterBar()}
                    {this._renderTable()}
                </Spin>
            </div>
        );
    }
}

OrderItemQuickfind.defaultProps = {
    customer: {},
    actions: {},
    orderItemSearchResults: [],
    height: null,
    isUpdatingOrderItem: false,
    isSearchingCustomerOrderItemsByFilters: false,
};

OrderItemQuickfind.propTypes = {
    customer: PropTypes.object,
    actions: PropTypes.object,
    height: PropTypes.number,
    orderItemSearchResults: PropTypes.array,
    isUpdatingOrderItem: PropTypes.bool,
    isSearchingCustomerOrderItemsByFilters: PropTypes.bool,
    params: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        orderItemSearchResults: state.customer.orderItemSearchResults,
        customer: state.customer.customer,
        isUpdatingOrderItem: state.customer.isUpdatingOrderItem,
        isSearchingCustomerOrderItemsByFilters: state.customer.isSearchingCustomerOrderItemsByFilters,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderItemQuickfind);
