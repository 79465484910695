import React from 'react';
import { Tabs } from 'antd';

import Layout from '../../components/layout/Layout';
import OrdersReady from '../../components/gridviews/OrdersReady';
import OrdersSearch from '../../components/gridviews/OrdersSearch';
import OrdersWaiting from '../../components/gridviews/OrdersWaiting';
import OrdersBackorder from '../../components/gridviews/OrdersBackorder/index';

const TabPane = Tabs.TabPane;

export default function () {
    return (
        <Layout title={this.state.title} toolbar={this.state.toolbar} breadcrumb="orders">
            <Tabs defaultActiveKey="1" className="ant-tabs--styled" onChange={this.handleTabChange}>
                <TabPane tab="Waiting on Script" key="1">
                    {/* Orders waiting gridview */}
                    <OrdersWaiting />
                </TabPane>

                <TabPane tab="Ready to authorise dispatch" key="2">
                    {/* Orders ready gridview */}
                    <OrdersReady />
                </TabPane>

                <TabPane tab="Back Order" key="3">
                    {/* Orders ready gridview */}
                    <OrdersBackorder />
                </TabPane>

                <TabPane tab="Search" key="4">
                    {/* Orders search gridview */}
                    <OrdersSearch />
                </TabPane>
            </Tabs>
        </Layout>
    );
}
