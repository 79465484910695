import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import * as authActions from '../../../core/auth/authActions';

import DOM from './forgotPassword';

class ForgotPasswordForm extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    // Handle submit forgotten password
    handleForgotPasswordSubmit = (values) => {
        const { actions, closeModal, reset } = this.props;
        actions.passwordResetRequest(values);
        closeModal();
        reset();
    };

    render() {
        return this.view();
    }
}

ForgotPasswordForm.defaultProps = {
    closeModal: () => {},
};

ForgotPasswordForm.propTypes = {
    actions: PropTypes.object.isRequired,
    reset: PropTypes.func.isRequired,
    closeModal: PropTypes.func,
};

const ForgotPassword = reduxForm({
    form: 'forgot-password',
    enableReinitialize: true,
})(ForgotPasswordForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...authActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
