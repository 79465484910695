import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_NURSES_REQUEST: null,
    GET_NURSES_SUCCESS: null,
    GET_NURSES_FAILURE: null,

    GET_NURSE_REQUEST: null,
    GET_NURSE_SUCCESS: null,
    GET_NURSE_FAILURE: null,

    UPDATE_NURSE_REQUEST: null,
    UPDATE_NURSE_SUCCESS: null,
    UPDATE_NURSE_FAILURE: null,

    GET_NURSE_CAREHOME_REQUEST: null,
    GET_NURSE_CAREHOME_SUCCESS: null,
    GET_NURSE_CAREHOME_FAILURE: null,

    ADD_NURSE_CAREHOME_REQUEST: null,
    ADD_NURSE_CAREHOME_SUCCESS: null,
    ADD_NURSE_CAREHOME_FAILURE: null,

    REMOVE_NURSE_CAREHOME_REQUEST: null,
    REMOVE_NURSE_CAREHOME_SUCCESS: null,
    REMOVE_NURSE_CAREHOME_FAILURE: null,

    GET_NURSE_CUSTOMER_REQUEST: null,
    GET_NURSE_CUSTOMER_SUCCESS: null,
    GET_NURSE_CUSTOMER_FAILURE: null,

    ADD_NURSE_CUSTOMER_REQUEST: null,
    ADD_NURSE_CUSTOMER_SUCCESS: null,
    ADD_NURSE_CUSTOMER_FAILURE: null,

    RESET_NURSES: null,
});

export const {
    getNursesRequest,
    getNursesSuccess,
    getNursesFailure
} = createActions(
    constants.GET_NURSES_REQUEST,
    constants.GET_NURSES_SUCCESS,
    constants.GET_NURSES_FAILURE
);

export const {
    getNurseRequest,
    getNurseSuccess,
    getNurseFailure
} = createActions(
    constants.GET_NURSE_REQUEST,
    constants.GET_NURSE_SUCCESS,
    constants.GET_NURSE_FAILURE
);

export const {
    updateNurseRequest,
    updateNurseSuccess,
    updateNurseFailure
} = createActions(
    constants.UPDATE_NURSE_REQUEST,
    constants.UPDATE_NURSE_SUCCESS,
    constants.UPDATE_NURSE_FAILURE
);

export const {
    getNurseCarehomeRequest,
    getNurseCarehomeSuccess,
    getNurseCarehomeFailure
} = createActions(
    constants.GET_NURSE_CAREHOME_REQUEST,
    constants.GET_NURSE_CAREHOME_SUCCESS,
    constants.GET_NURSE_CAREHOME_FAILURE
);

export const {
    addNurseCarehomeRequest,
    addNurseCarehomeSuccess,
    addNurseCarehomeFailure
} = createActions(
    constants.ADD_NURSE_CAREHOME_REQUEST,
    constants.ADD_NURSE_CAREHOME_SUCCESS,
    constants.ADD_NURSE_CAREHOME_FAILURE
);

export const {
    removeNurseCarehomeRequest,
    removeNurseCarehomeSuccess,
    removeNurseCarehomeFailure
} = createActions(
    constants.REMOVE_NURSE_CAREHOME_REQUEST,
    constants.REMOVE_NURSE_CAREHOME_SUCCESS,
    constants.REMOVE_NURSE_CAREHOME_FAILURE
);

export const {
    getNurseCustomerRequest,
    getNurseCustomerSuccess,
    getNurseCustomerFailure
} = createActions(
    constants.GET_NURSE_CUSTOMER_REQUEST,
    constants.GET_NURSE_CUSTOMER_SUCCESS,
    constants.GET_NURSE_CUSTOMER_FAILURE
);

export const {
    addNurseCustomerRequest,
    addNurseCustomerSuccess,
    addNurseCustomerFailure
} = createActions(
    constants.ADD_NURSE_CUSTOMER_REQUEST,
    constants.ADD_NURSE_CUSTOMER_SUCCESS,
    constants.ADD_NURSE_CUSTOMER_FAILURE
);

export const {
    resetNurses,
} = createActions(
    constants.RESET_NURSES,
);
