import { put, call, takeEvery } from 'redux-saga/effects';
import alertify from 'alertify.js';
import { isEmpty, isUndefined, mapObject } from 'underscore';
import { notification } from 'antd';
import { saveAs } from 'file-saver';
import { fetchApiAuth } from '../utils/api';

import { getOrdersBackorderRequest } from '../order/orderActions';
import {
    setBackorderChaserSuccess,
    setBackorderChaserFailure,

    runBackorderChaserSuccess,
    runBackorderChaserFailure,

} from './backorderChaserActions';

const {
    SET_BACKORDER_CHASER_REQUEST,
    RUN_BACKORDER_CHASER_REQUEST,
} = require('./backorderChaserActions').constants;

const search = {
    page: 1,
    page_size: 10
};

const makeChaserData = (payload) => {
    const data = {};

    mapObject(payload, (orderIds, type) => {
        orderIds.forEach((orderId) => {
            if (isUndefined(data[orderId])) {
                data[orderId] = [type];
            } else {
                data[orderId].push(type);
            }
        });
    });

    return data;
};


function* setBackorderChaser({ payload }) {
    try {
        yield put(setBackorderChaserSuccess(payload));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(setBackorderChaserFailure(e.response ? e.response.data.message : e));
    }
}

function* runBackorder({ payload }) {
    try {
        const orders = makeChaserData(payload);
        if (!isEmpty(orders)) {
            const response = yield call(fetchApiAuth, {
                method: 'POST',
                url: '/order/run-back-order',
                body: { orders },
                responseType: 'blob'
            });
            if (response && response.headers && response.headers['content-type'] == 'application/zip') {
                const blob = new Blob([response.data], { type: 'application/zip' });
                saveAs(blob, 'backorder-chasers.zip');
            }
        }
        notification.success({ message: 'Success', description: 'Picking/Despatch notes successfully downloaded' });
        yield put(getOrdersBackorderRequest(search));
        yield put(runBackorderChaserSuccess());
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(runBackorderChaserFailure(e.response ? e.response.data.message : e));
    }
}


/**
 * Watch actions
 */
export default function* backorderChaserSaga() {
    yield* [
        takeEvery(SET_BACKORDER_CHASER_REQUEST, setBackorderChaser),
        takeEvery(RUN_BACKORDER_CHASER_REQUEST, runBackorder),
    ];
}
