import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'antd';

import Loading from '../../../../elements/Loading';
import Form from '../../../../elements/Form';
import { isRequired } from '../../../../core/utils/validators';
import { mapToOptions, displayName } from '../../../../core/utils/helpers';

const { GENDER_OPTIONS } = require('../../../../core/utils/options').default;

const formTypes = Form.formTypes;
const FormGroup = Form.Group;

const CustomerDetails = ({ loading, nurses, referrers }) => {
    const nurseOptions = mapToOptions(nurses, 'id', displayName);
    const referrerOptions = mapToOptions(referrers, 'id', displayName);

    const formConfig = [
        // { type: formTypes.TEXT, name: 'nhs_no', label: 'NHS Number', validate: [isRequired, value => charLessThan(value, 20)] },
        { type: formTypes.TEXT, name: 'reference', label: 'Reference', disabled: true, placeholder: '<< Auto Generated >>' },
        { type: formTypes.TEXT, name: 'nhs_no', label: 'NHS Number' },
        { type: formTypes.TEXT, name: 'first_name', label: 'Forenames', validate: [isRequired] },
        { type: formTypes.TEXT, name: 'last_name', label: 'Surname', validate: [isRequired] },
        { type: formTypes.RADIO, name: 'gender', label: 'Gender', options: GENDER_OPTIONS, validate: [isRequired] },
        { type: formTypes.DATE, name: 'dob', label: 'DOB', validate: [isRequired] },
        { type: formTypes.SELECT, name: 'nurse_id', label: 'Nurse', options: nurseOptions },
        { type: formTypes.SELECT, name: 'referrer_id', label: 'Referrer', options: referrerOptions },
    ];

    const notesConfig = [
        { type: formTypes.TEXTAREA, name: 'admin_notes', label: 'Admin Notes' },
    ];

    return (
        <div className="section">
            <div className="container">
                <div className="section__container">
                    <div className="section__header">
                        <h3 className="h-4">Customer Details</h3>
                    </div>
                    {loading ?
                        <Loading className="section-white" />
                        :
                        <div className="section-white">
                            <Row gutter={48} type="flex" justify="center">
                                <Col md={12} lg={9}>
                                    <FormGroup config={formConfig} />
                                </Col>
                                <Col md={12} lg={15}>
                                    <FormGroup config={notesConfig} />
                                </Col>
                            </Row>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

CustomerDetails.defaultProps = {
    loading: false,
};

CustomerDetails.propTypes = {
    nurses: PropTypes.array.isRequired,
    referrers: PropTypes.array.isRequired,
    loading: PropTypes.bool
};

export default CustomerDetails;
