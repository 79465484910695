import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import moment from 'moment';

import * as productActions from '../../../core/product/productActions';

import DOM from './uploadProductStock';

class UpdateProductForm extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            fileName: '',
            fileList: [],
        };
    }

    // Get sample download
    handleSampleDownload = () => {
        const { actions } = this.props;
        actions.getProductStockRequest();
    }

    // Handle before upload
    handleBeforeUpload = (file) => {
        const fileName = file.name.split('.');
        const extension = fileName[fileName.length - 1];

        // Check file is csv or xlsx
        if (!['csv', 'xlsx'].includes(extension)) {
            return false;
        }

        // Save file name to save as
        this.setState({ fileName: `stocks_${moment().format('YYYY-MM-DD_HH:mm:ss')}.${extension}` });
        return true;
    }

    // Handle upload file change - only shows one
    handleUploadChange = (e) => {
        const listLength = e.fileList.length - 1;
        this.setState({ fileList: [e.fileList[listLength]] });
    }

    // Handle submit form
    handleUpdateStockSubmit = () => {
        const { actions, changeVisible } = this.props;
        actions.updateProductStockRequest({ filename: this.state.fileName });
        changeVisible(false);
    };

    render() {
        return this.view();
    }
}

UpdateProductForm.propTypes = {
    actions: PropTypes.object.isRequired,
    changeVisible: PropTypes.func.isRequired,
};

const UpdateProduct = reduxForm({
    form: 'update-product-stock',
    enableReinitialize: true,
})(UpdateProductForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.product.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...productActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProduct);
