import React, { useState, useRef } from 'react';
import SplitPane from 'react-split-pane';
import styled from 'styled-components';

import SideBar from './SideBar';
import GridviewSection from './GridviewSection';
import useWindowDimensions from '../../../../../core/utils/useWindowDimensions';
import CustomerNotes from '../customerNotes';
import Pane from 'react-split-pane/lib/Pane';

const RENDER_DELAY = 10;

const Wrapper = styled.div`
  .Resizer {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }

  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }

  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }

  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }

  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Pane1 {
  }
  .Pane2 {
  }
`;

const CustomerSummary = ({ loading, activeTab, params, customerNotes }) => {
    const lastChange = useRef(new Date());
    const onChangeTimeout = useRef(null);
    const size2 = useRef(1000);
    const { height, width } = useWindowDimensions();
    const heightOfGridviewSection = height - 154;
    const [size, setSize] = useState(heightOfGridviewSection / 2);

    function handleOnChange(size) {
        size2.current = size;
        const now = new Date();
        const diffTime = Math.abs(now - lastChange.current);
        if (diffTime > RENDER_DELAY) {
            setSize(size);
        } else {
            lastChange.current = now;
        }
        onChangeTimeout.current = setTimeout(setSize(size2.current), RENDER_DELAY);
    }

    const mainContainerStyle = {
        height: heightOfGridviewSection,
        display: 'flex',
    };

    return (
        <Wrapper>
            <div style={mainContainerStyle}>
                <SplitPane split="vertical">
                    <Pane initialSize="400px" >
                        <SideBar />
                    </Pane>
                    <SplitPane onChange={size => handleOnChange(size)} split="horizontal">
                        <Pane>
                            <GridviewSection
                                type={'order'}
                                activeTab={activeTab}
                                params={params} />
                        </Pane>
                        <Pane>
                            <GridviewSection
                                type={'item'}
                                params={params} />
                        </Pane>
                        <Pane>
                            <CustomerNotes
                                params={params} />
                        </Pane>
                    </SplitPane>
                </SplitPane>

            </div>
        </Wrapper>
    );
};

export default CustomerSummary;
