import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import DOM from './practice';

class PracticePage extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            modalAddDoctorVisible: false,
            name: parseInt(props.params.id, 10) ? `#${props.params.id}` : 'New Practice',
            toolbar: this.getToolbar(parseInt(props.params.id, 10))
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ toolbar: this.getToolbar(parseInt(nextProps.params.id, 10)) });
    }

    // Generate toolbar based on id
    getToolbar = (id) => {
        const toolbar = [];
        if (id) {
            toolbar.push({ id: 'actionNewOrder', caption: 'Add doctor', icon: 'new-order', onClick: () => { this.setModalAddDoctorVisible(true); } });
        }
        toolbar.push({ id: 'actionSave', caption: 'Save', icon: 'save', onClick: this.handleFormSubmit });

        return toolbar;
    }

    // Handle set page name
    setPageName = name => this.setState({ name });

    // Handle toggle add doctor modal visibility
    setModalAddDoctorVisible = modalAddDoctorVisible => this.setState({ modalAddDoctorVisible });

    // Handle submit update practice form
    handleFormSubmit = () => this.props.actions.submit('update-practice');

    render() {
        return this.view();
    }
}

PracticePage.propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ submit }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PracticePage);
