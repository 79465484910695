import { Component } from 'react';

import FormSection from './FormSection';
import FormGroup from './FormGroup';
import FormItem, * as formTypes from './FormItem';

class Form extends Component {
    render() {
        return false;
    }
}

Form.Section = FormSection;
Form.Group = FormGroup;
Form.Item = FormItem;
Form.formTypes = formTypes;

export default Form;
