import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'antd';

import FormItem from './FormItem';

/**
 * Generates a form group with redux form, container has no styling or title
 * @param {array} config            - Array of form config, each row corresponds to a field, options include any normal input props
 * @param {number|object} itemSpan  - Number or object of span of individual form items, see antd docs for Row
 * @param {bool|object} labelCol    - Span of input label, see antd docs for Col
 * @param {string} layout           - Layout type of form 'horizontal', 'vertical', 'inline'
 * @param {bool|object} wrapperCol  - Span of input, see antd docs for Col
 */
const FormGroup = ({ config, itemSpan, labelCol, layout, wrapperCol }) => {
    // Map over config and generate form items
    const formItems = config.map((item) => {
        // Make form item
        const formItem = (
            <FormItem
                {...item}
                labelCol={labelCol || {}}
                wrapperCol={wrapperCol || {}} />
        );

        // If horizontal layout add styling
        if (layout === 'horizontal') {
            return <Col span={itemSpan} key={item.name} style={{ paddingLeft: 0, paddingRight: 15 }}>{formItem}</Col>;
        }

        return <div key={item.name} className={labelCol ? 'item-label-row' : ''}>{formItem}</div>;
    });

    return <div>{formItems}</div>;
};

FormGroup.defaultProps = {
    itemSpan: 12,
    labelCol: false,
    layout: 'vertical',
    wrapperCol: false,
};

FormGroup.propTypes = {
    config: PropTypes.array.isRequired,
    itemSpan: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    labelCol: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    layout: PropTypes.string,
    wrapperCol: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default FormGroup;
