import { browserHistory } from 'react-router';
import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { isUndefined } from 'underscore';
import { notification } from 'antd';
import { fetchApiAuth, formatParams } from '../utils/api';
import { addKeyProp, mapPropsToInts, mapPropsToDbDates, mapPropsBoolToInts } from '../utils/helpers';

import {
    getCustomersSuccess,
    getCustomersFailure,

    getCustomerSuccess,
    getCustomerFailure,

    getCustomerByReferenceSuccess,
    getCustomerByReferenceFailure,

    updateCustomerSuccess,
    updateCustomerFailure,

    getCustomerDoctorSuccess,
    getCustomerDoctorFailure,

    updateCustomerDoctorSuccess,
    updateCustomerDoctorFailure,

    removeCustomerDoctorSuccess,
    removeCustomerDoctorFailure,

    getCustomerPracticeSuccess,
    getCustomerPracticeFailure,

    updateCustomerPracticeSuccess,
    updateCustomerPracticeFailure,

    removeCustomerPracticeSuccess,
    removeCustomerPracticeFailure,

    getCustomerOrdersSuccess,
    getCustomerOrdersFailure,

    getCustomerProductsSuccess,
    getCustomerProductsFailure,

    getCustomerAutoOrdersSuccess,
    getCustomerAutoOrdersFailure,

    updateCustomerAutoOrderSuccess,
    updateCustomerAutoOrderFailure,

    submitSummaryFormSuccess,
    submitSummaryFormFailure,

    searchCustomersByAttributesSuccess,
    searchCustomersByAttributesFailure,

    createupdateCustomerNoteSuccess,
    createupdateCustomerNoteFailure,

    getCustomerNotesRequest,
    getCustomerNotesSuccess,
    getCustomerNotesFailure,

    // -------------------- Order -------------------- //
    searchCustomerOrdersByFiltersSuccess,
    searchCustomerOrdersByFiltersFailure,

    updateCustomerOrderSuccess,
    updateCustomerOrderFailure,

    sendPickingAndDispatchLetterSuccess,
    sendPickingAndDispatchLetterFailure,

    // -------------------- Order Item -------------------- //
    updateOrderItemSuccess,
    updateOrderItemFailure,

    searchCustomerOrderItemsByFiltersSuccess,
    searchCustomerOrderItemsByFiltersFailure,

    // -------------------- Order Item Repeat Instruction -------------------- //
    searchOrderItemRepeatInstructionsByFiltersSuccess,
    searchOrderItemRepeatInstructionsByFiltersFailure,

    updateOrderItemRepeatInstructionSuccess,
    updateOrderItemRepeatInstructionFailure,

    deleteOrderItemRepeatInstructionSuccess,
    deleteOrderItemRepeatInstructionFailure,

    // -------------------- Prescription -------------------- //
    getPrescriptionSuccess,
    getPrescriptionFailure,

    searchPrescriptionsByFiltersSuccess,
    searchPrescriptionsByFiltersFailure,

    uploadPrescriptionSuccess,
    uploadPrescriptionFailure,

    updatePrescriptionSuccess,
    updatePrescriptionFailure,

    createupdatePrescriptionItemSuccess,
    createupdatePrescriptionItemFailure,

    deletePrescriptionItemSuccess,
    deletePrescriptionItemFailure,

    // -------------------- Letter -------------------- //
    createLetterSuccess,
    createLetterFailure,

    updateLetterSuccess,
    updateLetterFailure,

    upsertLettersSuccess,
    upsertLettersFailure,

    // -------------------- Scripts -------------------- //
    processScriptSuccess,
    processScriptFailure,

} from './customerActions';

import { updateMissingPrescriptionItemsOfflineRequest } from '../order/orderActions';

const {
    GET_CUSTOMERS_REQUEST,
    GET_CUSTOMER_REQUEST,
    GET_CUSTOMER_BY_REFERENCE_REQUEST,
    UPDATE_CUSTOMER_REQUEST,
    GET_CUSTOMER_DOCTOR_REQUEST,
    UPDATE_CUSTOMER_DOCTOR_REQUEST,
    REMOVE_CUSTOMER_DOCTOR_REQUEST,
    GET_CUSTOMER_PRACTICE_REQUEST,
    UPDATE_CUSTOMER_PRACTICE_REQUEST,
    REMOVE_CUSTOMER_PRACTICE_REQUEST,
    GET_CUSTOMER_ORDERS_REQUEST,
    GET_CUSTOMER_PRODUCTS_REQUEST,
    GET_CUSTOMER_AUTO_ORDERS_REQUEST,
    UPDATE_CUSTOMER_AUTO_ORDER_REQUEST,
    SUBMIT_SUMMARY_FORM_REQUEST,
    SEARCH_CUSTOMERS_BY_ATTRIBUTES_REQUEST,
    CREATEUPDATE_CUSTOMER_NOTE_REQUEST,
    GET_CUSTOMER_NOTES_REQUEST,
    UPSERT_LETTERS_REQUEST,

    // -------------------- Order -------------------- //
    SEARCH_CUSTOMER_ORDERS_BY_FILTERS_REQUEST,
    UPDATE_CUSTOMER_ORDER_REQUEST,
    SEND_PICKING_AND_DISPATCH_LETTER_REQUEST,

    // -------------------- Order Item -------------------- //
    UPDATE_ORDER_ITEM_REQUEST,
    SEARCH_CUSTOMER_ORDER_ITEMS_BY_FILTERS_REQUEST,

    // -------------------- Order Item Repeat Instruction -------------------- //
    SEARCH_ORDER_ITEM_REPEAT_INSTRUCTIONS_BY_FILTERS_REQUEST,
    UPDATE_ORDER_ITEM_REPEAT_INSTRUCTION_REQUEST,
    DELETE_ORDER_ITEM_REPEAT_INSTRUCTION_REQUEST,

    // -------------------- Prescription -------------------- //
    SEARCH_PRESCRIPTIONS_BY_FILTERS_REQUEST,
    UPLOAD_PRESCRIPTION_REQUEST,
    UPDATE_PRESCRIPTION_REQUEST,
    GET_PRESCRIPTION_REQUEST,
    CREATEUPDATE_PRESCRIPTION_ITEM_REQUEST,
    DELETE_PRESCRIPTION_ITEM_REQUEST,

    // -------------------- Letter -------------------- //
    CREATE_LETTER_REQUEST,
    UPDATE_LETTER_REQUEST,

    // -------------------- Scripts -------------------- //
    PROCESS_SCRIPT_REQUEST,

} = require('./customerActions').constants;

function* getCustomers({ payload }) {
    try {
        const params = mapPropsToDbDates(formatParams(payload), ['dob']);
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/customer/search',
            params
        });

        let data = !isUndefined(response.data) ? response.data : [];
        if (data) {
            data = mapPropsToInts(data, ['page', 'total']);
            data.rows = addKeyProp(data.rows);
        }

        yield put(getCustomersSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getCustomersFailure(e.response ? e.response.data.message : e));
    }
}

function* getCustomer({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (!isUndefined(params.id) && params.id !== 0) {
            response = yield call(fetchApiAuth, {
                method: 'GET',
                url: '/customer/get',
                params: payload
            });
        }

        const customer = !isUndefined(response) ? response.data : {};
        yield put(getCustomerSuccess(mapPropsToInts(customer, ['referrer_id', 'nurse_id'])));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getCustomerFailure(e.response ? e.response.data.message : e));
    }
}

function* getCustomerByReference({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (!isUndefined(params.reference) && params.reference !== '') {
            response = yield call(fetchApiAuth, {
                method: 'GET',
                url: '/customer/get-by-reference',
                params: payload
            });
        }

        const customer = !isUndefined(response) ? response.data : {};
        yield put(getCustomerByReferenceSuccess(mapPropsToInts(customer, ['referrer_id', 'nurse_id'])));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getCustomerByReferenceFailure(e.response ? e.response.data.message : e));
    }
}

function* updateCustomer({ payload }) {
    try {
        const body = mapPropsBoolToInts(payload, ['is_care_of_address_different', 'deliver_to_care_of_address']);
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/edit',
            body
        });

        const customer = !isUndefined(response.data) ? response.data : {};
        if (customer) {
            browserHistory.push(`/customer/${customer.id}`);
            notification.success({ message: 'Success', duration: 1, description: 'Customer has been updated' });
        }

        yield put(updateCustomerSuccess(customer));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(updateCustomerFailure(e.response ? e.response.data.message : e));
    }
}

function* getCustomerDoctor({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (!isUndefined(params.id) && params.id !== 0) {
            response = yield call(fetchApiAuth, {
                method: 'GET',
                url: '/customer/doctor',
                params: payload
            });
        }

        const doctor = !isUndefined(response) ? response.data : {};
        yield put(getCustomerDoctorSuccess(doctor));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getCustomerDoctorFailure(e.response ? e.response.data.message : e));
    }
}

function* updateCustomerDoctor({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/doctor',
            body: formatParams(payload)
        });

        const doctor = !isUndefined(response.data) ? response.data : {};
        yield put(updateCustomerDoctorSuccess(doctor));
        notification.success({ message: 'Success', duration: 1, description: 'Doctor assigned to customer successfully' });
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(updateCustomerDoctorFailure(e.response ? e.response.data.message : e));
    }
}

function* removeCustomerDoctor({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (params.id !== 0) {
            response = yield call(fetchApiAuth, {
                method: 'DELETE',
                url: '/customer/doctor',
                params
            });
        }

        const doctor = !isUndefined(response) ? response.data : {};
        yield put(removeCustomerDoctorSuccess(doctor));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(removeCustomerDoctorFailure(e.response ? e.response.data.message : e));
    }
}

function* getCustomerPractice({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (!isUndefined(params.id) && params.id !== 0) {
            response = yield call(fetchApiAuth, {
                method: 'GET',
                url: '/customer/practice',
                params: payload
            });
        }

        const practice = !isUndefined(response) ? response.data : {};
        yield put(getCustomerPracticeSuccess(practice));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getCustomerPracticeFailure(e.response ? e.response.data.message : e));
    }
}

function* updateCustomerPractice({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/practice',
            body: payload
        });

        const practice = !isUndefined(response.data) ? response.data : {};
        yield put(updateCustomerPracticeSuccess(practice));
        notification.success({ message: 'Success', duration: 1, description: 'Practice assigned to customer successfully' });
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(updateCustomerPracticeFailure(e.response ? e.response.data.message : e));
    }
}

function* removeCustomerPractice({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (params.id !== 0) {
            response = yield call(fetchApiAuth, {
                method: 'DELETE',
                url: '/customer/practice',
                params
            });
        }

        const practice = !isUndefined(response) ? response.data : {};
        yield put(removeCustomerPracticeSuccess(practice));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(removeCustomerPracticeFailure(e.response ? e.response.data.message : e));
    }
}

function* getCustomerOrders({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/customer/orders',
            params: formatParams(payload),
        });

        let data = !isUndefined(response.data) ? response.data : [];
        if (data) {
            data = mapPropsToInts(data, ['page', 'total']);
            data.rows = addKeyProp(data.rows);
        }

        yield put(getCustomerOrdersSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getCustomerOrdersFailure(e.response ? e.response.data.message : e));
    }
}

function* getCustomerProducts({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/customer/products',
            params: formatParams(payload)
        });

        let data = !isUndefined(response.data) ? response.data : [];
        if (data) {
            data = mapPropsToInts(data, ['page', 'total']);
            data.rows = addKeyProp(data.rows);
        }

        yield put(getCustomerProductsSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getCustomerProductsFailure(e.response ? e.response.data.message : e));
    }
}

function* getCustomerAutoOrders({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (params.id !== 0) {
            response = yield call(fetchApiAuth, {
                method: 'GET',
                url: '/customer/auto-orders',
                params
            });
        }

        let data = !isUndefined(response) ? response.data : [];
        if (data) {
            data = addKeyProp(data);
        }

        yield put(getCustomerAutoOrdersSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getCustomerAutoOrdersFailure(e.response ? e.response.data.message : e));
    }
}

function* updateCustomerAutoOrder({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/auto-orders',
            body: payload
        });

        let data = !isUndefined(response.data) ? response.data : [];
        if (data) {
            data = addKeyProp(data);
        }

        yield put(updateCustomerAutoOrderSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(updateCustomerAutoOrderFailure(e.response ? e.response.data.message : e));
    }
}

function* submitSummaryForm({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/submit-summary-form',
            body: payload,
        });

        yield put(submitSummaryFormSuccess(response));
        notification.success({ message: 'Success', duration: 1, description: 'Successfully submitted the summary form.' });
    } catch (e) {
        yield put(submitSummaryFormFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}


function* searchCustomersByAttributes({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/search-by-attributes',
            body: payload,
        });

        yield put(searchCustomersByAttributesSuccess(response));
        // notification.success({ message: 'Success', duration: 1, description: 'Successfully fetched customers.' });
    } catch (e) {
        yield put(searchCustomersByAttributesFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}


function* createupdateCustomerNote({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'customer/createupdate-customer-note',
            body: payload,
        });

        yield put(createupdateCustomerNoteSuccess(response));
        yield put(getCustomerNotesRequest(payload));
        notification.success({ message: 'Success', duration: 1, description: 'Successfully created/updated the customer note.' });
    } catch (e) {
        yield put(createupdateCustomerNoteFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}

function* getCustomerNotes({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'customer/get-customer-notes',
            body: payload,
        });

        yield put(getCustomerNotesSuccess(response));
        // notification.success({ message: 'Success', duration: 1, description: 'Successfully got customer notes.' });
    } catch (e) {
        yield put(getCustomerNotesFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}

// -------------------- Order -------------------- //
function* searchCustomerOrdersByFilters({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/search-customer-orders-by-filters',
            body: payload,
        });

        yield put(searchCustomerOrdersByFiltersSuccess(response));
        // notification.success({ message: 'Success', duration: 1, description: 'Successfully fetched orders.' });
    } catch (e) {
        yield put(searchCustomerOrdersByFiltersFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}
function* updateCustomerOrder({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/update-order',
            body: payload,
        });

        yield put(updateCustomerOrderSuccess(response));
        notification.success({ message: 'Success', duration: 1, description: 'Successfully updated the order.' });
    } catch (e) {
        yield put(updateCustomerOrderFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}
function* sendPickingAndDispatchLetter({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/send-picking-and-dispatch-letter',
            body: payload,
        });

        yield put(sendPickingAndDispatchLetterSuccess(response));
    } catch (e) {
    //  alertify.error(e.response ? e.response.data.message : e);
        yield put(sendPickingAndDispatchLetterFailure(e.response ? e.response.data.message : e));
    }
}

// -------------------- Order Item -------------------- //
function* updateOrderItem({ payload }) {
    // // Handle date
    // if (payload.dispatch_date && isMoment(payload.dispatch_date)) {
    //     payload.dispatch_date = payload.dispatch_date.format('YYYY-MM-DD');
    // }

    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/update-order-item',
            body: payload,
        });

        yield put(updateOrderItemSuccess(response));
        notification.success({ message: 'Success', duration: 1, description: 'Successfully updated the order item.' });
    } catch (e) {
        yield put(updateOrderItemFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}
function* searchCustomerOrderItemsByFilters({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'customer/search-customer-order-items-by-filters',
            body: payload,
        });

        yield put(searchCustomerOrderItemsByFiltersSuccess(response));
        // notification.success({ message: 'Success', duration: 1, description: 'Successfully fetched order items.' });
    } catch (e) {
        yield put(searchCustomerOrderItemsByFiltersFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}

// -------------------- Order Item Repeat Instruction -------------------- //
function* searchOrderItemRepeatInstructionsByFilters({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/search-order-item-repeat-instructions-by-filters',
            body: payload,
        });

        yield put(searchOrderItemRepeatInstructionsByFiltersSuccess(response));
        // notification.success({ message: 'Success', duration: 1, description: 'Successfully fetched order item repeat instructions.' });
    } catch (e) {
        yield put(searchOrderItemRepeatInstructionsByFiltersFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}
function* updateOrderItemRepeatInstruction({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/update-order-item-repeat-instruction',
            body: payload,
        });

        yield put(updateOrderItemRepeatInstructionSuccess(response));
        notification.success({ message: 'Success', duration: 1, description: 'Successfully updated the order item repeat instruction.' });
    } catch (e) {
        yield put(updateOrderItemRepeatInstructionFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}
function* deleteOrderItemRepeatInstruction({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/delete-order-item-repeat-instruction',
            body: payload,
        });

        yield put(deleteOrderItemRepeatInstructionSuccess(response));
        notification.success({ message: 'Success', duration: 1, description: 'Successfully deleted the order item repeat instruction.' });
    } catch (e) {
        yield put(deleteOrderItemRepeatInstructionFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}

// -------------------- Prescription -------------------- //
function* searchPrescriptionsByFilters({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/search-prescriptions-by-filters',
            body: payload,
        });

        yield put(searchPrescriptionsByFiltersSuccess(response));
        // notification.success({ message: 'Success', duration: 1, description: 'Successfully fetched prescriptions.' });
    } catch (e) {
        yield put(searchPrescriptionsByFiltersFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}
function* uploadPrescription({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/upload-prescription',
            body: payload,
        });

        yield put(uploadPrescriptionSuccess(response));
        notification.success({ message: 'Success', duration: 1, description: 'Successfully uploaded the prescription.' });
    } catch (e) {
        yield put(uploadPrescriptionFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}
function* updatePrescription({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/update-prescription',
            body: payload,
        });

        yield put(updatePrescriptionSuccess(response));
        notification.success({ message: 'Success', duration: 1, description: 'Successfully updated the prescription.' });
    } catch (e) {
        yield put(updatePrescriptionFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}
function* getPrescription({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/get-prescription',
            body: payload,
        });

        yield put(getPrescriptionSuccess(response));
        yield put(updateMissingPrescriptionItemsOfflineRequest(response.data.missingPrescriptionItems));
        // notification.success({ message: 'Success', duration: 1, description: 'Successfully fetched the prescription.' });
    } catch (e) {
        yield put(getPrescriptionFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}
function* createupdatePrescriptionItem({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/createupdate-prescription-item',
            body: payload,
        });

        yield put(createupdatePrescriptionItemSuccess(response));
        notification.success({ message: 'Success', duration: 1, description: 'Successfully created/updated the prescription item.' });
    } catch (e) {
        yield put(createupdatePrescriptionItemFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}
function* deletePrescriptionItem({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/customer/delete-prescription-item',
            body: payload,
        });

        yield put(deletePrescriptionItemSuccess(response));
        notification.success({ message: 'Success', duration: 1, description: 'Successfully deleted the prescription item.' });
    } catch (e) {
        yield put(deletePrescriptionItemFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}

// -------------------- Letter -------------------- //
function* createLetter({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'customer/create-letter',
            body: payload,
        });

        yield put(createLetterSuccess(response));
        notification.success({ message: 'Success', duration: 1, description: 'Successfully created the letter.' });
    } catch (e) {
        yield put(createLetterFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}

function* updateLetter({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'customer/update-letter',
            body: payload,
        });

        yield put(updateLetterSuccess(response));
        notification.success({ message: 'Success', duration: 1, description: 'Successfully updated the letter.' });
    } catch (e) {
        yield put(updateLetterFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}

function* upsertLetters({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'customer/upsert-letter',
            body: payload,
        });

        yield put(upsertLettersSuccess(response));
        notification.success({ message: 'Success', duration: 1, description: 'Successfully updated the letter.' });
    } catch (e) {
        yield put(upsertLettersFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}

// -------------------- Scripts -------------------- //
function* processScript({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'customer/process-script',
            body: payload,
        });

        yield put(processScriptSuccess(response.data));
        notification.success({ message: 'Success', duration: 1, description: 'Successfully processed the script.' });
    } catch (e) {
        yield put(processScriptFailure(e.response ? e.response.data.message : e));
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
    }
}


/**
 * Watch actions
 */
export default function* customerSaga() {
    yield* [
        takeEvery(GET_CUSTOMERS_REQUEST, getCustomers),
        takeEvery(GET_CUSTOMER_REQUEST, getCustomer),
        takeEvery(GET_CUSTOMER_BY_REFERENCE_REQUEST, getCustomerByReference),
        takeEvery(UPDATE_CUSTOMER_REQUEST, updateCustomer),
        takeEvery(GET_CUSTOMER_DOCTOR_REQUEST, getCustomerDoctor),
        takeEvery(UPDATE_CUSTOMER_DOCTOR_REQUEST, updateCustomerDoctor),
        takeEvery(REMOVE_CUSTOMER_DOCTOR_REQUEST, removeCustomerDoctor),
        takeEvery(GET_CUSTOMER_PRACTICE_REQUEST, getCustomerPractice),
        takeEvery(UPDATE_CUSTOMER_PRACTICE_REQUEST, updateCustomerPractice),
        takeEvery(REMOVE_CUSTOMER_PRACTICE_REQUEST, removeCustomerPractice),
        takeEvery(GET_CUSTOMER_ORDERS_REQUEST, getCustomerOrders),
        takeEvery(GET_CUSTOMER_PRODUCTS_REQUEST, getCustomerProducts),
        takeEvery(GET_CUSTOMER_AUTO_ORDERS_REQUEST, getCustomerAutoOrders),
        takeEvery(UPDATE_CUSTOMER_AUTO_ORDER_REQUEST, updateCustomerAutoOrder),
        takeEvery(SUBMIT_SUMMARY_FORM_REQUEST, submitSummaryForm),
        takeLatest(SEARCH_CUSTOMERS_BY_ATTRIBUTES_REQUEST, searchCustomersByAttributes),
        takeEvery(CREATEUPDATE_CUSTOMER_NOTE_REQUEST, createupdateCustomerNote),
        takeEvery(GET_CUSTOMER_NOTES_REQUEST, getCustomerNotes),

        // -------------------- Order -------------------- //
        takeEvery(SEARCH_CUSTOMER_ORDERS_BY_FILTERS_REQUEST, searchCustomerOrdersByFilters),
        takeEvery(UPDATE_CUSTOMER_ORDER_REQUEST, updateCustomerOrder),
        takeEvery(SEND_PICKING_AND_DISPATCH_LETTER_REQUEST, sendPickingAndDispatchLetter),

        // -------------------- Order Item -------------------- //
        takeEvery(UPDATE_ORDER_ITEM_REQUEST, updateOrderItem),
        takeEvery(SEARCH_CUSTOMER_ORDER_ITEMS_BY_FILTERS_REQUEST, searchCustomerOrderItemsByFilters),

        // -------------------- Order Item Repeat Instruction -------------------- //
        takeEvery(SEARCH_ORDER_ITEM_REPEAT_INSTRUCTIONS_BY_FILTERS_REQUEST, searchOrderItemRepeatInstructionsByFilters),
        takeEvery(UPDATE_ORDER_ITEM_REPEAT_INSTRUCTION_REQUEST, updateOrderItemRepeatInstruction),
        takeEvery(DELETE_ORDER_ITEM_REPEAT_INSTRUCTION_REQUEST, deleteOrderItemRepeatInstruction),

        // -------------------- Prescription -------------------- //
        takeEvery(SEARCH_PRESCRIPTIONS_BY_FILTERS_REQUEST, searchPrescriptionsByFilters),
        takeEvery(UPLOAD_PRESCRIPTION_REQUEST, uploadPrescription),
        takeEvery(UPDATE_PRESCRIPTION_REQUEST, updatePrescription),
        takeEvery(GET_PRESCRIPTION_REQUEST, getPrescription),
        takeEvery(CREATEUPDATE_PRESCRIPTION_ITEM_REQUEST, createupdatePrescriptionItem),
        takeEvery(DELETE_PRESCRIPTION_ITEM_REQUEST, deletePrescriptionItem),

        // -------------------- Letter -------------------- //
        takeEvery(CREATE_LETTER_REQUEST, createLetter),
        takeEvery(UPDATE_LETTER_REQUEST, updateLetter),
        takeEvery(UPSERT_LETTERS_REQUEST, upsertLetters),

        // -------------------- Scripts -------------------- //
        takeEvery(PROCESS_SCRIPT_REQUEST, processScript),
    ];
}
