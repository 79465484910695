import React from 'react';

import Gridview from '../../../elements/Gridview';
import { displayId, displayNumber, mapToOptions, GridButton } from '../../../core/utils/helpers';

export default function () {
    const { isFetching, productCategories, products, page, total } = this.props;
    const config = [
        { dataIndex: 'sku', title: 'Product Code', render: displayId },
        { dataIndex: 'id_name', title: 'Product Code', search: true, type: 'text', placeholder: 'Search (code or name)', visible: false },
        { dataIndex: 'description', title: 'Name' },
        { dataIndex: 'product_category_id', title: 'Category', search: true, type: 'select', placeholder: 'Category', options: mapToOptions(productCategories) },
        { dataIndex: 'stock_count', title: 'Est. Stock', className: 'text-center', render: (value, record) => displayNumber(record.quantity - record.issued_stock) },
        { dataIndex: 'key', align: 'right', render: (value, record) => <GridButton href={`/product/${record.id}`} /> }
    ];

    return (
        <Gridview
            column
            config={config}
            data={products}
            handleClearSearch={this.handleClearSearch}
            handlePagination={this.handlePagination}
            handleSearch={this.handleSearch}
            loading={isFetching}
            pagination={{
                simple: true,
                onChange: this.handlePagination,
                pageSize: 10,
                total,
                current: page,
                className: 'custom-pagination',
            }} />
    );
}
