import PropTypes from 'prop-types';
import React, { Component, } from 'react';
import { Modal, Button } from 'antd';
import { browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as customerActions from '../../../core/customer/customerActions';

class ChooseCustomer extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        const { visible, changeVisible, handleSubmit, actions } = this.props;
        return (
            <Modal centered width={540} footer={null} visible={visible} onOk={() => changeVisible(false)} onCancel={() => changeVisible(false)}>
                <h4 className="h-3 text-center">Select customer</h4>
                <div className="form-wrap">
                    <div className="btn-bar">
                        <Button
                            onClick={() => {
                                actions.updateOrderSearchResultsOfflineRequest([]);
                                browserHistory.push('/customer/0');
                            }
                            }
                            target="blank"
                            type="default"
                            icon="plus-circle"
                            className="medium">
                            Add new
                        </Button>
                        <Button type="primary" className="medium" onClick={handleSubmit}>Select existing</Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

ChooseCustomer.defaultProps = {
};

ChooseCustomer.propTypes = {
    visible: PropTypes.bool.isRequired,
    changeVisible: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseCustomer);
