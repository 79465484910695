import React from 'react';
import { Form as AntForm, Button, Row, Col, Spin, Icon, Select } from 'antd';

import Form from '../../../elements/Form';
import Loading from '../../../elements/Loading';
import { mapToOptions, debounce } from '../../../core/utils/helpers';
import { isRequired } from '../../../core/utils/validators';

const FormGroup = Form.Group;
const formTypes = Form.formTypes;
const Option = Select.Option;

const PRESCRIPTION_STATUS_OPTIONS = [
    { value: 0, label: 'No Script' },
    { value: 1, label: 'Script Partly Recieved' },
    { value: 2, label: 'Script Recieved' },
    { value: 3, label: 'Partly Processed' },
    { value: 4, label: 'Processed' },
    { value: 5, label: 'Written Off' },
    { value: 6, label: 'Cancelled' },
];

const REPEAT_INSTRUCTION_FREQUENCY_OPTIONS = [
    { value: 4, label: '4 weeks' },
    { value: 6, label: '6 weeks' },
    { value: 8, label: '8 weeks' },
    { value: 10, label: '10 weeks' },
    { value: 12, label: '12 weeks' },
];

const { REPORT_TYPES } = require('../../../core/utils/options').default;
const {
    REPORT_TYPE_SUPPLIER,
    REPORT_TYPE_PRODUCT,
    REPORT_TYPE_PROCESSED_PRESCRIPTIONS,
    REPORT_TYPE_AUTO_ORDER_ITEMS_PER_PATIENT,
    REPORT_TYPE_PAYMENT_EXAMPTION_LETTER
} = require('../../../core/utils/values').default;

const antIcon = <Icon type="loading-3-quarters" style={{ fontSize: 32, color: '#149566', width: 32, height: 32 }} spin />;

export default function () {
    const { reportType, products, suppliers, isFetching, actions, customerSearchResults } = this.props;
    const getOptionLabel = item => `#${item.sku} - ${item.description}`;
    const supplierOptions = mapToOptions(suppliers, 'id', getOptionLabel);
    const productOptions = mapToOptions(products, 'id', getOptionLabel);

    const formConfig = [
        { type: formTypes.SELECT, name: 'report_type', label: 'Report Type', options: REPORT_TYPES, validate: [isRequired] },
    ];

    if (reportType === REPORT_TYPE_SUPPLIER) {
        formConfig.push({ type: formTypes.SELECT, name: 'supplier_manufacturer', label: 'Associated Supplier/Manufacturer', options: supplierOptions, validate: [isRequired] });
    }

    if (reportType === REPORT_TYPE_PRODUCT) {
        formConfig.push({ type: formTypes.SELECT, name: 'product', label: 'Includes Product', options: productOptions, validate: [isRequired] });
    }

    if (reportType === REPORT_TYPE_PROCESSED_PRESCRIPTIONS) {
        formConfig.push({ type: formTypes.DATE, name: 'prescription_issue_date', label: 'Prescription Issue Date', validate: [isRequired] });
        formConfig.push({ type: formTypes.SELECT, name: 'prescription_status', label: 'Prescription Status', options: PRESCRIPTION_STATUS_OPTIONS, });
    }

    const patientOptions = Array.isArray(customerSearchResults) ?
        customerSearchResults.map(customerSearchResult => ({
            label: `${customerSearchResult.full_name} (ID: ${customerSearchResult.id})`,
            value: customerSearchResult.id,
        }))
        :
        [];

    if (reportType === REPORT_TYPE_AUTO_ORDER_ITEMS_PER_PATIENT) {
        formConfig.push({ type: formTypes.SELECT, name: 'auto_order_item_frequency', label: 'Auto Order Item Frequency', options: REPEAT_INSTRUCTION_FREQUENCY_OPTIONS, });
    }

    if (![REPORT_TYPE_PROCESSED_PRESCRIPTIONS, REPORT_TYPE_AUTO_ORDER_ITEMS_PER_PATIENT, REPORT_TYPE_PAYMENT_EXAMPTION_LETTER].includes(reportType)) {
        formConfig.push({ type: formTypes.DATE, name: 'report_from', label: 'From', validate: [isRequired] });
        formConfig.push({ type: formTypes.DATE, name: 'report_to', label: 'To', validate: [isRequired] });
    }

    return (
        <Row type="flex" justify="center">
            <Col md={12} lg={10}>
                <div className="section-white">
                    <Spin spinning={isFetching} indicator={antIcon}>
                        <AntForm onSubmit={this.props.handleSubmit(this.handleRunReportSubmit)} layout="vertical">
                            <FormGroup config={formConfig} />
                            { [REPORT_TYPE_AUTO_ORDER_ITEMS_PER_PATIENT, REPORT_TYPE_PAYMENT_EXAMPTION_LETTER].includes(reportType) &&
                            <div
                                className={'item-label-row'}
                            >
                                <div className="ant-col ant-form-item-label">
                                    <label
                                        className=""
                                        title="Auto Order Item Frequency"
                                    >
                                        Auto Order Item Patient Id
                                    </label>
                                </div>
                                <Select
                                    onSearch={(value) => {
                                        this.setState({ searchInput: value, });
                                        debounce(
                                            actions.searchCustomersByAttributesRequest({
                                                searchInput: value,
                                            }), 50);
                                    }}
                                    onChange={(value) => {
                                        this.setState({ searchValue: value, });
                                    }}
                                    value={this.state.searchValue}
                                    searchValue={this.state.searchInput}
                                    optionFilterProp="children"
                                    showSearch
                                    defaultActiveFirstOption
                                    suffixIcon={<Icon name="arrow-down" className="anticon anticon-down ant-select-arrow-icon" />}
                                >
                                    {patientOptions.map(option => <Option key={`${option.value}`} value={option.value}>{option.label}</Option>)}
                                </Select></div>
                            }
                            <div className="text-center mt-2">
                                <Button type="primary" htmlType="submit" className="wide">Run Report</Button>
                            </div>
                        </AntForm>
                    </Spin>
                </div>
            </Col>
        </Row>
    );
}
