import React from 'react';
import { Button } from 'antd';

import Layout from '../../components/layout/Layout';
import LogoMain from '../../elements/LogoMain';
import Login from '../../components/forms/Login';
import ForgotPassword from '../../components/modals/ForgotPassword';
import logoSrc from '../../assets/images/half-logo.svg';

export default function () {
    return (
        <Layout type="login" title="Login">
            <div className="loginform-wrap">
                <div className="loginform-container">
                    <LogoMain width="280px" color="black" />
                    <div className="loginform-backdrop">
                        <div className="form-wrap">

                            {/* Login form */}
                            <Login />

                            <div className="mt-1">
                                <Button className="link-green loginform__forgotten btn-empty underlined" onClick={() => this.setModalForgotPasswordVisible(true)}>
                                    Forgot password
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="loginform-aside">
                    <img className="loginform__illustr" src={logoSrc} alt="Jemsys" />
                </div>
            </div>

            {/* Forgot password form */}
            <ForgotPassword visible={this.state.modalForgotPasswordVisible} changeVisible={this.setModalForgotPasswordVisible} />
        </Layout>
    );
}
