import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import * as practiceActions from '../../../core/practice/practiceActions';

import DOM from './addDoctor';

class AddDoctorForm extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    // Handle submit form
    handleAddDoctorSubmit = (values) => {
        const { actions, practiceId, reset, closeModal } = this.props;
        actions.addPracticeDoctorRequest({ id: practiceId, ...values });
        closeModal();
        reset();
    };

    render() {
        return this.view();
    }
}

AddDoctorForm.defaultProps = {
    closeModal: () => {},
};

AddDoctorForm.propTypes = {
    actions: PropTypes.object.isRequired,
    closeModal: PropTypes.func,
    practiceId: PropTypes.number.isRequired,
    reset: PropTypes.func.isRequired,
};

const AddDoctor = reduxForm({
    form: 'add-doctor',
})(AddDoctorForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...practiceActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDoctor);
