import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { Icon as AntdIcon, Upload, Button, Empty, Input, Row, Col, DatePicker, Select, Carousel, Spin } from 'antd';
import { isEmpty, isEqual } from 'underscore';

import line from '../../../assets/images/line.png';

import ImageCapture from '../../../components/modals/ImageCapture/index.js';

import styles from './styles.js';

import { checkDate } from '../../../core/utils/helpers.js';

const { TextArea } = Input;

class Script extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isImageCaptureModalActive: false,
        };
    }

    handleCloseImageCaptureModal() {
        this.setState({ isImageCaptureModalActive: false });
    }

    handleOpenInnerModal() {
        this.setState({ isImageCaptureModalActive: true });
    }

    _renderImageSection() {
        const { fileList, onRemove } = this.props;

        return (
            <div
                style={{
                    gridColumnStart: 1,
                    gridColumnEnd: 13,
                    gridRowStart: 1,
                    gridRowEnd: 7,
                }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', }}>
                    { (!isEmpty(fileList)) ?
                        <Carousel>
                            {(fileList.map(file => (<Upload
                                key={file.url}
                                onPreview={() => {
                                    const image = new Image();
                                    image.src = file.url;
                                    const w = window.open('');
                                    w.document.write(image.outerHTML);
                                }}
                                onRemove={() => onRemove(file.uid)}
                                multiple={false}
                                listType="picture-card"
                                fileList={[file]} />)))}
                        </Carousel>
                        :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No image'} />
                    }
                </div>
            </div>
        );
    }

    _renderUploadSection() {
        const {
            onUpload,
        } = this.props;

        return (
            <div
                style={{
                    gridColumnStart: 1,
                    gridColumnEnd: 13,
                    gridRowStart: 7,
                    gridRowEnd: 8,
                }}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', }}>
                    <img src={line} style={{ width: '100%' }} />
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flex: 1, paddingTop: 12, }}>
                        <p style={{ ...styles.text3, padding: 0, margin: 0, }}>Add Script Image</p>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: 160, }}>
                            <Upload
                                beforeUpload={file => onUpload(file)}
                                multiple={false}
                                showUploadList={false}
                                style={{ flex: 1, }}>
                                <Button
                                    style={styles.uploadButton}
                                    success="true">Upload
                                </Button>
                            </Upload>
                            <p style={{ ...styles.text3, padding: 0, margin: 0, }}>or</p>
                            <div
                                onClick={() => this.handleOpenInnerModal()}
                                style={styles.capturePictureWrapper}>
                                <AntdIcon type="camera" theme="filled" style={{ fontSize: '150%' }} />
                                <p style={{ ...styles.text3, padding: 0, margin: 0, }}>Camera</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    _renderRow(value) {
        const { handleSetFormValue, prescriptionInformation } = this.props;

        let inputElement = null;

        switch (value[0]) {
        case 'issue_date':
            inputElement = (
                <DatePicker
                    format="DD/MM/YYYY"
                    value={checkDate(value[1].value, 'input', 'DD/MM/YYYY')}
                    onChange={(date, dateString) => handleSetFormValue(dateString, 'prescription', value[0])} />
            );
            break;
        case 'status':
            inputElement = (
                <Select
                    value={value[1].value}
                    size={'small'}
                    style={styles.rowDropdown}
                    onChange={option => handleSetFormValue(option, 'prescription', value[0])}>
                    <Select.Option value={0}>Written off</Select.Option>
                    <Select.Option value={1}>Reminder letter</Select.Option>
                    <Select.Option value={2}>Clear</Select.Option>
                    <Select.Option value={3}>Cancelled</Select.Option>
                </Select>
            );
            break;
        case 'type':
            inputElement = (
                <Select
                    value={value[1].value}
                    size={'small'}
                    style={styles.rowDropdown}
                    onChange={option => handleSetFormValue(option, 'prescription', value[0])}>
                    <Select.Option value={0}>FP10</Select.Option>
                    <Select.Option value={1}>Electronic</Select.Option>
                </Select>
            );
            break;
        default:
            inputElement = (
                <TextArea
                    autoSize={{ maxRows: 2, minRows: 2, }}
                    style={styles.textArea}
                    value={value[1].value}
                    onChange={event => handleSetFormValue(event.target.value, 'prescription', value[0])} />
            );
        }

        if (value[0] === 'exemption_reason' && !prescriptionInformation.exempt_from_prescription_fee) {
            return null;
        }
        return (
            <div key={value[0]}>
                <Row key={value[0]} gutter={0} type="flex" justify="start" style={{ width: '100%', margin: 0, padding: 0, height: '100%', }}>
                    <Col span={12} type="flex" align="flex-start"><p style={{ ...styles.rowName, textAlign: 'left' }}>{value[1].label}</p>
                    </Col>
                    <Col span={12} type="flex" align="flex-start">
                        {inputElement}
                    </Col>
                </Row>
            </div>

        );
    }

    _renderFormSection() {
        const { prescription } = this.props;

        return (
            <div
                style={{
                    gridColumnStart: 1,
                    gridColumnEnd: 13,
                    gridRowStart: 8,
                    gridRowEnd: 13,
                }}>
                <img src={line} style={{ width: '100%' }} />
                <div
                    style={{
                        marginTop: 12,
                        marginBottom: 12,
                    }}>
                    {Object.entries(prescription).map(value => this._renderRow(value))}
                </div>

            </div>
        );
    }

    handleCapture(base64Image) {
        const { onCapture } = this.props;

        onCapture(base64Image);
        this.setState({
            isImageCaptureModalActive: false
        });
    }

    render() {
        const { isImageCaptureModalActive } = this.state;
        const {
            isUpdatingPrescription,
            isFetchingPrescription,
        } = this.props;

        return (
            <div
                className={'prescription_matcher_script'} style={{ height: '100%', width: '100%', }}>
                <Spin spinning={isUpdatingPrescription || isFetchingPrescription}>
                    <div style={styles.gridLayout}>
                        {this._renderImageSection()}
                        {this._renderUploadSection()}
                        {this._renderFormSection()}
                        <ImageCapture
                            isActive={isImageCaptureModalActive}
                            onCapture={this.handleCapture.bind(this)}
                            onClose={this.handleCloseImageCaptureModal.bind(this)} />
                    </div>
                </Spin>
            </div>
        );
    }
}

Script.defaultProps = {
    isUpdatingPrescription: false,
    isFetchingPrescription: false,
};

Script.propTypes = {
    actions: PropTypes.object.isRequired,
    prescription: PropTypes.object.isRequired,
    fileList: PropTypes.array.isRequired,
    handleSetFormValue: PropTypes.func.isRequired,
    onCapture: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    isUpdatingPrescription: PropTypes.bool,
    isFetchingPrescription: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isUpdatingPrescription: state.customer.isUpdatingPrescription,
        isFetchingPrescription: state.customer.isFetchingPrescription,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ submit }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Script);
