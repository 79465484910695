import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { isUndefined } from 'underscore';
import { reduxForm } from 'redux-form';

import * as referrerActions from '../../../core/referrer/referrerActions';

import DOM from './updateReferrer';

class UpdateReferrerForm extends Component {
    constructor(props) {
        super(props);
        this.handleSetPageName(props);
        this.view = DOM;
    }

    componentWillMount() {
        const { actions, referrerId } = this.props;
        actions.getReferrerRequest(referrerId);
    }

    componentWillReceiveProps(nextProps) {
        this.handleSetPageName(nextProps);
    }

    // Handle set page name
    handleSetPageName(props) {
        const { setPageName, initialValues, referrerId, isFetching } = props;
        if (!isFetching) {
            if (!isUndefined(initialValues.first_name)) {
                setPageName(`#${initialValues.id} - ${initialValues.first_name} ${initialValues.last_name}`);
            } else if (referrerId === 0) {
                setPageName('New Referrer');
            }
        }
    }

    // Handle submit form
    handleUpdateReferrerSubmit = (values) => {
        const { actions, referrerId } = this.props;
        actions.updateReferrerRequest({ id: referrerId, ...values });
    };

    render() {
        return this.view();
    }
}

UpdateReferrerForm.propTypes = {
    actions: PropTypes.object.isRequired,
    referrerId: PropTypes.number.isRequired,
};

const UpdateReferrer = reduxForm({
    form: 'update-referrer',
    enableReinitialize: true,
})(UpdateReferrerForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        initialValues: state.referrer.referrer,
        isFetching: state.referrer.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...referrerActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateReferrer);
