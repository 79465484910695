import { isEmpty } from 'underscore';

const { STATUS_INACTIVE, ORDER_STATUS_SCRIPT_SUPPLIED } = require('./values').default;

// Validate required
const isRequired = value => (value ? undefined : 'Required');

// Validate required and 0 is allowed
const isRequiredOrZero = value => (value || value === 0 ? undefined : 'Required');

// Validate array value must have at least one child value
const isOneOfManyRequired = value => (!isEmpty(value) ? undefined : 'At least one is required');

// Validate is string
const isString = value => (typeof value === 'string' ? undefined : 'Must be a string');

// Validate is number
const isNumber = value => (parseInt(value, 10) ? undefined : 'Must be a number');

// Validate is character length is less than number
const charLessThan = (value, condition) => {
    if (value) {
        return value.length < condition ? undefined : `Must be less than ${condition} characters`;
    }
    return undefined;
};

// ----- Specific validators ----- //

/**
 * Validate order form
 * @param  {object} values
 * @return {object} - object containing errors for problem field
 */
const validateOrder = (values) => {
    const errors = {};
    if (values.auto_order) {
        // if (!values.end_date) {
        //     errors.end_date = 'Required';
        // }
        if (!values.interval) {
            errors.interval = 'Required';
        }
    }

    if (values.order_status === ORDER_STATUS_SCRIPT_SUPPLIED) {
        // if (!values.script_reference) {
        //     errors.script_reference = 'Required';
        // }
        if (!values.funding_method) {
            errors.funding_method = 'Required';
        }
    }

    return errors;
};

/**
 * Validate customer form
 * @param  {object} values
 * @return {object} - object containing errors for problem field
 */
const validateCustomer = (values) => {
    const errors = {};
    // if (values.is_active === STATUS_INACTIVE) {
    //     if (!values.deactivation_reason) {
    //         errors.deactivation_reason = 'Required';
    //     }
    // }

    return errors;
};

export {
    isRequired,
    isRequiredOrZero,
    isOneOfManyRequired,
    isString,
    isNumber,
    charLessThan,

    validateOrder,
    validateCustomer,
};
