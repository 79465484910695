import { checkDate } from '../../../core/utils/helpers';

Array.prototype.unique = function () {
    const a = this.concat();
    for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j]) { a.splice(j--, 1); }
        }
    }

    return a;
};

function generateOrderItems({ orderItemSearchResults, prescriptionId }) {
    const valueLists = {
        orderItemIdList: [],
        orderIdList: [],
        productTitleList: [],
        createdAtList: [],
        deliveryStatusList: [],
        quantityList: [],
        valueList: [],
        matchList: [],
    };

    orderItemSearchResults.map((orderItem) => {
        if (orderItem.product.requires_prescription === 1) {
            valueLists.orderItemIdList.push(orderItem.id);
            valueLists.orderIdList.push(orderItem.order_id);
            valueLists.productTitleList.push(orderItem.product.title);
            valueLists.createdAtList.push(orderItem.created_at ? checkDate(orderItem.created_at, 'output', 'DD/MM/YYYY') : 'N/a');
            valueLists.deliveryStatusList.push(orderItem.delivery_status);
            valueLists.quantityList.push(orderItem.quantity);
            valueLists.valueList.push((orderItem.product.price) ? `£${Math.round(orderItem.product.price * 100) / 100}` : 'N/a');
            valueLists.matchList.push(+orderItem.prescription_id === +prescriptionId);
        }
    });

    return [
        {
            fieldName: 'order_item_id',
            fieldLabel: '',
            fieldValues: valueLists.orderItemIdList,
        },
        {
            fieldName: 'order_id',
            fieldLabel: 'Order',
            fieldValues: valueLists.orderIdList,
        },
        {
            fieldName: 'title',
            fieldLabel: 'Item Name',
            fieldValues: valueLists.productTitleList,
        },
        {
            fieldName: 'created_at',
            fieldLabel: 'Created At',
            fieldValues: valueLists.createdAtList,
        },
        {
            fieldName: 'delivery_status',
            fieldLabel: 'Delivery Status',
            fieldValues: valueLists.deliveryStatusList,
        },
        {
            fieldName: 'quantity',
            fieldLabel: 'Quantity',
            fieldValues: valueLists.quantityList,
        },
        {
            fieldName: 'price',
            fieldLabel: 'Value',
            fieldValues: valueLists.valueList,
        },
        {
            fieldName: 'match',
            fieldLabel: '',
            fieldValues: valueLists.matchList,
        },
    ];
}

function deliveryStatusOptions() {
    return [
        {
            label: 'Picking',
            value: 0,
        },
        {
            label: 'Dispatch',
            value: 1,
        },
        {
            label: 'Backorder',
            value: 2,
        },
        {
            label: 'Processed',
            value: 3,
        },
        {
            label: 'Cancelled',
            value: 4,
        },
        {
            label: 'All',
            value: null,
        },
    ];
}

function periodOptions() {
    return [
        {
            label: '4 weeks',
            value: 4,
        },
        {
            label: '3 months',
            value: 13,
        },
        {
            label: '12 months',
            value: 52,
        },
        {
            label: 'All',
            value: null,
        },
    ];
}

export { generateOrderItems, deliveryStatusOptions, periodOptions };
