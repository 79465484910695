import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';

const NavLink = (props, context) => {
    let className = '';
    if (props.to) {
        const isActive = context.router.isActive(props.to, true);
        className = isActive ? 'is-active' : '';
    }

    return (
        <li className={className}>
            <Link {...props} />
        </li>
    );
};

NavLink.defaultProps = {
    to: null,
};

NavLink.propTypes = {
    to: PropTypes.string,
};

NavLink.contextTypes = {
    router: PropTypes.object
};

export default NavLink;
