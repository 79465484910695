import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { Checkbox, Input, Button, Spin } from 'antd';

import blackAtIcon from '../../../assets/images/black-at-icon.png';
import blackKeypad from '../../../assets/images/black-keypad.png';
import blackMailIcon from '../../../assets/images/black-mail-icon.png';
import blackClipboard from '../../../assets/images/black-clipboard.png';
import line from '../../../assets/images/line.png';

import styles from './styles.js';

const { TextArea } = Input;

class BalanceLetter extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        const {
            handleSetFormValue,
            balance_letter,
            onSubmitLetter,
            params,
            isCreatingLetter,
            isUpdatingLetter,
        } = this.props;
        const prescriptionId = params && params.prescriptionId;

        return (
            <div style={styles.mainContainer} className={'prescription_matcher_balance_letter'}>
                <Spin spinning={isCreatingLetter || isUpdatingLetter}>
                    <div>
                        <div style={styles.headerContainer}>
                            <img
                                src={blackClipboard}
                                style={{
                                    height: 23,
                                    width: 23,
                                    marginRight: 41,
                                    marginLeft: 20,
                                }}
                                alt={'Black Clipboard'} />
                            <p style={styles.title}>Balance Letter</p>
                            <Button
                                onClick={() => onSubmitLetter()}
                                style={{ ...styles.submitButton, visibility: prescriptionId ? 'visible' : 'hidden' }}>Go
                            </Button>
                        </div>
                        <img src={line} style={{ width: '100%' }} />
                    </div>
                    <TextArea
                        value={balance_letter.text.value}
                        onChange={e => handleSetFormValue(e.target.value, 'balance_letter', 'text')}
                        style={styles.textArea}
                        autoSize={{ maxRows: 3, minRows: 3, }} />
                    <div style={styles.footerContainer}>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                            <div>
                                <img
                                    src={blackAtIcon}
                                    style={{
                                        height: 20,
                                        width: 20,
                                        marginRight: 5,
                                        marginLeft: 20,
                                    }}
                                    alt={'Black At Icon'} />
                                <Checkbox
                                    checked={balance_letter.email.value}
                                    onChange={e => handleSetFormValue(e.target.checked ? 1 : 0, 'balance_letter', 'email')} />
                            </div>
                            <div>
                                <img
                                    src={blackMailIcon}
                                    style={{
                                        height: 20,
                                        width: 20,
                                        marginRight: 5,
                                        marginLeft: 20,
                                    }}
                                    alt={'Black Mail Icon'} />
                                <Checkbox
                                    checked={balance_letter.letter.value}
                                    onChange={e => handleSetFormValue(e.target.checked ? 1 : 0, 'balance_letter', 'letter')} />
                            </div>
                            <div>
                                <img
                                    src={blackKeypad}
                                    style={{
                                        height: 20,
                                        width: 20,
                                        marginRight: 5,
                                        marginLeft: 20,
                                    }}
                                    alt={'Black Keypad'} />
                                <Checkbox
                                    checked={balance_letter.fax.value}
                                    onChange={e => handleSetFormValue(e.target.checked ? 1 : 0, 'balance_letter', 'fax')} />
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        );
    }
}

BalanceLetter.defaultProps = {
    isCreatingLetter: false,
    isUpdatingLetter: false,
};

BalanceLetter.propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    handleSetFormValue: PropTypes.func.isRequired,
    balance_letter: PropTypes.object.isRequired,
    onSubmitLetter: PropTypes.func.isRequired,
    isCreatingLetter: PropTypes.bool,
    isUpdatingLetter: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isCreatingLetter: state.customer.isCreatingLetter,
        isUpdatingLetter: state.customer.isUpdatingLetter,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ submit }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceLetter);
