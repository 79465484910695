import React from 'react';
import { Tabs } from 'antd';

import Layout from '../../components/layout/Layout';
import ProductsAdmin from '../../components/gridviews/ProductsAdmin';
import PracticesSearch from '../../components/gridviews/PracticesSearch';
import HomesNursesSearch from '../../components/gridviews/HomesNursesSearch';
import ReferrersSearch from '../../components/gridviews/ReferrersSearch';
import SuppliersSearch from '../../components/gridviews/SuppliersSearch';
import UploadStock from '../../components/modals/UploadStock';

const TabPane = Tabs.TabPane;

export default function () {
    return (
        <Layout title={this.state.title} toolbar={this.state.toolbar} breadcrumb="admin">
            <Tabs defaultActiveKey="1" className="ant-tabs--styled" onChange={this.handleTabChange}>
                {/* Products tab */}
                <TabPane tab="Products" key="1">
                    <ProductsAdmin />
                </TabPane>

                {/* Doctors & Practices tab */}
                <TabPane tab={'Doctor & practices'} key="2">
                    <PracticesSearch />
                </TabPane>

                {/* Nurses & Homes tab */}
                <TabPane tab={'Nurses & homes'} key="3">
                    <HomesNursesSearch />
                </TabPane>

                {/* Referrers tab */}
                <TabPane tab="Referrers" key="4">
                    <ReferrersSearch />
                </TabPane>

                {/* Suppliers & Manufacturers tab */}
                <TabPane tab={'Suppliers & manufacturers'} key="5">
                    <SuppliersSearch />
                </TabPane>
            </Tabs>

            <UploadStock visible={this.state.modalUploadStockVisible} changeVisible={this.setModalUploadStockVisible} />
        </Layout>
    );
}
