import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    SET_BACKORDER_CHASER_REQUEST: null,
    SET_BACKORDER_CHASER_SUCCESS: null,
    SET_BACKORDER_CHASER_FAILURE: null,

    RUN_BACKORDER_CHASER_REQUEST: null,
    RUN_BACKORDER_CHASER_SUCCESS: null,
    RUN_BACKORDER_CHASER_FAILURE: null,

    SET_BACKORDER_CHASER_ALL_REQUEST: null,
});

export const {
    setBackorderChaserRequest,
    setBackorderChaserSuccess,
    setBackorderChaserFailure,
} = createActions(
    constants.SET_BACKORDER_CHASER_REQUEST,
    constants.SET_BACKORDER_CHASER_SUCCESS,
    constants.SET_BACKORDER_CHASER_FAILURE,
);

export const {
    setBackorderChaserAllRequest,
} = createActions(
    constants.SET_BACKORDER_CHASER_ALL_REQUEST,
);

export const {
    runBackorderChaserRequest,
    runBackorderChaserSuccess,
    runBackorderChaserFailure,
} = createActions(
    constants.RUN_BACKORDER_CHASER_REQUEST,
    constants.RUN_BACKORDER_CHASER_SUCCESS,
    constants.RUN_BACKORDER_CHASER_FAILURE,
);
