import React, { useState, useEffect } from 'react';
import { InputNumber, notification, Checkbox, DatePicker, Button, Select } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import './scriptsModal.scss';

const ScriptsModal = ({ data, onCancel, onSubmit }) => {
    const uniqueOrderIds = [...new Set(data.map(item => item.orderId))];
    const createRow = orderObj => ({ orderId: orderObj.orderId, etp: false, numberOfForms: 1, numberOfLines: orderObj.itemsCount, issueDate: null });
    const createExtraRow = () => ({ orderId: uniqueOrderIds[0], etp: false, numberOfForms: 1, numberOfLines: null, issueDate: null });

    const groupedData = uniqueOrderIds.map(id => ({ orderId: id, itemsCount: data.filter(item => item.orderId === id).length }));

    const initialDataToSubmit = groupedData.map(orderObj => createRow(orderObj));
    const [dataToSubmit, setDataToSubmit] = useState(initialDataToSubmit);
    const [numberOfExtraRows, setNumberOfExtraRows] = useState(0);

    const { Option } = Select;

    useEffect(() => {
        if (numberOfExtraRows > dataToSubmit.length - uniqueOrderIds.length) {
            const additionalRow = createExtraRow();
            setDataToSubmit([...dataToSubmit, additionalRow]);
        } else if (numberOfExtraRows < dataToSubmit.length - uniqueOrderIds.length) {
            setDataToSubmit(prev => prev.slice(0, -1));
        }
    }, [numberOfExtraRows]);

    useEffect(() => {
        setDataToSubmit(initialDataToSubmit);
    }, [data]);

    const isDataComplete = dataToCheck => dataToCheck.every(item => Object.values(item).every(value => value !== null && value !== undefined));

    const handleContinueClick = () => {
        if (isDataComplete(dataToSubmit)) {
            onSubmit(dataToSubmit);
            onCancel();
        } else {
            notification.error({ message: 'Incomplete data', description: 'Please complete all inputs' });
        }
    };

    const handleCancelClick = () => {
        onCancel();
    };

    const handleChange = (index, field, value) => {
        const newDataToSubmit = [...dataToSubmit];
        newDataToSubmit[index][field] = value;
        setDataToSubmit(newDataToSubmit);
    };

    const handleNumberOfExtraRowsChange = (value) => {
        if (value <= 10) {
            setNumberOfExtraRows(value);
        } else {
            notification.error({ message: 'Error detected', description: 'Maximum number of extra rows is 10' });
        }
    };

    const getOrderRow = (index) => {
        const rowClass = index % 2 === 0 ? 'evenRow' : 'oddRow';
        const rowData = dataToSubmit[index];

        const handleETPChange = (e) => {
            handleChange(index, 'etp', e.target.checked);
        };

        const handleNumberOfFormsChange = (value) => {
            handleChange(index, 'numberOfForms', value);
        };

        const handleNumberOfLinesChange = (value) => {
            handleChange(index, 'numberOfLines', value);
        };

        const handleIssueDateChange = (date, dateString) => {
            handleChange(index, 'issueDate', dateString);
        };

        if (rowData) {
            return (
                <div key={uniqueOrderIds[index]} className={`script-row ${rowClass}`}>
                    {index > uniqueOrderIds.length - 1 ?
                        <div className="select-wrapper" style={{ width: '25%' }} >
                            <Select size="small" style={{ width: '100px' }} defaultValue={uniqueOrderIds[0]} onChange={value => handleChange(index, 'orderId', value)}>
                                {uniqueOrderIds.map(id => <Option key={id} value={id}>{id}</Option>)}
                            </Select>
                        </div>
                        :
                        <div className="item-wrapper" style={{ width: '25%' }}>
                            <span>{rowData.orderId}</span>
                        </div>
                    }
                    <div className="item-wrapper" style={{ width: '10%' }}>
                        <Checkbox size="small" onChange={handleETPChange} checked={rowData.etp} />
                    </div>
                    <div className="item-wrapper" style={{ width: '16%' }}>
                        <InputNumber size="small" onChange={handleNumberOfFormsChange} value={rowData.numberOfForms} min={0} />
                    </div>
                    <div className="item-wrapper" style={{ width: '16%' }}>
                        <InputNumber size="small" onChange={handleNumberOfLinesChange} value={rowData.numberOfLines} min={0} />
                    </div>
                    <div className="item-wrapper" style={{ width: '33%' }}>
                        <DatePicker size="small" onChange={handleIssueDateChange} value={rowData.issueDate ? moment(rowData.issueDate) : null} />
                    </div>
                </div>
            );
        }
        return null;
    };


    return (
        <div className="scripts-modal-container">
            <div className="scripts-processing-header">
                <label htmlFor="number-of-scripts">Number of extra rows:</label>
                <InputNumber
                    value={numberOfExtraRows}
                    min={0}
                    max={10}
                    defaultValue={0}
                    onChange={handleNumberOfExtraRowsChange}
                    id="number-of-scripts" />
            </div>
            <div className="scripts-rows-container">
                <div className="scripts-header">
                    <h5 style={{ width: '25%' }}>Order</h5>
                    <h5 style={{ width: '10%' }}>ETP</h5>
                    <h5 style={{ width: '16%' }}>No. of Forms</h5>
                    <h5 style={{ width: '16%' }}>No. of Lines</h5>
                    <h5 style={{ width: '33%' }}>Date of Issue</h5>
                </div>
                <div className="scripts-body">
                    {[...Array(uniqueOrderIds.length + numberOfExtraRows)].map((x, index) => getOrderRow(index))}
                </div>
            </div>
            <div className="scripts-footer">
                <Button type="default" onClick={handleCancelClick}>Cancel</Button>
                <Button type="primary" onClick={handleContinueClick}>Continue</Button>
            </div>
        </div>
    );
};

export default ScriptsModal;

ScriptsModal.propTypes = {
    data: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

ScriptsModal.defaultProps = {
    data: ['No data'],
};
