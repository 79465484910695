import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import DOM from './customersSearch';

import {
    handleBasicGridviewMount,
    handleBasicGridviewSearch,
    handleBasicGridviewClearSearch,
    handleBasicGridviewPagination,
} from '../../../core/utils/helpers';

import * as customerActions from '../../../core/customer/customerActions';
import * as nurseActions from '../../../core/nurse/nurseActions';
import * as referrerActions from '../../../core/referrer/referrerActions';

class CustomersSearch extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.getNursesRequest();
        actions.getReferrersRequest();
        handleBasicGridviewMount(actions.getCustomersRequest, 'searchCustomers');
    }

    // Handle gridview search
    handleSearch = (values) => {
        const { actions } = this.props;
        handleBasicGridviewSearch(actions.getCustomersRequest, 'searchCustomers', values);
    }

    // Handle gridview clear search
    handleClearSearch = (reset) => {
        const { actions } = this.props;
        handleBasicGridviewClearSearch(actions.getCustomersRequest, 'searchCustomers');
        reset();
    }

    // Handle gridview pagination
    handlePagination = (page, pageSize) => {
        const { actions } = this.props;
        handleBasicGridviewPagination(actions.getCustomersRequest, 'searchCustomers', page, pageSize);
    }

    render() {
        return this.view();
    }
}

CustomersSearch.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        customers: state.customer.customers.rows,
        isFetching: state.customer.isFetching,
        nurses: state.nurse.nurses.rows,
        page: state.customer.customers.page,
        referrers: state.referrer.referrers.rows,
        total: state.customer.customers.total,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
            ...nurseActions,
            ...referrerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomersSearch);
