import authSaga from './auth/authSaga';
import carehomeSaga from './carehome/carehomeSaga';
import chaserSaga from './chaser/chaserSaga';
import customerSaga from './customer/customerSaga';
import dispatchSaga from './dispatch/dispatchSaga';
import doctorSaga from './doctor/doctorSaga';
import nurseSaga from './nurse/nurseSaga';
import orderSaga from './order/orderSaga';
import practiceSaga from './practice/practiceSaga';
import productSaga from './product/productSaga';
import referrerSaga from './referrer/referrerSaga';
import reportSaga from './report/reportSaga';
import supplierSaga from './supplier/supplierSaga';
import userSaga from './user/userSaga';
import backorderChaserSaga from './backorderChaser/backorderChaserSaga';

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield [
        authSaga(),
        carehomeSaga(),
        chaserSaga(),
        customerSaga(),
        dispatchSaga(),
        doctorSaga(),
        nurseSaga(),
        orderSaga(),
        practiceSaga(),
        productSaga(),
        referrerSaga(),
        reportSaga(),
        supplierSaga(),
        userSaga(),
        backorderChaserSaga(),
    ];
}
