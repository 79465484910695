import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'underscore';

import * as productActions from '../../../core/product/productActions';
import * as supplierActions from '../../../core/supplier/supplierActions';

import DOM from './addProductSupplier';

class AddProductSupplierForm extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            productId: null,
            supplierTypes: [],
            productError: false,
            typesError: false,
        };
    }

    componentWillMount() {
        const { actions } = this.props;
        actions.resetProducts();
    }

    // Handle change supplier types
    handleChangeSupplierTypes = supplierTypes => this.setState({ supplierTypes });

    // Handle change product select
    handleChangeProducts = productId => this.setState({ productId });

    // Handle product select search
    handleSearchProducts = (value) => {
        const { actions } = this.props;
        if (value.length >= 3) {
            actions.getProductsRequest({ id_name: value });
        } else if (value.length < 2) {
            actions.resetProducts();
        }
    }

    // Handle submit form
    handleAddProductSupplierSubmit = () => {
        const { productId, supplierTypes } = this.state;
        const errors = {
            productError: !productId,
            typesError: isEmpty(supplierTypes)
        };

        if (errors.productError || errors.typesError) {
            this.setState(errors);
        } else {
            const { actions, supplierId, changeVisible } = this.props;
            actions.addSupplierProductRequest({
                id: supplierId,
                product_id: productId,
                type: supplierTypes || [],
            });
            changeVisible(false);
        }
    };

    render() {
        return this.view();
    }
}

AddProductSupplierForm.propTypes = {
    actions: PropTypes.object.isRequired,
    changeVisible: PropTypes.func.isRequired,
    supplierId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        products: state.product.products.rows,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...productActions,
            ...supplierActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProductSupplierForm);
