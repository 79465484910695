import React from 'react';

import CancelAutoOrder from '../../components/modals/CancelAutoOrder';
import Layout from '../../components/layout/Layout';
import SearchDoctorsPractice from '../../components/modals/SearchDoctorsPractice';
import UpdateAutoOrder from '../../components/modals/UpdateAutoOrder';
import UpdateCustomer from '../../components/forms/UpdateCustomer';

export default function () {
    const {
        name,
        toolbar,
        modalAssignVisible,
        modalCancelAutoOrderVisible,
        modalCancelAutoOrderId,
        modalUpdateAutoOrderVisible,
        modalUpdateAutoOrderId,
        modalUpdateAutoOrderInterval,
        modalUpdateAutoOrderIntervalType,
    } = this.state;
    const { params } = this.props;
    const id = parseInt(this.props.params.id, 10);
    const breadcrumb = { name: 'customer', params: { id, name } };

    return (
        <Layout title={name} toolbar={toolbar} breadcrumb={breadcrumb}>
            {/* FORM: update customer */}
            <UpdateCustomer
                customerId={id}
                handleTabChange={this.handleTabChange}
                handleOpenModal={this.handleOpenModal}
                setPageName={this.setPageName}
                params={params} />

            {/* MODALS */}
            <SearchDoctorsPractice customerId={id} visible={modalAssignVisible} changeVisible={this.setModalAssignVisible} />
            <CancelAutoOrder id={modalCancelAutoOrderId} customerId={id} visible={modalCancelAutoOrderVisible} changeVisible={this.setModalCancelAutoOrderVisible} />
            <UpdateAutoOrder
                id={modalUpdateAutoOrderId}
                customerId={id}
                interval={modalUpdateAutoOrderInterval}
                intervalType={modalUpdateAutoOrderIntervalType}
                visible={modalUpdateAutoOrderVisible}
                changeVisible={this.setModalUpdateAutoOrderVisible} />
        </Layout>
    );
}
