import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, Collapse } from 'antd';

import Loading from '../../../../elements/Loading';
import Form from '../../../../elements/Form';
import { isRequired, charLessThan } from '../../../../core/utils/validators';

const formTypes = Form.formTypes;
const FormSection = Form.Section;
const FormGroup = Form.Group;
const FormItem = Form.Item;
const Panel = Collapse.Panel;

const { YES_NO_OPTIONS } = require('../../../../core/utils/options').default;

const CustomerAddresses = ({ loading, differentCare }) => {
    const itemDisabled = !differentCare;
    const validate = differentCare ? [isRequired] : [];

    // Home address form config
    const homeConfig = [
        { type: formTypes.TEXT, name: 'home_address.address_line_1', label: 'Line 1', validate: [isRequired] },
        { type: formTypes.TEXT, name: 'home_address.address_line_2', label: 'Line 2' },
        { type: formTypes.TEXT, name: 'home_address.address_line_3', label: 'Line 3' },
        { type: formTypes.TEXT, name: 'home_address.city_region', label: 'City/Region', validate: [isRequired] },
        { type: formTypes.TEXT, name: 'home_address.postcode', label: 'Postcode', validate: [isRequired, value => charLessThan(value, 10)] },
        { type: formTypes.TEXT, name: 'home_address.telephone', label: 'Telephone Number', validate: [isRequired, value => charLessThan(value, 15)] },
        { type: formTypes.TEXT, name: 'home_address.mobile', label: 'Mobile Number', validate: [value => charLessThan(value, 15)] },
        { type: formTypes.TEXT, name: 'home_address.email', label: 'Email Address', validate: [value => charLessThan(value, 50)] },
        { type: formTypes.RADIO, name: 'home_address.is_residential_home', label: 'Is this a residential home:', options: YES_NO_OPTIONS, className: 'radio-checkbox-row' },
    ];

    // Care address form config
    const careConfig = [
        { type: formTypes.TEXT, name: 'care_address.address_line_1', label: 'Line 1', itemDisabled, validate },
        { type: formTypes.TEXT, name: 'care_address.address_line_2', label: 'Line 2', itemDisabled },
        { type: formTypes.TEXT, name: 'care_address.address_line_3', label: 'Line 3', itemDisabled },
        { type: formTypes.TEXT, name: 'care_address.city_region', label: 'City/Region', itemDisabled, validate },
        { type: formTypes.TEXT, name: 'care_address.postcode', label: 'Postcode', itemDisabled, validate: validate.concat([value => charLessThan(value, 10)]) },
        { type: formTypes.TEXT, name: 'care_address.telephone', label: 'Telephone Number', itemDisabled, validate: validate.concat([value => charLessThan(value, 15)]) },
        { type: formTypes.CHECKBOX, name: 'deliver_to_care_of_address', label: 'Deliver to care the address:', itemDisabled, className: 'radio-checkbox-row reverse' },
    ];

    // Delivery notes form config
    const deliveryConfig = [
        { type: formTypes.TEXTAREA, name: 'delivery_notes' },
    ];

    // Contact preferences form config
    const contactConfig = [
        { type: formTypes.RADIO, name: 'share_contact_details', label: 'Share contact details with courier:', options: YES_NO_OPTIONS, className: 'radio-checkbox-row' },
    ];

    return (
        <div className="section">
            <div className="container">
                <Row type="flex" justify="center">
                    <Col span={24} md={20} lg={17}>
                        <FormSection loading={loading} config={homeConfig} title="Home Address" itemsSpan={14} />

                        <div className="section__container">
                            <div className="section__header">
                                <h3 className="h-4">Care of Address</h3>
                            </div>
                            {loading ?
                                <Loading className="section-white" />
                                :
                                <div className="section-white">
                                    <Collapse
                                        className="form-collapse"
                                        activeKey={differentCare ? ['1'] : []}
                                        bordered={false}
                                        expandIcon={false}>

                                        <Panel
                                            header={
                                                <FormItem
                                                    type={formTypes.CHECKBOX}
                                                    name="is_care_of_address_different"
                                                    label="Customer has a care of address that is different from the home address"
                                                    className="m-0 p-0" />
                                            }
                                            key="1"
                                            showArrow={false}>

                                            <div className="divider mt-0" />
                                            <Row type="flex" justify="center">
                                                <Col span={14}>
                                                    <FormGroup config={careConfig} />
                                                </Col>
                                            </Row>
                                        </Panel>
                                    </Collapse>
                                </div>
                            }
                        </div>

                        <FormSection loading={loading} config={deliveryConfig} title="If out / Delivery Notes" />
                        <FormSection loading={loading} config={contactConfig} title="Contact Preferences" />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

CustomerAddresses.defaultProps = {
    loading: false,
};

CustomerAddresses.propTypes = {
    differentCare: PropTypes.number.isRequired,
    loading: PropTypes.bool
};

export default CustomerAddresses;
