import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import DOM from './nursesHome';

import * as carehomeActions from '../../../core/carehome/carehomeActions';

class NursesHome extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            modalAddNurseVisible: false,
            selectedNurseId: 0,
        };
    }

    componentWillMount() {
        const { actions, carehomeId } = this.props;
        actions.getCarehomeNurseRequest(carehomeId);
    }

    // Handle toggle add carehome nurse visible
    setModalAddNurseVisible = modalAddNurseVisible => this.setState({ modalAddNurseVisible });

    // Handle remove carehome nurse
    handleRemoveCarehomeNurse = (nurseId) => {
        const { actions, carehomeId } = this.props;
        actions.removeCarehomeNurseRequest({ carehomeId, nurseId });
    }

    render() {
        return this.view();
    }
}

NursesHome.propTypes = {
    actions: PropTypes.object.isRequired,
    carehomeId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        nurses: state.carehome.nurses,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...carehomeActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NursesHome);
