import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_USER_REQUEST: null,
    GET_USER_SUCCESS: null,
    GET_USER_FAILURE: null,
});

export const {
    getUserRequest,
    getUserSuccess,
    getUserFailure
} = createActions(
    constants.GET_USER_REQUEST,
    constants.GET_USER_SUCCESS,
    constants.GET_USER_FAILURE
);
