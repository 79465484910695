import React from 'react';

import Gridview from '../../../elements/Gridview';
import { displayNumber, GridButton } from '../../../core/utils/helpers';

export default function () {
    const config = {
        'Search for practices': [
            // { dataIndex: 'gp_reference', title: 'GP Reference', search: true, type: 'text' },
            { dataIndex: 'title', title: 'Practice Name', search: true, type: 'text' },
            { dataIndex: 'city_region', title: 'City/Region', search: true, type: 'text', visible: false },
            { dataIndex: 'postcode', title: 'Postcode', search: true, type: 'text' },
            { dataIndex: 'num_doctors', title: 'Num Doctors', className: 'text-center', render: displayNumber },
            { dataIndex: 'key', align: 'right', render: (value, record) => <GridButton href={`/practice/${record.id}`} /> },
        ],
        'Search practices by doctor': [
            { dataIndex: 'doctor_first_name', title: 'Forename', search: true, type: 'text' },
            { dataIndex: 'doctor_last_name', title: 'Surname', search: true, type: 'text' },
            { dataIndex: 'doctor_email', title: 'Email', search: true, type: 'text' },
            { dataIndex: 'doctor_phone_no', title: 'Phone', search: true, type: 'text' },
        ],
    };

    const { practices, isFetching, total, page } = this.props;
    return (
        <Gridview
            config={config}
            data={{ 'Search for practices': practices }}
            handleClearSearch={this.handleClearSearch}
            handlePagination={this.handlePagination}
            handleSearch={this.handleSearch}
            loading={isFetching}
            pagination={{
                simple: true,
                onChange: this.handlePagination,
                pageSize: 10,
                total,
                current: page,
                className: 'custom-pagination',
            }}
            searchTitle="Doctor Search Criteria"
            title="Practice Search Results" />
    );
}
