import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { size, isEmpty, isUndefined, isArray, mapObject, keys, values } from 'underscore';

import Grid from './Grid';
import Search from './Search';

import './gridview.css';

class Gridview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortBy: null,
        };
    }

    // Separate config for search, sort and table
    getSeparatedConfig = (separateConfig) => {
        // Filter where search is true
        const search = separateConfig.filter(row => row.search);

        // Filter where sort is true
        const sort = separateConfig.filter(row => row.sort);

        // Filter where visible is true or undefined
        const table = separateConfig.filter(row => row.visible || isUndefined(row.visible)).map((row) => {
            const newRow = row;
            // If type is select and render is not set then add render function to display option label
            if (row.type === 'select' && isUndefined(row.render)) {
                newRow.render = (value) => {
                    const option = row.options.find(item => (item.value === value));
                    return option ? option.label : value;
                };
            }
            return newRow;
        });

        return { search, sort, table };
    };

    // Handle sort table
    handleSort = (sortBy, table) => {
        this.setState({ sortBy });
        this.props.handleSort(sortBy, table);
    }

    // Handle clear table search criteria
    handleClearSearch = (reset) => {
        this.setState({ sortBy: null });
        this.props.handleClearSearch(reset);
    }

    render() {
        const {
            className,
            column,
            config,
            data,
            expandedRowRender,
            expandIcon,
            expandRowByClick,
            handleRow,
            handleSearch,
            header,
            loading,
            pagination,
            paginationSize,
            postTable,
            preHeader,
            scroll,
            searchTitle,
            title,
        } = this.props;

        let searchConfig = {};
        let sortConfig = {};
        let tableConfig = {};
        let searchForm = false;
        let gridTable = false;

        // Separate search, sort and table components from config
        if (!isArray(config)) {
            mapObject(config, (childConfig, tableName) => {
                const { search, sort, table } = this.getSeparatedConfig(childConfig);
                searchConfig[tableName] = search;
                sortConfig[tableName] = sort;
                tableConfig[tableName] = table;

                return false;
            });
        } else {
            const { search, sort, table } = this.getSeparatedConfig(config);
            searchConfig = search;
            sortConfig = sort;
            tableConfig = table;
        }

        // Make search form
        if (!isEmpty(searchConfig)) {
            searchForm = <Search title={searchTitle} config={searchConfig} column={column} onSubmit={handleSearch} onClear={this.handleClearSearch} />;
        }

        if (!isEmpty(tableConfig)) {
            // Make table
            if (!isArray(tableConfig)) {
                // Get array of table names
                const tableNames = keys(tableConfig);

                // If table config is an object then we have multiple tables
                gridTable = values(tableConfig).map((childTableConfig, index) => {
                    if (data[tableNames[index]]) {
                        // Get table name
                        const tableName = tableNames[index];

                        // If multiple sections get post table component based on name
                        let postTableComponent = postTable;
                        if (typeof postTable === 'function') {
                            postTableComponent = postTable(tableNames[index]);
                        }
                        // Return table for each section
                        return (
                            <Grid
                                config={childTableConfig}
                                data={data[tableName]}
                                expandedRowRender={expandedRowRender}
                                expandIcon={expandIcon}
                                expandRowByClick={expandRowByClick}
                                handleRow={(record, rowIndex) => handleRow(record, rowIndex, tableName)}
                                handleSort={(value) => { this.handleSort(value, tableName); }}
                                key={tableName}
                                loading={loading}
                                pagination={pagination[tableName]}
                                paginationSize={paginationSize}
                                postTable={postTableComponent}
                                scroll={scroll}
                                sortBy={this.state.sortBy}
                                sortConfig={sortConfig[tableName]}
                                subtitle={size(data) === 1 ? '' : tableName} />
                        );
                    }

                    return false;
                });

                // Add title for all tables
                gridTable.unshift(title ? <div className="section__header" key="header"><h3 className="h-4">{title}</h3></div> : false);
            } else {
                // Return table for single section
                gridTable = (
                    <Grid
                        config={tableConfig}
                        data={data}
                        expandedRowRender={expandedRowRender}
                        expandIcon={expandIcon}
                        expandRowByClick={expandRowByClick}
                        handleRow={handleRow}
                        handleSort={this.handleSort}
                        loading={loading}
                        pagination={pagination}
                        paginationSize={paginationSize}
                        postTable={postTable}
                        scroll={scroll}
                        sortBy={this.state.sortBy}
                        sortConfig={sortConfig}
                        title={title} />
                );
            }
        }

        // Add classes and pre header component
        const gridview = (
            <div className={`${header ? 'table-wrap' : 'table--nohead'} ${className}`}>
                {preHeader}
                {gridTable}
            </div>
        );

        // Put all components together
        return (
            <div className="section">
                <div className="container">
                    {searchForm ?
                        <Row gutter={48} justify="center" type="flex" style={{ flexDirection: column ? 'column' : 'row' }}>
                            <Col md={column ? {} : 9}>
                                {searchForm}
                            </Col>
                            <Col md={column ? {} : 15}>
                                {gridview}
                            </Col>
                        </Row>
                        : gridview}
                </div>
            </div>
        );
    }
}

Gridview.defaultProps = {
    className: '',
    column: false,
    expandedRowRender: null,
    expandIcon: null,
    expandRowByClick: false,
    handleClearSearch: () => {},
    handleRow: () => {},
    handleSearch: () => {},
    handleSort: () => {},
    header: true,
    loading: false,
    pagination: true,
    paginationSize: 10,
    postTable: '',
    preHeader: '',
    scroll: {},
    searchTitle: '',
    title: '',
};

Gridview.propTypes = {
    config: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    className: PropTypes.string,
    column: PropTypes.bool,
    expandedRowRender: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    expandIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    expandRowByClick: PropTypes.bool,
    handleClearSearch: PropTypes.func,
    handleRow: PropTypes.func,
    handleSearch: PropTypes.func,
    handleSort: PropTypes.func,
    header: PropTypes.bool,
    loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    paginationSize: PropTypes.number,
    postTable: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    preHeader: PropTypes.node,
    scroll: PropTypes.object,
    searchTitle: PropTypes.node,
    title: PropTypes.node,
};

export default Gridview;
