const styles = {
    // Main
    gridviewContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        paddingTop: 15,
        minWidth: '75vw',
        height: '100%',
    },
    dispatchText: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: 'green',
        fontWeight: 'bold'
    },
    greenBold: {
        color: 'green',
        fontWeight: 'bold',
    },
    selectInput: {
        control: (provided, state) => ({
            ...provided,
            flexWrap: 'nowrap',
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '25px',
            height: '25px',
            boxShadow: state.isFocused ? null : null,
            '&:hover': {
                borderColor: '#149566',
                color: '#149566'
            }
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '25px',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: 'inherit',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: 'inherit',
        }),
    },

    // Filter
    filterBarTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2d3748',
        verticalAlign: 'center',
        margin: 0,
        whiteSpace: 'nowrap',
    },
    filterLabel: {
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2d3748',
        verticalAlign: 'center',
        margin: 0,
        marginRight: 5,
        whiteSpace: 'nowrap',
    },
    filterGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    filterBarContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginTop: 10,
        marginBottom: 10,
        width: '100%',
        maxWidth: 900,
    },

    // Table
    tableHeaderRow: {
    },
    tableHeaderElement: {
        fontSize: 10,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#a0aec0',
        verticalAlign: 'center',
        padding: 5,
        borderWidth: 0,
        borderSpacing: 0,
        borderTop: '1px solid #a0aec0',
    },
    tableBodyRow: {
    },
    tableBodyElement: {
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#2d3748',
        verticalAlign: 'center',
        padding: 5,
        borderWidth: 0,
        borderSpacing: 0,
        borderTop: '1px solid #a0aec0',
    },
    table: {
        marginTop: 20,
        margin: 0,
        padding: 0,
        width: '100%',
    },
    tableBody: {

    },
    tableHeader: {

    },

    // Main
    buttonWrapper: {
        all: 'unset',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        cursor: 'pointer',
    },
};

export default styles;
