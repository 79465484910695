import { isEmpty } from 'underscore';
import { Record } from 'immutable';

const {
    GET_REFERRERS_REQUEST,
    GET_REFERRERS_SUCCESS,
    GET_REFERRERS_FAILURE,

    GET_REFERRER_REQUEST,
    GET_REFERRER_SUCCESS,
    GET_REFERRER_FAILURE,

    UPDATE_REFERRER_REQUEST,
    UPDATE_REFERRER_SUCCESS,
    UPDATE_REFERRER_FAILURE,

    GET_REFERRER_CUSTOMER_REQUEST,
    GET_REFERRER_CUSTOMER_SUCCESS,
    GET_REFERRER_CUSTOMER_FAILURE,

    ADD_REFERRER_CUSTOMER_REQUEST,
    ADD_REFERRER_CUSTOMER_SUCCESS,
    ADD_REFERRER_CUSTOMER_FAILURE,
} = require('./referrerActions').constants;

const { STATUS_ACTIVE } = require('../utils/values').default;

const initialReferrers = {
    rows: [],
    page: 1,
    total: 0,
};

const InitialState = Record({
    referrers: initialReferrers,
    referrer: {},
    customers: [],
    isFetching: false,
    error: null,
});

const initialState = new InitialState();

const initialFormValues = { status: STATUS_ACTIVE };

/**
 * ## referrerReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function referrerReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {
    case GET_REFERRERS_REQUEST:
    case GET_REFERRER_REQUEST:
    case UPDATE_REFERRER_REQUEST:
    case GET_REFERRER_CUSTOMER_REQUEST:
    case ADD_REFERRER_CUSTOMER_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case GET_REFERRERS_SUCCESS:
        return state.set('referrers', payload).set('isFetching', false).set('error', null);

    case GET_REFERRER_SUCCESS:
    case UPDATE_REFERRER_SUCCESS:
        return state.set('referrer', !isEmpty(payload) ? payload : initialFormValues).set('isFetching', false).set('error', null);

    case GET_REFERRER_CUSTOMER_SUCCESS:
    case ADD_REFERRER_CUSTOMER_SUCCESS:
        return state.set('customers', payload).set('isFetching', false).set('error', null);

    case GET_REFERRERS_FAILURE:
    case GET_REFERRER_FAILURE:
    case UPDATE_REFERRER_FAILURE:
    case GET_REFERRER_CUSTOMER_FAILURE:
    case ADD_REFERRER_CUSTOMER_FAILURE:
        return state.set('error', payload).set('isFetching', false);

    default:
        return state;
    }
}
