import { isEmpty } from 'underscore';
import { Record } from 'immutable';

const {
    GET_ORDERS_REQUEST,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_FAILURE,

    GET_ORDERS_WAITING_REQUEST,
    GET_ORDERS_WAITING_SUCCESS,
    GET_ORDERS_WAITING_FAILURE,

    GET_ORDERS_READY_REQUEST,
    GET_ORDERS_READY_SUCCESS,
    GET_ORDERS_READY_FAILURE,

    GET_ORDER_REQUEST,
    GET_ORDER_SUCCESS,
    GET_ORDER_FAILURE,

    UPDATE_ORDER_REQUEST,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_FAILURE,

    UPDATE_MISSING_PRESCRIPTION_ITEMS_OFFLINE_REQUEST,
    SELECTED_ORDER_ITEMS_REQUEST,

    CHECK_FOR_REPEAT_ORDER_ITEM_ERRORS_REQUEST,
    CHECK_FOR_REPEAT_ORDER_ITEM_ERRORS_SUCCESS,
    CHECK_FOR_REPEAT_ORDER_ITEM_ERRORS_FAILURE,

    GET_ORDERS_BACKORDER_REQUEST,
    GET_ORDERS_BACKORDER_SUCCESS,
    GET_ORDERS_BACKORDER_FAILURE,

    ORDER_ITEMS_DISPATCH_REQUEST,
    ORDER_ITEMS_DISPATCH_SUCCESS,
    ORDER_ITEMS_DISPATCH_FAILURE,

} = require('./orderActions').constants;

const { ORDER_STATUS_SCRIPT_WAITING, INTERVAL_WEEK } = require('../utils/values').default;

const initialRows = {
    rows: [],
    page: 1,
    total: 0,
};

const InitialState = Record({
    orders: initialRows,
    ordersWaiting: initialRows,
    ordersReady: initialRows,
    ordersBackorder: initialRows,
    order: {},
    missingPrescriptionItems: [],
    repeatOrderItemErrors: [],
    isFetching: false,
    isCheckingForRepeatOrderItemErrors: false,
    error: null,
    selectedOrderItemsArray: [],
});

const initialState = new InitialState();
const initialFormValues = {
    auto_order: false,
    order_status: ORDER_STATUS_SCRIPT_WAITING,
    interval_type: INTERVAL_WEEK,
    admin_notes: '',
    delivery_notes: '',
    funding_method: null,
};

/**
 * ## globalReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function globalReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {
    case GET_ORDERS_REQUEST:
    case GET_ORDERS_WAITING_REQUEST:
    case GET_ORDERS_READY_REQUEST:
    case GET_ORDERS_BACKORDER_REQUEST:
    case GET_ORDER_REQUEST:
    case UPDATE_ORDER_REQUEST:
        return state
            .set('isFetching', true)
            .set('error', null);

    case CHECK_FOR_REPEAT_ORDER_ITEM_ERRORS_REQUEST:
        return state
            .set('isCheckingForRepeatOrderItemErrors', true)
            .set('error', null);

    case UPDATE_MISSING_PRESCRIPTION_ITEMS_OFFLINE_REQUEST:
        return state.set('isFetching', true).set('missingPrescriptionItems', payload).set('error', null);


    case SELECTED_ORDER_ITEMS_REQUEST:
        return state.set('selectedOrderItemsArray', payload);

    case GET_ORDERS_SUCCESS:
        return state.set('orders', payload).set('isFetching', false);

    case GET_ORDERS_WAITING_SUCCESS:
        return state.set('ordersWaiting', payload).set('isFetching', false);

    case GET_ORDERS_READY_SUCCESS:
        return state.set('ordersReady', payload).set('isFetching', false);

    case GET_ORDERS_BACKORDER_SUCCESS:
        return state.set('ordersBackorder', payload).set('isFetching', false);

    case GET_ORDER_SUCCESS:
    case UPDATE_ORDER_SUCCESS:
        return state.set('order', !isEmpty(payload) ? payload : initialFormValues).set('isFetching', false);

    case CHECK_FOR_REPEAT_ORDER_ITEM_ERRORS_SUCCESS:
        return state.set('repeatOrderItemErrors', payload.data)
            .set('isCheckingForRepeatOrderItemErrors', false);

    case GET_ORDERS_BACKORDER_FAILURE:
    case GET_ORDERS_FAILURE:
    case GET_ORDERS_WAITING_FAILURE:
    case GET_ORDERS_READY_FAILURE:
    case GET_ORDER_FAILURE:
    case UPDATE_ORDER_FAILURE:
        return state.set('error', payload).set('isFetching', false);

    case CHECK_FOR_REPEAT_ORDER_ITEM_ERRORS_FAILURE:
        return state.set('error', payload).set('isCheckingForRepeatOrderItemErrors', false);

    case ORDER_ITEMS_DISPATCH_REQUEST:
        return state
            .set('isFetching', true)
            .set('error', null);

    case ORDER_ITEMS_DISPATCH_SUCCESS: {
        return state.set('isFetching', false);
    }
    case ORDER_ITEMS_DISPATCH_FAILURE:
        return state
            .set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}
