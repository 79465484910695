import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import DOM from './practicesCustomer';

import * as customerActions from '../../../core/customer/customerActions';

class PracticesCustomer extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        const { actions, customerId } = this.props;
        if (customerId) {
            actions.getCustomerPracticeRequest({ id: customerId });
        }
    }

    // Handle remove practice associated with customer
    handleRemovePractice = () => {
        const { actions, customerId } = this.props;
        actions.removeCustomerPracticeRequest(customerId);
    }

    render() {
        return this.view();
    }
}

PracticesCustomer.propTypes = {
    actions: PropTypes.object.isRequired,
    customerId: PropTypes.number.isRequired,
};


function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        practice: state.customer.practice,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PracticesCustomer);
