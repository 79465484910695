import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { isUndefined } from 'underscore';
import { reduxForm } from 'redux-form';

import * as nurseActions from '../../../core/nurse/nurseActions';

import DOM from './updateNurse';

class UpdateNurseForm extends Component {
    constructor(props) {
        super(props);
        this.handleSetPageName(props);
        this.view = DOM;
    }

    componentWillMount() {
        const { actions, nurseId } = this.props;
        actions.getNurseRequest(nurseId);
    }

    componentWillReceiveProps(nextProps) {
        this.handleSetPageName(nextProps);
    }

    // Handle set page name
    handleSetPageName(props) {
        const { setPageName, initialValues, nurseId, isFetching } = props;
        if (!isFetching) {
            if (!isUndefined(initialValues.first_name)) {
                setPageName(`#${initialValues.id} - ${initialValues.first_name} ${initialValues.last_name}`);
            } else if (nurseId === 0) {
                setPageName('New Nurse');
            }
        }
    }

    // Handle submit form
    handleUpdateNurseSubmit = (values) => {
        const { actions, nurseId } = this.props;
        actions.updateNurseRequest({ id: nurseId, ...values });
    };

    render() {
        return this.view();
    }
}

UpdateNurseForm.propTypes = {
    actions: PropTypes.object.isRequired,
    nurseId: PropTypes.number.isRequired,
};

const UpdateNurse = reduxForm({
    form: 'update-nurse',
    enableReinitialize: true,
})(UpdateNurseForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        initialValues: state.nurse.nurse,
        isFetching: state.nurse.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...nurseActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateNurse);
