import PropTypes from 'prop-types';
import React from 'react';

import spriteSrc from '../../assets/images/sprite.svg';

const Icon = props => (
    <svg className={`icon icon-${props.name}  ${props.className} ${!props.visible ? 'hidden' : ''}`}>
        <use xlinkHref={`${spriteSrc}#icon-${props.name}`} />
    </svg>
);

Icon.defaultProps = {
    className: '',
    name: '',
    visible: true,
};

Icon.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    visible: PropTypes.bool,
};
export default Icon;
