import { Component } from 'react';
import { browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as customerActions from '../../core/customer/customerActions';

import DOM from './customers';

class CustomersPage extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            title: 'Customers Search',
            toolbar: [{
                id: 'actionNewCustomer',
                caption: 'New customer',
                icon: 'new-order',
                onClick: () => {
                    props.actions.updateOrderSearchResultsOfflineRequest([]);
                    browserHistory.push('/customer/0');
                }
            }]
        };
    }

    render() {
        return this.view();
    }
}

CustomersPage.defaultProps = {
};

CustomersPage.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomersPage);
