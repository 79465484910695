import { checkDate } from '../../../../core/utils/helpers';
import values from '../../../../core/utils/values';

function generateOrders({ orderSearchResults }) {
    const valueLists = {
        idList: [],
        statusList: [],
        createdAtList: [],
        dueAtList: [],
        reminderSentList: [],
        valueList: [],
    };

    orderSearchResults.map((orderSearchResult) => {
        if (orderSearchResult?.order_status !== values.ORDER_STATUS_DISPATCHED) {
            valueLists.idList.push((orderSearchResult.id != null) ? orderSearchResult.id : null);
            valueLists.statusList.push((orderSearchResult.order_status != null) ? parseInt(orderSearchResult.order_status, 10) : null); //
            valueLists.createdAtList.push(orderSearchResult.created_at != null ? checkDate(orderSearchResult.created_at, 'output', 'DD/MM/YYYY') : 'N/a');
            valueLists.dueAtList.push(orderSearchResult.due_date != null ? checkDate(orderSearchResult.due_date, 'output', 'DD/MM/YYYY') : 'N/a');
            valueLists.reminderSentList.push(orderSearchResult.letters.filter(x => x.type === 3));
            valueLists.valueList.push((orderSearchResult.orderValue != null) ? `£${Math.round(orderSearchResult.orderValue * 100) / 100}` : 'N/a');
        }
    });

    return [
        {
            fieldName: 'order_id',
            fieldLabel: 'Order Id',
            fieldValues: valueLists.idList,
        },
        {
            fieldName: 'status',
            fieldLabel: 'Status',
            fieldValues: valueLists.statusList,
        },
        {
            fieldName: 'created_at',
            fieldLabel: 'Created At',
            fieldValues: valueLists.createdAtList,
        },
        {
            fieldName: 'due_at',
            fieldLabel: 'Due At',
            fieldValues: valueLists.dueAtList,
        },
        {
            fieldName: 'value',
            fieldLabel: 'Value',
            fieldValues: valueLists.valueList,
        },
        {
            fieldName: 'confirm_order_btn',
            fieldLabel: '',
            fieldValues: valueLists.idList,
        }
    ];
}

function generateItems({ itemList, activeOrderId }) {
    const valueLists = {
        orderIdList: [],
        itemIdList: [],
        scriptStatusList: [],
        deliveryStatusList: [],
        quantityList: [],
        quantityListItem: [],
        quantityAllocatedList: [],
        descriptionList: [],
        valueList: [],
        deliveryDateList: [],
        dispatchDateList: [],
        dueDateList: [],
        quantityMatchedList: [],
        quantityDispatchedList: [],
        totalValue: [],
        quantityDispatched: [],
    };

    itemList.map((item) => {
        if (item?.delivery_status !== values.ITEM_DELIVERY_STATUS_DISPATCHED) {
            valueLists.orderIdList.push(activeOrderId);
            valueLists.itemIdList.push((item.id != null) ? item.id : null);
            valueLists.scriptStatusList.push((item.script_status != null) ? item.script_status : null); //
            valueLists.deliveryStatusList.push((item.delivery_status != null) ? item.delivery_status : null); //
            valueLists.quantityList.push((item.quantity != null) ? item.quantity : 'N/a');
            valueLists.quantityListItem.push([item.item_quantity, item.quantity]);
            valueLists.quantityDispatched.push([item.quantity_dispatched]);
            valueLists.quantityAllocatedList.push((item.product.quantity != null) ? item.product.quantity : null); //
            valueLists.descriptionList.push((item.product.description != null) ? item.product.description : 'N/a');
            valueLists.deliveryDateList.push((item.delivery_date) ? checkDate(item.delivery_date, 'output', 'DD/MM/YYYY') : 'N/a');
            valueLists.dispatchDateList.push((item.dispatch_date) ? checkDate(item.dispatch_date, 'output', 'DD/MM/YYYY') : null); //
            valueLists.dueDateList.push((item.due_date) ? checkDate(item.due_date, 'output', 'DD/MM/YYYY') : 'N/a');
            valueLists.valueList.push((item.product.price) ? `£${Math.round(item.product.price * 100) / 100}` : 'N/a');
            valueLists.quantityMatchedList.push([item.quantity_matched, item.quantityIncludingMissing]);
            valueLists.quantityDispatchedList.push([item.quantity_dispatched, item.quantityIncludingMissing]);
            valueLists.totalValue.push((item.product.price) ? `£${Math.round(item.product.price * 100 * item?.quantity) / 100}` : 'N/a');
        }
    });

    return [
        {
            fieldName: 'select',
            fieldLabel: '',
            fieldValues: valueLists.orderIdList,
        },
        {
            fieldName: 'order_id',
            fieldLabel: 'Order Id',
            fieldValues: valueLists.orderIdList,
        },
        {
            fieldName: 'item_id',
            fieldLabel: 'Item Id',
            fieldValues: valueLists.itemIdList,
        },
        {
            fieldName: 'description',
            fieldLabel: 'Description',
            fieldValues: valueLists.descriptionList,
        },
        {
            fieldName: 'delivery_status',
            fieldLabel: 'Delivery Status',
            fieldValues: valueLists.deliveryStatusList,
        },
        {
            fieldName: 'quantity_instock',
            fieldLabel: 'Quantity In Stock',
            fieldValues: valueLists.quantityAllocatedList,
        },
        {
            fieldName: 'quantity',
            fieldLabel: 'Quantity',
            fieldValues: valueLists.quantityList,
        },
        {
            fieldName: 'quantity_dispatched',
            fieldLabel: 'Quantity Dispatched',
            fieldValues: valueLists.quantityDispatchedList,
        },
        {
            fieldName: 'delivery_date',
            fieldLabel: 'Delivery Date',
            fieldValues: valueLists.deliveryDateList,
        },
        {
            fieldName: 'due_date',
            fieldLabel: 'Due Date',
            fieldValues: valueLists.dueDateList,
        },
        {
            fieldName: 'dispatch_date',
            fieldLabel: 'Dispatch Date',
            fieldValues: valueLists.dispatchDateList,
        },
        {
            fieldName: 'Unit value',
            fieldLabel: 'Value',
            fieldValues: valueLists.valueList,
        },
        {
            fieldName: 'total_value',
            fieldLabel: 'Total value',
            fieldValues: valueLists.totalValue,
        },
    ];
}

function orderStatusOptions() {
    return [
        {
            label: 'No script',
            value: values.ORDER_STATUS_NO_SCRIPT,
        },
        {
            label: 'Script partly received',
            value: values.ORDER_STATUS_SCRIPT_PARTLY_RECEIVED,
        },
        {
            label: 'Script received',
            value: values.ORDER_STATUS_SCRIPT_RECEIVED,
        },
        {
            label: 'Partly processed',
            value: values.ORDER_STATUS_PARTLY_PROCESSED,
        },
        {
            label: 'Processed',
            value: values.ORDER_STATUS_PROCESSED,
        },
        {
            label: 'Cancelled',
            value: values.ORDER_STATUS_CANCELLED,
        },
    ];
}

function itemScriptStatusOptions() {
    return [
        {
            label: 'Matched',
            value: values.ORDER_ITEM_STATUS_MATCHED,
        },
        {
            label: 'Clear',
            value: values.ORDER_ITEM_STATUS_CLEAR,
        },
        {
            label: 'Written off',
            value: values.ORDER_ITEM_STATUS_WRITTEN_OFF,
        },
        {
            label: 'Item cancelled',
            value: values.ORDER_ITEM_STATUS_ITEM_CANCELLED,
        },
        {
            label: 'Issue reminder',
            value: values.ORDER_ITEM_STATUS_REMINDER_ISSUED,
        },
    ];
}

function itemDeliveryStatusOptions() {
    return [
        {
            label: 'Picking',
            value: 0,
        },
        {
            label: 'Dispatch',
            value: 1,
        },
        {
            label: 'Backorder',
            value: 2,
        },
        {
            label: 'Processed',
            value: 3,
        },
        {
            label: 'Cancelled',
            value: 4,
        },
    ];
}

export { generateOrders, generateItems, orderStatusOptions, itemScriptStatusOptions, itemDeliveryStatusOptions };
