import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import './orderSummaryModal.scss';

const OrderSummaryModal = ({ customer, orderId }) => {
    const foundOrder = customer.customerOrders.find(order => order.id === orderId);

    if (!foundOrder) {
        return null;
    }

    const col1 = '16%';
    const col2 = '15%';
    const col3 = '13%';
    const col4 = '49%';
    const col5 = '7%';
    const col1_2 = '31%';

    const scriptHistories = [...foundOrder.scriptHistories];

    for (let i = 0; i < scriptHistories.length; i++) {
        const script0 = scriptHistories[i];
        script0.extraScripts = [];

        for (let j = scriptHistories.length - 1; j > i; j--) {
            const script1 = scriptHistories[j];

            if (script0.created_at === script1.created_at) {
                script0.extraScripts.push(script1);
                scriptHistories.splice(j, 1);
            }
        }
    }

    for (let i = 0; i < scriptHistories.length; i++) {
        const scriptHistory = scriptHistories[i];
        const scriptHistoryDate = new Date(scriptHistory.created_at);
        scriptHistory.items = [];

        for (let j = 0; j < foundOrder.orderItems.length; j++) {
            const orderItem = foundOrder.orderItems[j];

            for (let k = 0; k < orderItem.itemScriptHistories.length; k++) {
                const itemScriptHistory = orderItem.itemScriptHistories[k];
                const itemScriptHistoryDate = new Date(itemScriptHistory.created_at);

                if (Math.abs(scriptHistoryDate - itemScriptHistoryDate) <= 5000) {
                    scriptHistory.items.push(itemScriptHistory);
                }
            }
        }
    }

    const getOrderStatus = () => {
        let statusText = '';
        let color = '';
        switch (foundOrder?.script_status) {
        case 0:
            statusText = 'No Script';
            color = 'red';
            break;
        case 1:
            statusText = 'Script Partly Received';
            color = 'orange';
            break;
        case 2:
            statusText = 'Script Received';
            color = 'green';
            break;
        default:
            statusText = 'Unknown';
            color = 'black';
            break;
        }
        return <span style={{ color }}>{statusText}</span>;
    };

    const getItemRows = () => {
        const getFirstRow = orderItem => (
            <div className="summary-row" key={orderItem.id}>
                <h5 className="align-right" style={{ width: col1 }}>Created</h5>
                <span style={{ width: col2 }}>{moment(foundOrder.created_at).format('DD/MM/YY HH:mm')}</span>
                <span style={{ width: col3 }}>{orderItem.id}</span>
                <span style={{ width: col4 }}>{orderItem.product.description}</span>
                <span style={{ width: col5 }} className="padding-left">{orderItem.quantity}</span>
            </div>
        );

        const getItemRow = orderItem => (
            <div className="summary-row" key={orderItem.id}>
                <div style={{ width: col1_2 }} />
                <span style={{ width: col3 }}>{orderItem.id}</span>
                <span style={{ width: col4 }}>{orderItem.product.description}</span>
                <span style={{ width: col5 }} className="padding-left">{orderItem.quantity}</span>
            </div>);

        return foundOrder.orderItems.map((orderItem, index) => {
            if (index === 0) {
                return getFirstRow(orderItem);
            }
            return getItemRow(orderItem);
        });
    };

    const getRecievedRows = () => {
        const getFirstRow = (item) => {
            const foundItem = foundOrder.orderItems.find(orderItem => orderItem.id === item.order_item_id);
            return (
                <div className="summary-row" key={item.id}>
                    <h5 className="align-right" style={{ width: col1 }}>Received</h5>
                    <span style={{ width: col2 }}>{moment(item.created_at).format('DD/MM/YY HH:mm')}</span>
                    <span style={{ width: col3 }}>{item.order_item_id}</span>
                    <span style={{ width: col4 }}>{foundItem.product.description}</span>
                    <span style={{ width: col5 }} className="padding-left">{item.quantity}</span>
                </div>
            );
        };

        const getItemRow = (item) => {
            const foundItem = foundOrder.orderItems.find(orderItem => orderItem.id === item.order_item_id);
            return (
                <div className="summary-row" key={item.id}>
                    <div style={{ width: col1_2 }} />
                    <span style={{ width: col3 }}>{item.order_item_id}</span>
                    <span style={{ width: col4 }}>{foundItem.product.description}</span>
                    <span style={{ width: col5 }} className="padding-left">{item.quantity}</span>
                </div>
            );
        };

        const getScriptDetails = scriptHistory => (
            <div className="summary-row" key={scriptHistory.id}>
                <h5 className="align-right" style={{ width: col1 }}>Allocated from</h5>
                <span style={{ width: col2 }} className="background-yellow font-bold">{scriptHistory.number_of_forms} Script/{scriptHistory.number_of_lines} {scriptHistory.number_of_lines > 1 ? 'Lines' : 'Line'}</span>
                <span style={{ width: col3 }} className="background-yellow font-bold">Issued On</span>
                <span style={{ width: col4 }} className="background-yellow font-bold">{moment(scriptHistory.created_at).format('DD/MM/YY HH:mm')}</span>
                <span style={{ width: col5 }} className="padding-left font-bold">{scriptHistory.is_electronic ? 'ETP' : ''}</span>
            </div>
        );

        const renderScriptHistory = scriptHistory => (
            <>
                <section className="script-history-section">
                    <div>
                        {scriptHistory.items.map((item, index) => {
                            if (index === 0) {
                                return getFirstRow(item);
                            }
                            return getItemRow(item);
                        })}
                    </div>
                    {getScriptDetails(scriptHistory)}
                    {scriptHistory.extraScripts && scriptHistory.extraScripts.map(extraScript => getScriptDetails(extraScript))}
                </section>
            </>
        );

        return scriptHistories.map(scriptHistory => renderScriptHistory(scriptHistory));
    };

    return (
        <div className="order-summary-modal-container">
            <div className="order-summary-header">
                <div style={{ width: col1_2 }} />
                <h3 style={{ width: col3 }}>Item Code</h3>
                <h3 style={{ width: col4 }}>Description</h3>
                <h3 style={{ width: col5 }} className="padding-left">Qty</h3>
            </div>
            <div className="order-summary-body">

                <div className="summary-row">
                    <h5 className="align-right" style={{ width: col1 }}>Order No.</h5>
                    <span style={{ width: col2 }}>{foundOrder.id}</span>
                    <div style={{ width: col3 }} />
                    <div style={{ width: col4 }}>
                        {getOrderStatus()}
                    </div>
                    <div style={{ width: col5 }} />
                </div>
                <section className="script-history-section">
                    {getItemRows()}
                </section>
                {getRecievedRows()}
            </div>
        </div>
    );
};

OrderSummaryModal.propTypes = {
    orderId: PropTypes.number.isRequired,
    customer: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    customer: state.customer.customer,
});

export default connect(mapStateToProps)(OrderSummaryModal);

