import React from 'react';
import { Row, Col } from 'antd';

import CustomersHome from '../../components/gridviews/CustomersHome';
import Layout from '../../components/layout/Layout';
import NursesHome from '../../components/gridviews/NursesHome';
import UpdateCareHome from '../../components/forms/UpdateCareHome';

export default function () {
    const { name, toolbar } = this.state;
    const id = parseInt(this.props.params.id, 10);
    const breadcrumb = { name: 'care-home', params: { id, name } };

    return (
        <Layout title={name} toolbar={toolbar} breadcrumb={breadcrumb}>
            <div className="container">
                <div className="section">
                    <UpdateCareHome carehomeId={id} setPageName={this.setPageName} />
                </div>

                {id ?
                    <div className="mt-3 mb-2">
                        <div className="section__header">
                            <h3 className="h-4-bold">Care Home associations</h3>
                        </div>
                        <Row gutter={80} type="flex" justify="center" className="section-p-0">
                            <Col span={24} lg={12}>
                                <NursesHome carehomeId={id} />
                            </Col>
                            <Col span={24} lg={12}>
                                <CustomersHome carehomeId={id} />
                            </Col>
                        </Row>
                    </div>
                : false}
            </div>
        </Layout>
    );
}
