import PropTypes from 'prop-types';
import alertify from 'alertify.js';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { find, isEmpty } from 'underscore';
import { reduxForm, getFormValues } from 'redux-form';

import * as orderActions from '../../../core/order/orderActions';

import DOM from './splitOrderForm';

class SplitOrderFormPre extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    // Handle submit split order
    handleSplitOrderSubmit = (values) => {
        const {
            actions,
            reset,
            closeModal,
            orderId,
            customerId,
            formValues,
            letterList,
            orderPickingAndDispatchNote,
            prescriptionInformation,
            products,
            initialValues,
            orderProducts
        } = this.props;

        const productsToTransmit = values.split_order.products.map((productA) => {
            const productABuffer = productA;
            const selectedProduct = orderProducts.find(productB => productB.id === productA.id);
            productABuffer.orderItemRepeatInstruction = selectedProduct.orderItemRepeatInstruction;

            return productABuffer;
        });

        if (customerId && !isEmpty(values.split_order.products)) {
            const data = {
                id: orderId,
                order: {
                    customer_id: customerId,
                    letterList: letterList.filter(letter => !isEmpty(letter.text)),
                    orderPickingAndDispatchNote: !Object.keys(orderPickingAndDispatchNote).map(key => orderPickingAndDispatchNote[key]).every(text => isEmpty(text)) ? orderPickingAndDispatchNote : null,
                    prescriptionInformation,
                    split_order: {
                        script_reference: values.split_order.script_reference,
                    },
                    ...formValues,
                },
                products: productsToTransmit,
            };

            actions.updateOrderRequest(data);
        } else if (!customerId) {
            alertify.error('Please select a customer');
        } else if (isEmpty(values.split_order.products)) {
            alertify.error('Please select a product');
        }
        closeModal();
        reset();
    };

    render() {
        return this.view();
    }
}

SplitOrderFormPre.defaultProps = {
    letterList: [],
    orderPickingAndDispatchNote: {},
    prescriptionInformation: {},
    closeModal: () => {},
    formValues: false,
    initialValues: [],
};

SplitOrderFormPre.propTypes = {
    letterList: PropTypes.array,
    orderPickingAndDispatchNote: PropTypes.object,
    prescriptionInformation: PropTypes.object,
    reset: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    orderId: PropTypes.number.isRequired,
    customerId: PropTypes.number.isRequired,
    formValues: PropTypes.object.isRequired,
    closeModal: PropTypes.func,
    products: PropTypes.array.isRequired,
    initialValues: PropTypes.array.isRequired,
    orderProducts: PropTypes.array.isRequired,
};

const SplitOrderForm = reduxForm({
    form: 'split-order',
    enableReinitialize: true,
})(SplitOrderFormPre);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        initialValues: ownProps.initialValues,
        formValues: getFormValues('update-order')(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...orderActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SplitOrderForm);
