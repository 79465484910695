import { put, call, takeEvery } from 'redux-saga/effects';
import alertify from 'alertify.js';
import { isEmpty, isUndefined, mapObject } from 'underscore';
import { notification } from 'antd';
import { saveAs } from 'file-saver';
import { fetchApiAuth } from '../utils/api';

import { getOrdersWaitingRequest } from '../order/orderActions';
import {
    setChasersSuccess,
    setChasersFailure,

    runChasersSuccess,
    runChasersFailure,

    markCommAsReceivedSuccess,
    markCommAsReceivedFailure,
} from './chaserActions';

const {
    SET_CHASERS_REQUEST,
    RUN_CHASERS_REQUEST,
    MARK_COMM_AS_RECEIVED_REQUEST,
} = require('./chaserActions').constants;

const search = {
    page: 1,
    page_size: 10
};

const makeChaserData = (payload) => {
    const data = {};

    mapObject(payload, (orderIds, type) => {
        orderIds.forEach((orderId) => {
            if (isUndefined(data[orderId])) {
                data[orderId] = [type];
            } else {
                data[orderId].push(type);
            }
        });
    });

    return data;
};

function* setChasers({ payload }) {
    try {
        yield put(setChasersSuccess(payload));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(setChasersFailure(e.response ? e.response.data.message : e));
    }
}

function* runChasers({ payload }) {
    try {
        const orders = makeChaserData(payload);
        if (!isEmpty(orders)) {
            const response = yield call(fetchApiAuth, {
                method: 'POST',
                url: '/order/run-chaser',
                body: { orders },
                responseType: 'blob'
            });
            if (response && response.headers && response.headers['content-type'] == 'application/zip') {
                const blob = new Blob([response.data], { type: 'application/zip' });
                saveAs(blob, 'chasers.zip');
            }
        }
        notification.success({ message: 'Success', description: 'Successfully ran chasers' });
        yield put(getOrdersWaitingRequest(search));
        yield put(runChasersSuccess());
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(runChasersFailure(e.response ? e.response.data.message : e));
    }
}

function* markCommAsReceived({ payload }) {
    try {
        yield call(fetchApiAuth, {
            method: 'POST',
            url: '/order/comm-received',
            body: payload
        });

        yield put(getOrdersWaitingRequest(search));
        yield put(markCommAsReceivedSuccess(payload));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(markCommAsReceivedFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* chaserSaga() {
    yield* [
        takeEvery(SET_CHASERS_REQUEST, setChasers),
        takeEvery(RUN_CHASERS_REQUEST, runChasers),
        takeEvery(MARK_COMM_AS_RECEIVED_REQUEST, markCommAsReceived),
    ];
}
