import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_SUPPLIERS_REQUEST: null,
    GET_SUPPLIERS_SUCCESS: null,
    GET_SUPPLIERS_FAILURE: null,

    GET_SUPPLIER_REQUEST: null,
    GET_SUPPLIER_SUCCESS: null,
    GET_SUPPLIER_FAILURE: null,

    UPDATE_SUPPLIER_REQUEST: null,
    UPDATE_SUPPLIER_SUCCESS: null,
    UPDATE_SUPPLIER_FAILURE: null,

    GET_SUPPLIER_PRODUCT_REQUEST: null,
    GET_SUPPLIER_PRODUCT_SUCCESS: null,
    GET_SUPPLIER_PRODUCT_FAILURE: null,

    ADD_SUPPLIER_PRODUCT_REQUEST: null,
    ADD_SUPPLIER_PRODUCT_SUCCESS: null,
    ADD_SUPPLIER_PRODUCT_FAILURE: null,

    REMOVE_SUPPLIER_PRODUCT_REQUEST: null,
    REMOVE_SUPPLIER_PRODUCT_SUCCESS: null,
    REMOVE_SUPPLIER_PRODUCT_FAILURE: null,
});

export const {
    getSuppliersRequest,
    getSuppliersSuccess,
    getSuppliersFailure
} = createActions(
    constants.GET_SUPPLIERS_REQUEST,
    constants.GET_SUPPLIERS_SUCCESS,
    constants.GET_SUPPLIERS_FAILURE
);

export const {
    getSupplierRequest,
    getSupplierSuccess,
    getSupplierFailure
} = createActions(
    constants.GET_SUPPLIER_REQUEST,
    constants.GET_SUPPLIER_SUCCESS,
    constants.GET_SUPPLIER_FAILURE
);

export const {
    updateSupplierRequest,
    updateSupplierSuccess,
    updateSupplierFailure
} = createActions(
    constants.UPDATE_SUPPLIER_REQUEST,
    constants.UPDATE_SUPPLIER_SUCCESS,
    constants.UPDATE_SUPPLIER_FAILURE
);

export const {
    getSupplierProductRequest,
    getSupplierProductSuccess,
    getSupplierProductFailure
} = createActions(
    constants.GET_SUPPLIER_PRODUCT_REQUEST,
    constants.GET_SUPPLIER_PRODUCT_SUCCESS,
    constants.GET_SUPPLIER_PRODUCT_FAILURE
);

export const {
    addSupplierProductRequest,
    addSupplierProductSuccess,
    addSupplierProductFailure
} = createActions(
    constants.ADD_SUPPLIER_PRODUCT_REQUEST,
    constants.ADD_SUPPLIER_PRODUCT_SUCCESS,
    constants.ADD_SUPPLIER_PRODUCT_FAILURE
);

export const {
    removeSupplierProductRequest,
    removeSupplierProductSuccess,
    removeSupplierProductFailure
} = createActions(
    constants.REMOVE_SUPPLIER_PRODUCT_REQUEST,
    constants.REMOVE_SUPPLIER_PRODUCT_SUCCESS,
    constants.REMOVE_SUPPLIER_PRODUCT_FAILURE
);
