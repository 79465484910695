import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import DOM from './nurse';

class NursePage extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            name: parseInt(props.params.id, 10) ? `#${props.params.id}` : 'New Nurse',
            toolbar: [{ id: 'actionSave', caption: 'Save', icon: 'save', onClick: this.handleFormSubmit }]
        };
    }

    // Handle set page name
    setPageName = name => this.setState({ name });

    // Handle submit update nurse form
    handleFormSubmit = () => this.props.actions.submit('update-nurse');

    render() {
        return this.view();
    }
}

NursePage.propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ submit }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NursePage);
