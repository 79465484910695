import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DOM from './routeLayout';

import Loading from '../../../elements/Loading';

import * as globalActions from '../../../core/global/globalActions';

class RouteLayout extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    render() {
        const { rehydrationCompleted } = this.props;

        // wait until rehydration is completed by redux from local storage
        if (!rehydrationCompleted) {
            return <Loading className="fully-loading" />;
        }

        return this.view();
    }
}

RouteLayout.propTypes = {
    rehydrationCompleted: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        rehydrationCompleted: state.global.rehydrationCompleted,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...globalActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteLayout);
