import moment from 'moment';
import { notification } from 'antd';
import { isEmpty, isUndefined, isNull } from 'underscore';
import { put, call, takeEvery } from 'redux-saga/effects';
import { browserHistory } from 'react-router';

import { fetchApiAuth, formatParams } from '../utils/api';
import { addKeyProp, mapPropsToInts, mapPropsToDbDates, mapPropsToDisplayDates } from '../utils/helpers';

import {
    getCustomerRequest,
    getCustomerDoctorRequest,
    getCustomerPracticeRequest,
} from '../customer/customerActions';

import {
    getOrdersSuccess,
    getOrdersFailure,

    getOrdersWaitingSuccess,
    getOrdersWaitingFailure,

    getOrdersReadySuccess,
    getOrdersReadyFailure,

    getOrderSuccess,
    getOrderFailure,

    updateOrderSuccess,
    updateOrderFailure,

    checkForRepeatOrderItemErrorsSuccess,
    checkForRepeatOrderItemErrorsFailure,

    getOrdersBackorderSuccess,
    getOrdersBackorderFailure,

    orderItemsDispatchSuccess,
    orderItemsDispatchFailure,
} from './orderActions';

const {
    GET_ORDERS_REQUEST,
    GET_ORDERS_WAITING_REQUEST,
    GET_ORDERS_READY_REQUEST,
    GET_ORDER_REQUEST,
    UPDATE_ORDER_REQUEST,
    CHECK_FOR_REPEAT_ORDER_ITEM_ERRORS_REQUEST,
    GET_ORDERS_BACKORDER_REQUEST,
    ORDER_ITEMS_DISPATCH_REQUEST,
} = require('./orderActions').constants;

const {
    ORDER_STATUS_SCRIPT_WAITING,
    ORDER_STATUS_SCRIPT_SUPPLIED,
    ORDER_STATUS_WITHOUT_SCRIPT,
    ORDER_STATUS_DISPATCH,
    ORDER_STATUS_DISPATCH_WITHOUT_SCRIPT,
    ORDER_STATUS_NO_SCRIPT,
    DB_DATE_FORMAT,
    ORDER_STATUS_PARTLY_PROCESSED,
    ORDER_STATUS_WAITING_FOR_DISPATCH
} = require('../utils/values').default;

const getOrderStatus = (status) => {
    if (status === ORDER_STATUS_DISPATCH) {
        return ORDER_STATUS_SCRIPT_SUPPLIED;
    }
    if (status === ORDER_STATUS_DISPATCH_WITHOUT_SCRIPT) {
        return ORDER_STATUS_WITHOUT_SCRIPT;
    }
    return status;
};

const getOrderData = (data) => {
    let order = {
        id: data.id,
        script_reference: data.script_reference,
        order_status: getOrderStatus(data.order_status),
        auto_order: false,
        order_notes: data.order_notes,
        delivery_notes: data.customer.delivery_notes,
        funding_method: data.funding_method,
        certificate_no: data.certificate_no,
        certificate_expiry_date: data.certificate_expiry_date,
        letter_notes: data.letter_notes,
        is_dispatched: [ORDER_STATUS_DISPATCH, ORDER_STATUS_DISPATCH_WITHOUT_SCRIPT].includes(data.order_status),
        orderItems: data.orderItems,
        orderPickingAndDispatchNote: data.orderPickingAndDispatchNote,
        letters: data.letters,
        national_insurance_no: data.national_insurance_no,
    };

    if (data.order_status === ORDER_STATUS_SCRIPT_WAITING || (isNull(data.funding_method) && data.script_reference)) {
        // order.funding_method = '-';
    }

    if (!isNull(data.items) && !isUndefined(data.items) && !isEmpty(data.items)) {
        order.products = [];
        data.items.forEach((item) => {
            order.products.push({ id: item.product_id, quantity: item.quantity });
        });
    }

    if (!isNull(data.repeat) && !isUndefined(data.repeat) && !isEmpty(data.repeat)) {
        order.auto_order = true;
        order.interval = data.repeat.interval;
        order.interval_type = data.repeat.interval_type;
        order.end_date = data.repeat.end_date;
    }

    order = mapPropsToInts(order, ['order_status']);
    order = mapPropsToDisplayDates(order, ['end_date', 'certificate_expiry_date']);

    return order;
};

const makeOrderData = (order, products) => {
    const product_id = [];
    const quantity = [];
    const orderItemRepeatInstructions = [];
    const split_quantity = [];

    products.forEach((product) => {
        product_id.push(product.id);
        quantity.push(product.quantity);
        orderItemRepeatInstructions.push(product.orderItemRepeatInstruction || null);
        if (product.split_quantity) {
            split_quantity.push(product.split_quantity);
        }
    });

    const data = {
        ...order,
        product_id,
        quantity,
        orderItemRepeatInstructions,
        auto_order: order.auto_order ? 1 : 0,
        end_date: order.end_date || null,
        order_date: moment().format(DB_DATE_FORMAT),
    };

    if (order.funding_method === '-') {
        delete data.funding_method;
    }

    if (!isEmpty(split_quantity)) {
        data.split_order = {
            product_id,
            quantity: split_quantity,
            script_reference: order.split_order.script_reference
        };
    }

    return mapPropsToDbDates(data, ['end_date', 'certificate_expiry_date']);
};

function* getOrders({ payload }) {
    try {
        const params = mapPropsToDbDates(payload, ['order_date_to', 'order_date_from']);
        if (payload.sort === 'customer_name') {
            params.sort = 'last_name';
        }
        if (payload.order_status === ORDER_STATUS_SCRIPT_SUPPLIED) {
            params.order_status = [ORDER_STATUS_SCRIPT_SUPPLIED, ORDER_STATUS_WITHOUT_SCRIPT];
        }
        if (payload.order_status === ORDER_STATUS_DISPATCH) {
            params.order_status = [ORDER_STATUS_DISPATCH, ORDER_STATUS_DISPATCH_WITHOUT_SCRIPT];
        }
        if (isUndefined(params.sort)) {
            params.sort = '-created_at';
        }

        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/orders',
            params
        });

        let orders = {
            rows: addKeyProp(response.data),
            page: response.headers['x-pagination-current-page'],
            total: response.headers['x-pagination-total-count'],
        };
        orders = mapPropsToInts(orders, ['page', 'total']);

        yield put(getOrdersSuccess(orders));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getOrdersFailure(e.response ? e.response.data.message : e));
    }
}

function* getOrdersWaiting({ payload }) {
    try {
        const params = formatParams(payload);
        params.order_status = [ORDER_STATUS_SCRIPT_WAITING, ORDER_STATUS_NO_SCRIPT];
        params.sort = '-created_at';
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/orders',
            params
        });

        let orders = {
            rows: addKeyProp(response.data),
            page: response.headers['x-pagination-current-page'],
            total: response.headers['x-pagination-total-count'],
        };
        orders = mapPropsToInts(orders, ['page', 'total']);

        yield put(getOrdersWaitingSuccess(orders));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getOrdersWaitingFailure(e.response ? e.response.data.message : e));
    }
}

function* getOrdersReady({ payload }) {
    try {
        const params = formatParams(payload);
        params.order_status = [ORDER_STATUS_SCRIPT_SUPPLIED, ORDER_STATUS_WITHOUT_SCRIPT];
        params.sort = '-created_at';

        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/orders',
            params
        });

        let orders = {
            rows: addKeyProp(response.data),
            page: response.headers['x-pagination-current-page'],
            total: response.headers['x-pagination-total-count'],
        };
        orders = mapPropsToInts(orders, ['page', 'total']);

        yield put(getOrdersReadySuccess(orders));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getOrdersReadyFailure(e.response ? e.response.data.message : e));
    }
}

function* getOrder({ payload }) {
    try {
        let data = {};
        const customerSearch = {};
        const params = formatParams(payload);

        if (params.id !== 0) {
            const response = yield call(fetchApiAuth, {
                method: 'GET',
                url: `/order/${params.id}`
            });

            data = getOrderData(response.data);
            customerSearch.id = response.data.customer.id;
            if (response.data.customer.addresses.billing) {
                customerSearch.address_id = response.data.customer.addresses.billing.id;
            }
            if (response.data.customer.profile) {
                customerSearch.profile_id = response.data.customer.profile.id;
            }
            if (response.data.customer.practice) {
                customerSearch.practice_id = response.data.customer.practice.id;
            }
            if (response.data.customer.doctor) {
                customerSearch.doctor_id = response.data.customer.doctor.id;
            }
        }

        if (isEmpty(customerSearch) && !isUndefined(params.customer_id) && !isNull(params.customer_id)) {
            customerSearch.id = params.customer_id;
        }

        yield put(getCustomerRequest(customerSearch));
        yield put(getCustomerDoctorRequest(customerSearch));
        yield put(getCustomerPracticeRequest(customerSearch));
        yield put(getOrderSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getOrderFailure(e.response ? e.response.data.message : e));
    }
}

function* updateOrder({ payload }) {
    try {
        const body = makeOrderData(payload.order, payload.products);
        const activeTab = payload?.activeTab;
        let url = '/order';
        let method = 'POST';
        if (payload.id !== 0) {
            url += `/${payload.id}`;
            method = 'PUT';
        }

        const response = yield call(fetchApiAuth, { method, url, body });
        const order = getOrderData(response.data);

        if (order) {
            const customerID = order?.orderPickingAndDispatchNote?.order?.customer_id;

            notification.success({ message: 'Success', duration: 1, description: 'Order has been updated' });
            // if selected tab is 4 means save and submit hence redirect to customer page
            if (+activeTab === 3) {
                browserHistory.push(`/customer/${customerID}`);
            } else {
                browserHistory.push(`/order/${order.id}`);
            }
        }

        yield put(updateOrderSuccess(order));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(updateOrderFailure(e.response ? e.response.data.message : e));
    }
}

function* checkForRepeatOrderItemErrors() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/order/check-for-repeat-order-item-errors'
        });

        yield put(checkForRepeatOrderItemErrorsSuccess(response));
    } catch (e) {
        // alertify.error(e.response ? e.response.data.message : e);
        yield put(checkForRepeatOrderItemErrorsFailure(e.response ? e.response.data.message : e));
    }
}

function* getOrdersBackorder({ payload }) {
    try {
        const params = formatParams(payload);
        params.order_status = [ORDER_STATUS_PARTLY_PROCESSED, ORDER_STATUS_WAITING_FOR_DISPATCH];
        params.sort = '-created_at';

        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/orders',
            params
        });

        let orders = {
            rows: addKeyProp(response.data),
            page: response.headers['x-pagination-current-page'],
            total: response.headers['x-pagination-total-count'],
        };
        orders = mapPropsToInts(orders, ['page', 'total']);

        yield put(getOrdersBackorderSuccess(orders));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getOrdersBackorderFailure(e.response ? e.response.data.message : e));
    }
}

function* orderItemsDispatch({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/order/dispatch-order-items',
            body: payload,
        });
        yield put(orderItemsDispatchSuccess(response.data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(orderItemsDispatchFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* gallerySaga() {
    yield* [
        takeEvery(GET_ORDERS_REQUEST, getOrders),
        takeEvery(GET_ORDERS_WAITING_REQUEST, getOrdersWaiting),
        takeEvery(GET_ORDERS_READY_REQUEST, getOrdersReady),
        takeEvery(GET_ORDER_REQUEST, getOrder),
        takeEvery(UPDATE_ORDER_REQUEST, updateOrder),
        takeEvery(CHECK_FOR_REPEAT_ORDER_ITEM_ERRORS_REQUEST, checkForRepeatOrderItemErrors),
        takeEvery(GET_ORDERS_BACKORDER_REQUEST, getOrdersBackorder),
        takeEvery(ORDER_ITEMS_DISPATCH_REQUEST, orderItemsDispatch),
    ];
}
