import PropTypes from 'prop-types';
import React from 'react';
import { Select } from 'antd';

import Icon from '../../elements/Icon';

const Option = Select.Option;

const Sort = ({ options, sortBy, handleChange }) => {
    const optionsList = options.map((option) => {
        let value = option.dataIndex;
        if (option.sortDirection) {
            value = `${option.sortDirection.toLowerCase() === 'desc' ? '-' : ''}${value}`;
        }
        return <Option key={value} value={value}>{option.title}</Option>;
    });

    return (
        <span className="section__label">
            <div className="select-naked">
                <Select
                    onChange={handleChange}
                    placeholder="sort by"
                    style={{ width: 150 }}
                    suffixIcon={<Icon name="arrow-down" className="anticon anticon-down ant-select-arrow-icon" />}
                    value={sortBy || undefined}>
                    {optionsList}
                </Select>
            </div>
        </span>
    );
};

Sort.defaultProps = {
    sortBy: null,
};

Sort.propTypes = {
    handleChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    sortBy: PropTypes.node,
};

export default Sort;
