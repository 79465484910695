import PropTypes from 'prop-types';
import React from 'react';

import logoSrc from '../../assets/images/main-logo.svg';

const LogoMain = ({ width }) => <img className="github" src={logoSrc} width={width} alt="Jemsys" />;

LogoMain.defaultProps = {
    width: 'auto'
};

LogoMain.propTypes = {
    width: PropTypes.string
};

export default LogoMain;
