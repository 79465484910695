import { Record } from 'immutable';

const {

    SET_BACKORDER_CHASER_REQUEST,
    SET_BACKORDER_CHASER_SUCCESS,
    SET_BACKORDER_CHASER_FAILURE,

    RUN_BACKORDER_CHASER_REQUEST,
    RUN_BACKORDER_CHASER_SUCCESS,
    RUN_BACKORDER_CHASER_FAILURE,

    SET_BACKORDER_CHASER_ALL_REQUEST,
} = require('./backorderChaserActions').constants;


const InitialState = Record({
    backorderPost: [],
    setBackorderChaserAllRequest: false,
    post: [],
    backorderFax: [],
    backorderEmail: [],
    isFetching: false,
    error: null,
});

const initialState = new InitialState();

/**
 * ## chaserReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function backorderChaserReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {
    case SET_BACKORDER_CHASER_REQUEST:
    case RUN_BACKORDER_CHASER_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case SET_BACKORDER_CHASER_ALL_REQUEST:
        return state.set('setBackorderChaserAllRequest', payload?.status);

    case SET_BACKORDER_CHASER_SUCCESS:
        return state.set(payload?.type, payload.ids).set('isFetching', false);

    case RUN_BACKORDER_CHASER_SUCCESS:
        return state.set('post', []).set('backorderFax', []).set('backorderFax', []).set('backorderEmail', [])
            .set('isFetching', false)
            .set('setBackorderChaserAllRequest', false);


    case SET_BACKORDER_CHASER_FAILURE:
    case RUN_BACKORDER_CHASER_FAILURE:
        return state.set('error', payload).set('isFetching', false);

    default:
        return state;
    }
}
