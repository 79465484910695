import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'antd';

import ForgotPassword from '../../forms/ForgotPassword';

const ForgotPasswordModal = ({ visible, changeVisible }) => (
    <Modal centered width={540} footer={null} visible={visible} onOk={() => changeVisible(false)} onCancel={() => changeVisible(false)}>
        <h4 className="h-3 text-center">Forgot password</h4>
        <div className="form-wrap">
            <ForgotPassword closeModal={() => { changeVisible(false); }} />
        </div>
    </Modal>
);

ForgotPasswordModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    changeVisible: PropTypes.func.isRequired,
};

export default ForgotPasswordModal;
