import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import * as authActions from '../../../core/auth/authActions';

import './login.css';

import DOM from './login';

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    // Handle submit login
    handleLoginSubmit = (values) => {
        const { actions } = this.props;
        actions.loginRequest(values);
    };

    render() {
        return this.view();
    }
}

LoginForm.propTypes = {
    actions: PropTypes.object.isRequired,
};

const Login = reduxForm({
    form: 'login',
})(LoginForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        loading: state.auth.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...authActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
