import { Component } from 'react';

import DOM from './header';

class Header extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    render() {
        return this.view(this.props);
    }
}

export default Header;
