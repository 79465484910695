import PropTypes from 'prop-types';
import React from 'react';
import { Spin, Icon } from 'antd';

const Loading = ({ className, style, floating }) => (
    <div className={`text-center ${className}${floating ? ' floating-loading' : ''}`} style={style}>
        <Spin style={{ margin: 10 }} indicator={<Icon type="loading-3-quarters" style={{ fontSize: 32, color: '#149566', width: 32, height: 32 }} spin />} />
    </div>
);

Loading.defaultProps = {
    className: '',
    style: {},
    floating: false,
};

Loading.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    floating: PropTypes.bool,
};

export default Loading;
