import { Record } from 'immutable';

const {
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
} = require('./userActions').constants;

const InitialState = Record({
    user: {},
    isFetching: false,
    error: null,
});

const initialState = new InitialState();

/**
 * ## userReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {
    case GET_USER_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case GET_USER_SUCCESS:
        return state.set('user', payload).set('isFetching', false).set('error', null);

    case GET_USER_FAILURE:
        return state.set('error', payload).set('isFetching', false);

    default:
        return state;
    }
}
