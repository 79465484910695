import { put, call, takeEvery } from 'redux-saga/effects';
import alertify from 'alertify.js';
import { browserHistory } from 'react-router';
import { isUndefined } from 'underscore';
import { notification } from 'antd';
import { fetchApiAuth, formatParams } from '../utils/api';
import { addKeyProp, mapPropsToInts } from '../utils/helpers';

import {
    getPracticesSuccess,
    getPracticesFailure,

    getPracticeSuccess,
    getPracticeFailure,

    updatePracticeSuccess,
    updatePracticeFailure,

    getPracticeDoctorSuccess,
    getPracticeDoctorFailure,

    addPracticeDoctorSuccess,
    addPracticeDoctorFailure,

    removePracticeDoctorSuccess,
    removePracticeDoctorFailure,
} from './practiceActions';

const {
    GET_PRACTICES_REQUEST,
    GET_PRACTICE_REQUEST,
    UPDATE_PRACTICE_REQUEST,
    GET_PRACTICE_DOCTOR_REQUEST,
    ADD_PRACTICE_DOCTOR_REQUEST,
    REMOVE_PRACTICE_DOCTOR_REQUEST,
} = require('./practiceActions').constants;

function* getPractices({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/practice/search',
            params: formatParams(payload)
        });

        let data = !isUndefined(response) ? response.data : [];
        if (data) {
            data = mapPropsToInts(data, ['page', 'total']);
            data.rows = addKeyProp(data.rows);
        }

        yield put(getPracticesSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getPracticesFailure(e.response ? e.response.data.message : e));
    }
}

function* getPractice({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (params.id !== 0) {
            response = yield call(fetchApiAuth, {
                method: 'GET',
                url: '/practice/get',
                params
            });
        }

        const practice = !isUndefined(response) ? response.data : {};
        yield put(getPracticeSuccess(practice));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getPracticeFailure(e.response ? e.response.data.message : e));
    }
}

function* updatePractice({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/practice/edit',
            body: payload
        });

        const practice = !isUndefined(response.data) ? response.data : {};
        if (practice) {
            browserHistory.push(`/practice/${practice.id}`);
            notification.success({ message: 'Success', description: 'Practice has been updated' });
        }

        yield put(updatePracticeSuccess(practice));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(updatePracticeFailure(e.response ? e.response.data.message : e));
    }
}

function* getPracticeDoctor({ payload }) {
    try {
        const params = formatParams(payload);
        let response;

        if (params.id !== 0) {
            response = yield call(fetchApiAuth, {
                method: 'GET',
                url: '/practice/doctor',
                params
            });
        }

        let data = !isUndefined(response) ? response.data : [];
        if (data) {
            data = addKeyProp(data);
        }

        yield put(getPracticeDoctorSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(getPracticeDoctorFailure(e.response ? e.response.data.message : e));
    }
}

function* addPracticeDoctor({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/practice/doctor',
            body: payload
        });

        let data = !isUndefined(response.data) ? response.data : [];
        if (data) {
            data = addKeyProp(data);
        }

        yield put(addPracticeDoctorSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(addPracticeDoctorFailure(e.response ? e.response.data.message : e));
    }
}

function* removePracticeDoctor({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'DELETE',
            url: '/practice/doctor',
            params: {
                id: payload.practiceId,
                doctor_id: payload.doctorId,
            }
        });

        let data = !isUndefined(response.data) ? response.data : [];
        if (data) {
            data = addKeyProp(data);
        }

        yield put(removePracticeDoctorSuccess(data));
    } catch (e) {
        notification.error({ message: 'Error detected', description: (e.response ? e.response.data.message : e) });
        yield put(removePracticeDoctorFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* practiceSaga() {
    yield* [
        takeEvery(GET_PRACTICES_REQUEST, getPractices),
        takeEvery(GET_PRACTICE_REQUEST, getPractice),
        takeEvery(UPDATE_PRACTICE_REQUEST, updatePractice),
        takeEvery(GET_PRACTICE_DOCTOR_REQUEST, getPracticeDoctor),
        takeEvery(ADD_PRACTICE_DOCTOR_REQUEST, addPracticeDoctor),
        takeEvery(REMOVE_PRACTICE_DOCTOR_REQUEST, removePracticeDoctor),
    ];
}
