import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'antd';
import { isEmpty, pluck, find } from 'underscore';

import SplitOrderForm from '../../forms/SplitOrderForm';

const SplitOrderModal = ({ orderId, customerId, products, orderProducts, visible, changeVisible, letterList, orderPickingAndDispatchNote, prescriptionInformation }) => {
    // Filter products by added product ids
    let added = [];
    if (!isEmpty(orderProducts)) {
        added = products.filter(product => pluck(orderProducts, 'id').includes(product.id));
    }

    // Generate initial values
    const initialValues = added.map((item) => {
        const initial = find(orderProducts, product => item.id === product.id);
        return {
            id: initial.id,
            quantity: `${initial.quantity}`,
            split_quantity: '0',
        };
    });

    return (
        <Modal
            centered
            width={848}
            className="modal-side-padding"
            footer={null} visible={visible}
            onOk={() => { changeVisible(false); }}
            onCancel={() => { changeVisible(false); }}>
            <h4 className="h-3 text-center">Split order</h4>
            <p className="mt-2">Splitting an order creates a new order with the current order’s details. You may move products from the current order to the new order below.</p>
            <SplitOrderForm
                letterList={letterList}
                orderPickingAndDispatchNote={orderPickingAndDispatchNote}
                prescriptionInformation={prescriptionInformation}
                orderId={orderId}
                customerId={customerId}
                products={added}
                orderProducts={orderProducts}
                initialValues={{
                    split_order: {
                        products: initialValues
                    }
                }}
                closeModal={() => changeVisible(false)} />
        </Modal>
    );
};

SplitOrderModal.defaultProps = {
    letterList: [],
    orderPickingAndDispatchNote: {},
    prescriptionInformation: {},
};

SplitOrderModal.propTypes = {
    letterList: PropTypes.array,
    orderPickingAndDispatchNote: PropTypes.object,
    prescriptionInformation: PropTypes.object,
    orderId: PropTypes.number.isRequired,
    customerId: PropTypes.number.isRequired,
    products: PropTypes.array.isRequired,
    orderProducts: PropTypes.array.isRequired,
    visible: PropTypes.bool.isRequired,
    changeVisible: PropTypes.func.isRequired,
};

export default SplitOrderModal;
