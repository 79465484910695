import { Record } from 'immutable';

const {
    SET_CHASERS_REQUEST,
    SET_CHASERS_SUCCESS,
    SET_CHASERS_FAILURE,

    RUN_CHASERS_REQUEST,
    RUN_CHASERS_SUCCESS,
    RUN_CHASERS_FAILURE,

    MARK_COMM_AS_RECEIVED_REQUEST,
    MARK_COMM_AS_RECEIVED_SUCCESS,
    MARK_COMM_AS_RECEIVED_FAILURE,
} = require('./chaserActions').constants;

const InitialState = Record({
    post: [],
    fax: [],
    email: [],
    isFetching: false,
    error: null,
});

const initialState = new InitialState();

/**
 * ## chaserReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function chaserReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {
    case SET_CHASERS_REQUEST:
    case RUN_CHASERS_REQUEST:
    case MARK_COMM_AS_RECEIVED_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case SET_CHASERS_SUCCESS:
        return state.set(payload.type, payload.ids).set('isFetching', false);

    case RUN_CHASERS_SUCCESS:
        return state.set('post', []).set('fax', []).set('email', []).set('isFetching', false);

    case MARK_COMM_AS_RECEIVED_SUCCESS:
        return state.set('isFetching', false).set('error', null);

    case SET_CHASERS_FAILURE:
    case RUN_CHASERS_FAILURE:
    case MARK_COMM_AS_RECEIVED_FAILURE:
        return state.set('error', payload).set('isFetching', false);

    default:
        return state;
    }
}
