import React from 'react';
import { Form as AntForm, Button } from 'antd';

import Form from '../../../elements/Form';
import Loading from '../../../elements/Loading';
import { isRequired } from '../../../core/utils/validators';

const FormGroup = Form.Group;
const formTypes = Form.formTypes;

export default function () {
    const formConfig = [
        { type: formTypes.EMAIL, name: 'email', label: 'Email', validate: [isRequired] },
        { type: formTypes.PASSWORD, name: 'password', label: 'Password', validate: [isRequired] },
    ];

    return (
        <AntForm id="login" onSubmit={this.props.handleSubmit(this.handleLoginSubmit)} layout="vertical">
            {this.props.loading ? <Loading className="flex-center-middle" style={{ minHeight: 185 }} /> : <FormGroup config={formConfig} /> }
            <Button type="primary" htmlType="submit" className="wide">Sign in</Button>
        </AntForm>
    );
}
