import React from 'react';

import Layout from '../../components/layout/Layout';
import RunReport from '../../components/forms/RunReport';

export default function () {
    return (
        <Layout title={this.state.title} breadcrumb="reports">
            <div className="container mt-3">
                <div className="section">
                    <RunReport />
                </div>
            </div>
        </Layout>
    );
}
