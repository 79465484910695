import PropTypes from 'prop-types';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DOM from './homesNursesSearch';

import {
    handleBasicGridviewMount,
    handleBasicGridviewSearch,
    handleBasicGridviewClearSearch,
    handleBasicGridviewPagination,
} from '../../../core/utils/helpers';

import * as carehomeActions from '../../../core/carehome/carehomeActions';
import * as nurseActions from '../../../core/nurse/nurseActions';

class HomesNursesSearch extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentWillMount() {
        const { actions } = this.props;
        handleBasicGridviewMount(actions.getCarehomesRequest, 'searchCarehomes');
        handleBasicGridviewMount(actions.getNursesRequest, 'searchNurses');
    }

    // Handle gridview search
    handleSearch = (values) => {
        const { actions } = this.props;
        handleBasicGridviewSearch(actions.getCarehomesRequest, 'searchCarehomes', values);
        handleBasicGridviewSearch(actions.getNursesRequest, 'searchNurses', values);
    }

    // Handle gridview clear search
    handleClearSearch = (reset) => {
        const { actions } = this.props;
        handleBasicGridviewClearSearch(actions.getCarehomesRequest, 'searchCarehomes');
        handleBasicGridviewClearSearch(actions.getNursesRequest, 'searchNurses');
        reset();
    }

    // Handle carehome gridview pagination
    handleCarehomePagination = (page, pageSize) => {
        const { actions } = this.props;
        handleBasicGridviewPagination(actions.getCarehomesRequest, 'searchCarehomes', page, pageSize);
    }

    // Handle nurse gridview pagination
    handleNursePagination = (page, pageSize) => {
        const { actions } = this.props;
        handleBasicGridviewPagination(actions.getNursesRequest, 'searchNurses', page, pageSize);
    }

    render() {
        return this.view();
    }
}

HomesNursesSearch.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        carehomes: state.carehome.carehomes.rows,
        carehomesPage: state.carehome.carehomes.page,
        carehomesTotal: state.carehome.carehomes.total,
        nurses: state.nurse.nurses.rows,
        nursesPage: state.nurse.nurses.page,
        nursesTotal: state.nurse.nurses.total,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...carehomeActions,
            ...nurseActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomesNursesSearch);
