const styles = {
    searchBarButton: {
        width: 64,
        borderRadius: 12,
        border: 'solid',
        borderColor: '#149566',
        borderWidth: 1,
        fontSize: 10,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#149566',
        height: 24,
    },
};

export default styles;
