import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { DatePicker, Checkbox, InputNumber, Spin } from 'antd';
import moment from 'moment';
import { checkDate } from '../../../../core/utils/helpers';
import * as customerActions from '../../../../core/customer/customerActions';
import * as orderActions from '../../../../core/order/orderActions';
import './itemDispatch.scss';

const ItemDispatch = (props) => {
    const { selectedOrderItemsArray } = props;
    const { actions } = props;
    const { activeOrderId, customerDispatchBasket, customer, isGettingCustomer } = props?.customer;
    const { customerOrders } = customer;

    const displayedOrder = customerOrders?.find(order => order?.id === activeOrderId);
    const displayedItems = displayedOrder?.orderItems;
    const displayDispatchStatuses = [0, 2, 3];

    useEffect(() => {
        // remove items from other customers from dispatch basket
        // if items in displayed orders are different to items in dispatch basket, update dispatch basket
        const customerBasketItems = customerDispatchBasket.filter(basketItem => (basketItem?.customerId === customer.id));
        const newBasketItems = [];
        if (displayedItems?.length) {
            displayedItems.forEach((item) => {
                const dispatchItem = customerBasketItems.find(basketItem => basketItem.itemId === item.id);
                if (dispatchItem && !displayDispatchStatuses.includes(item.delivery_status)) {
                    customerBasketItems.filter(basketItem => basketItem.itemId !== item.id);
                } else
                if (!dispatchItem && displayDispatchStatuses.includes(item.delivery_status)) {
                    // if item is not in dispatch basket, add to dispatch basket
                    newBasketItems.push({
                        customerId: customer.id,
                        itemId: item.id,
                        orderId: item.order_id,
                        quantityDispatched: 0,
                        dispatchDate: '',
                    });
                }
            });
            actions.updateCustomerDispatchBasketRequest([...customerBasketItems, ...newBasketItems]);
        }
    }, [displayedItems]);

    const handleSelectedAllItems = (e) => {
        // go through each display order.  Find in the basket and update the basket quantity dispatched with the maxiumum available
        if (e.target.checked) {
            const availableItems = displayedItems.filter(item => displayDispatchStatuses.includes(item.delivery_status));
            const inStockItems = availableItems.filter(item => item.product.quantity > 0);
            inStockItems.forEach((item) => {
                const dispatchItem = customerDispatchBasket.find(basketItem => basketItem.itemId === item.id);
                dispatchItem.quantityDispatched = Math.min(Number(item.quantity) - Number(item.quantity_dispatched), item.product.quantity);
                const otherItems = customerDispatchBasket.filter(basketItem => basketItem.itemId !== item.id);
                actions.updateCustomerDispatchBasketRequest([...otherItems, dispatchItem]);
            });
            actions.selectedOrderItemsRequest(inStockItems.map(item => item.id));
        } else {
            displayedItems.forEach((item) => {
                const dispatchItem = customerDispatchBasket.find(basketItem => basketItem.itemId === item.id);
                dispatchItem.quantityDispatched = 0;
                const otherItems = customerDispatchBasket.filter(basketItem => basketItem.itemId !== item.id);
                actions.updateCustomerDispatchBasketRequest([...otherItems, dispatchItem]);
            });
            actions.selectedOrderItemsRequest([]);
        }
    };

    const handleSelectItemChange = (e, id) => {
        if (e.target.checked) {
            actions.selectedOrderItemsRequest([...selectedOrderItemsArray, id]);
        } else {
            actions.selectedOrderItemsRequest(selectedOrderItemsArray.filter(item => item !== id));
        }
    };

    const getAllSelectedChecked = () => {
        const availableItems = displayedItems.filter(item => displayDispatchStatuses.includes(item.delivery_status));
        const inStockItems = availableItems.filter(item => item.product.quantity > 0);
        if (inStockItems.length === 0) {
            return false;
        }
        return inStockItems.every(item => selectedOrderItemsArray.includes(item.id));
    };

    const renderTableRow = (item, index) => {
        const dispatchItem = customerDispatchBasket.find(basketItem => basketItem.itemId === item.id);

        const handleDispatchChange = (value) => {
            dispatchItem.quantityDispatched = Number(value);
            const otherItems = customerDispatchBasket.filter(basketItem => basketItem.itemId !== item.id);
            actions.updateCustomerDispatchBasketRequest([...otherItems, dispatchItem]);
        };

        const handleDispatchDateChange = (date, dateString) => {
            dispatchItem.dispatchDate = dateString;
            const otherItems = customerDispatchBasket.filter(basketItem => basketItem.itemId !== item.id);
            actions.updateCustomerDispatchBasketRequest([...otherItems, dispatchItem]);
        };

        const getTotalValue = () => {
            const totalValue = item?.product?.price * item?.quantity;
            return totalValue ? totalValue.toFixed(2) : '0.00';
        };

        const getDeliveryStatus = () => {
            if (item?.delivery_status === 0) {
                return 'Picking';
            } else if (item?.delivery_status === 1) {
                return 'Dispatched';
            } else if (item?.delivery_status === 2) {
                return 'Backorder';
            } else if (item?.delivery_status === 3) {
                return 'Backorder';
            } else if (item?.delivery_status === 4) {
                return 'Cancelled';
            }
            return 'Unknown';
        };

        const getDisabled = () => {
            if (displayDispatchStatuses.includes(item.delivery_status)) {
                return false;
            }
            return true;
        };

        const getInputMax = () => {
            const max = Math.min(Number(item.quantity) - Number(item.quantity_dispatched), item.product.quantity);
            return max;
        };

        return (
            <tr key={index}>
                <td>
                    <div className="checkbox-wrapper">
                        <Checkbox
                            checked={selectedOrderItemsArray.includes(item.id)}
                            onChange={(e) => { handleSelectItemChange(e, item.id); }}
                            disabled={getDisabled()} />
                    </div>
                </td>
                <td className="order-id">{item?.order_id}</td>
                <td>{item?.id}</td>
                <td>{item?.product?.description}</td>
                <td>{getDeliveryStatus(item)}</td>
                <td>{item?.product?.quantity}</td>
                <td>{item?.quantity}</td>
                <td>{item?.quantity_dispatched}</td>
                <td>
                    {displayDispatchStatuses.includes(item.delivery_status) ? <>
                        <InputNumber
                            value={dispatchItem?.quantityDispatched}
                            size="small"
                            style={{ width: 45, height: 24, }}
                            onChange={handleDispatchChange}
                            min={0}
                            max={getInputMax()}
                            defaultValue={0} />
                        <span> / {item.quantity - item.quantity_dispatched}</span>
                    </> : <span>n/a</span>}
                </td>
                <td>{item?.delivery_date ? checkDate(item.delivery_date, 'output', 'DD/MM/YYYY') : 'N/a'}</td>
                <td>{item?.due_date ? checkDate(item.delivery_date, 'output', 'DD/MM/YYYY') : 'N/a'}</td>
                <td style={{ width: '120px' }}>
                    {item.dispatch_date === null ? <>
                        <DatePicker
                            format="DD/MM/YYYY"
                            value={checkDate(dispatchItem?.dispatchDate, 'input', 'DD/MM/YYYY')}
                            placeholder="pick"
                            onChange={handleDispatchDateChange} />
                    </> : <span>{item?.dispatch_date ? moment(item.dispatch_date).format('DD/MM/YY') : ''}</span>}
                </td>
                <td>£{item?.product?.price}</td>
                <td>£{getTotalValue(item)}</td>
            </tr>
        );
    };

    return (
        <Spin spinning={isGettingCustomer}>
            <div className="container" style={{ overflow: 'auto', minWidth: '100%' }}>
                <table>
                    <thead>
                        <tr>
                            <th>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        checked={getAllSelectedChecked()}
                                        onChange={e => handleSelectedAllItems(e)} />
                                </div>
                            </th>
                            <th style={{ minWidth: '60px' }}>order id</th>
                            <th style={{ minWidth: '60px' }}>item id</th>
                            <th style={{ minWidth: '170px' }}>description</th>
                            <th style={{ minWidth: '90px' }}>delivery status</th>
                            <th style={{ minWidth: '70px' }}>quantity in stock</th>
                            <th style={{ minWidth: '70px' }}>quantity</th>
                            <th style={{ minWidth: '70px' }}>quantity dispatched</th>
                            <th style={{ minWidth: '85px' }}>dispatch</th>
                            <th style={{ minWidth: '85px' }}>delivery date</th>
                            <th style={{ minWidth: '85px' }}>due date</th>
                            <th style={{ minWidth: '130px' }}>dispatch date</th>
                            <th style={{ minWidth: '50px' }}>value</th>
                            <th style={{ minWidth: '60px' }}>total value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedItems.map((item, index) => renderTableRow(item, index))}
                    </tbody>
                </table>
            </div>
        </Spin>
    );
};

ItemDispatch.defaultProps = {
    customer: {},
    actions: {},
    selectedOrderItemsArray: [],
};

ItemDispatch.propTypes = {
    selectedOrderItemsArray: PropTypes.array,
    actions: PropTypes.object,
};

const mapStateToProps = state => ({
    order: state.order,
    customer: state.customer,
    selectedOrderItemsArray: state.order.selectedOrderItemsArray,
});

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
            ...orderActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemDispatch);
