import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from 'underscore';
import { Table } from 'antd';

import Loading from '../Loading';
import Sort from './Sort';

const Grid = ({
    config,
    data,
    expandedRowRender,
    expandIcon,
    expandRowByClick,
    handleRow,
    handleSort,
    loading,
    pagination,
    paginationSize,
    postTable,
    scroll,
    sortBy,
    sortConfig,
    subtitle,
    title
}) => {
    // Generate sort component
    let sortSelect = false;
    if (!isEmpty(sortConfig)) {
        sortSelect = <Sort options={sortConfig} sortBy={sortBy} handleChange={handleSort} />;
    }

    // Generate title
    let header = false;
    if (subtitle) {
        header = (
            <div>
                <h5 className="h-5 mt-2 mb-2 capitalise">{subtitle}</h5>
                {sortSelect}
            </div>
        );
    } else if (title || sortSelect) {
        header = (
            <div className="section__header">
                {title ? <h3 className="h-4">{title}</h3> : false}
                {sortSelect}
            </div>
        );
    }

    // Make pagination object
    let paginationObject = false;
    if (pagination === true) {
        paginationObject = {
            simple: true,
            pageSize: paginationSize,
            total: data.length,
            className: 'custom-pagination',
        };
    } else if (pagination !== false) {
        paginationObject = pagination;
    }
    // Generate table
    return (
        <div>
            {header}
            {loading ? <Loading className={loading !== 'floating' ? 'section-white' : ''} floating={loading === 'floating'} /> : null}
            {!loading || loading === 'floating' ?
                <Table
                    columns={config}
                    dataSource={data}
                    expandedRowRender={expandedRowRender}
                    expandIcon={expandIcon}
                    expandRowByClick={expandRowByClick}
                    onRow={handleRow}
                    pagination={paginationObject}
                    scroll={scroll} />
                : null}
            {postTable}
            <br />
        </div>
    );
};

Grid.defaultProps = {
    expandedRowRender: null,
    expandIcon: null,
    expandRowByClick: false,
    handleRow: () => {},
    handleSort: () => {},
    loading: false,
    pagination: true,
    paginationSize: 10,
    postTable: '',
    scroll: {},
    sortBy: null,
    sortConfig: [],
    subtitle: '',
    title: '',
};

Grid.propTypes = {
    config: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    expandedRowRender: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    expandIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    expandRowByClick: PropTypes.bool,
    handleRow: PropTypes.func,
    handleSort: PropTypes.func,
    loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    paginationSize: PropTypes.number,
    postTable: PropTypes.node,
    scroll: PropTypes.object,
    sortBy: PropTypes.node,
    sortConfig: PropTypes.array,
    subtitle: PropTypes.string,
    title: PropTypes.string,
};

export default Grid;
