import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { Button, notification, } from 'antd';
import { browserHistory } from 'react-router';
import { isEqual, isEmpty } from 'underscore';

import styles from './styles.js';

import Icon from '../../elements/Icon';
import Layout from '../../components/layout/Layout';
import Script from './Script';
import BalanceLetter from './BalanceLetter';
import Patient from './Patient';
import OrderItemQuickfind from './OrderItemQuickfind';
import MatchedOrderItem from './MatchedOrderItem';
import MissingPrescriptionItem from './MissingPrescriptionItem';

import { generateFormValues, generateFileList } from './dataGenerators';
import { checkDate, debounce } from '../../core/utils/helpers.js';

import * as customerActions from '../../core/customer/customerActions';
import * as orderActions from '../../core/order/orderActions';

class PrescriptionMatcher extends Component {
    constructor(props) {
        super(props);

        const { currentPresciption, customer } = props;
        const customerId = customer && customer.id;

        this.state = {
            name: 'Prescription Matcher',
            toolbar: [
                {
                    id: 'actionSave',
                    caption: 'Save',
                    icon: 'save',
                    onClick: () => this.handleSubmit(),
                }, {
                    id: 'actionComplete',
                    caption: 'Complete',
                    icon: 'check',
                    onClick: () => {
                        this.handleSubmit();
                        browserHistory.push({
                            pathname: `/customer/${customerId}`,
                            state: {
                                from: 'prescription-matcher',
                            },
                        });
                    },
                },
            ],

            // Dimensions
            width: 0,
            height: 0,

            // Current prescription
            currentPresciption,

            // Camera
            fileList: [],

            // Form
            formValues: {
                prescription: {
                    notes: {
                        label: 'Script Notes',
                        value: null
                    },
                    issue_date: {
                        label: 'Issue Date',
                        value: null
                    },
                    status: {
                        label: 'Update Status',
                        value: null
                    },
                    type: {
                        label: 'Type',
                        value: null
                    },
                },
                balance_letter: {
                    text: {
                        label: 'Text',
                        value: null,
                    },
                    email: {
                        label: 'Email',
                        value: null || 0,
                    },
                    letter: {
                        label: 'Letter',
                        value: null || 0,
                    },
                    fax: {
                        label: 'Fax',
                        value: null || 0,
                    },
                    type: {
                        label: 'Type',
                        value: null || 3
                    },
                    required: {
                        label: 'Required',
                        value: null || 1
                    },
                }
            }
        };
    }

    componentDidMount() {
        const { actions, params } = this.props;
        const { currentPresciption } = this.state;
        const prescriptionId = params && params.prescriptionId;
        if (prescriptionId) actions.getPrescriptionRequest({ id: prescriptionId });
        if (!prescriptionId) actions.updateMissingPrescriptionItemsOfflineRequest([]);

        const formValues = generateFormValues(currentPresciption);
        const fileList = generateFileList(currentPresciption);
        this.setState({ formValues, fileList });

        this.updateWindowDimensions();
        window.addEventListener('resize', debounce(this.updateWindowDimensions.bind(this), 50));

        window.onpopstate = () => {
            if (prescriptionId) actions.getPrescriptionRequest({ id: prescriptionId });
            if (!prescriptionId) actions.updateMissingPrescriptionItemsOfflineRequest([]);
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentPresciption, actions, isFetchingPrescription } = this.props;

        if (!isEqual(currentPresciption, prevProps.currentPresciption)) {
            const formValues = generateFormValues(currentPresciption);
            const fileList = generateFileList(currentPresciption);
            this.setState({ formValues, fileList, currentPresciption });
        }

        if (isFetchingPrescription === false && prevProps.isFetchingPrescription === true) {
            actions.getCustomerRequest({ id: currentPresciption.customer_id });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', debounce(this.updateWindowDimensions.bind(this), 50));
        window.onpopstate = () => {};
    }

    componentWillReceiveProps(nextProps) {
        const { currentPresciption } = this.props;

        if (!isEqual(currentPresciption, nextProps.currentPresciption)) {
            browserHistory.push(`/prescription-matcher/${nextProps.currentPresciption.id}`);
        }
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    handleSetFormValue(value, form, input) {
        const formValues = JSON.parse(JSON.stringify(this.state.formValues));

        formValues[form][input].value = value;
        this.setState({ formValues });
    }

    handleCapture(base64Image) {
        const fileList = JSON.parse(JSON.stringify(this.state.fileList));

        const file = {
            uid: Math.random(),
            name: 'screenshot.png',
            url: base64Image,
        };

        fileList.push(file);

        this.setState({ fileList });
    }

    handleUpload(file) {
        let fileName = file.name;

        const reader = new FileReader();

        let splitString = fileName.split('.');
        splitString = splitString.map(str => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase());
        fileName = splitString.join('.');

        reader.onload = (e) => {
            const fileList = JSON.parse(JSON.stringify(this.state.fileList));
            const base64Image = e.target.result;

            const f = {
                uid: Math.random(),
                name: fileName,
                url: base64Image,
            };

            fileList.push(f);

            this.setState({ fileList });
        };

        reader.readAsDataURL(file);

        return false;
    }

    handleRemove(uid) {
        let fileList = JSON.parse(JSON.stringify(this.state.fileList));
        const currentPresciption = JSON.parse(JSON.stringify(this.state.currentPresciption));

        fileList = fileList.filter(file => file.uid !== uid);
        const mediaModels = currentPresciption.mediaModels.filter(mediaModel => mediaModel.id !== uid);
        currentPresciption.mediaModels = mediaModels;

        this.setState({ fileList, currentPresciption: { ...currentPresciption } });
    }

    handleSubmitLetter() {
        const { actions, params } = this.props;
        const { currentPresciption } = this.state;
        const { formValues } = this.state;
        const { balance_letter } = formValues;
        const prescriptionId = params && params.prescriptionId;

        if (!/\S/.test(balance_letter.text.value)) {
            notification.error({ message: 'Error detected', description: 'Letters must contain characters.' });
        } else if ([balance_letter.email.value, balance_letter.letter.value, balance_letter.fax.value].every(val => val === 0)) {
            notification.error({ message: 'Error detected', description: 'Letters must have an output stream.' });
        } else if (!currentPresciption.letter) {
            actions.createLetterRequest({
                prescriptionId,
                text: balance_letter.text.value,
                email: balance_letter.email.value,
                letter: balance_letter.letter.value,
                fax: balance_letter.fax.value,
                type: balance_letter.type.value,
                required: balance_letter.required.value,
            });
        } else {
            actions.updateLetterRequest({
                ...currentPresciption.letter,
                text: balance_letter.text.value,
                email: balance_letter.email.value,
                letter: balance_letter.letter.value,
                fax: balance_letter.fax.value,
                type: balance_letter.type.value,
                required: balance_letter.required.value,
            });
        }
    }

    handleSubmit() {
        const { customer, actions, params } = this.props;
        const { currentPresciption, formValues } = this.state;
        const { prescription } = formValues;
        const { fileList } = this.state;
        const customerId = customer && customer.id;
        const prescriptionId = params && params.prescriptionId;

        if (!fileList.every(file => file.name && file.url)) {
            notification.error({ message: 'Error detected', description: 'One or more required inputs were not provided.' });
        // } else if (!fileList.every((file) => {
        //     const splitString = file.name.split('.');
        //     return ['png', 'jpeg', 'jpg'].includes(splitString[splitString.length - 1]);
        // })) {
        //     notification.error({ message: 'Error detected', description: 'One or more files were not of the right type (png, jpeg, jpg).' });
        } else if (!prescriptionId) {
            actions.uploadPrescriptionRequest({
                customerId,
                fileList,
                title: `Prescription ${(new Date()).toISOString().split('.')[0].replace(/[^\d]/gi, '')}`,
                notes: prescription.notes.value,
                issue_date: checkDate(prescription.issue_date.value, 'output', 'YYYY-MM-DD hh:mm:ss'),
                status: prescription.status.value,
                type: prescription.type.value,
            });
        } else {
            actions.updatePrescriptionRequest({
                fileList,
                prescription: {
                    ...currentPresciption,
                    notes: prescription.notes.value,
                    issue_date: checkDate(prescription.issue_date.value, 'output', 'YYYY-MM-DD hh:mm:ss'),
                    status: prescription.status.value,
                    type: prescription.type.value,
                },
            });
        }
    }

    handleBackLink() {
        const { customer } = this.props;
        const customerId = customer && customer.id;

        browserHistory.push({
            pathname: `/customer/${customerId}`,
            state: {
                from: 'prescription-matcher',
            },
        });
    }

    render() {
        const { name, toolbar, formValues, fileList, } = this.state;
        const { prescription, balance_letter } = formValues;

        // Header back link
        const backlink = (
            <Button
                type="primary"
                size="large"
                className="transparent backlink"
                onClick={() => this.handleBackLink()}>
                <Icon name="arrow-left" />
            </Button>
        );

        const { height } = this.state;
        const heightOfGridviewSection = height - 56;
        const mainContainerStyle = {
            height: '960px',
            minHeight: 'calc(100vh - 56px)',
            width: '100%',
            display: 'flex',
            padding: 10,
            backgroundColor: '#f7f7f7',
        };

        return (
            <Layout
                title={name}
                toolbar={toolbar}
                breadcrumb={null}
                button={backlink}>
                <div style={mainContainerStyle} className={'prescription_matcher'}>
                    <div
                        style={styles.gridLayout}>
                        <div
                            style={{
                                gridColumnStart: 1,
                                gridColumnEnd: 5,
                                gridRowStart: 1,
                                gridRowEnd: 10,
                            }}>
                            <Script
                                fileList={fileList}
                                prescription={prescription}
                                handleSetFormValue={this.handleSetFormValue.bind(this)}
                                onCapture={this.handleCapture.bind(this)}
                                onUpload={this.handleUpload.bind(this)}
                                onRemove={this.handleRemove.bind(this)} />
                        </div>
                        <div
                            style={{
                                gridColumnStart: 1,
                                gridColumnEnd: 5,
                                gridRowStart: 10,
                                gridRowEnd: 13,
                            }}>
                            <BalanceLetter
                                params={this.props.params}
                                onSubmitLetter={this.handleSubmitLetter.bind(this)}
                                balance_letter={balance_letter}
                                handleSetFormValue={this.handleSetFormValue.bind(this)} />
                        </div>
                        <div
                            style={{
                                gridColumnStart: 5,
                                gridColumnEnd: 13,
                                gridRowStart: 1,
                                gridRowEnd: 3,
                            }}>
                            <Patient />
                        </div>
                        <div
                            style={{
                                gridColumnStart: 5,
                                gridColumnEnd: 13,
                                gridRowStart: 3,
                                gridRowEnd: 7,
                            }}>
                            <OrderItemQuickfind params={this.props.params} />
                        </div>
                        <div
                            style={{
                                gridColumnStart: 5,
                                gridColumnEnd: 13,
                                gridRowStart: 7,
                                gridRowEnd: 10,
                            }}>
                            <MatchedOrderItem params={this.props.params} />
                        </div>
                        <div
                            style={{
                                gridColumnStart: 5,
                                gridColumnEnd: 13,
                                gridRowStart: 10,
                                gridRowEnd: 13,
                            }}>
                            <MissingPrescriptionItem params={this.props.params} />
                        </div>
                    </div>

                </div>
            </Layout>
        );
    }
}

PrescriptionMatcher.propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    currentPresciption: PropTypes.object.isRequired,
    isFetchingPrescription: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        currentPresciption: state.customer.currentPresciption,
        customer: state.customer.customer,
        isFetchingPrescription: state.customer.isFetchingPrescription,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            submit,
            ...customerActions,
            ...orderActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionMatcher);
