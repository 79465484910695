import React from 'react';

import Layout from '../../components/layout/Layout';
import AddDoctor from '../../components/modals/AddDoctor';
import DoctorsPractice from '../../components/gridviews/DoctorsPractice';
import UpdatePractice from '../../components/forms/UpdatePractice';

export default function () {
    const { name, toolbar, modalAddDoctorVisible } = this.state;
    const id = parseInt(this.props.params.id, 10);
    const breadcrumb = { name: 'practice', params: { id, name } };

    return (
        <Layout title={name} toolbar={toolbar} breadcrumb={breadcrumb}>
            {/* FORM */}
            <UpdatePractice practiceId={id} setPageName={this.setPageName} />

            {/* GRIDVIEW */}
            {id ? <DoctorsPractice practiceId={id} /> : false}

            {/* MODALS */}
            {id ? <AddDoctor practiceId={id} visible={modalAddDoctorVisible} changeVisible={this.setModalAddDoctorVisible} /> : false}
        </Layout>
    );
}
