import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Row, Col } from 'antd';

import AddDoctor from '../../../components/forms/AddDoctor';

const AddDoctorModal = ({ practiceId, visible, changeVisible }) => (
    <Modal centered width={540} footer={null} visible={visible} onOk={() => changeVisible(false)} onCancel={() => changeVisible(false)}>
        <div className="text-center">
            <h4 className="h-4-regular">Add doctor to practice</h4>
        </div>
        <div className="mt-2">
            <Row type="flex" justify="center">
                <Col span={24} md={20}>
                    <AddDoctor practiceId={practiceId} closeModal={() => changeVisible(false)} />
                </Col>
            </Row>
        </div>
    </Modal>
);

AddDoctorModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    changeVisible: PropTypes.func.isRequired,
    practiceId: PropTypes.number.isRequired,
};

export default AddDoctorModal;
