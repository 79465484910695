import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { Modal } from 'antd';

import Products from './Products';

import styles from './styles.js';

class AddMissingProductsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        const { isActive, onClose } = this.props;

        return (
            <Modal
                header={null}
                footer={null}
                onCancel={() => onClose()}
                visible={isActive}
                className="add_missing_products_modal">
                <Products />
            </Modal>
        );
    }
}

AddMissingProductsModal.propTypes = {
    actions: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ submit }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMissingProductsModal);
