import { isEmpty, sortBy } from 'underscore';
import { Record } from 'immutable';

const {
    // ----- Customer ----- //
    GET_CUSTOMERS_REQUEST,
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMERS_FAILURE,

    GET_CUSTOMER_REQUEST,
    GET_CUSTOMER_SUCCESS,
    GET_CUSTOMER_FAILURE,

    GET_CUSTOMER_BY_REFERENCE_REQUEST,
    GET_CUSTOMER_BY_REFERENCE_SUCCESS,
    GET_CUSTOMER_BY_REFERENCE_FAILURE,

    UPDATE_CUSTOMER_REQUEST,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_FAILURE,

    CREATEUPDATE_CUSTOMER_NOTE_REQUEST,
    CREATEUPDATE_CUSTOMER_NOTE_SUCCESS,
    CREATEUPDATE_CUSTOMER_NOTE_FAILURE,

    GET_CUSTOMER_NOTES_REQUEST,
    GET_CUSTOMER_NOTES_SUCCESS,
    GET_CUSTOMER_NOTES_FAILURE,

    UPDATE_CUSTOMER_DISPATCH_BASKET_REQUEST,

    // ----- Customer Practice Doctor ----- //
    GET_CUSTOMER_DOCTOR_REQUEST,
    GET_CUSTOMER_DOCTOR_SUCCESS,
    GET_CUSTOMER_DOCTOR_FAILURE,

    UPDATE_CUSTOMER_DOCTOR_REQUEST,
    UPDATE_CUSTOMER_DOCTOR_SUCCESS,
    UPDATE_CUSTOMER_DOCTOR_FAILURE,

    REMOVE_CUSTOMER_DOCTOR_REQUEST,
    REMOVE_CUSTOMER_DOCTOR_SUCCESS,
    REMOVE_CUSTOMER_DOCTOR_FAILURE,

    GET_CUSTOMER_PRACTICE_REQUEST,
    GET_CUSTOMER_PRACTICE_SUCCESS,
    GET_CUSTOMER_PRACTICE_FAILURE,

    UPDATE_CUSTOMER_PRACTICE_REQUEST,
    UPDATE_CUSTOMER_PRACTICE_SUCCESS,
    UPDATE_CUSTOMER_PRACTICE_FAILURE,

    REMOVE_CUSTOMER_PRACTICE_REQUEST,
    REMOVE_CUSTOMER_PRACTICE_SUCCESS,
    REMOVE_CUSTOMER_PRACTICE_FAILURE,

    // ----- Customer Historical Orders & Products ----- //
    GET_CUSTOMER_ORDERS_REQUEST,
    GET_CUSTOMER_ORDERS_SUCCESS,
    GET_CUSTOMER_ORDERS_FAILURE,

    GET_CUSTOMER_PRODUCTS_REQUEST,
    GET_CUSTOMER_PRODUCTS_SUCCESS,
    GET_CUSTOMER_PRODUCTS_FAILURE,

    // ----- Customer Auto Orders ----- //
    GET_CUSTOMER_AUTO_ORDERS_REQUEST,
    GET_CUSTOMER_AUTO_ORDERS_SUCCESS,
    GET_CUSTOMER_AUTO_ORDERS_FAILURE,

    UPDATE_CUSTOMER_AUTO_ORDER_REQUEST,
    UPDATE_CUSTOMER_AUTO_ORDER_SUCCESS,
    UPDATE_CUSTOMER_AUTO_ORDER_FAILURE,

    // ----- Customer Resets ----- //
    RESET_CUSTOMERS,
    RESET_CUSTOMER,

    // ----- Submit Summary Form ----- //
    SUBMIT_SUMMARY_FORM_REQUEST,
    SUBMIT_SUMMARY_FORM_SUCCESS,
    SUBMIT_SUMMARY_FORM_FAILURE,

    // ----- Search Customers By Attributes ----- //
    SEARCH_CUSTOMERS_BY_ATTRIBUTES_REQUEST,
    SEARCH_CUSTOMERS_BY_ATTRIBUTES_SUCCESS,
    SEARCH_CUSTOMERS_BY_ATTRIBUTES_FAILURE,

    // -------------------- Order -------------------- //
    // ----- Get Customer Orders By Filter ----- //
    SEARCH_CUSTOMER_ORDERS_BY_FILTERS_REQUEST,
    SEARCH_CUSTOMER_ORDERS_BY_FILTERS_SUCCESS,
    SEARCH_CUSTOMER_ORDERS_BY_FILTERS_FAILURE,
    // ----- Set Active Search Order Index Offline ----- //
    SET_ACTIVE_SEARCH_ORDER_ID_OFFLINE_REQUEST,
    // ----- Update Order ----- //
    UPDATE_CUSTOMER_ORDER_REQUEST,
    UPDATE_CUSTOMER_ORDER_SUCCESS,
    UPDATE_CUSTOMER_ORDER_FAILURE,
    // ----- Set Order Search Results Offline ----- //
    UPDATE_ORDER_SEARCH_RESULTS_OFFLINE_REQUEST,
    // ----- Send Picking And Dispatch Letter ----- //
    SEND_PICKING_AND_DISPATCH_LETTER_REQUEST,
    SEND_PICKING_AND_DISPATCH_LETTER_SUCCESS,
    SEND_PICKING_AND_DISPATCH_LETTER_FAILURE,

    // -------------------- Order Item -------------------- //
    // ----- Update Order Item ----- //
    UPDATE_ORDER_ITEM_REQUEST,
    UPDATE_ORDER_ITEM_SUCCESS,
    UPDATE_ORDER_ITEM_FAILURE,
    // ----- Search Customer Order Items By Filters ----- //
    SEARCH_CUSTOMER_ORDER_ITEMS_BY_FILTERS_REQUEST,
    SEARCH_CUSTOMER_ORDER_ITEMS_BY_FILTERS_SUCCESS,
    SEARCH_CUSTOMER_ORDER_ITEMS_BY_FILTERS_FAILURE,
    // ----- Update Order Item Search Rseults Offline ----- //
    UPDATE_ORDER_ITEM_SEARCH_RESULTS_OFFLINE_REQUEST,

    // -------------------- Order Item Repeat Instruction -------------------- //
    // ----- Get Order Item Repeat Instructions By Filters ----- //
    SEARCH_ORDER_ITEM_REPEAT_INSTRUCTIONS_BY_FILTERS_REQUEST,
    SEARCH_ORDER_ITEM_REPEAT_INSTRUCTIONS_BY_FILTERS_SUCCESS,
    SEARCH_ORDER_ITEM_REPEAT_INSTRUCTIONS_BY_FILTERS_FAILURE,
    // ----- Update Order Item Repeat Instruction ----- //
    UPDATE_ORDER_ITEM_REPEAT_INSTRUCTION_REQUEST,
    UPDATE_ORDER_ITEM_REPEAT_INSTRUCTION_SUCCESS,
    UPDATE_ORDER_ITEM_REPEAT_INSTRUCTION_FAILURE,
    // ----- Delete Order Item Repeat Instruction ----- //
    DELETE_ORDER_ITEM_REPEAT_INSTRUCTION_REQUEST,
    DELETE_ORDER_ITEM_REPEAT_INSTRUCTION_SUCCESS,
    DELETE_ORDER_ITEM_REPEAT_INSTRUCTION_FAILURE,

    // -------------------- Letter -------------------- //
    // ----- Create Letter ----- //
    CREATE_LETTER_REQUEST,
    CREATE_LETTER_SUCCESS,
    CREATE_LETTER_FAILURE,
    // ----- Update Letter ----- //
    UPDATE_LETTER_REQUEST,
    UPDATE_LETTER_SUCCESS,
    UPDATE_LETTER_FAILURE,

    UPSERT_LETTERS_REQUEST,
    UPSERT_LETTERS_SUCCESS,
    UPSERT_LETTERS_FAILURE,

    // -------------------- Prescription -------------------- //
    // ----- Search Prescriptions By Filters ----- //
    SEARCH_PRESCRIPTIONS_BY_FILTERS_REQUEST,
    SEARCH_PRESCRIPTIONS_BY_FILTERS_SUCCESS,
    SEARCH_PRESCRIPTIONS_BY_FILTERS_FAILURE,
    // ----- Upload Prescription ----- //
    UPLOAD_PRESCRIPTION_REQUEST,
    UPLOAD_PRESCRIPTION_SUCCESS,
    UPLOAD_PRESCRIPTION_FAILURE,
    // ----- Update Prescription ----- //
    UPDATE_PRESCRIPTION_REQUEST,
    UPDATE_PRESCRIPTION_SUCCESS,
    UPDATE_PRESCRIPTION_FAILURE,
    // ----- Get Prescription ----- //
    GET_PRESCRIPTION_REQUEST,
    GET_PRESCRIPTION_SUCCESS,
    GET_PRESCRIPTION_FAILURE,
    // ----- CreateUpdate Prescription Item ----- //
    CREATEUPDATE_PRESCRIPTION_ITEM_REQUEST,
    CREATEUPDATE_PRESCRIPTION_ITEM_SUCCESS,
    CREATEUPDATE_PRESCRIPTION_ITEM_FAILURE,
    // ----- Delete Prescription Item ----- //
    DELETE_PRESCRIPTION_ITEM_REQUEST,
    DELETE_PRESCRIPTION_ITEM_SUCCESS,
    DELETE_PRESCRIPTION_ITEM_FAILURE,

    // ----- Script Actions ----- //
    PROCESS_SCRIPT_REQUEST,
    PROCESS_SCRIPT_SUCCESS,
    PROCESS_SCRIPT_FAILURE,

} = require('./customerActions').constants;

const { STATUS_ACTIVE, GENDER_MALE } = require('../utils/values').default;

const initialRows = {
    rows: [],
    page: 1,
    total: 0,
};

const InitialState = Record({
    customers: initialRows,
    customer: {},
    doctor: {},
    practice: {},
    orders: initialRows,
    products: initialRows,
    autoOrders: [],
    customerSearchResults: [],
    orderSearchResults: [],
    orderItemSearchResults: [],
    activeOrderId: 0,
    summaryPageRerenderToggle: true,
    orderItemRepeatInstructionSearchResults: [],
    presciptionSearchResults: [],
    currentPresciption: null,
    customerNotes: null,
    error: null,
    customerDispatchBasket: [],

    // Progress markers
    isFetching: false,
    isSearchingCustomerOrdersByFilters: false,
    isSearchingCustomerOrderItemsByFilters: false,
    isSearchingOrderItemRepeatInstructionsByFilters: false,
    isSearchingCustomersByAttributes: false,
    isUpdatingSummaryOrder: false,
    isCreatingupdatingPrescriptionItem: false,
    isDeletingPrescriptionItem: false,
    isUpdatingOrderItem: false,
    isGettingCustomer: false,
    isSubmittingSummaryForm: false,
    isUpdatingOrderItemRepeatInstruction: false,
    isDeletingOrderItemRepeatInstruction: false,
    isSearchingPrescriptionsByFilters: false,
    isUpdatingPrescription: false,
    isFetchingPrescription: false,
    isCreatingLetter: false,
    isUpdatingLetter: false,
    isCreatingUpdatingCustomerNote: false,
    isGettingCustomerNotes: false,
});

const initialState = new InitialState();

const initialFormValues = {
    home_address: { is_residential_home: 0 },
    is_care_of_address_different: 0,
    deliver_to_care_of_address: 0,
    gender: GENDER_MALE,
    share_contact_details: 1,
    is_active: STATUS_ACTIVE,
};

/**
 * ## customerReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function customerReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {
    // --------------------------------------- REQUESTS
    case GET_CUSTOMERS_REQUEST:
    case GET_CUSTOMER_DOCTOR_REQUEST:
    case UPDATE_CUSTOMER_DOCTOR_REQUEST:
    case REMOVE_CUSTOMER_DOCTOR_REQUEST:
    case GET_CUSTOMER_PRACTICE_REQUEST:
    case UPDATE_CUSTOMER_PRACTICE_REQUEST:
    case REMOVE_CUSTOMER_PRACTICE_REQUEST:
    case GET_CUSTOMER_ORDERS_REQUEST:
    case GET_CUSTOMER_PRODUCTS_REQUEST:
    case GET_CUSTOMER_AUTO_ORDERS_REQUEST:
    case UPDATE_CUSTOMER_AUTO_ORDER_REQUEST: {
        return state
            .set('isFetching', true)
            .set('error', null);
    }

    case SUBMIT_SUMMARY_FORM_REQUEST: {
        return state
            .set('isSubmittingSummaryForm', true)
            .set('isFetching', true)
            .set('error', null);
    }
    case GET_CUSTOMER_REQUEST: {
        return state
            .set('isFetching', true)
            .set('isGettingCustomer', true)
            .set('error', null);
    }
    case GET_CUSTOMER_BY_REFERENCE_REQUEST: {
        return state
            .set('isFetching', true)
            .set('isGettingCustomer', true)
            .set('error', null);
    }
    case UPDATE_CUSTOMER_REQUEST: {
        return state
            .set('isFetching', true)
            .set('error', null);
    }
    case SEARCH_CUSTOMERS_BY_ATTRIBUTES_REQUEST: {
        return state
            .set('customerSearchResults', 'reset')
            .set('isSearchingCustomersByAttributes', true)
            .set('isFetching', true)
            .set('error', null);
    }
    case CREATEUPDATE_CUSTOMER_NOTE_REQUEST:
        return state
            .set('isCreatingUpdatingCustomerNote', true)
            .set('isFetching', true)
            .set('error', null);
    case GET_CUSTOMER_NOTES_REQUEST:
        return state
            .set('isGettingCustomerNotes', true)
            .set('isFetching', true)
            .set('error', null);
    case UPDATE_CUSTOMER_DISPATCH_BASKET_REQUEST:
        return state.set('customerDispatchBasket', payload);


    // -------------------- Order -------------------- //
    case SEARCH_CUSTOMER_ORDERS_BY_FILTERS_REQUEST: {
        return state
            .set('orderSearchResults', 'reset')
            .set('isFetching', true)
            .set('isSearchingCustomerOrdersByFilters', true)
            .set('error', null);
    }
    case SET_ACTIVE_SEARCH_ORDER_ID_OFFLINE_REQUEST: {
        return state.set('activeOrderId', payload);
    }
    case UPDATE_CUSTOMER_ORDER_REQUEST: {
        return state.set('isFetching', true)
            .set('isUpdatingSummaryOrder', true)
            .set('error', null);
    }
    case UPDATE_ORDER_SEARCH_RESULTS_OFFLINE_REQUEST:
        return state
            .set('orderSearchResults', payload)
            .set('summaryPageRerenderToggle', !state.summaryPageRerenderToggle); // Forces re-render.
    case SEND_PICKING_AND_DISPATCH_LETTER_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    // -------------------- Order Item -------------------- //
    case UPDATE_ORDER_ITEM_REQUEST:
        return state.set('isFetching', true)
            .set('isUpdatingOrderItem', true)
            .set('error', null);
    case SEARCH_CUSTOMER_ORDER_ITEMS_BY_FILTERS_REQUEST:
        return state
            .set('isFetching', true)
            .set('isSearchingCustomerOrderItemsByFilters', true)
            .set('error', null);
    case UPDATE_ORDER_ITEM_SEARCH_RESULTS_OFFLINE_REQUEST: {
        return state.set('orderItemSearchResults', payload);
    }

    // -------------------- Order Item Repeat Instruction -------------------- //
    case SEARCH_ORDER_ITEM_REPEAT_INSTRUCTIONS_BY_FILTERS_REQUEST:
        return state.set('isFetching', true)
            .set('isSearchingOrderItemRepeatInstructionsByFilters', true)
            .set('error', null);
    case UPDATE_ORDER_ITEM_REPEAT_INSTRUCTION_REQUEST:
        return state.set('isFetching', true)
            .set('isUpdatingOrderItemRepeatInstruction', true)
            .set('error', null);
    case DELETE_ORDER_ITEM_REPEAT_INSTRUCTION_REQUEST:
        return state
            .set('isFetching', true)
            .set('isDeletingOrderItemRepeatInstruction', true)
            .set('error', null);

    // -------------------- Prescription -------------------- //
    case SEARCH_PRESCRIPTIONS_BY_FILTERS_REQUEST:
        return state
            .set('isFetching', true)
            .set('isSearchingPrescriptionsByFilters', true)
            .set('error', null);
    case UPDATE_PRESCRIPTION_REQUEST:
        return state.set('isFetching', true)
            .set('isUpdatingPrescription', true)
            .set('error', null);
    case GET_PRESCRIPTION_REQUEST:
        return state.set('isFetching', true)
            .set('isFetchingPrescription', true)
            .set('error', null);
    case UPLOAD_PRESCRIPTION_REQUEST:
        return state.set('isFetching', true).set('error', null);
    case CREATEUPDATE_PRESCRIPTION_ITEM_REQUEST:
        return state.set('isFetching', true)
            .set('isCreatingupdatingPrescriptionItem', true)
            .set('error', null);
    case DELETE_PRESCRIPTION_ITEM_REQUEST:
        return state.set('isFetching', true)
            .set('isDeletingPrescriptionItem', true)
            .set('error', null);

    // -------------------- Letter -------------------- //
    case CREATE_LETTER_REQUEST:
        return state.set('isFetching', true)
            .set('isCreatingLetter', true)
            .set('error', null);
    case UPDATE_LETTER_REQUEST:
        return state.set('isFetching', true)
            .set('isUpdatingLetter', true)
            .set('error', null);

    case UPSERT_LETTERS_REQUEST:
        return state.set('isFetching', true)
            .set('isUpdatingLetter', true)
            .set('error', null);

    // --------------------------------------- SUCCESS
    case GET_CUSTOMERS_SUCCESS: {
        return state.set('customers', payload).set('isFetching', false).set('error', null);
    }
    case GET_CUSTOMER_SUCCESS:
        return state
            .set('customer', !isEmpty(payload) ? payload : initialFormValues)
            .set('isGettingCustomer', false)
            .set('isFetching', false);
    case GET_CUSTOMER_BY_REFERENCE_SUCCESS:
        return state
            .set('customer', !isEmpty(payload) ? payload : initialFormValues)
            .set('isGettingCustomer', false)
            .set('isFetching', false);
    case UPDATE_CUSTOMER_SUCCESS:
        return state
            .set('customer', !isEmpty(payload) ? payload : initialFormValues)
            .set('isFetching', false);

    case GET_CUSTOMER_DOCTOR_SUCCESS:
    case UPDATE_CUSTOMER_DOCTOR_SUCCESS:
    case REMOVE_CUSTOMER_DOCTOR_SUCCESS:
        return state.set('doctor', payload).set('isFetching', false);

    case GET_CUSTOMER_PRACTICE_SUCCESS:
    case UPDATE_CUSTOMER_PRACTICE_SUCCESS:
    case REMOVE_CUSTOMER_PRACTICE_SUCCESS:
        return state.set('practice', payload).set('isFetching', false);

    case GET_CUSTOMER_ORDERS_SUCCESS:
        return state.set('orders', payload).set('isFetching', false);

    case GET_CUSTOMER_PRODUCTS_SUCCESS:
        return state.set('products', payload).set('isFetching', false);

    case GET_CUSTOMER_AUTO_ORDERS_SUCCESS:
    case UPDATE_CUSTOMER_AUTO_ORDER_SUCCESS:
        return state.set('autoOrders', payload).set('isFetching', false);

    case RESET_CUSTOMERS:
        return state.set('customers', initialRows);

    case RESET_CUSTOMER:
        return state.set('customer', {}).set('doctor', {}).set('practice', {});

    case SUBMIT_SUMMARY_FORM_SUCCESS:
        return state
            .set('isSubmittingSummaryForm', false)
            .set('isFetching', false);

    case SEARCH_CUSTOMERS_BY_ATTRIBUTES_SUCCESS:
        return state.set('isFetching', false)
            .set('isSearchingCustomersByAttributes', false)
            .set('customerSearchResults', payload.data);

    case CREATEUPDATE_CUSTOMER_NOTE_SUCCESS:
        return state
            .set('isCreatingUpdatingCustomerNote', false)
            .set('isFetching', false);

    case GET_CUSTOMER_NOTES_SUCCESS:
        return state
            .set('customerNotes', payload.data)
            .set('isGettingCustomerNotes', false)
            .set('isFetching', false);

    // -------------------- Order -------------------- //
    case UPDATE_CUSTOMER_ORDER_SUCCESS:
        return state.set('isFetching', false)
            .set('isUpdatingSummaryOrder', false);
    case SEARCH_CUSTOMER_ORDERS_BY_FILTERS_SUCCESS: {
        const orderSearchResults = sortBy(payload.data, 'created_at').reverse();
        return state.set('isFetching', false)
            .set('isSearchingCustomerOrdersByFilters', false)
            .set('orderSearchResults', orderSearchResults);
    }
    case SEND_PICKING_AND_DISPATCH_LETTER_SUCCESS:
        return state.set('isFetching', false);

    // -------------------- Order Item -------------------- //
    case UPDATE_ORDER_ITEM_SUCCESS:
        return state.set('isFetching', false)
            .set('isUpdatingOrderItem', false);
    case SEARCH_CUSTOMER_ORDER_ITEMS_BY_FILTERS_SUCCESS:
        return state.set('orderItemSearchResults', payload.data)
            .set('isSearchingCustomerOrderItemsByFilters', false)
            .set('isFetching', false);

    // -------------------- Order Item Repeat Instruction -------------------- //
    case SEARCH_ORDER_ITEM_REPEAT_INSTRUCTIONS_BY_FILTERS_SUCCESS:
        return state
            .set('orderItemRepeatInstructionSearchResults', payload.data)
            .set('isSearchingOrderItemRepeatInstructionsByFilters', false)
            .set('isFetching', false);
    case UPDATE_ORDER_ITEM_REPEAT_INSTRUCTION_SUCCESS:
        return state
            .set('isFetching', false)
            .set('isUpdatingOrderItemRepeatInstruction', false);
    case DELETE_ORDER_ITEM_REPEAT_INSTRUCTION_SUCCESS:
        return state
            .set('isFetching', false)
            .set('isDeletingOrderItemRepeatInstruction', false);

    // -------------------- Prescription -------------------- //
    case SEARCH_PRESCRIPTIONS_BY_FILTERS_SUCCESS:
        return state
            .set('presciptionSearchResults', payload.data)
            .set('isFetching', false)
            .set('isSearchingPrescriptionsByFilters', false);
    case UPLOAD_PRESCRIPTION_SUCCESS: {
        return state
            .set('currentPresciption', payload.data)
            .set('isFetching', false);
    }
    case UPDATE_PRESCRIPTION_SUCCESS:
        return state.set('isFetching', false)
            .set('isUpdatingPrescription', false);
    case GET_PRESCRIPTION_SUCCESS:
        return state
            .set('currentPresciption', payload.data)
            .set('isFetching', false)
            .set('isFetchingPrescription', false);
    case CREATEUPDATE_PRESCRIPTION_ITEM_SUCCESS:
        return state.set('isCreatingupdatingPrescriptionItem', false)
            .set('isFetching', false);
    case DELETE_PRESCRIPTION_ITEM_SUCCESS:
        return state.set('isDeletingPrescriptionItem', false)
            .set('isFetching', false);

    // -------------------- Letter -------------------- //
    case CREATE_LETTER_SUCCESS:
        return state
            .set('isFetching', false)
            .set('isCreatingLetter', false);
    case UPDATE_LETTER_SUCCESS:
        return state.set('isFetching', false)
            .set('isUpdatingLetter', false);

    case UPSERT_LETTERS_SUCCESS:
        return state.set('isFetching', false)
            .set('isUpdatingLetter', false);

    // --------------------------------------- FAILURE
    case GET_CUSTOMERS_FAILURE:
    case UPDATE_CUSTOMER_FAILURE:
    case GET_CUSTOMER_DOCTOR_FAILURE:
    case UPDATE_CUSTOMER_DOCTOR_FAILURE:
    case REMOVE_CUSTOMER_DOCTOR_FAILURE:
    case GET_CUSTOMER_PRACTICE_FAILURE:
    case UPDATE_CUSTOMER_PRACTICE_FAILURE:
    case REMOVE_CUSTOMER_PRACTICE_FAILURE:
    case GET_CUSTOMER_ORDERS_FAILURE:
    case GET_CUSTOMER_PRODUCTS_FAILURE:
    case GET_CUSTOMER_AUTO_ORDERS_FAILURE:
    case UPDATE_CUSTOMER_AUTO_ORDER_FAILURE:
        return state
            .set('isFetching', false)
            .set('error', payload);
    case SUBMIT_SUMMARY_FORM_FAILURE:
        return state
            .set('isSubmittingSummaryForm', false)
            .set('isFetching', false)
            .set('error', payload);
    case GET_CUSTOMER_FAILURE:
        return state
            .set('isGettingCustomer', false)
            .set('isFetching', false)
            .set('error', payload);
    case GET_CUSTOMER_BY_REFERENCE_FAILURE:
        return state
            .set('isGettingCustomer', false)
            .set('isFetching', false)
            .set('error', payload);
    case SEARCH_CUSTOMERS_BY_ATTRIBUTES_FAILURE:
        return state
            .set('isSearchingCustomersByAttributes', false)
            .set('isFetching', false)
            .set('error', payload);
    case CREATEUPDATE_CUSTOMER_NOTE_FAILURE:
        return state
            .set('isCreatingUpdatingCustomerNote', false)
            .set('isFetching', false)
            .set('error', payload);
    case GET_CUSTOMER_NOTES_FAILURE:
        return state
            .set('isGettingCustomerNotes', false)
            .set('isFetching', false)
            .set('error', payload);

    // -------------------- Order -------------------- //
    case SEARCH_CUSTOMER_ORDERS_BY_FILTERS_FAILURE:
        return state
            .set('isSearchingCustomerOrdersByFilters', false)
            .set('isFetching', false)
            .set('error', payload);
    case UPDATE_CUSTOMER_ORDER_FAILURE:
        return state
            .set('isUpdatingSummaryOrder', false)
            .set('isFetching', false)
            .set('error', payload);
    case SEND_PICKING_AND_DISPATCH_LETTER_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    // -------------------- Order Item -------------------- //
    case SEARCH_CUSTOMER_ORDER_ITEMS_BY_FILTERS_FAILURE:
        return state
            .set('isSearchingCustomerOrderItemsByFilters', false)
            .set('isFetching', false)
            .set('error', payload);
    case UPDATE_ORDER_ITEM_FAILURE:
        return state
            .set('isUpdatingOrderItem', false)
            .set('isFetching', false)
            .set('error', payload);

    // -------------------- Order Item Repeat Instruction -------------------- //
    case SEARCH_ORDER_ITEM_REPEAT_INSTRUCTIONS_BY_FILTERS_FAILURE:
        return state
            .set('isSearchingOrderItemRepeatInstructionsByFilters', false)
            .set('isFetching', false)
            .set('error', payload);
    case UPDATE_ORDER_ITEM_REPEAT_INSTRUCTION_FAILURE:
        return state
            .set('isUpdatingOrderItemRepeatInstruction', false)
            .set('isFetching', false)
            .set('error', payload);
    case DELETE_ORDER_ITEM_REPEAT_INSTRUCTION_FAILURE:
        return state
            .set('isDeletingOrderItemRepeatInstruction', false)
            .set('isFetching', false)
            .set('error', payload);

    // -------------------- Prescription -------------------- //
    case SEARCH_PRESCRIPTIONS_BY_FILTERS_FAILURE:
        return state
            .set('isSearchingPrescriptionsByFilters', false)
            .set('isFetching', false)
            .set('error', payload);
    case UPDATE_PRESCRIPTION_FAILURE:
        return state
            .set('isUpdatingPrescription', false)
            .set('isFetching', false)
            .set('error', payload);
    case GET_PRESCRIPTION_FAILURE:
        return state
            .set('isFetchingPrescription', false)
            .set('isFetching', false)
            .set('error', payload);
    case UPLOAD_PRESCRIPTION_FAILURE:
        return state
            .set('isFetching', false)
            .set('error', payload);
    case CREATEUPDATE_PRESCRIPTION_ITEM_FAILURE:
        return state.set('isCreatingupdatingPrescriptionItem', false)
            .set('isFetching', false)
            .set('error', payload);
    case DELETE_PRESCRIPTION_ITEM_FAILURE:
        return state.set('isDeletingPrescriptionItem', false)
            .set('isFetching', false)
            .set('error', payload);

    // -------------------- Letter -------------------- //
    case CREATE_LETTER_FAILURE:
        return state
            .set('isCreatingLetter', false)
            .set('isFetching', false)
            .set('error', payload);
    case UPDATE_LETTER_FAILURE:
        return state
            .set('isUpdatingLetter', false)
            .set('isFetching', false)
            .set('error', payload);

    case UPSERT_LETTERS_FAILURE:
        return state.set('isFetching', false)
            .set('isUpdatingLetter', false)
            .set('error', payload);

    // -------------------- Scripts -------------------- //
    case PROCESS_SCRIPT_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case PROCESS_SCRIPT_SUCCESS:
        return state.set('isFetching', false).set('error', null).set('customer', !isEmpty(payload) ? payload : initialFormValues);

    case PROCESS_SCRIPT_FAILURE:
        return state.set('isFetching', false).set('error', payload);

    default:
        return state;
    }
}
