import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Input } from 'antd';

import Letter from './letter';

import styles from './styles.js';

const { TextArea } = Input;

class Letters extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.activeTab !== '3') {
            return false;
        }
        return true;
    }

    convertTypeToFieldName({ type, }) {
        let fieldName = null;

        switch (type) {
        case 0:
            fieldName = 'requested_by';
            break;
        case 1:
            fieldName = 'comments';
            break;
        case 2:
            fieldName = 'other_instructions';
            break;
        case 3:
            fieldName = 'additional_comments';
            break;
        default:
            break;
        }

        return fieldName;
    }

    _renderAdditionalCommentInputGroup({ type }) {
        const { orderPickingAndDispatchNote, setOrderPickingAndDispatchNote } = this.props;
        const fieldName = this.convertTypeToFieldName({ type, });

        return (
            <div style={{ flex: 2, display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 10, width: '100%', }}>
                <p style={styles.text2}>Delivery Text:</p>
                <TextArea
                    value={orderPickingAndDispatchNote[fieldName]}
                    onChange={e => setOrderPickingAndDispatchNote({ fieldName, value: e.target.value })}
                    style={{ minHeight: 300, }} />
            </div>
        );
    }

    _renderSpecificCommentInputGroup({ title, type }) {
        const { orderPickingAndDispatchNote, setOrderPickingAndDispatchNote } = this.props;
        const fieldName = this.convertTypeToFieldName({ type, });

        return (
            <div style={{ display: 'flex', alignItems: 'flex-start', padding: 10, flexDirection: 'column', width: '100%', }}>
                <p style={styles.text2}>{`${title}:`}</p>
                <TextArea
                    value={orderPickingAndDispatchNote[fieldName]}
                    onChange={e => setOrderPickingAndDispatchNote({ fieldName, value: e.target.value })}
                    style={{ minHeight: 58.5, }} />
            </div>
        );
    }

    _renderPickingAndDispatchNotes() {
        return (
            <div style={{ width: '100%', position: 'relative', marginBottom: 20, backgroundColor: 'white', boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.07)', }}>
                <div style={{ position: 'relative', minHeight: 100, display: 'flex', flexDirection: 'column', }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                        <p style={{ ...styles.text1, marginTop: 20, }}>Picking and Dispatch Note Details</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', }}>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'flex-start', flexDirection: 'column', width: '100%', }}>
                            {this._renderSpecificCommentInputGroup({ title: 'Requested By', type: 0, })}
                            {this._renderSpecificCommentInputGroup({ title: 'Comments', type: 1, })}
                            {this._renderSpecificCommentInputGroup({ title: 'Other Instructions', type: 2, })}
                        </div>
                        {this._renderAdditionalCommentInputGroup({ type: 3, })}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { letterList, setletter } = this.props;

        return (
            <div className="section new_letter">
                <div className="container">
                    <Letter
                        title={'Additional Text to Be Included in the GP Letter'}
                        setletter={setletter}
                        letter={letterList.find(letter => letter.type === 0)} />
                    {/* <Letter
                        title={'Payment Letter'}
                        setletter={setletter}
                        letter={letterList.find(letter => letter.type === 1)} /> */}
                    {/* <Letter
                        title={'Exemption Letter'}
                        setletter={setletter}
                        letter={letterList.find(letter => letter.type === 2)} /> */}
                    {this._renderPickingAndDispatchNotes()}
                </div>
            </div >
        );
    }
}

Letters.defaultProps = {
    letterList: [],
    orderPickingAndDispatchNote: {},
    setletter: () => {},
    setOrderPickingAndDispatchNote: () => {},
};

Letters.propTypes = {
    letterList: PropTypes.array,
    orderPickingAndDispatchNote: PropTypes.object,
    setletter: PropTypes.func,
    setOrderPickingAndDispatchNote: PropTypes.func,
    activeTab: PropTypes.string.isRequired,
};

export default Letters;
