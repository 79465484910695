import React from 'react';
import { Form as ReduxForm } from 'redux-form';
import { Tabs, Row, Col } from 'antd';

// import AutoOrdersCustomer from '../../../components/gridviews/AutoOrdersCustomer';
import DoctorsCustomer from '../../../components/gridviews/DoctorsCustomer';
import OrdersCustomer from '../../../components/gridviews/OrdersCustomer';
import PracticesCustomer from '../../../components/gridviews/PracticesCustomer';
import ProductsCustomer from '../../../components/gridviews/ProductsCustomer';
import { CustomerAddresses, CustomerDetails, CustomerSettings, CustomerSummary, CustomerAutoOrders, CustomerNotes, CustomerScripts } from './tabs';

const TabPane = Tabs.TabPane;

export default function () {
    const { products, nurses, referrers, isFetching, customerId, handleSubmit, handleOpenModal, differentCare, customerStatus } = this.props;
    const { activeTab } = this.state;
    const { params } = this.props;

    const renderCustomerNotes = () => (
        <CustomerNotes handleOpenModal={handleOpenModal} customerId={customerId} activeTab={activeTab} />
    );

    return (
        <ReduxForm onSubmit={handleSubmit(this.handleUpdateCustomerSubmit)} className="ant-form ant-form-vertical">
            <Tabs
                activeKey={activeTab}
                // defaultActiveKey="1"
                className="ant-tabs--styled"
                onChange={this.handleTabChange}>
                {/* Customer summary form */}
                <TabPane tab="Summary" key="1">
                    <CustomerSummary
                        customerNotes={<CustomerNotes handleOpenModal={handleOpenModal} customerId={customerId} activeTab={activeTab} />}
                        loading={isFetching}
                        activeTab={activeTab}
                        params={params} />
                </TabPane>

                {/* Customer details form */}
                <TabPane tab="Customer Details" key="2">
                    <CustomerDetails loading={isFetching} nurses={nurses} referrers={referrers} />
                </TabPane>

                {/* Customer addresses form */}
                <TabPane tab="Addresses" key="3">
                    <CustomerAddresses loading={isFetching} differentCare={differentCare} />
                </TabPane>

                {/* Customer doctor and practice gridviews */}
                {customerId ?
                    <TabPane tab="Doctor" key="4">
                        <div className="container">
                            <Row type="flex" justify="center">
                                <Col span={24} lg={18}>
                                    <DoctorsCustomer customerId={customerId} />
                                    <PracticesCustomer customerId={customerId} />
                                </Col>
                            </Row>
                        </div>
                    </TabPane>
                    : false}

                {/* Customer order and product history gridviews */}
                {customerId ?
                    <TabPane tab="Historical orders" key="5">
                        <div className="container">
                            <Row type="flex" justify="center">
                                <Col span={24} lg={18}>
                                    <OrdersCustomer customerId={customerId} />
                                    <ProductsCustomer customerId={customerId} />
                                </Col>
                            </Row>
                        </div>
                    </TabPane>
                    : false}

                {/* Customer auto orders table */}
                {customerId ?
                    <TabPane tab="Auto-Order Items" key="6">
                        <CustomerAutoOrders handleOpenModal={handleOpenModal} customerId={customerId} activeTab={activeTab} />
                    </TabPane>
                    : false}

                {/* Customer scripts table */}
                {customerId ?
                    <TabPane tab="Scripts" key="7">
                        <CustomerScripts handleOpenModal={handleOpenModal} customerId={customerId} activeTab={activeTab} />
                    </TabPane>
                    : false}

                {/* Customer notes form */}
                {customerId ?
                    <TabPane tab="Notes" key="8">
                        {renderCustomerNotes()}
                    </TabPane>
                    : false}

                {/* Customer settings form */}
                <TabPane tab="Settings" key="9">
                    <CustomerSettings loading={isFetching} products={products} customerStatus={customerStatus} />
                </TabPane>

            </Tabs>
        </ReduxForm>
    );
}
