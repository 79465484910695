import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_REPORT_REQUEST: null,
    GET_REPORT_SUCCESS: null,
    GET_REPORT_FAILURE: null,
});

export const {
    getReportRequest,
    getReportSuccess,
    getReportFailure
} = createActions(
    constants.GET_REPORT_REQUEST,
    constants.GET_REPORT_SUCCESS,
    constants.GET_REPORT_FAILURE
);
