import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import DOM from './MarkAsDispatch';


class MarkAsDispatch extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {

            name: parseInt(props.params.id, 10) ? `#${props.params.id}` : 'Confirming',
            toolbar: this.getToolbar(props.params.id),
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ toolbar: this.getToolbar(parseInt(nextProps.params.id, 10)) });
    }

    // Handle get toolbar based on tab
    getToolbar = (id, key) => {
        const tab = key || 1;
        const toolbar = [
            { id: 'actionNewOrder', caption: 'New order', icon: 'new-order', href: `/order/0/customer/${id}`, target: 'blank' },
        ];

        return toolbar;
    };

    // Handle set page name
    setPageName = name => this.setState({ name });

    // Handle open modal
    handleOpenModal = (modal, id, interval, type) => {
        const newState = {};
        newState[`${modal}Visible`] = true;
        newState[`${modal}Id`] = id;
        if (interval && type) {
            newState[`${modal}Interval`] = interval;
            newState[`${modal}IntervalType`] = type;
        }
        this.setState(newState);
    }


    // Handle tab change
    handleTabChange = key => this.setState({ toolbar: this.getToolbar(this.props.params.id, parseInt(key, 10)) });

    render() {
        return this.view();
    }
}

MarkAsDispatch.propTypes = {
    // actions: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ submit }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MarkAsDispatch);
