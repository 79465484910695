import React, { Component, } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Button, Empty, Spin, InputNumber } from 'antd';
import { isEqual, isEmpty, isArray, values } from 'underscore';

import styles from './styles';

import AddMissingProductsModal from './AddMissingProductsModal';

import { generateProducts } from './dataGenerators';
import utilValues from '../../../core/utils/values';

import * as customerActions from '../../../core/customer/customerActions';
import * as orderActions from '../../../core/order/orderActions';

class MissingPrescriptionItem extends Component {
    constructor(props) {
        super(props);
        const { products } = this.props;

        this.state = {
            products,
            rowsToRender: 20,
            addMissingProductsModalIsActive: false,
        };

        this.gridView = React.createRef();
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            params,
            products,
            missingPrescriptionItems,
            actions
        } = this.props;

        const { prescriptionId } = params;

        // Update the state with new redux state values
        if (!prescriptionId && !isEmpty(missingPrescriptionItems)) {
            actions.updateMissingPrescriptionItemsOfflineRequest([]);
        }

        if (!isEqual(products, prevProps.products)) {
            this.setState({ products });
        }
    }

    onScroll() {
        // If scrolled to the bottom.
        if (this.gridView.current) {
            const { scrollTop, scrollHeight, clientHeight } = this.gridView.current;
            if (Math.abs((scrollTop + clientHeight) - scrollHeight) < 5) {
                this.setState({ rowsToRender: this.state.rowsToRender + 20 });
            }
        }
    }

    handleSetQuantityMatched({ value, productId, }) {
        const { actions, missingPrescriptionItems, orderItemSearchResults } = this.props;
        const missingPrescriptionItemsBuffer = JSON.parse(JSON.stringify(missingPrescriptionItems));
        const orderItemSearchResultsBuffer = JSON.parse(JSON.stringify(orderItemSearchResults));
        const index = missingPrescriptionItemsBuffer.findIndex(missingPrescriptionItem => missingPrescriptionItem.product_id === productId);
        missingPrescriptionItemsBuffer[index].quantity = value;

        orderItemSearchResultsBuffer
            .map((orderItem) => {
                const prescriptionItem = missingPrescriptionItems.find(prescriptionItem =>
                    prescriptionItem.product_id === orderItem.product_id &&
                    prescriptionItem.prescription_id === orderItem.prescription_id
                );
                if (prescriptionItem) {
                    orderItem.quantityIncludingMissing = (+orderItem.quantity + +value).toString();
                }
                return orderItem;
            });

        actions.updateMissingPrescriptionItemsOfflineRequest(missingPrescriptionItemsBuffer);
        actions.createupdatePrescriptionItemRequest(missingPrescriptionItemsBuffer[index]);
        actions.updateOrderItemSearchResultsOfflineRequest(orderItemSearchResultsBuffer);
    }


    handleRemove({ productId, }) {
        const { actions, missingPrescriptionItems, currentPresciption } = this.props;
        const prescriptionId = currentPresciption.id;

        let missingPrescriptionItemsBuffer = JSON.parse(JSON.stringify(missingPrescriptionItems));
        missingPrescriptionItemsBuffer = missingPrescriptionItemsBuffer.filter(missingPrescriptionItem => missingPrescriptionItem.product_id !== productId);

        actions.updateMissingPrescriptionItemsOfflineRequest(missingPrescriptionItemsBuffer);
        actions.deletePrescriptionItemRequest({
            product_id: productId,
            prescription_id: prescriptionId,
        });
    }

    _renderFilterBar() {
        return (
            <div style={styles.filterBarContainer}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}>
                    <p style={styles.filterBarTitle}>Missing Prescription Items</p>
                    <Button
                        onClick={() => this.setState({ addMissingProductsModalIsActive: true, })}
                        style={styles.submitButton}>Add
                    </Button>
                </div>
            </div>
        );
    }

    _renderTableHeaderElements({ field }) {
        switch (field.fieldName) {
        default:
            return (
                <th
                    key={field.fieldName}
                    style={styles.tableHeaderElement}>
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                        {field.fieldLabel.toUpperCase()}
                    </div>
                </th>
            );
        }
    }

    transpose(a) {
        return Object.keys(a[0]).map(c => a.map(r => r[c]));
    }

    _renderTableBodyRowElement({ column, fieldName, productId, }) {
        switch (fieldName) {
        case 'quantity':
            return (
                <td
                    key={`${productId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <InputNumber
                        value={column[0]}
                        size="small"
                        style={{ width: 45, height: 24, }}
                        onChange={value => this.handleSetQuantityMatched({ value, productId, })}
                        min={0}
                        // max={parseInt(column[1])}
                        defaultValue={3} />{` / ${column[1]}`}
                </td>
            );
        case 'remove':
            return (
                <td
                    key={`${productId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <Button
                        onClick={() => this.handleRemove({ productId, })}
                        style={styles.removeButton}>
                        {'Remove'}
                    </Button>
                </td>
            );
        default:
            return (
                <td
                    key={`${productId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    {column}
                </td>
            );
        }
    }

    _renderTableBody(data) {
        const matrix = [];
        const fieldNames = [];
        data.map((element) => {
            matrix.push(element.fieldValues);
            fieldNames.push(element.fieldName);
        });
        const transpose = this.transpose(matrix);

        return (
            <tbody style={styles.tableBody}>
                {transpose.map((row, rowIndex) => (
                    <tr
                        key={rowIndex}
                        style={styles.tableBodyRow}>
                        {row.map((column, columnIndex) => this._renderTableBodyRowElement({
                            column,
                            fieldName: fieldNames[columnIndex],
                            productId: row[0],
                        }))}
                    </tr>
                ))}
                <tr style={{ height: 200, }} />
            </tbody>
        );
    }

    _renderTable() {
        const { products, rowsToRender, } = this.state;
        const { missingPrescriptionItems } = this.props;
        const productsBuffer = [];
        missingPrescriptionItems.map((missingPrescriptionItem) => {
            const product = products.rows.flat(1).find(p => (
                p.id === missingPrescriptionItem.product_id
                && missingPrescriptionItem?.status !== utilValues.ORDER_STATUS_CANCELLED
            ));
            if (product) {
                product.item_quantity = missingPrescriptionItem.quantity;
                productsBuffer.push(product);
            }
        });
        const data = isArray(productsBuffer) && !isEmpty(productsBuffer) ? generateProducts({ products: productsBuffer.slice(0, rowsToRender) }) : [];
        const shouldRenderTableContent = data && isArray(data) && data[0] && isArray(data[0].fieldValues) && !isEmpty(data[0].fieldValues);

        return (
            <table
                style={styles.table}>
                {shouldRenderTableContent ?
                    <React.Fragment>
                        <thead style={styles.tableHeader}>
                            <tr style={styles.tableHeaderRow}>
                                {data.map(field => (
                                    this._renderTableHeaderElements({ field })
                                ))}
                            </tr>
                        </thead>
                        {this._renderTableBody(data)}
                    </React.Fragment>
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No order items'} />
                }
            </table>
        );
    }

    render() {
        const { height, isCreatingupdatingPrescriptionItem, isDeletingPrescriptionItem } = this.props;
        const { addMissingProductsModalIsActive } = this.state;

        let gridviewContainerStyles = styles.gridviewContainer;
        if (height || height === 0) {
            gridviewContainerStyles = { ...gridviewContainerStyles, height, minHeight: height };
        }

        return (
            <div
                ref={this.gridView}
                style={gridviewContainerStyles}
                className="prescription_missing_prescription_item">
                <Spin
                    spinning={isCreatingupdatingPrescriptionItem || isDeletingPrescriptionItem}>
                    {this._renderFilterBar()}
                    {this._renderTable()}
                </Spin>
                <AddMissingProductsModal
                    isActive={addMissingProductsModalIsActive}
                    onClose={() => this.setState({ addMissingProductsModalIsActive: false })} />
            </div>
        );
    }
}

MissingPrescriptionItem.defaultProps = {
    customer: {},
    actions: {},
    products: [],
    height: null,
    missingPrescriptionItems: [],
    orderItemSearchResults: [],
};

MissingPrescriptionItem.propTypes = {
    customer: PropTypes.object,
    actions: PropTypes.object,
    height: PropTypes.number,
    missingPrescriptionItems: PropTypes.array,
    orderItemSearchResults: PropTypes.array,
    products: PropTypes.array,
    currentPresciption: PropTypes.object.isRequired,
    isCreatingupdatingPrescriptionItem: PropTypes.bool.isRequired,
    isDeletingPrescriptionItem: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        products: state.product.products,
        customer: state.customer.customer,
        missingPrescriptionItems: state.order.missingPrescriptionItems,
        orderItemSearchResults: state.customer.orderItemSearchResults,
        currentPresciption: state.customer.currentPresciption,
        isCreatingupdatingPrescriptionItem: state.customer.isCreatingupdatingPrescriptionItem,
        isDeletingPrescriptionItem: state.customer.isDeletingPrescriptionItem,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
            ...orderActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MissingPrescriptionItem);
