import React from 'react';
import { Checkbox } from 'antd';

import Gridview from '../../../elements/Gridview';
import Icon from '../../../elements/Icon';

import { displayId, displayDate, displayName, displayNumber, GridButton } from '../../../core/utils/helpers';

export default function () {
    const { orders, total, page, isFetching } = this.props;

    // Render checkbox
    const getCheckbox = (value, record) => (
        <Checkbox
            checked={this.getCheckedValue(record.id)}
            onChange={(e) => { this.handleCheckboxChange(record.id, e.target.checked); }} />
    );
    // Gridview config
    const config = [
        { dataIndex: 'ref', title: 'Order ID', render: displayId },
        { dataIndex: 'name', title: 'Customer Name', className: 'text-semibold', render: (value, record) => displayName(record, 'customer_first_name', 'customer_last_name') },
        { dataIndex: 'created_at', title: 'Order Created', render: value => displayDate(value) },
        { dataIndex: 'items', title: <Icon name="order" />, className: 'text-center', render: displayNumber },
        { dataIndex: 'authorised', title: <Icon name="choosed" />, className: 'text-center', render: getCheckbox },
        { dataIndex: 'key', align: 'right', render: (value, record) => <GridButton href={`/order/${record.id}`} /> },
    ];

    // Pre header commponent
    const preHeader = (
        <div className="table__selection mb-2">
            <h5 className="h-5">Your selection</h5>
            <div className="table__selection-items">
                <div className="table__selection-item"><Icon name="order" /> {displayNumber(this.state.ordersCount)}</div>
                <div className="table__selection-item"><Icon name="choosed" /> {displayNumber(this.props.dispatches.length)}</div>
            </div>
        </div>
    );
    
    return (
        <Gridview
            config={config}
            data={orders}
            loading={isFetching}
            pagination={{
                simple: true,
                onChange: this.handlePagination,
                pageSize: 10,
                total,
                current: page,
                className: 'custom-pagination',
            }}
            preHeader={preHeader} />
    );
}
