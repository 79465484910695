import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    // ----- Customer ----- //
    GET_CUSTOMERS_REQUEST: null,
    GET_CUSTOMERS_SUCCESS: null,
    GET_CUSTOMERS_FAILURE: null,

    GET_CUSTOMER_REQUEST: null,
    GET_CUSTOMER_SUCCESS: null,
    GET_CUSTOMER_FAILURE: null,

    GET_CUSTOMER_BY_REFERENCE_REQUEST: null,
    GET_CUSTOMER_BY_REFERENCE_SUCCESS: null,
    GET_CUSTOMER_BY_REFERENCE_FAILURE: null,

    UPDATE_CUSTOMER_REQUEST: null,
    UPDATE_CUSTOMER_SUCCESS: null,
    UPDATE_CUSTOMER_FAILURE: null,

    CREATEUPDATE_CUSTOMER_NOTE_REQUEST: null,
    CREATEUPDATE_CUSTOMER_NOTE_SUCCESS: null,
    CREATEUPDATE_CUSTOMER_NOTE_FAILURE: null,

    GET_CUSTOMER_NOTES_REQUEST: null,
    GET_CUSTOMER_NOTES_SUCCESS: null,
    GET_CUSTOMER_NOTES_FAILURE: null,

    UPDATE_CUSTOMER_DISPATCH_BASKET_REQUEST: null,

    // ----- Customer Practice Doctor ----- //
    GET_CUSTOMER_DOCTOR_REQUEST: null,
    GET_CUSTOMER_DOCTOR_SUCCESS: null,
    GET_CUSTOMER_DOCTOR_FAILURE: null,

    UPDATE_CUSTOMER_DOCTOR_REQUEST: null,
    UPDATE_CUSTOMER_DOCTOR_SUCCESS: null,
    UPDATE_CUSTOMER_DOCTOR_FAILURE: null,

    REMOVE_CUSTOMER_DOCTOR_REQUEST: null,
    REMOVE_CUSTOMER_DOCTOR_SUCCESS: null,
    REMOVE_CUSTOMER_DOCTOR_FAILURE: null,

    GET_CUSTOMER_PRACTICE_REQUEST: null,
    GET_CUSTOMER_PRACTICE_SUCCESS: null,
    GET_CUSTOMER_PRACTICE_FAILURE: null,

    UPDATE_CUSTOMER_PRACTICE_REQUEST: null,
    UPDATE_CUSTOMER_PRACTICE_SUCCESS: null,
    UPDATE_CUSTOMER_PRACTICE_FAILURE: null,

    REMOVE_CUSTOMER_PRACTICE_REQUEST: null,
    REMOVE_CUSTOMER_PRACTICE_SUCCESS: null,
    REMOVE_CUSTOMER_PRACTICE_FAILURE: null,

    // ----- Customer Historical Orders & Products ----- //
    GET_CUSTOMER_ORDERS_REQUEST: null,
    GET_CUSTOMER_ORDERS_SUCCESS: null,
    GET_CUSTOMER_ORDERS_FAILURE: null,

    GET_CUSTOMER_PRODUCTS_REQUEST: null,
    GET_CUSTOMER_PRODUCTS_SUCCESS: null,
    GET_CUSTOMER_PRODUCTS_FAILURE: null,

    // ----- Customer Auto Orders ----- //
    GET_CUSTOMER_AUTO_ORDERS_REQUEST: null,
    GET_CUSTOMER_AUTO_ORDERS_SUCCESS: null,
    GET_CUSTOMER_AUTO_ORDERS_FAILURE: null,

    UPDATE_CUSTOMER_AUTO_ORDER_REQUEST: null,
    UPDATE_CUSTOMER_AUTO_ORDER_SUCCESS: null,
    UPDATE_CUSTOMER_AUTO_ORDER_FAILURE: null,

    // ----- Customer Resets ----- //
    RESET_CUSTOMERS: null,
    RESET_CUSTOMER: null,

    // ----- Submit Summary Form ----- //
    SUBMIT_SUMMARY_FORM_REQUEST: null,
    SUBMIT_SUMMARY_FORM_SUCCESS: null,
    SUBMIT_SUMMARY_FORM_FAILURE: null,

    // ----- Search Customers By Attributes ----- //
    SEARCH_CUSTOMERS_BY_ATTRIBUTES_REQUEST: null,
    SEARCH_CUSTOMERS_BY_ATTRIBUTES_SUCCESS: null,
    SEARCH_CUSTOMERS_BY_ATTRIBUTES_FAILURE: null,

    // -------------------- Order -------------------- //
    // ----- Get Customer Orders By Filter ----- //
    SEARCH_CUSTOMER_ORDERS_BY_FILTERS_REQUEST: null,
    SEARCH_CUSTOMER_ORDERS_BY_FILTERS_SUCCESS: null,
    SEARCH_CUSTOMER_ORDERS_BY_FILTERS_FAILURE: null,
    // ----- Set Active Search Order Index Offline ----- //
    SET_ACTIVE_SEARCH_ORDER_ID_OFFLINE_REQUEST: null,
    SET_ACTIVE_SEARCH_ORDER_ID_OFFLINE_SUCCESS: null,
    SET_ACTIVE_SEARCH_ORDER_ID_OFFLINE_FAILURE: null,
    // ----- Update Order Request ----- //
    UPDATE_CUSTOMER_ORDER_REQUEST: null,
    UPDATE_CUSTOMER_ORDER_SUCCESS: null,
    UPDATE_CUSTOMER_ORDER_FAILURE: null,
    // ----- Update Order Search Results Offline ----- //
    UPDATE_ORDER_SEARCH_RESULTS_OFFLINE_REQUEST: null,
    UPDATE_ORDER_SEARCH_RESULTS_OFFLINE_SUCCESS: null,
    UPDATE_ORDER_SEARCH_RESULTS_OFFLINE_FAILURE: null,
    // ----- Send Picking And Dispatch Letter ----- //
    SEND_PICKING_AND_DISPATCH_LETTER_REQUEST: null,
    SEND_PICKING_AND_DISPATCH_LETTER_SUCCESS: null,
    SEND_PICKING_AND_DISPATCH_LETTER_FAILURE: null,

    // -------------------- Order Item -------------------- //
    // ----- Update Order Item ----- //
    UPDATE_ORDER_ITEM_REQUEST: null,
    UPDATE_ORDER_ITEM_SUCCESS: null,
    UPDATE_ORDER_ITEM_FAILURE: null,
    // ----- Search Customer Order Items By Filters ----- //
    SEARCH_CUSTOMER_ORDER_ITEMS_BY_FILTERS_REQUEST: null,
    SEARCH_CUSTOMER_ORDER_ITEMS_BY_FILTERS_SUCCESS: null,
    SEARCH_CUSTOMER_ORDER_ITEMS_BY_FILTERS_FAILURE: null,
    // ----- Update Order Item Search Rseults Offline ----- //
    UPDATE_ORDER_ITEM_SEARCH_RESULTS_OFFLINE_REQUEST: null,

    // -------------------- Order Item Repeat Instruction -------------------- //
    // ----- Get Order Item Repeat Instructions By Filters ----- //
    SEARCH_ORDER_ITEM_REPEAT_INSTRUCTIONS_BY_FILTERS_REQUEST: null,
    SEARCH_ORDER_ITEM_REPEAT_INSTRUCTIONS_BY_FILTERS_SUCCESS: null,
    SEARCH_ORDER_ITEM_REPEAT_INSTRUCTIONS_BY_FILTERS_FAILURE: null,
    // ----- Update Order Item Repeat Instruction ----- //
    UPDATE_ORDER_ITEM_REPEAT_INSTRUCTION_REQUEST: null,
    UPDATE_ORDER_ITEM_REPEAT_INSTRUCTION_SUCCESS: null,
    UPDATE_ORDER_ITEM_REPEAT_INSTRUCTION_FAILURE: null,
    // ----- Delete Order Item Repeat Instruction ----- //
    DELETE_ORDER_ITEM_REPEAT_INSTRUCTION_REQUEST: null,
    DELETE_ORDER_ITEM_REPEAT_INSTRUCTION_SUCCESS: null,
    DELETE_ORDER_ITEM_REPEAT_INSTRUCTION_FAILURE: null,

    // -------------------- Letter -------------------- //
    // ----- Create Letter ----- //
    CREATE_LETTER_REQUEST: null,
    CREATE_LETTER_SUCCESS: null,
    CREATE_LETTER_FAILURE: null,
    // ----- Update Letter ----- //
    UPDATE_LETTER_REQUEST: null,
    UPDATE_LETTER_SUCCESS: null,
    UPDATE_LETTER_FAILURE: null,

    // -------------------- Prescription -------------------- //
    // ----- Upload Prescription ----- //
    UPLOAD_PRESCRIPTION_REQUEST: null,
    UPLOAD_PRESCRIPTION_SUCCESS: null,
    UPLOAD_PRESCRIPTION_FAILURE: null,
    // ----- Update Prescription ----- //
    UPDATE_PRESCRIPTION_REQUEST: null,
    UPDATE_PRESCRIPTION_SUCCESS: null,
    UPDATE_PRESCRIPTION_FAILURE: null,
    // ----- Get Prescription ----- //
    GET_PRESCRIPTION_REQUEST: null,
    GET_PRESCRIPTION_SUCCESS: null,
    GET_PRESCRIPTION_FAILURE: null,
    // ----- Search Prescriptions By Filters ----- //
    SEARCH_PRESCRIPTIONS_BY_FILTERS_REQUEST: null,
    SEARCH_PRESCRIPTIONS_BY_FILTERS_SUCCESS: null,
    SEARCH_PRESCRIPTIONS_BY_FILTERS_FAILURE: null,
    // ----- CreateUpdate Prescription Item ----- //
    CREATEUPDATE_PRESCRIPTION_ITEM_REQUEST: null,
    CREATEUPDATE_PRESCRIPTION_ITEM_SUCCESS: null,
    CREATEUPDATE_PRESCRIPTION_ITEM_FAILURE: null,
    // ----- Delete Prescription Item ----- /
    DELETE_PRESCRIPTION_ITEM_REQUEST: null,
    DELETE_PRESCRIPTION_ITEM_SUCCESS: null,
    DELETE_PRESCRIPTION_ITEM_FAILURE: null,

    UPSERT_LETTERS_REQUEST: null,
    UPSERT_LETTERS_SUCCESS: null,
    UPSERT_LETTERS_FAILURE: null,

    // ----- Script Actions ----- //
    PROCESS_SCRIPT_REQUEST: null,
    PROCESS_SCRIPT_SUCCESS: null,
    PROCESS_SCRIPT_FAILURE: null,
});

// ----- Customer ----- //

export const {
    getCustomersRequest,
    getCustomersSuccess,
    getCustomersFailure
} = createActions(
    constants.GET_CUSTOMERS_REQUEST,
    constants.GET_CUSTOMERS_SUCCESS,
    constants.GET_CUSTOMERS_FAILURE
);

export const {
    getCustomerRequest,
    getCustomerSuccess,
    getCustomerFailure
} = createActions(
    constants.GET_CUSTOMER_REQUEST,
    constants.GET_CUSTOMER_SUCCESS,
    constants.GET_CUSTOMER_FAILURE
);

export const {
    getCustomerByReferenceRequest,
    getCustomerByReferenceSuccess,
    getCustomerByReferenceFailure
} = createActions(
    constants.GET_CUSTOMER_BY_REFERENCE_REQUEST,
    constants.GET_CUSTOMER_BY_REFERENCE_SUCCESS,
    constants.GET_CUSTOMER_BY_REFERENCE_FAILURE
);

export const {
    updateCustomerRequest,
    updateCustomerSuccess,
    updateCustomerFailure
} = createActions(
    constants.UPDATE_CUSTOMER_REQUEST,
    constants.UPDATE_CUSTOMER_SUCCESS,
    constants.UPDATE_CUSTOMER_FAILURE
);

export const {
    createupdateCustomerNoteRequest,
    createupdateCustomerNoteSuccess,
    createupdateCustomerNoteFailure
} = createActions(
    constants.CREATEUPDATE_CUSTOMER_NOTE_REQUEST,
    constants.CREATEUPDATE_CUSTOMER_NOTE_SUCCESS,
    constants.CREATEUPDATE_CUSTOMER_NOTE_FAILURE
);

export const {
    getCustomerNotesRequest,
    getCustomerNotesSuccess,
    getCustomerNotesFailure
} = createActions(
    constants.GET_CUSTOMER_NOTES_REQUEST,
    constants.GET_CUSTOMER_NOTES_SUCCESS,
    constants.GET_CUSTOMER_NOTES_FAILURE
);

export const {
    updateCustomerDispatchBasketRequest
} = createActions(
    constants.UPDATE_CUSTOMER_DISPATCH_BASKET_REQUEST
);

// ----- Customer Practice Doctor ----- //

export const {
    getCustomerDoctorRequest,
    getCustomerDoctorSuccess,
    getCustomerDoctorFailure
} = createActions(
    constants.GET_CUSTOMER_DOCTOR_REQUEST,
    constants.GET_CUSTOMER_DOCTOR_SUCCESS,
    constants.GET_CUSTOMER_DOCTOR_FAILURE
);

export const {
    updateCustomerDoctorRequest,
    updateCustomerDoctorSuccess,
    updateCustomerDoctorFailure
} = createActions(
    constants.UPDATE_CUSTOMER_DOCTOR_REQUEST,
    constants.UPDATE_CUSTOMER_DOCTOR_SUCCESS,
    constants.UPDATE_CUSTOMER_DOCTOR_FAILURE
);

export const {
    removeCustomerDoctorRequest,
    removeCustomerDoctorSuccess,
    removeCustomerDoctorFailure
} = createActions(
    constants.REMOVE_CUSTOMER_DOCTOR_REQUEST,
    constants.REMOVE_CUSTOMER_DOCTOR_SUCCESS,
    constants.REMOVE_CUSTOMER_DOCTOR_FAILURE
);

export const {
    getCustomerPracticeRequest,
    getCustomerPracticeSuccess,
    getCustomerPracticeFailure
} = createActions(
    constants.GET_CUSTOMER_PRACTICE_REQUEST,
    constants.GET_CUSTOMER_PRACTICE_SUCCESS,
    constants.GET_CUSTOMER_PRACTICE_FAILURE
);

export const {
    updateCustomerPracticeRequest,
    updateCustomerPracticeSuccess,
    updateCustomerPracticeFailure
} = createActions(
    constants.UPDATE_CUSTOMER_PRACTICE_REQUEST,
    constants.UPDATE_CUSTOMER_PRACTICE_SUCCESS,
    constants.UPDATE_CUSTOMER_PRACTICE_FAILURE
);

export const {
    removeCustomerPracticeRequest,
    removeCustomerPracticeSuccess,
    removeCustomerPracticeFailure
} = createActions(
    constants.REMOVE_CUSTOMER_PRACTICE_REQUEST,
    constants.REMOVE_CUSTOMER_PRACTICE_SUCCESS,
    constants.REMOVE_CUSTOMER_PRACTICE_FAILURE
);

// ----- Customer Historical Orders & Products ----- //

export const {
    getCustomerOrdersRequest,
    getCustomerOrdersSuccess,
    getCustomerOrdersFailure
} = createActions(
    constants.GET_CUSTOMER_ORDERS_REQUEST,
    constants.GET_CUSTOMER_ORDERS_SUCCESS,
    constants.GET_CUSTOMER_ORDERS_FAILURE
);

export const {
    getCustomerProductsRequest,
    getCustomerProductsSuccess,
    getCustomerProductsFailure
} = createActions(
    constants.GET_CUSTOMER_PRODUCTS_REQUEST,
    constants.GET_CUSTOMER_PRODUCTS_SUCCESS,
    constants.GET_CUSTOMER_PRODUCTS_FAILURE
);

// ----- Customer Auto Orders ----- //

export const {
    getCustomerAutoOrdersRequest,
    getCustomerAutoOrdersSuccess,
    getCustomerAutoOrdersFailure
} = createActions(
    constants.GET_CUSTOMER_AUTO_ORDERS_REQUEST,
    constants.GET_CUSTOMER_AUTO_ORDERS_SUCCESS,
    constants.GET_CUSTOMER_AUTO_ORDERS_FAILURE
);

export const {
    updateCustomerAutoOrderRequest,
    updateCustomerAutoOrderSuccess,
    updateCustomerAutoOrderFailure
} = createActions(
    constants.UPDATE_CUSTOMER_AUTO_ORDER_REQUEST,
    constants.UPDATE_CUSTOMER_AUTO_ORDER_SUCCESS,
    constants.UPDATE_CUSTOMER_AUTO_ORDER_FAILURE
);

// ----- Customer Resets ----- //

export const {
    resetCustomers,
    resetCustomer,
} = createActions(
    constants.RESET_CUSTOMERS,
    constants.RESET_CUSTOMER,
);

// ----- Submit Summary Form ----- //

export const {
    submitSummaryFormRequest,
    submitSummaryFormSuccess,
    submitSummaryFormFailure
} = createActions(
    constants.SUBMIT_SUMMARY_FORM_REQUEST,
    constants.SUBMIT_SUMMARY_FORM_SUCCESS,
    constants.SUBMIT_SUMMARY_FORM_FAILURE
);

// ----- Search Customers By Attributes ----- //

export const {
    searchCustomersByAttributesRequest,
    searchCustomersByAttributesSuccess,
    searchCustomersByAttributesFailure
} = createActions(
    constants.SEARCH_CUSTOMERS_BY_ATTRIBUTES_REQUEST,
    constants.SEARCH_CUSTOMERS_BY_ATTRIBUTES_SUCCESS,
    constants.SEARCH_CUSTOMERS_BY_ATTRIBUTES_FAILURE
);

// -------------------- Order -------------------- //
// ----- Get Customer Orders By Filter ----- //
export const {
    searchCustomerOrdersByFiltersRequest,
    searchCustomerOrdersByFiltersSuccess,
    searchCustomerOrdersByFiltersFailure
} = createActions(
    constants.SEARCH_CUSTOMER_ORDERS_BY_FILTERS_REQUEST,
    constants.SEARCH_CUSTOMER_ORDERS_BY_FILTERS_SUCCESS,
    constants.SEARCH_CUSTOMER_ORDERS_BY_FILTERS_FAILURE
);
// ----- Set Active Search Order Index Offline ----- //
export const {
    setActiveSearchOrderIdOfflineRequest,
} = createActions(
    constants.SET_ACTIVE_SEARCH_ORDER_ID_OFFLINE_REQUEST,
);
// ----- Update Order Request ----- //
export const {
    updateCustomerOrderRequest,
    updateCustomerOrderSuccess,
    updateCustomerOrderFailure
} = createActions(
    constants.UPDATE_CUSTOMER_ORDER_REQUEST,
    constants.UPDATE_CUSTOMER_ORDER_SUCCESS,
    constants.UPDATE_CUSTOMER_ORDER_FAILURE
);
// ----- Update Order Search Results Offline ----- //
export const {
    updateOrderSearchResultsOfflineRequest,
} = createActions(
    constants.UPDATE_ORDER_SEARCH_RESULTS_OFFLINE_REQUEST,
);
// ----- Send Picking And Dispatch Letter ----- //
export const {
    sendPickingAndDispatchLetterRequest,
    sendPickingAndDispatchLetterSuccess,
    sendPickingAndDispatchLetterFailure
} = createActions(
    constants.SEND_PICKING_AND_DISPATCH_LETTER_REQUEST,
    constants.SEND_PICKING_AND_DISPATCH_LETTER_SUCCESS,
    constants.SEND_PICKING_AND_DISPATCH_LETTER_FAILURE
);

// -------------------- Order Item -------------------- //
// ----- Update Order Item ----- //
export const {
    updateOrderItemRequest,
    updateOrderItemSuccess,
    updateOrderItemFailure
} = createActions(
    constants.UPDATE_ORDER_ITEM_REQUEST,
    constants.UPDATE_ORDER_ITEM_SUCCESS,
    constants.UPDATE_ORDER_ITEM_FAILURE
);
// ----- Search Customer Order Items By Filters ----- //
export const {
    searchCustomerOrderItemsByFiltersRequest,
    searchCustomerOrderItemsByFiltersSuccess,
    searchCustomerOrderItemsByFiltersFailure
} = createActions(
    constants.SEARCH_CUSTOMER_ORDER_ITEMS_BY_FILTERS_REQUEST,
    constants.SEARCH_CUSTOMER_ORDER_ITEMS_BY_FILTERS_SUCCESS,
    constants.SEARCH_CUSTOMER_ORDER_ITEMS_BY_FILTERS_FAILURE
);
// ----- Update Order Item Search Rseults Offline ----- //
export const {
    updateOrderItemSearchResultsOfflineRequest,
} = createActions(
    constants.UPDATE_ORDER_ITEM_SEARCH_RESULTS_OFFLINE_REQUEST,
);

// -------------------- Order Item Repeat Instruction -------------------- //
// ----- Get Order Item Repeat Instructions By Filters ----- //
export const {
    searchOrderItemRepeatInstructionsByFiltersRequest,
    searchOrderItemRepeatInstructionsByFiltersSuccess,
    searchOrderItemRepeatInstructionsByFiltersFailure
} = createActions(
    constants.SEARCH_ORDER_ITEM_REPEAT_INSTRUCTIONS_BY_FILTERS_REQUEST,
    constants.SEARCH_ORDER_ITEM_REPEAT_INSTRUCTIONS_BY_FILTERS_SUCCESS,
    constants.SEARCH_ORDER_ITEM_REPEAT_INSTRUCTIONS_BY_FILTERS_FAILURE
);
// ----- Update Order Item Repeat Instruction ----- //
export const {
    updateOrderItemRepeatInstructionRequest,
    updateOrderItemRepeatInstructionSuccess,
    updateOrderItemRepeatInstructionFailure
} = createActions(
    constants.UPDATE_ORDER_ITEM_REPEAT_INSTRUCTION_REQUEST,
    constants.UPDATE_ORDER_ITEM_REPEAT_INSTRUCTION_SUCCESS,
    constants.UPDATE_ORDER_ITEM_REPEAT_INSTRUCTION_FAILURE
);
// ----- Delete Order Item Repeat Instruction ----- //
export const {
    deleteOrderItemRepeatInstructionRequest,
    deleteOrderItemRepeatInstructionSuccess,
    deleteOrderItemRepeatInstructionFailure
} = createActions(
    constants.DELETE_ORDER_ITEM_REPEAT_INSTRUCTION_REQUEST,
    constants.DELETE_ORDER_ITEM_REPEAT_INSTRUCTION_SUCCESS,
    constants.DELETE_ORDER_ITEM_REPEAT_INSTRUCTION_FAILURE
);

// -------------------- Letter -------------------- //
// ----- Create Letter ----- //
export const {
    createLetterRequest,
    createLetterSuccess,
    createLetterFailure
} = createActions(
    constants.CREATE_LETTER_REQUEST,
    constants.CREATE_LETTER_SUCCESS,
    constants.CREATE_LETTER_FAILURE
);
// ----- Update Letter ----- //
export const {
    updateLetterRequest,
    updateLetterSuccess,
    updateLetterFailure
} = createActions(
    constants.UPDATE_LETTER_REQUEST,
    constants.UPDATE_LETTER_SUCCESS,
    constants.UPDATE_LETTER_FAILURE
);

// -------------------- Prescription -------------------- //
// ----- Upload Prescription ----- //
export const {
    uploadPrescriptionRequest,
    uploadPrescriptionSuccess,
    uploadPrescriptionFailure
} = createActions(
    constants.UPLOAD_PRESCRIPTION_REQUEST,
    constants.UPLOAD_PRESCRIPTION_SUCCESS,
    constants.UPLOAD_PRESCRIPTION_FAILURE
);
// ----- Update Prescription ----- //
export const {
    updatePrescriptionRequest,
    updatePrescriptionSuccess,
    updatePrescriptionFailure
} = createActions(
    constants.UPDATE_PRESCRIPTION_REQUEST,
    constants.UPDATE_PRESCRIPTION_SUCCESS,
    constants.UPDATE_PRESCRIPTION_FAILURE
);
// ----- Get Prescription ----- //
export const {
    getPrescriptionRequest,
    getPrescriptionSuccess,
    getPrescriptionFailure
} = createActions(
    constants.GET_PRESCRIPTION_REQUEST,
    constants.GET_PRESCRIPTION_SUCCESS,
    constants.GET_PRESCRIPTION_FAILURE
);
// ----- Search Prescriptions By Filters ----- //
export const {
    searchPrescriptionsByFiltersRequest,
    searchPrescriptionsByFiltersSuccess,
    searchPrescriptionsByFiltersFailure
} = createActions(
    constants.SEARCH_PRESCRIPTIONS_BY_FILTERS_REQUEST,
    constants.SEARCH_PRESCRIPTIONS_BY_FILTERS_SUCCESS,
    constants.SEARCH_PRESCRIPTIONS_BY_FILTERS_FAILURE
);
// ----- CreateUpdate Prescription Item ----- //
export const {
    createupdatePrescriptionItemRequest,
    createupdatePrescriptionItemSuccess,
    createupdatePrescriptionItemFailure
} = createActions(
    constants.CREATEUPDATE_PRESCRIPTION_ITEM_REQUEST,
    constants.CREATEUPDATE_PRESCRIPTION_ITEM_SUCCESS,
    constants.CREATEUPDATE_PRESCRIPTION_ITEM_FAILURE
);
// ----- Delete Prescription Item ----- //
export const {
    deletePrescriptionItemRequest,
    deletePrescriptionItemSuccess,
    deletePrescriptionItemFailure
} = createActions(
    constants.DELETE_PRESCRIPTION_ITEM_REQUEST,
    constants.DELETE_PRESCRIPTION_ITEM_SUCCESS,
    constants.DELETE_PRESCRIPTION_ITEM_FAILURE
);

export const {
    upsertLettersRequest,
    upsertLettersSuccess,
    upsertLettersFailure,
} = createActions(
    constants.UPSERT_LETTERS_REQUEST,
    constants.UPSERT_LETTERS_SUCCESS,
    constants.UPSERT_LETTERS_FAILURE,
);

// ----- Script Actions ----- //

export const {
    processScriptRequest,
    processScriptSuccess,
    processScriptFailure
} = createActions(
    constants.PROCESS_SCRIPT_REQUEST,
    constants.PROCESS_SCRIPT_SUCCESS,
    constants.PROCESS_SCRIPT_FAILURE
);

