import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { Button, Modal } from 'antd';
import Webcam from 'react-webcam';

import styles from './styles.js';

class ImageCapture extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.webcamRef = React.createRef();
    }

    handleCapture() {
        const { onCapture, onClose } = this.props;

        const base64Image = this.webcamRef.current.getScreenshot();

        onCapture(base64Image);
        onClose();
    }

    render() {
        const { isActive, onClose } = this.props;

        return (
            <Modal
                onCancel={() => onClose()}
                visible={isActive}
                className="image_capture_modal"
                footer={<div
                    style={styles.footer}>
                    <Button
                        onClick={() => this.handleCapture()}
                        style={{ ...styles.searchBarButton, ...styles.text2, color: 'white', }}>Capture</Button>
                </div>}>
                {
                    isActive &&
                    <Webcam
                        ref={this.webcamRef}
                        screenshotFormat="image/jpeg"
                        width={'100%'}
                        height={'100%'} />
                }
            </Modal>
        );
    }
}

ImageCapture.propTypes = {
    actions: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onCapture: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ submit }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageCapture);
