import React from 'react';
import Helmet from 'react-helmet';

import Breadcrumb from '../Breadcrumb';
import Header from '../Header';
import RepeatOrderItemFailureBanner from '../RepeatOrderItemFailureBanner';

import './layout.css';
import icon from '../../../assets/favicon.ico';

export default function () {
    const { children, title, type, toolbar, button, breadcrumb, } = this.props;

    return (
        <div className={`page-wrap ${type}`}>
            <RepeatOrderItemFailureBanner />

            <Helmet>
                <title>{title}</title>
                <link rel="shortcut icon" href={icon} />
            </Helmet>

            {type !== 'login' ?
                <div>
                    <Header title={title} toolbar={toolbar} backlink={button} />
                    <Breadcrumb breadcrumb={breadcrumb} />
                </div>
                : false}

            {children}
        </div>
    );
}
