import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Button, Form, Input, Row, Col } from 'antd';

const FormItem = Form.Item;

const UpdateStock = ({ changeVisible, handleInputChange, handleSubmit, newStockCount, stockCount, stockCountUpdated, visible }) => (
    <Modal centered width={540} footer={null} visible={visible} onOk={() => changeVisible(false)} onCancel={() => changeVisible(false)}>
        <div className="text-center">
            <h4 className="h-4-regular">Update stock levels</h4>
        </div>

        <div className="mt-2">
            <Row type="flex" justify="center">
                <Col span={24} md={20}>
                    <Form layout="vertical">
                        <div className="stock">

                            <div className="stock-row">
                                <h6 className="h-6 mr-1">
                                    <span className="mr-1">Current stock count</span>
                                    <span className="italic-grey fz-small">(last updated {stockCountUpdated})</span>
                                </h6>
                                <div className="stock-row__right">
                                    <span>{stockCount}</span>
                                </div>
                            </div>

                            <div className="stock-row">
                                <h6 className="h-6 mr-1">
                                    <span>New stock count</span>
                                </h6>
                                <div className="stock-row__right">
                                    <FormItem><Input type="text" style={{ width: 88 }} onChange={handleInputChange} value={newStockCount} /></FormItem>
                                </div>
                            </div>

                        </div>

                        <p className="mt-2" style={{ maxWidth: 344, textAlign: 'center', color: '#000', margin: '0 auto', fontSize: 12, fontStyle: 'italic', lineHeight: '1.5' }}>
                            Changing the stock levels here or uploading a new stock-level CSV will reset the «issued since last stock export» to 0.
                        </p>

                        <div className="text-center">
                            <Button type="primary" className="wide" onClick={handleSubmit}>Agree and update</Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>
    </Modal>
);

UpdateStock.propTypes = {
    changeVisible: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    newStockCount: PropTypes.string.isRequired,
    stockCount: PropTypes.number.isRequired,
    stockCountUpdated: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
};

export default UpdateStock;
