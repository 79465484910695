import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import DOM from './doctorsCustomer';

import * as customerActions from '../../../core/customer/customerActions';

class DoctorsCustomer extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        const { actions, customerId } = this.props;
        if (customerId) {
            actions.getCustomerDoctorRequest({ id: customerId });
        }
    }

    // Handle remove doctor associated with customer
    handleRemoveDoctor = () => {
        const { actions, customerId } = this.props;
        actions.removeCustomerDoctorRequest(customerId);
    }

    render() {
        return this.view();
    }
}

DoctorsCustomer.propTypes = {
    actions: PropTypes.object.isRequired,
    customerId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        doctor: state.customer.doctor,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorsCustomer);
