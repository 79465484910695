import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Row, Col } from 'antd';

import AddCustomerReferrer from '../../forms/AddCustomerReferrer';

const AddCustomerReferrerModal = ({ referrerId, visible, changeVisible }) => (
    <Modal centered width={540} footer={null} visible={visible} onOk={() => changeVisible(false)} onCancel={() => changeVisible(false)}>
        <div className="text-center">
            <h4 className="h-4">Add customer to referrer</h4>
        </div>
        <div className="mt-2">
            <Row type="flex" justify="center">
                <Col span={24} md={20}>
                    <AddCustomerReferrer referrerId={referrerId} changeVisible={changeVisible} />
                </Col>
            </Row>
        </div>
    </Modal>
);

AddCustomerReferrerModal.propTypes = {
    changeVisible: PropTypes.func.isRequired,
    referrerId: PropTypes.number.isRequired,
    visible: PropTypes.bool.isRequired,
};

export default AddCustomerReferrerModal;
