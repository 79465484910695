import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Button } from 'antd';

import warningSrc from '../../../assets/images/warning.png';

const LeaveUpdateOrder = ({ visible, changeVisible }) => (
    <Modal centered width={540} footer={null} visible={visible} onOk={() => changeVisible(false)} onCancel={() => changeVisible(false)}>

        <div className="text-center">
            <img src={warningSrc} alt="Warning" width={66} height={58} />
            <p className="mt-3">Leaving the page will lose all unsaved changes.</p>
            <p className="mt-2 text-bold">Are you sure you want to leave the page?</p>
        </div>

        <div className="form-wrap">
            <div className="btn-bar justify-content-center">
                <Button href="/orders" type="default" className="medium">Leave page</Button>
                <Button
                    type="default"
                    className="medium"
                    onClick={() => {
                        changeVisible(false);
                    }}>
                    Stay on current page
                </Button>
            </div>
        </div>
    </Modal>
);

LeaveUpdateOrder.propTypes = {
    visible: PropTypes.bool.isRequired,
    changeVisible: PropTypes.func.isRequired,
};

export default LeaveUpdateOrder;
